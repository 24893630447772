import React from 'react'
import moment from 'moment'

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const PpcSemanalNotification = (props: Props) => {

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>{props.data.title}</span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        El Porcentaje de Plan Completado{' '}
                        <strong>(PPC)</strong>{' '}
                        del proyecto <strong>{props.data.project.shortName}</strong>{' '}
                        de la semana {props.data.projectWeek}{' '}
                        del {moment().week(props.data.week).startOf('week').format('DD/MM/YYYY')}{' '}
                        al {moment().week(props.data.week).endOf('week').format('DD/MM/YYYY')}{' '}
                        es del {props.data.ppcPercent}%
                    </span>
                </p>
            </div>
        </div>
    )
}

export default PpcSemanalNotification