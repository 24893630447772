
import React from "react";

type Props = {
  top: number;
  height: number;
  width: number;
  isActive: boolean;
  needsConfirmation: boolean;
  onItemHover: () => void;
}

const GanttChartBodyGridRow = (props: Props) => {

  const getClassName = () => {

    let className = 'grid-row-bg'

    if (props.isActive) {

      className += ' active'
    }

    if (props.needsConfirmation) {

      className += ' needs-confirmation'
    }

    return className
  }

  return (
    <g id="chart-grid-row-item"
      onMouseOver={props.onItemHover}
    >
      <rect
        className={getClassName()}
        x={0}
        y={0}
        height={0}
        width={props.width}
        style={{
          transform: `translateY(${props.top}px)`,
          height: props.height - 1
        }}

      ></rect>
      <line
        className="grid-row-line"
        x1={0}
        y1={0}
        x2={props.width}
        y2={0}
        style={{ transform: `translateY(${(props.top + props.height - 1)}px)` }}
      />
    </g>
  )
}

export default GanttChartBodyGridRow
