
const moment = require('moment')

export const getYMDFromDate = (date: Date): string => {

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const getYearWeekFromDate = (date: Date) => {
    const year = date.getFullYear();
    const firstDayOfYear = new Date(year, 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const week = Math.ceil((date.getTime() - firstDayOfYear.getTime()) / millisecondsPerWeek);
    return `${year}${week}`;
}

export const getWeekNumber = (date: Date): number => {

    const weekNumber = moment.utc(date).format('YYYYWW')

    return Number(weekNumber)
}

export const dateToYYYYWW = (date: Date): string => {

    // retrieve the year as YYYY plus the week number as WW
    const year = date.getFullYear()
    const week = getWeekNumber(date)

    return `${year}${week.toString().padStart(2, '0')}`
}

export const getDiffDays = (dateA: string, dateB: string, nonWorkingDays: any) => {

    if (!dateA || !dateB) return 0

    const partsA = dateA.split('-')
    const partsB = dateB.split('-')

    const day = new Date()
    day.setDate(Number(partsA[2]))
    day.setMonth(Number(partsA[1]) - 1)
    day.setFullYear(Number(partsA[0]))

    const end = new Date()
    end.setDate(Number(partsB[2]))
    end.setMonth(Number(partsB[1]) - 1)
    end.setFullYear(Number(partsB[0]))

    if (day > end) return 0

    let days = 0

    while (day <= end) {
        const formattedDay = getYMDFromDate(day)

        if (!nonWorkingDays.includes(formattedDay)) {
            days += 1
        }

        day.setDate(day.getDate() + 1)
    }

    return days
}

export const getNonWorkingDaysFromRange = (dateA: string, dateB: string, project: any) => {

    let nonWorkingDays: any = []

    nonWorkingDays = [...project.nonWorkingDays]

    const weekDays: any = {
        lunes: project.settings.workMonday,
        martes: project.settings.workTuesday,
        miercoles: project.settings.workWednesday,
        jueves: project.settings.workThursday,
        viernes: project.settings.workFriday,
        sábado: project.settings.workSaturday,
        domingo: project.settings.workSunday,
    }

    if (!dateA || !dateB) return 0

    const A = moment(dateA)
    const B = moment(dateB)

    project.timeExtensions.map((extension: any) => {

        B.add(extension, 'days')
    })

    const diff = B.diff(A.subtract(1, 'day'), 'days')

    const C = moment(dateA)

    for (let index = 0; index < diff; index++) {

        const dayName = C.format("dddd").toLowerCase()

        if (!weekDays[dayName] && !nonWorkingDays.includes(C.toISOString())) {

            nonWorkingDays.push(C.toISOString())
        }

        C.add(1, 'days')
    }

    return nonWorkingDays
}

export const getExpectedEndDay = (task: any, nonWorkingDays: any, stretchUpToday: boolean = false) => {

    const startDate = moment(task.startedAt.split("T")[0].split(" ")[0])

    const daysToAdd = task.executeDays - 1

    if (daysToAdd === 0) {

        return startDate
    }

    let daysAdded = 0

    while(daysAdded < daysToAdd) {

        startDate.add(1, 'days')

        const isIncluded = nonWorkingDays.find((day: any) => moment(day.split("T")[0]).isSame(startDate, 'day'))

        if (!isIncluded) {

            daysAdded += 1
        }
    }

    if (stretchUpToday) {

        return startDate.isSameOrBefore(moment()) ? moment() : startDate
    }

    return startDate
}

export const addWorkableDays = (date: string, days: number, nonWorkingDays: string[]) => {

    const dayDate = moment(date)

    while(days > 0) {

        dayDate.add(1, 'day')

        if (!nonWorkingDays.includes(dayDate.format('YYYY-MM-DD'))) {

            days -= 1
        }
    }

    return dayDate.format('YYYY-MM-DD')
}

export const aIsSameOrAfterB = (date1: string, date2: string) => {
     // Convert the date strings to Date objects
     const d1 = new Date(date1);
     const d2 = new Date(date2);

     // Compare the dates
     return d1.getTime() >= d2.getTime();
}

export const aIsSameOrBeforeB = (date1: string, date2: string) => {

    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Compare the dates
    return d2.getTime() >= d1.getTime();
}

export const aIsBeforeB = (date1: string, date2: string) => {

    const d1 = new Date(date1);
    d1.setHours(0, 0, 0)
    const d2 = new Date(date2);
    d2.setHours(0, 0, 0)

    // Compare the dates
    return d1.getTime() < d2.getTime();
}

export const aIsAfterB = (date1: string, date2: string) => {

    const d1 = new Date(date1);
    d1.setHours(0, 0, 0)
    const d2 = new Date(date2);
    d2.setHours(0, 0, 0)

    // Compare the dates
    return d1.getTime() > d2.getTime();
}

export default {
    getDiffDays,
    getNonWorkingDaysFromWeek: getNonWorkingDaysFromRange,
}
