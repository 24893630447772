
import React from 'react'

import PCard from '../PCard/PCard'
import TaskCard from '../TaskCard/TaskCard'

type Props = {
    height: number,
    columns: any,
    onSelectTask: any,
}

const Kanban = (props: Props) => {

    return (
        <div className='table-responsive custom-scrollbar'
            style={{
                margin: '-1rem -2rem',
                padding: '1rem 2rem',
                width: 'calc(100% + 4rem)'
            }}
        >
            <div className="kanban" style={{
                height: props.height,
            }}>
                {props.columns.map((column: any, i: number) => (
                    <div className="kanban-column" key={i}>
                        <PCard
                            icon={column.icon}
                            title={column.title}
                            className={column.className}
                        >
                            <div className="kanban-list custom-scrollbar"
                                style={{
                                    height: props.height - 57,
                                    padding: '0.3rem',
                                    width: 325
                                }}
                            >
                                {column.tasks.map((task: any) => (
                                    <TaskCard key={task._id}
                                        task={task}
                                        responsibles={task.responsibles}
                                        onClick={() => props.onSelectTask(task._id)}
                                    />
                                ))}
                            </div>
                        </PCard>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Kanban
