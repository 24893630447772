
import React from "react";
import InputSearchControl from "../../../../../Components/InputSearchControl/InputSearchControl";
import { getUsers } from "../../../../../Services/AdminUsersService";

import config from "../../../../../config";

import './UsersSearch.scss'

interface UsersSearchProps {
  selected?: any
  onSelect: (user: any) => void
}

const UsersSearch = (props: UsersSearchProps) => {

  const [ criteria, setCriteria ] = React.useState('')

  const [ users, setUsers ] = React.useState([])

  const handleSearch = async (value: string) => {

    setCriteria(value)

    if (value === '') {
      return
    }

    const { data, error } = await getUsers(value, 0, 10, true)

    if (error) {
      console.log(error)
      return
    }

    setUsers(data)
  }

  const handleSelect = (user: any) => {

    setCriteria('')
    setUsers([])
    props.onSelect(user)
  }

  return (
    <div className="users-search">
      {props.selected && (
        <InputSearchControl
          value={props.selected.name + ' ' + props.selected.surname}
          icon="cancelar"
          onIconClick={() => props.onSelect(null)}
          readOnly
        />
      )}
      {!props.selected && (
        <>
          <InputSearchControl
            value={criteria}
            placeholder='Buscar...'
            onChange={(value) => handleSearch(value)}
          />
          <div className="users-list">
            {
              users.map((user: any) => (
                <div
                  key={user._id}
                  className="user-list-item"
                  onClick={() => handleSelect(user)}
                >
                  <div className="user-avatar">
                    <img src={config.apiHost + '/' + user.avatar} alt={user.name} className="rounded-circle" />
                  </div>
                  <div className="user-name">
                    {user.name} {user.surname}
                  </div>
                </div>
              ))
            }
          </div>
        </>
      )}
    </div>
  )
}

export default UsersSearch;
