
import { Dispatch } from "redux";
import { createGroup, deleteGroup, importFromExcel, loadMacro, moveItem, updateGroup } from "../../Services/MacroService";

export const cleanMacro = () => {

  return {
    type: 'MACRO_CLEANUP'
  }
}

export const setMacroAction = (result: any, start: string, end: string, collapsedGroups: string[]) => {

  return {
    type: 'MACRO_LOAD_SUCCESS',
    payload: {
      result,
      start,
      end,
      collapsedGroups,
    }
  }
}

export const setMacroCollapsedGroupsAction = (collapsedGroups: string[]) => {

  return {
    type: 'MACRO_SET_COLLAPSED_GROUPS',
    payload: {
      collapsedGroups,
    }
  }
}

export const setMacroGanttItems = (ganttItems: any[]) => {

  return {
    type: 'MACRO_SET_GANTT_ITEMS',
    payload: {
      ganttItems
    }
  }
}

export const createGroupAction = (project_id: string, group_data: any) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'PROGRAMMATION_LOAD_START'
    })

    const { error, data: { task_list } } = await createGroup(project_id, group_data)

    if (error) {

      dispatch({
        type: 'MACRO_LOAD_ERROR',
        error
      })
      return false
    }

    dispatch({
      type: 'PROGRAMMATION_UPDATE_LIST',
      payload: {
        list: task_list.list
      }
    })
    dispatch({
      type: 'MACRO_LOAD_NONE'
    })

    return true
  }
}

export const updateGroupAction = (project_id: string, group_data: any) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'PROGRAMMATION_LOAD_START'
    })

    const { error, data: { task_list } } = await updateGroup(project_id, group_data)

    if (error) {

      dispatch({
        type: 'MACRO_LOAD_ERROR',
        error
      })
      return false
    }

    dispatch({
      type: 'PROGRAMMATION_UPDATE_LIST',
      payload: {
        list: task_list.list
      }
    })
    dispatch({
      type: 'MACRO_LOAD_NONE'
    })

    return true
  }
}

export const deleteGroupAction = (project_id: string, group_id: string) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'PROGRAMMATION_LOAD_START'
    })

    const res = await deleteGroup(project_id, group_id)

    if (res.error) {

      dispatch({
        type: 'MACRO_LOAD_ERROR',
        error: res.error
      })
      return false
    }

    const { task_list, deleted_tasks_ids } = res.data

    dispatch({
      type: 'PROGRAMMATION_DELETE_GROUP',
      payload: {
        list: task_list.list,
        deleted_tasks_ids,
      }
    })
    dispatch({
      type: 'MACRO_LOAD_NONE'
    })
  }
}

export const moveItemAction = (project_id: string, action: any) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'PROGRAMMATION_LOAD_START'
    })

    const { error, data: { task_list} } = await moveItem(project_id, action)

    if (error) {

      dispatch({
        type: 'MACRO_LOAD_ERROR',
        error
      })
      return false
    }

    dispatch({
      type: 'PROGRAMMATION_UPDATE_LIST',
      payload: {
        list: task_list.list
      }
    })
    dispatch({
      type: 'MACRO_LOAD_NONE'
    })

    return true
  }
}

export const importFromExcelAction = (project_id: string, excelData: any) => {

  return async () => {

    const { error } = await importFromExcel(project_id, excelData)

    if (error) return false

    return true
  }
}
