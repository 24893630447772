
import React from 'react'

import UserAvatar from '../UserAvatar/UserAvatar'

import './TaskCard.scss'

const moment = require('moment')

type Props = {
    task: any,
    responsibles: any,

    onClick: any
}

const TaskCard = (props: Props) => {

    const { all, toDo, done } = props.task.restrictions.reduce((acc: any, restriction: any) => {

        if (restriction.checked) {

          acc.done += 1
        }
        acc.toDo += 1

        return acc
      }, { done: 0, toDo: 0, all: props.task.restrictions })

    const getPreviewTaskDates = () => {

        const startsAt = moment(props.task.startsAt, 'YYYY-MM-DD').format('DD-MM-YY')
        const endsAt = moment(props.task.endsAt, 'YYYY-MM-DD').format('DD-MM-YY')

        return startsAt + ' al ' + endsAt
    }

    const getPreviewRestrictionsList = () => {

        return (
            <div className="task-restrictions">
                <ul>
                    {all.map((restriction: any, i: number) => {

                        if (i === 2) {

                            return '...'
                        } else if (i >= 3) {

                            return null
                        } else {

                            return (
                                <li key={i} className={`${!restriction.checked ? '' : 'cleared'}`}>
                                    {i === 2 ? '...' : restriction.description}
                                </li>
                            )
                        }
                    })}
                </ul>
                {toDo === 0 ? null : (
                    <div>
                        {`(${done}/${toDo})`}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={`kanban-task status-${props.task.status || 'activa'} mb-2`}
            onClick={() => props.onClick()}
        >
            <div style={{
                display: 'flex',
            }}>
                <div className="task-name">{props.task.name}</div>
                <div className="task-position">
                    {props.task.position}
                </div>
            </div>
            <div className="task-dates">
                {getPreviewTaskDates()}
                <span className="task-date-days text-center">
                    {props.task.executeDays} días
                </span>
            </div>
            {getPreviewRestrictionsList()}
            <div>
                <div className={`task-status-name task-status-${props.task.status || 'activa'} float-left`}>
                    {props.task.status}
                </div>
                <div className="task-responsibles-avatars text-right">
                    {props.responsibles.map((responsible: any, i: number) => (
                        <UserAvatar key={i} user={responsible} size="sm" />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TaskCard
