import { NONAME } from "dns"

const initialState = {
    loadResultStatus: 'none',
    createRowStatus: 'none',
    updateRowStatus: 'none',
    result: [],
}

const sortOrder = [
    'obra_construccion',
    'diseno_oficina_tecnica_de_obra',
    'administracion',
    'logistica',
    'condiciones_externas',
]

const ProjectCNCReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === 'PROJECT_CNC_RESET') {
        st.loadResultStatus = 'none'
        st.createRowStatus = 'none'
        st.updateRowStatus = 'none'
        st.result = []
    }else if (action.type === 'PROJECT_CNC_LOAD_RESULT_START') {
        st.loadResultStatus = 'loading'
        st.result = []
    }else if (action.type === 'PROJECT_CNC_LOAD_RESULT_END') {
        st.loadResultStatus = 'loaded'

        const result = action.content.data.sort((a: any, b: any) => {

            if (a.cnc.type == b.cnc.type) {

                return a.cnc.code - b.cnc.code || a.cnc.type.localeCompare(b.cnc.type);
            }else{

                return sortOrder.indexOf(a.cnc.type) - sortOrder.indexOf(b.cnc.type);
            }
        })

        // const result = action.content.data.sort((a: any, b: any) => a.cnc.code - b.cnc.code)

        st.result = result
    } else if (action.type === 'PROJECT_CNC_LOAD_RESULT_ERROR') {
        st.loadResultStatus = action.content.error
    } else if (action.type === 'PROJECT_CNC_CREATE_ROW_START') {
        st.createRowStatus = 'creating'
    }else if (action.type === 'PROJECT_CNC_CREATE_ROW_END') {
        st.createRowStatus = 'created'
    } else if (action.type === 'PROJECT_CNC_CREATE_ROW_ERROR') {
        st.createRowStatus = action.content.error
    } else if (action.type === 'PROJECT_CNC_UPDATE_ROW_START') {
        st.updateRowStatus = 'updating'
    }else if (action.type === 'PROJECT_CNC_UPDATE_ROW_END') {
        st.updateRowStatus = 'updated'
    } else if (action.type === 'PROJECT_CNC_UPDATE_ROW_ERROR') {
        st.updateRowStatus = action.content.error
    }

    return st

}

export default ProjectCNCReducer