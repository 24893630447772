import React, { useEffect, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal";
import InputFormControl from "../InputFormControl/InputFormControl";
import InputMaskedControl from "../InputMaskedControl/InputMaskedControl";

const ProjectCreator = (props: any) => {
  const [state, setState] = useState({
    canCreate: false,
    project: {
      name: "",
      shortName: "",
      address: "",
      cost: "",
      startsAt: "",
      endsAt: "",
      lookahead: 4,
      method: "Gantt",
    },
  });

  const [titleFocused, setTitleFocused] = useState(false);

  const handleChange = (field: string, value: any) => {
    const project: any = state.project;

    let canCreate = state.canCreate;

    if (field === "cost") {
      const cost = parseInt(value, 10);

      if (value === "" || value === "0" || isNaN(cost)) {
        canCreate = false;
      } else {
        canCreate = true;
      }
    }

    project[field] = value;

    setState({
      ...state,
      project,
      canCreate,
    });
  };

  const validate = () => {
    const project: any = state.project;

    if (project.name === "") {
      alert("Ingresa el nombre completo del proyecto");
      return false;
    }

    if (project.shortName === "") {
      alert("Ingresa el nombre corto del proyecto");
      return false;
    }

    if (project.cost === "" || project.cost === "0") {
      alert("Ingresa el costo del proyecto");
      return false;
    }

    const cost = parseInt(project.cost, 10);

    if (isNaN(cost)) {
      alert("Ingresa un costo válido");
      return false;
    }

    if (project.startsAt === "") {
      alert("Selecciona la fecha inicial");
      return false;
    }

    if (project.endsAt === "") {
      alert("Selecciona la fecha final");
      return false;
    }

    return true;
  };

  const saveProject = () => {
    if (!validate()) {
      return;
    }

    props.onSave(state.project);
  };

  useEffect(() => {
    if (!titleFocused) {
      setTitleFocused(true);
    }
  }, [props.show]);

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={true}
        onEntered={() => document.getElementById("subjectInput")?.focus()}>
        <Modal.Header>
          <Modal.Title>Nuevo proyecto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-md-8 mb-3">
              <label htmlFor="name">Nombre completo</label>
              <InputFormControl
                placeholder="Nombre completo del proyecto"
                value={state.project.name}
                onChange={(value: string) => handleChange("name", value)}
                onBlur={() => setTitleFocused(false)}
                readOnly={false}
                autoFocus={true}
                id="subjectInput"
              />
            </div>
            <div className="form-group col-md-4 mb-3">
              <label htmlFor="short-name">Nombre corto</label>
              <InputFormControl
                id="short-name"
                placeholder="Nombre corto del proyecto"
                value={state.project.shortName}
                onChange={(value: string) => handleChange("shortName", value)}
              />
            </div>
            <div className="form-group col-md-12 mb-3">
              <label htmlFor="address">Dirección</label>
              <InputFormControl
                id="address"
                placeholder="Dirección del proyecto"
                value={state.project.address}
                onChange={(value: string) => handleChange("address", value)}
              />
            </div>
            <div className="form-group col-md-3 mb-3">
              <label htmlFor="cost">Costo</label>
              <InputMaskedControl
                id="cost"
                placeholder="Costo del proyecto"
                value={state.project.cost}
                onChange={(e: any) => handleChange("cost", e.target.value)}
              />
            </div>
            <div className="col-md-3 form-group mb-3">
              <label htmlFor="startsAt">Inicio</label>
              <InputFormControl
                type="date"
                id="startsAt"
                placeholder="Inicio del proyecto"
                value={state.project.startsAt}
                onChange={(value: string) => handleChange("startsAt", value)}
              />
            </div>
            <div className="col-md-3 form-group mb-3">
              <label htmlFor="fin">Fin</label>
              <InputFormControl
                type="date"
                id="endsAt"
                placeholder="Fin del proyecto"
                value={state.project.endsAt}
                onChange={(value: string) => handleChange("endsAt", value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => props.onHide()}>
            <span className="icon-cancelar"></span> Cancelar
          </button>
          &nbsp;
          <button
            className="btn btn-raised btn-link active"
            onClick={() => saveProject()}
            disabled={!state.canCreate}>
            <span className="icon-guardar"></span> Crear
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectCreator;
