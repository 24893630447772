import { Dispatch } from "redux"
import { getProgrammedAndRealProgressData } from "../../Services/ProjectDashboardService"

export const cleanDashboardAction = () => {

  return {
    type: 'DASHBOARD_CLEANUP'
  }
}

export const setDashboardDelayDataAction = (delayData: any) => {

  return {
    type: 'DASHBOARD_SET_DELAY_DATA',
    payload: {
      delayData,
    }
  }
}

export const setDashboardProgressDataAction = (weeks: any[]) => {

  return {
    type: 'DASHBOARD_SET_PROGRESS_DATA',
    payload: {
      progressData: weeks.map((week: any) => {

        return {
          name: week.name,
          'Programado': week.programmed,
          'Real': week.real
        }
      })
    }
  }
}
