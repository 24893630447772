
const showLoader = () => {

    return {
        type: 'INTERFACE_SHOW_LOADER'
    }
}

const hideLoader = () => {

    return {
        type: 'INTERFACE_HIDE_LOADER'
    }
}

const setDimension = (key: string, value: number) => {

    return {
        type : 'INTERFACE_SET_HEIGHT',
        payload: {
            key,
            value
        }
    }
}

export default {
    showLoader,
    hideLoader,
    setDimension,
}
