import moment from "moment";


const getWeeksRange = (currentWeek: string, weeksSize: number) => {

  const range: number[] = []

  for(let i = 0; i < weeksSize; i++) {

    const week = Number(moment(currentWeek, 'YYYYWW').add(i, 'w').format('YYYYWW'))

    range.push(week)
  }

  return range
}
/**
 * Get the week navigator for lookahead
 * @param projectStart The start date of the project format utc
 * @param projectEnd The end date of the project format utc
 * @param week The current week of the project format YYYYWW
 * @returns The week navigator in an object with previous, next and current week
 */
export const getWeekNavigator = (projectStart: string, projectEnd: string, weeksSize: number, week?: string) => {

  const start = moment(projectStart);
  const end = moment(projectEnd);

  const startWeek = Number(start.format('YYYYWW'));
  const endWeek = Number(end.format('YYYYWW'));

  const lastNavigableWeek = endWeek - weeksSize + 1;

  let current = !week ? moment() : moment(week, 'YYYYWW');

  if (current.isBefore(start, 'week')) {

    current = start;
  } else if (current.isAfter(end, 'week') || current.isAfter(moment(lastNavigableWeek, 'YYYYWW'))) {

    current = moment(lastNavigableWeek, 'YYYYWW')
  }

  const currentWeek: number = Number(current.format('YYYYWW'));

  const previousWeek = Number(current.clone().subtract(1, 'week').format('YYYYWW'));
  const nextWeek = Number(current.clone().add(1, 'week').format('YYYYWW'));

  const range = getWeeksRange(currentWeek.toString(), weeksSize)
  const rangeFirstDay = moment(range[0].toString(), 'YYYYWW').startOf('W')
  const rangeLastDay = moment(range[range.length - 1].toString(), 'YYYYWW').endOf('W')

  const rangeStartDay = rangeFirstDay.isBefore(start) ? start : rangeFirstDay
  const rangeEndDay = rangeLastDay.isAfter(end) ? end : rangeLastDay

  return {
      prev: previousWeek >= startWeek ? previousWeek : '',
      next: (nextWeek <= lastNavigableWeek) ? nextWeek : '',
      week: currentWeek,
      range: getWeeksRange(currentWeek.toString(), weeksSize),
      start: startWeek,
      startDay: rangeStartDay.format('YYYY-MM-DD'),
      end: endWeek,
      endDay: rangeEndDay.format('YYYY-MM-DD'),
  };
}
