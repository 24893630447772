import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const AbandonNotification = (props: Props) => {

    const history = useHistory();

    const handleNotificationClick = () => {

        const projectUrl = `/project/${props.data.project._id}/teams`;
        history.push(projectUrl);
    };

    return (
        <div
            className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={handleNotificationClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>Proyecto <strong>{props.data.project.shortName}</strong></span>
                    {props.data.status === 'read' ? null : (
                        <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                    )}
                    <span className="flex-grow-1"></span>
                    {/* <span className="text-small text-muted ml-auto">Menos de un min.</span> */}
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        El usuario <strong>{props.data.userTo.name}</strong> ha abandonado el proyecto
                    </span>
                </p>
            </div>
        </div>
    )
}

export default AbandonNotification