
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";

import config from "../../../../../config";

interface Props {
  show: boolean;
  row: any;
  onHide: () => void;
  onSave: (row: any) => void;
}

const UserEditor = (props: Props) => {

  const [ tab, setTab ] = useState<string>('generals')

  const [ row, setRow ] = useState<any>(props.row)

  const handleCancel = () => {

    props.onHide()
  }

  const handleSave = () => {

    props.onSave(row)
  }

  useEffect(() => {
    setRow(props.row)

    setTab('generals')
  }, [ props.row ])

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={true}
    >
      <Modal.Body>
        <Tab.Container activeKey={tab} onSelect={(tab) => setTab(tab || '')}>
          <div className="ul-widget__head">
            <div className="ul-widget__head-label flex-grow-1">
              <h4 className="ul-widget__head-title">
                {!row.name && (row._id ? 'Editar plan' : 'Nuevo plan')}
                {row.name && `${row.name} ${row.surname}`}
              </h4>
            </div>
            <div className="ul-widget__head-nav">
              <Nav variant="tabs" className={`nav-`}>
                <Nav.Item>
                  <Nav.Link eventKey='generals' >
                    <i className="fas fa-fw fa-home"></i>
                    <span className="ocultar-en-pantallas-pequeñas"> General</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="ul-widget__body">
            <Tab.Content>
              <Tab.Pane eventKey='generals'>
                <Row>
                  <Col md={4}>
                    <img className="rounded-circle" src={config.apiHost + '/' + row.avatar} alt="" />
                  </Col>
                  <Col md={8}>
                    <Form.Group as={Row} controlId="name">
                      <Form.Label column md={3}>
                        Nombres
                      </Form.Label>
                      <Col md={9}>
                        <Form.Control
                          type="text"
                          value={row.name || ''}
                          onChange={(e) => setRow({ ...row, name: e.target.value })}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="surname">
                      <Form.Label column md={3}>
                        Apellidos
                      </Form.Label>
                      <Col md={9}>
                        <Form.Control
                          type="text"
                          value={row.surname || ''}
                          onChange={(e) => setRow({ ...row, surname: e.target.value })}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="email">
                      <Form.Label column md={3}>
                        Email
                      </Form.Label>
                      <Col md={9}>
                        <Form.Control
                          type="text"
                          value={row.email || ''}
                          onChange={(e) => setRow({ ...row, email: e.target.value })}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="verified">
                      <Form.Label column md={3}>
                        Verificado
                      </Form.Label>
                      <Col md={9}>
                        <Button variant="link-primary" size="sm"
                          onClick={() => setRow({ ...row, verified: !row.verified })}
                        >
                          {row.verified ? (
                            <>
                              <i className="far fa-fw fa-check-square"></i> Verificado
                            </>
                          ) : (
                            <>
                              <i className="far fa-fw fa-square"></i> No verificado
                            </>
                          )}
                        </Button>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="isAdmin">
                      <Form.Label column md={3}>
                        Es administrador
                      </Form.Label>
                      <Col md={9}>
                        <Button variant="link-primary" size="sm"
                          onClick={() => setRow({ ...row, isAdmin: !row.isAdmin })}
                        >
                          {row.isAdmin ? (
                            <>
                              <i className="far fa-fw fa-check-square"></i> Si
                            </>
                          ) : (
                            <>
                              <i className="far fa-fw fa-square"></i> No
                            </>
                          )}
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={handleCancel}>
          <i className="fas fa-fw fa-times"></i> Cancelar
        </button>
        <button className="btn btn-primary" onClick={handleSave}>
          <i className="fas fa-fw fa-save"></i> Guardar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserEditor
