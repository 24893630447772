
import React, { useEffect, useRef, useState } from 'react'

import GanttTableBodyCell from './GanttTableBodyCell'
import GanttTableBodyRow from './GanttTableBodyRow'

interface DnDAction {
  source: string
  action: 'before' | 'after' | 'inside'
  target: string
  parent: string
}

interface Props {
  index?: any,
  items: any
  columns: any
  selectedItems: string[]
  showVerticalBorders: boolean
  onToggleCollapse: (group_id: string) => void
  onItemClick: (item: any, isItRight: boolean) => void
  schema: string;
  onUpdateOrder?: (action: DnDAction) => void
  sortEnabled: boolean
  rowHeight: number
  height: number;
  onItemHover: (itemIndex: number) => void
  onItemOut: () => void
}


const GanttTableBody = (props: Props) => {

  const dragRef = useRef<number>(-1);

  const [ rowItems, setRowItems ] = useState(props.items);

  const handleDragStart = (e: React.DragEvent, i: number) => {
   dragRef.current = i;
  };

  const getIndexesToMove = () => {

    const list = [ dragRef.current ]

    const itemDragged = rowItems[dragRef.current]

    if (itemDragged.schema === 'group') {

      let nextIndex = dragRef.current + 1

      while (nextIndex && nextIndex < rowItems.length) {

        const item = rowItems[nextIndex]

        if (!item.position.includes(itemDragged.position)) break

        list.push(nextIndex)

        nextIndex++
      }
    }

    return list
  }

  const handleUpdateOrder = (action: DnDAction) => {

    props.onUpdateOrder && props.onUpdateOrder(action)
  }

  const handleDrop = (e: React.DragEvent, targetIndex: number) => {
    e.preventDefault();

    if (!dragRef || dragRef.current === -1 || targetIndex === -1) return;
    if (dragRef.current === targetIndex) return

    moveItems(targetIndex)
  };

  const moveItems = (targetIndex: number) => {

    const indexesToMove = getIndexesToMove()

    const itemDragged = rowItems[dragRef.current]

    const itemTarget = rowItems[targetIndex]

    if (itemDragged.parentId !== itemTarget.parentId) return

    if (itemDragged.parent !== itemTarget.parent) return

    const targetIsGreater = targetIndex > indexesToMove[0]

    const action: DnDAction = {
      source: itemDragged._id,
      action: targetIsGreater ? 'after' : 'before',
      target: itemTarget._id,
      parent: itemTarget.parentId
    }

    handleUpdateOrder(action)
  }

  useEffect(() => {
    setRowItems(props.items)
  }, [ props.items ])

  return (
    <div className='table-body-container'
      style={{ height: props.height }}
    >
      {rowItems.map((item: any, rowIndex: number) => (
        <GanttTableBodyRow
          key={`${item._id}-${rowIndex}`}
          index={rowIndex}
          item={item}
          columns={props.columns}
          isSelected={props.selectedItems.some((selectedItem: any) => selectedItem._id === item._id)}
          onClick={props.onItemClick}
          onDragStart={(e: React.DragEvent) => handleDragStart(e, rowIndex)}
          onDrop={(e: React.DragEvent) => handleDrop(e, rowIndex)}
          onDragOver={(e: any) => e.preventDefault()}
          schema={props.schema}
          sortEnabled={props.sortEnabled}
          rowHeight={props.rowHeight}
          onMouseOver={() => props.onItemHover(rowIndex)}
          onMouseOut={() => props.onItemOut()}
        >
          {props.columns.map((column: any, j: number) => (
            <GanttTableBodyCell
              key={`${j}-${column.field}`}
              column={column}
              item={item}
              showVerticalBorders={props.showVerticalBorders}
              isRowActive={props.selectedItems.includes(item._id)}
              onToggleCollapse={props.onToggleCollapse}
            />
          ))}
        </GanttTableBodyRow>
      ))}
    </div>
  )
}

export default GanttTableBody

