
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import InputFormControl from '../InputFormControl/InputFormControl'

import './InputDateTime.scss'

interface Props {
    date: string;
    time: string;
    onChange: (date_time: string) => void;
    format?: string;
    ISOformat?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
}

const InputDateTime = (props: Props) => {

    const [ date, setDate ] = useState(props.date)
    const [ time, setTime ] = useState(props.time)

    const onChangeDate = (date: string) => {

        setDate(date)
        updateDateTime({ d: date })
    }

    const onChangeTime = (time: string) => {

        setTime(time)
        updateDateTime({ t: time })
    }

    const updateDateTime = ({ d = date, t = time}: any) => {

        if (d && t) {

            const date_time = moment()
                                .year(d.split('-')[0])
                                .month(d.split('-')[1] - 1)
                                .date(d.split('-')[2])
                                .hour(t.split(':')[0])
                                .minute(t.split(':')[1])
                                .seconds(0)


            if (props.ISOformat) {

                return props.onChange(date_time.toISOString())
            }

            props.onChange(date_time.format(props.format || 'YYYY-MM-DD HH:mm:ss'))
        }
    }

    useEffect(() => {

        setDate(props.date)
        setTime(props.time)
    }, [ props.date, props.time ])

    return (
        <div className="input-date-time input-group">
            <InputFormControl
                type="date"
                value={date}
                onChange={(date: any) => onChangeDate(date)}
                readOnly={props.readOnly}
            />
            <InputFormControl
                type="time"
                value={time}
                onChange={(time: any) => onChangeTime(time)}
                readOnly={props.readOnly}
            />
        </div>
    )
}

export default InputDateTime
