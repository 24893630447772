
import React, { useState } from 'react'

import { getHeaders } from '../../Services/ApiService'

import Avatar from './Avatar'
import ImageUploader from '../ImageUploader/ImageUploader'

import './AvatarUploader.scss'
import ImageCropper from '../ImageCropper/ImageCropper'

type Props = {
    baseUrl?: string
    endpoint: string
    image: string
    mode?: 'form-upload' | 'html5-canvas-upload'
    onUpload: (image: string) => void
    disabled?: boolean
}

const AvatarUploader = (props: Props) => {

    const [ showUploader, setShowUploader ] = useState(false)

    const handleClick = () => {

        if (props.disabled) {

            return
        }

        setShowUploader(true)
    }

    const handleFileUploaded = (file: any) => {

        setShowUploader(false)
        props.onUpload(file)
    }

    return (
        <>
            <div data-testid="avatar-uploader" className="avatar-uploader">

                <Avatar
                    baseUrl={props.baseUrl}
                    src={props.image}
                    onClick={() => handleClick()}
                    disabled={props.disabled}
                />
            </div>

            {props.mode === 'form-upload' && (
                <ImageUploader
                    show={showUploader}
                    onHide={() => setShowUploader(false)}
                    headers={getHeaders()}
                    baseUrl={props.baseUrl}
                    url={`${props.baseUrl}${props.endpoint}`}
                    type="avatar"
                    onUploadFile={(file: any) => handleFileUploaded(file) }
                />
            )}
            {props.mode === 'html5-canvas-upload' && (
                <ImageCropper
                    show={showUploader}
                    onHide={() => setShowUploader(false)}
                    apiUrl={`${props.baseUrl}${props.endpoint}`}
                    headers={getHeaders()}
                    type="avatar"
                    onUploadFile={(fileUploaded: any) => handleFileUploaded(fileUploaded) }
                />
            )}
        </>
    )
}

export default AvatarUploader
