
import React from 'react';

import { ProgrammationSchema } from './types';
import GroupBarTask from './GroupBarTask';

export type GroupBarProps = {
  schema: ProgrammationSchema
  item: any;
  useCodeColors: boolean;
  selectedItems: any[];
  onItemClick: (item: any, isItRight: boolean) => void
}

const GroupBar = (props: GroupBarProps) => {

  const itemLeft = props.item.left + 4
  const top = props.item.top + 6
  const itemWidth = props.item.width - 8
  const groupHeight = props.item.height - 26
  const barsHeight = props.item.height - 12

  const itemSize = props.item.height / 32

  return (
    <g className='gantt-chart-item-bar group-bar'
      style={{ cursor: 'pointer' }}
    >
      <rect
        className="grid-row-item"
        fill={'#33333360'}
        x={itemLeft}
        y={0}
        height={0}
        width={itemWidth}
        style={{
          height: props.item.isCollapsed ? groupHeight : 6,
          transform: `translateY(${props.item.isCollapsed ? top : top + 6}px)`,
          opacity: props.item.isCollapsed ? 0 : 1
        }}
      ></rect>
      {props.item.bars.map((bar: any) => (
        <GroupBarTask key={bar._id}
          schema={props.schema}
          bar={bar}
          height={barsHeight}
          itemSize={itemSize}
          top={top}
          isSelected={props.selectedItems.some((selectedItem: any) => selectedItem._id === bar._id)}
          isParentCollapsed={props.item.isCollapsed}
          useCodeColors={props.useCodeColors}
          onClick={props.onItemClick}
        />
      ))}
    </g>
  )
}

export default GroupBar
