
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";

import { getTaskMonths } from "../../Helpers/TasksHelper";

import TaskCard from "../../Components/TaskCard/TaskCard";

import './TasksGroupByMonth.scss'

type Props = {
  height: number;
  tasks: any;
  teams: any;

  onSelectTask: (task_id: string) => void;
}

const TasksGroupByMonth = (props: Props) => {

  const getAllTasksFromList = (list: any) => {

    const tasks: any[] = []

    list.forEach((item: any) => {

      if (item.type === 'task') {

        tasks.push(item.task)
      } else {

        const subTasks = getAllTasksFromList(item.group.items)

        if (subTasks.length)
          tasks.push(...subTasks)
      }
    })

    return tasks
  }

  const groupedByMonth = useMemo(() => {

    const allTasks = getAllTasksFromList(props.tasks)

    const months: any = {}

    allTasks.map((task: any) => {

      const task_months: any = getTaskMonths(task)

      task_months.map((month: string) => {

        if (!months[month]) {

          months[month] = []
        }

        months[month].push(task)
      })
    })

    const groups: any = []

    for (const key in months) {

      groups.push({
        name: moment(`${key}`).format('MMMM - YYYY'),
        tasks: months[key]
      })
    }

    return groups
  }, [ props.tasks ])

  return (
    <div className="custom-scrollbar"
      style={{
        overflow: 'auto',
        margin: '-10px -2rem 0',
        padding: '10px 2rem 0'
      }}
    >
      <div
        style={{
          height: props.height,
        }}
      >
        <div style={{ paddingBottom: '1rem'}}>
          {groupedByMonth.map((month: any, i: number) => (
            <Card className="table-card mb-3" key={i}>
              <div>
                <Table className="card-table">
                  <thead>
                    <tr>
                      <th>{month.name.toUpperCase()}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="row">
                          {month.tasks.map((task: any) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mb-3" key={task._id}>
                              <TaskCard
                                task={task}
                                responsibles={task.responsibles}
                                onClick={() => props.onSelectTask(task._id)}
                              />
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TasksGroupByMonth
