
import moment from "moment";

export const getCnCWeekNavigator = (projectStart: string, projectEnd: string, week?: string) => {

  const start = moment(projectStart);
  const end = moment(projectEnd);

  const startWeek = Number(start.format('YYYYWW'));
  const endWeek = Number(end.format('YYYYWW'));

  const lastWeek = !week ? moment().subtract(1, 'w') : moment(week, 'YYYYWW');

  let prev = lastWeek.clone().subtract(1, 'w').format('YYYYWW')
  let next = lastWeek.clone().add(1, 'w').format('YYYYWW')
  let current: number | string = lastWeek.format('YYYYWW')

  if (lastWeek.isSameOrBefore(start, 'week')) {

    prev = ''
    next = start.add(1, 'w').format('YYYYWW');
    current = startWeek;
  }

  if (lastWeek.isSameOrAfter(end, 'week')) {

    prev = end.subtract(1, 'w').format('YYYYWW');
    next = ''
    current = endWeek;
  }

  return {
    prev,
    next,
    week: current,
    start: startWeek,
    end: endWeek,
  }
}
