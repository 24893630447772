import React, { useState, createContext, FC, ReactElement, useContext } from 'react';

interface FeedbackContextProps {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(undefined);

export const FeedbackProvider = ({ children }: { children: ReactElement }) => {
  const [isVisible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const contextValue: FeedbackContextProps = {
    isVisible: isVisible,
    show,
    hide,
  };

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): FeedbackContextProps => {
  const context = useContext(FeedbackContext);

  if (!context) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }

  return context;
};