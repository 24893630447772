
import React, { ReactNode } from 'react'

import DropdownToggle from './DropdownToggle'
import DropdownContent from './DropdownContent'

type Props = {
    show?: boolean,
    toggle?: ReactNode,
    toggleClass?: string,
    contentClass?: string,
    onHide?: any,
    onToggle?: any,

    disabled?: boolean,
    style?: any,
    children?: ReactNode,
}

type State = {
    show: boolean,
}

class Dropdown extends React.Component<Props, State> {

    static myInstance: any

    navContainer: any

    constructor(props: Props) {
        super(props)

        // this.state = {
        //     show: props.show || false
        // }

        this.handleClickOutside = this.handleClickOutside.bind(this)

        Dropdown.myInstance = this
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    setNavContainer(node: any) {
        this.navContainer = node
    }

    handleClickOutside(e: any) {

        if (this.navContainer && !this.navContainer.contains(e.target)) {

            // this.setState({
            //     show: false
            // })

            this.setToggle(false)


            if (this.props.onHide && typeof this.props.onHide === 'function') {

                this.props.onHide()
            }
        }
    }

    setToggle(toggle: boolean) {

        if (this.props.onToggle) {

            this.props.onToggle(toggle)
        }
    }

    toggle() {

        const {
            props,
        } = this

        if (!props.show) {

            // this.setState({
            //     show: true
            // })

            this.setToggle(true)

        } else {

            // this.setState({
            //     show: false
            // })

            this.setToggle(false)

            if (this.props.onHide && typeof this.props.onHide === 'function') {

                this.props.onHide()
            }
        }
    }

    static close() {

        this.myInstance._close()
    }

    _close() {

        this.setState({
            show: false
        })
    }

    render() {

        const {
            props,
            // state,
        } = this

        const toggleProps = {
            className: props.toggleClass || ''
        }

        const contentProps = {
            className: props.contentClass || ''
        }

        return (
            <div className="dropdown"
                ref={(node: any) => this.setNavContainer(node)}
                style={props.style}
            >
                <DropdownToggle
                    {...toggleProps}
                    onClick={() => this.toggle()}
                    disabled={props.disabled}
                >
                    {props.toggle}
                </DropdownToggle>
                <DropdownContent
                    {...contentProps}
                    show={props.show}
                >
                    {props.children}
                </DropdownContent>
            </div>
        )
    }
}

export default Dropdown
