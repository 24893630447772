import ProjectCNCService from "../../Services/ProjectCNCService"

const clearAction = () => {
    return {
        type: "PROJECT_CNC_RESET"
    }
}

const loadResult = (project_id: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: "PROJECT_CNC_LOAD_RESULT_START"
        })

        try {
            const data = await ProjectCNCService.getResult(project_id)

            dispatch({
                type: "PROJECT_CNC_LOAD_RESULT_END",
                content: {
                    data: data.result
                }
            })
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: "PROJECT_CNC_LOAD_RESULT_ERROR",
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}

const createRow = (row: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: "PROJECT_CNC_CREATE_ROW_START"
        })

        try {

            const result = await ProjectCNCService.createRow(row)

            dispatch({
                type: "PROJECT_CNC_CREATE_ROW_END",
                content: {
                    data: result.result
                }
            })
        } catch (error) {

            if (error instanceof Error) {
                dispatch({
                    type: "PROJECT_CNC_UPDATE_ROW_ERROR",
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}

const updateRow = (project_id: string, project_cnc_id: string, row: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: "PROJECT_CNC_UPDATE_ROW_START"
        })

        try {
            const res = await ProjectCNCService.updateRow(project_id, project_cnc_id, row)

            dispatch({
                type: "PROJECT_CNC_UPDATE_ROW_END",
                content: {
                    data: res.result
                }
            })
        } catch (error) {
            if (error instanceof Error) {
                dispatch({
                    type: "PROJECT_CNC_UPDATE_ROW_ERROR",
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export default {
    clearAction,
    loadResult,
    createRow,
    updateRow,
}