
export const getStatusName = (status: string) => {

    switch(status) {
        case 'activa':
            return 'Activa'
        case 'pendiente':
            return 'Pendiente de Liberación'
        case 'liberada':
            return 'Liberada'
        case 'completada':
            return 'Completada'
        case 'atrasada':
            return 'Atrasada'
        case 'activa-atrasada':
            return 'Activa atrasada'
    }

    return ''
}

export const getMemberFromList = (list: any, _id: string) => {

    const found = list.filter((item: any) => item._id === _id)

    if (!found.length) return undefined

    return found[0]
}

export default {
    getStatusName,
    getMemberFromList,
}
