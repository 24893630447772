
import config from "../config"

import { get } from "./ApiService"

export const loadWeeklyProgress = async (project_id: string) => {

  const url = config.apiHost + '/indicators-ppc/progress-percent-history?project_id=' + project_id

  return await get(url, 'INDICATORS_PPC_LOAD_WEEKLY_PROGRESS')
}

export const loadTasks = async (project_id: string, week: string) => {

  const url = `${config.apiHost}/indicators-ppc/${project_id}/tasks?week=${week}`

  return await get(url, 'INDICATORS_PPC_LOAD_TASKS')
}
