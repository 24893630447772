import moment from "moment"
import { aIsSameOrBeforeB, dateToYYYYWW, getWeekNumber, getYMDFromDate } from "./DateFunctions"

export const getDelayedTasks = (tasks: any) => {

  return tasks.filter((task: any) => {

    const isValidType = task.type !== 'hito' && task.type !== 'reunion'
    const hasNotStartedYet = task.progress === 0

    const startsAt = new Date(task.startsAt)
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    const startsBeforeToday = startsAt < today

    const isValidStatus = task.status === 'atrasada' || task.status === 'activa-atrasada'

    return isValidType && hasNotStartedYet && startsBeforeToday && isValidStatus
  })
}

const getTaskDelay = (task: any, nonWorkingDays: string[]) => {

  let delay = 0

  if (!task.startedAt) {

    const startsAt = new Date(task.startsAt)
    startsAt.setHours(0, 0, 0)

    const today = new Date()
    today.setHours(0, 0, 0)

    while(startsAt < today) {

      const YMD = getYMDFromDate(startsAt)

      const isNWD = nonWorkingDays.includes(YMD)

      if (!isNWD)
        delay += 1

      startsAt.setDate(startsAt.getDate() + 1)
    }
  }

  return delay > 0 ? delay - 1 : 0
}

const getTaskAdvance = (task: any, nonWorkingDays: string[]) => {

  let advance = 0

  if (!task.startedAt) {

    const day = new Date(task.startsAt)
    day.setHours(0, 0, 0)

    const today = new Date()
    today.setHours(0, 0, 0)

    while(day > today) {

      const YMD = getYMDFromDate(day)

      const isNWD = nonWorkingDays.includes(YMD)

      if (!isNWD)
        advance += 1

      day.setDate(day.getDate() - 1)
    }
  }

  return advance > 0 ? advance - 1: 0
}

export const getEstimatedTotalDelay = (tasks: any[], nonWorkingDays: string[]) => {

  let estimatedTotalDelay: number = 0
  let estimatedTotalAdvance: number = 0

  tasks.forEach((task: any) => {

    const taskDelay = getTaskDelay(task, nonWorkingDays)
    const taskAdvance = getTaskAdvance(task, nonWorkingDays)

    if (taskDelay > estimatedTotalDelay)
      estimatedTotalDelay = taskDelay

    if (taskAdvance > estimatedTotalAdvance)
      estimatedTotalAdvance = taskAdvance
})


  return estimatedTotalAdvance - estimatedTotalDelay
}

export const getDelayData = (tasks: any[], nonWorkingDays: string[]) => {

  const delayedTasks = getDelayedTasks(tasks)

  const estimatedTotalDelay = getEstimatedTotalDelay(delayedTasks, nonWorkingDays)

  return {
    delayedTasks: delayedTasks.length,
    estimatedTotalDelay
  }
}

interface IWeekProgress {
  name: string
  week: number
  tasks: number
  programmed: number
  real: number | undefined
}

interface IYearWeekDay {
  yearWeek: string
  days: number
}

const getWeeksFromProject = (start: string, end: string): IWeekProgress[] => {

  const weeks: IWeekProgress[] = []

  const thisWeek = getWeekNumber(new Date())

  const endWeek = getWeekNumber(new Date(end))

  const currentWeekDate = new Date(start)

  let sem = 1

  while (getWeekNumber(currentWeekDate) <= endWeek) {
    const currentWeek = getWeekNumber(currentWeekDate).toString().padStart(2, '0')

    weeks.push({
      name: `Sem ${sem++}`,
      week: Number(currentWeek),
      tasks: 0,
      programmed: 0,
      real: getWeekNumber(currentWeekDate) <= thisWeek ? 0 : undefined
    })

    currentWeekDate.setDate(currentWeekDate.getDate() + 7)
  }

  return weeks
}

export const getTaskYearWeeksDays = (task: any, nonWorkingDays: any[]) => {

  const weekDays: IYearWeekDay[] = []

  const dayDate = new Date(task.startsAt)
  dayDate.setHours(0, 0, 0)
  const dayYMD = getYMDFromDate(dayDate)

  const endDayDate = new Date(task.endsAt)
  endDayDate.setHours(0, 0, 0)

  while (dayDate <= endDayDate) {

      const isNonWorkingDay = nonWorkingDays.includes(dayYMD)

      if (!isNonWorkingDay) {

          const yearWeek = getWeekNumber(dayDate).toString()

          const yearWeekDay = weekDays.find((d: IYearWeekDay) => d.yearWeek === yearWeek)

          if (yearWeekDay) {

              yearWeekDay.days++
          } else {

              weekDays.push({
                  yearWeek,
                  days: 1
              })
          }
      }

      dayDate.setDate(dayDate.getDate() + 1)
  }

  return weekDays
}

const populateWeekProgrammedProgress = (weeks: IWeekProgress[], tasks: any[], nonWorkingDays: string[]): IWeekProgress[] => {

  tasks.forEach((task: any) => {

    const programmedYearWeekDays: IYearWeekDay[] = getTaskYearWeeksDays(task, nonWorkingDays)

    programmedYearWeekDays.forEach((yearWeekDays: IYearWeekDay) => {

      const week = weeks.find((w: IWeekProgress) => w.week === Number(yearWeekDays.yearWeek))

      if (week) {

        week.tasks++
        week.programmed += yearWeekDays.days
      }
    })
  })

  return weeks
}

export const getTaskWeekVersion = (weeks: IWeekProgress[], weekIndex: number, versions: any) => {

  if (weekIndex < 0) return undefined

  let previousVersion: any = undefined

  for (let i = weekIndex; i >= 0; i--) {

      const week = weeks[i]

      previousVersion = [...versions].reverse().find((version: any) => moment.utc(version.updatedAt).format('YYYYWW') === week.week.toString())

      if (previousVersion) break
  }

  return previousVersion
}

export const getTaskLastWeekVersion = (weeks: IWeekProgress[], weekIndex: number, versions: any) => {

  if (weekIndex < 0) return undefined

  const week = weeks[weekIndex]

  return [...versions].reverse().find((version: any) => moment.utc(version.updatedAt).format('YYYYWW') === week.week.toString())
}

const populateWeekRealProgress = (weeks: IWeekProgress[], tasks: any[]): IWeekProgress[] => {

  weeks.forEach((week: IWeekProgress, weekIndex: number) => {

    if (week.real === undefined) return

    let weekProgress = 0

    tasks.forEach((task: any) => {

      const previousWeekTaskVersion = getTaskWeekVersion(weeks, weekIndex - 1, task.versions)
      const weekTaskVersion = getTaskLastWeekVersion(weeks, weekIndex, task.versions)

      if (weekTaskVersion && !previousWeekTaskVersion) {

        weekProgress += weekTaskVersion.progress
      } else if (weekTaskVersion && previousWeekTaskVersion) {

        if (weekTaskVersion.progress >= previousWeekTaskVersion.progress) {

          weekProgress += weekTaskVersion.progress - previousWeekTaskVersion.progress
        }
      }
    })

    if (weekProgress) {

      week.real = Number((weekProgress / tasks.length).toFixed(0))
    }
  })

  return weeks
}

export const getProgressData = ({ project, tasks, nonWorkingDays, versions }: any) => {

  let weeks = getWeeksFromProject(project.startsAt, project.endsAt)

  tasks.forEach((task: any) => {

    const taskVersions = versions.find((version: any) => version.task === task._id)

    if (taskVersions)
      task.versions = taskVersions.versions
  })

  weeks = populateWeekProgrammedProgress(weeks, tasks, nonWorkingDays)
  weeks = populateWeekRealProgress(weeks, tasks)

  let totalProgrammed = 0

  weeks.forEach((week: IWeekProgress) => {

    totalProgrammed += week.programmed

    week.programmed = totalProgrammed
  })

  //convert to percentage where totalProgrammed is 100%
  let totalReal = 0
  weeks = weeks.map((week: IWeekProgress) => {

    const programmed = Number(((week.programmed / totalProgrammed) * 100).toFixed(0))

    if (week.real !== undefined) {

      totalReal += week.real
    }

    return {
      ...week,
      programmed,
      real: week.real !== undefined ? totalReal : undefined
    }
  })

  return {
    weeks,
    totalProgrammed
  }
}

