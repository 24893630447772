
import { Dispatch } from "redux";

import store from "../store";

export const setLastChatsAction = (last: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_LAST',
      payload: {
        last
      }
    })
  }
}

export const placeAtFirstPosition = (chat: any) => {

  const lastChats = store.getState().ChatReducer.last

  const index = lastChats.findIndex((c: any) => c._id === chat._id)
  if (index > -1) {

    lastChats.splice(index, 1)
  }

  lastChats.unshift(chat)

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_LAST',
      payload: {
        last: lastChats
      }
    })
  }
}

export const addChatAction = (chat: any) => {

  const lastChats = store.getState().ChatReducer.last

  const index = lastChats.findIndex((c: any) => c._id === chat._id)

  if (index === -1) {

    lastChats.unshift(chat)
  } else {

    lastChats[index].hasNewMessages = false
  }

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_LAST',
      payload: {
        last: lastChats
      }
    })
  }
}

export const setMessagesAction = (messages: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_MESSAGES',
      payload: {
        messages
      }
    })
  }
}

export const addMessageAction = (message: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_ADD_MESSAGE',
      payload: {
        message
      }
    })
  }
}

export const deleteChatAction = (chatId: string) => {

  const {
    last: lastChats,
    chat: currentChat
  } = store.getState().ChatReducer

  const index = lastChats.findIndex((c: any) => c._id === chatId)

  if (index > -1) {

    lastChats.splice(index, 1)
  }

  return async (dispatch: Dispatch) => {

    if (currentChat && currentChat._id === chatId) {

      dispatch({
        type: 'CHAT_SET_CHAT',
        payload: {
          chat: null
        }
      })
    }

    dispatch({
      type: 'CHAT_SET_LAST',
      payload: {
        last: lastChats
      }
    })
  }
}

export const setNewChatsAction = (newChats: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_NEW_CHATS',
      payload: {
        newChats
      }
    })
  }
}

export const decreaseNewChatAction = () => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_DECREASE_NEW_CHAT',
      payload: {}
    })
  }
}

export const setChatAction = (chat: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'CHAT_SET_CHAT',
      payload: {
        chat
      }
    })
  }
}

export const setChatTreeData = (projectsTree: any) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'SET_PROJECTS_TREE',
      payload: {
        projectsTree
      }
    })
  }
}

