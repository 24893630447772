
import React from "react";
import { Modal } from "react-bootstrap";

import config from "../../../config";

interface Props {
  show: boolean
  title: string;
  onHide: () => void;
}

const CantCreateCustomCNCsModal = (props: Props) => {

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Tu plan actual no te permite crear CNCs personalizadas.
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> Explora nuestros planes
          </a> y mejora tu plan para poder crear CNCs personalizadas.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CantCreateCustomCNCsModal
