import React from 'react';

import { TChat, TChatUser } from '../../../Types/Chat';

import { useChat } from '../../../Hooks/UseChat';

import XChatListItem from '../ChatListItem/ChatListItem';

import './ChatList.scss';
import config from '../../../config';

interface ChatListProps {
  chats: TChat[];
  onSelectChat: (chat: TChat) => void;
}

const ChatList: React.FC<ChatListProps> = ({ chats, onSelectChat }) => {

  const { currentChat, unsetCurrentChat } = useChat();

  const iaUser: TChatUser = {
    _id: '1',
    name: 'Pl4nner AI',
    surname: '',
    avatar: '',
  }

  const iaChat: TChat = {
    _id: 'pl4nner-ia-chat',
    type: 'private',
    user: iaUser,
  }

  return (
    <div className={`x-chat-list ${!chats.length && 'empty'}`}>
      {!chats.length && (
        <>
          Aún no tienes ningún chat, <br />crea uno nuevo desde el menú de proyectos
        </>
      )}
      {config.chat.pl4nnerBot.enabled && (
        <XChatListItem chat={iaChat} onClick={() => unsetCurrentChat()} active={!currentChat} />
      )}
      {chats.map((chat: TChat, index: any) => (
        <XChatListItem key={index} chat={chat} onClick={() => onSelectChat(chat)} />
      ))}
    </div>
  );
};

export default ChatList;
