
import moment from 'moment'
import { ProjectDefaults } from '../../Types/Project'

const defRow = {
    fake: true,
    _id: undefined,
    name: '',
    shortName: '',
    address: '',
    codes: [],
    nonWorkingDays: [],
    code: '',
    timeExtensions: [],
    client: {},
    supervisor: {},
    showOnlyMyTasks: true,
    settings: {
        stretchUpToday: true,
        workTimeStart: ProjectDefaults.settings_workTimeStart,
        workTimeEnd: ProjectDefaults.settings_workTimeEnd,
        workMonday: true,
        workTuesday: true,
        workWednesday: true,
        workThursday: true,
        workFriday: true,
        workSaturday: true,
        workSunday: false,
        workTimeAtWeek: 0,
    },
    mySettings: {},
    plan: undefined,
}

const initialState: any = {
    loadResultStatus: 'none',
    loadRowStatus: 'none',
    createStatus: 'none',
    updateStatus: 'none',
    data: {
        active: [],
        archived: [],
        mine: [],
        other: []
    },
    rowData: defRow,
}

const ProjectsReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === 'PROJECTS_CLEAR_ALL') {

        st.loadResultStatus = 'none'
        st.loadRowStatus = 'none'
        st.rowData = defRow
        st.data = {
            active: [],
            archived: [],
            mine: [],
            other: []
        }
    } else if (action.type === 'PROJECTS_CLEAR_ONE') {

        st.loadRowStatus = 'none'
        st.rowData = defRow
    } else if (action.type === 'PROJECTS_LOAD_RESULT_START') {

        st.loadRowStatus = 'none'
        st.loadResultStatus = 'loading'
    } else if (action.type === 'PROJECTS_LOAD_RESULT_END') {

        st.loadResultStatus = 'loaded'
        st.data = action.content.data
    } else if (action.type === 'PROJECTS_LOAD_RESULT_ERROR') {

        st.loadResultStatus = action.content.error
    } else if (action.type === 'PROJECTS_LOAD_ROW_START') {

        st.loadRowStatus = 'loading'
        st.rowData = defRow
    } else if (action.type === 'PROJECTS_LOAD_ROW_END') {

        st.loadRowStatus = 'loaded'

        const data = action.content.data

        const weekStart = moment(data.startsAt).week()
        const weekEnd = moment(data.endsAt).week()

        const mySettings = data.mySettings

        st.rowData = {
            ...action.content.data,
            weekStart,
            weekEnd,
            mySettings
        }
    } else if (action.type === 'PROJECTS_LOAD_ROW_ERROR') {

        st.loadRowStatus = action.content.error
        st.rowData = defRow
    } else if (action.type === 'PROJECTS_LOAD_ROW_RESET') {

        st.loadRowStatus = 'none'
    } else if (action.type === 'PROJECTS_CREATE_START') {

        st.createStatus = 'creating'
    } else if (action.type === 'PROJECTS_CREATE_END') {

        st.createStatus = 'created'
    } else if (action.type === 'PROJECTS_CREATE_ERROR') {

        st.createStatus = action.content.error
    } else if (action.type === 'PROJECTS_CREATE_RESET') {

        st.createStatus = 'none'
    } else if (action.type === 'PROJECT_UPDATE_START') {

        st.updateStatus = 'updating'
    } else if (action.type === 'PROJECT_UPDATE_END') {

        st.updateStatus = 'updated'
        st.rowData = action.content.data
    } else if (action.type === 'PROJECT_UPDATE_ERROR') {

        st.updateStatus = action.content.error
    } else if (action.type === 'PROJECT_UPDATE_RESET') {

        st.updateStatus = 'none'
    } else if (action.type === 'PROJECT_UPDATE_CODES') {

        st.rowData.codes = action.codes
    } else if (action.type === 'PROJECT_DELETE_START') {

        const mine: any[] = st.data.mine.map((project: any) => {

            if (project._id === action.project_id) {

                project.deleting = true
            }

            return project
        })

        st.data.mine = mine
    } else if (action.type === 'USER_PROJECT_SETTINGS_UPDATE') {

        st.rowData.mySettings = action.payload
    }

    return st
}

export default ProjectsReducer
