
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import OptionsDialog from '../OptionsDialog/OptionsDialog'

// TODO: remove unused classes
import './BigButton.scss'

type Props = {
    to?: string,
    icon: string,
    text: string,
    onClick?: any,
    onDisabledClick?: any,
    disabled?: boolean,
    altButtonClick?: any,
    altMenu?: any,
}

const BigButton = (props: Props) => {

    const history = useHistory()

    const [ showAltMenu, setShowAltMenu ] = useState(false)

    const handleClick = (e:any) => {
        e.preventDefault()

        if (props.disabled) {

            props.onDisabledClick()

            return
        }

        if (props.to) {

            history.push(props.to)
        } else {

            props.onClick()
        }
    }

    const handleAltClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        props.altButtonClick()
    }

    return (
        <>
            <a href="#" data-testid="big-button" onClick={(e) => handleClick(e)}>
                <div className="big-button">
                    {props.altButtonClick && (
                        <button data-testid="alt-button" className="alt-button"
                            onClick={(e) => handleAltClick(e)}
                        >
                            <i className="fas fa-f2 fa-times"></i>
                        </button>
                    )}
                    {props.altMenu && (
                        <button data-testid="alt-button" className="alt-button"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                setShowAltMenu(true)
                            }}
                        >
                            <i className="fas fa-fw fa-ellipsis-v"></i>
                        </button>
                    )}
                    <div className="icon">
                        <span className={props.icon}></span>
                    </div>
                    <div className="text">
                        {props.text}
                    </div>
                </div>
            </a>

            {props.altMenu && (
                <OptionsDialog
                    show={showAltMenu}
                    title={props.text}
                    closeButton
                    onHide={() => setShowAltMenu(false)}
                    options={props.altMenu}
                />
            )}
        </>
    )
}

export default BigButton
