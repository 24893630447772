import React, { useEffect, useRef, useState } from "react";

import { getTaskStatus } from "../../Helpers/TasksHelper";
import ColorCodeText from "./GanttChart/Bars/GroupTaskBar/ColorCodeText";

type Props = {
  item: any;
  ganttWidth: number;
  useCodeColors: boolean;
  schema: string
  lineBaseTasks: boolean;
  onClick: (item: any, isItRight: boolean) => void;
};

const GanttChartBodyTask = (props: Props) => {

  const textRef: any = useRef();
  const positionRef: any = useRef();
  const colorRef: any = useRef();
  const progressStringRef: any = useRef();
  const restrictionsRef: any = useRef();

  const [ truncatedText, setTruncatedText ] = useState(props.item.barName);
  const [ fullText, setFullText ] = useState(props.item.barName);
  const [ isHovered, setIsHovered ] = useState(false);

  const [ positionWidth, setPositionWidth ] = useState(0);
  const [ colorWidth, setColorWidth ] = useState(0);
  const [ progressStringWidth, setProgressStringWidth ] = useState(0);
  const [ restrictionsWidth, setRestrictionsWidth ] = useState(0);

  const itemSize = props.item.height / 32

  const itemLeft = props.item.left + 4;
  const leftProgrammed = props.item.leftProgrammed + 4;

  const itemTop = props.item.top + 6;
  const itemWidth = props.item.width - 8;
  const widthProgrammed = props.item.widthProgrammed - 8

  const isMacro = props.schema === 'macro'

  let color = `var(--text-color-${props.item.status})`;
  let progressColor = `var(--progress-color-${props.item.status})`;

  if (props.useCodeColors) {
    color = props.item.colorCodeColor || '#808080';
    progressColor = `${color}90`;
  }

  const nameTop = props.item.top + 20;
  const nameLeft = props.item.left + 10;
  const positionTop = nameTop
  let positionLeft = itemLeft + itemWidth - positionWidth - 4
  const colorTop = ((nameTop) + (itemSize * 32) / 2) - 12
  const colorLeft = itemLeft + (itemWidth / 2) - (colorWidth / 2)
  const progressStringTop = colorTop + 12
  let progressStringLeft = colorLeft + colorWidth - progressStringWidth
  const statusTop = props.item.top + props.item.height - 14
  const statusLeft = nameLeft;
  const restrictionsTop = statusTop
  const restrictionsLeft = itemLeft + (itemWidth / 2) - (restrictionsWidth / 2)

  if (positionLeft < itemLeft) positionLeft = itemLeft + 6

  if (progressStringLeft < colorLeft) progressStringLeft = colorLeft
  if (progressStringLeft + progressStringWidth > itemWidth) progressStringLeft = itemLeft + itemWidth - progressStringWidth - 4
  if (progressStringLeft < itemLeft) progressStringLeft = itemLeft + 6

  const progressWidth = (props.item.width - 8) * (props.item.progress || 0) / 100;

  const getName = () => {

    if (itemSize > 1) return props.item.name

    if (isHovered) return fullText

    if (truncatedText.length < props.item.barName.length) return truncatedText + '..'

    return truncatedText
  }

  const handleClick = (e: any) => {

    props.onClick(props.item, false)
  }

  const handleRightClick = (e: any) => {
    e.preventDefault();

    props.onClick(props.item, true)
  }

  useEffect(() => {
    const textNode = textRef.current;
    if (textNode) {
      const availableWidth = props.item.width - 8;
      let originalText = props.item.barName;
      const oldText = textNode.textContent;

      // Measure ellipsis width
      textNode.textContent = '..';
      const ellipsisWidth = textNode.getComputedTextLength();

      // Restore original text
      textNode.textContent = oldText;

      while (
        textNode.getComputedTextLength() + ellipsisWidth > availableWidth &&
        originalText.length > 0
      ) {
        originalText = originalText.slice(0, -1);
        textNode.textContent = originalText + '..';
      }
      setTruncatedText(originalText);
      setFullText(props.item.barName);
    }
  }, [ props.item.barName, props.item.width ]);

  // useEffect(() => {

  //   const truncatedText = truncateText(props.item.colorCodeName, props.item.width)

  // }, [ props.item.colorCodeName, itemSize, props.item.width ])

  useEffect(() => {

    if (!positionRef.current) return

    setPositionWidth(positionRef.current.getComputedTextLength());
  }, [ positionRef.current ])

  useEffect(() => {

    if (!colorRef.current) return

    setColorWidth(colorRef.current.getComputedTextLength());
  }, [ colorRef.current, itemSize ])

  useEffect(() => {

    if (!progressStringRef.current) return

    setProgressStringWidth(progressStringRef.current.getComputedTextLength());
  }, [ progressStringRef.current ])

  useEffect(() => {

    if (!restrictionsRef.current) return

    setRestrictionsWidth(restrictionsRef.current.getComputedTextLength());
  }, [ restrictionsRef.current ])

  return (
    <g
      className="gantt-chart-item-bar"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
      onContextMenu={handleRightClick}
    >
      <rect className="grid-row-item" fill="#FFFFFFB0"
        x={itemLeft}
        y={0}
        height={0}
        width={itemWidth}
        style={{
          height: props.item.height - 12,
          transform: `translateY(${itemTop}px)`
        }}
      ></rect>
      <rect className="grid-row-item hover" fill={progressColor}
        x={itemLeft}
        y={0}
        height={0}
        width={itemWidth}
        style={{
          height: props.item.height - 12,
          transform: `translateY(${itemTop}px)`
        }}
        ></rect>
      <rect className="grid-row-item-progress" fill={color}
        x={itemLeft + 1}
        y={0}
        height={0}
        width={progressWidth || 3}
        style={{
          height: props.item.height - 14,
          transform: `translateY(${itemTop + 1}px)`
        }}
        ></rect>
      <rect className="grid-row-item" fill="#716977"
        x={leftProgrammed}
        y={0}
        height={4}
        width={widthProgrammed}
        display={props.lineBaseTasks && isMacro ? 'block' : 'none'}
        style={{
          transform: `translateY(${itemTop + 16}px)`
        }}
        ></rect>
      <text
        ref={textRef}
        className="grid-row-item-text dark"
        x={nameLeft}
        y={0}
        style={{
          fontWeight: isHovered ? 'bold' : 'normal',
          textShadow: isHovered ? '1px 1px 3px #ffffff' : 'none',
          WebkitTextStroke: isHovered ? '2px white' : 'none', // Agrega un borde blanco al texto
          WebkitTextFillColor: isHovered ? '#ffffff' : 'black', // Define el color de relleno
          transform: `translateY(${nameTop}px)`,
          userSelect: 'none'
        }}
      >
        {getName()}
      </text>
      {props.schema === 'weekly' && (
        <>
          <text
            ref={positionRef}
            className="grid-row-item-text dark"
            x={positionLeft}
            y={0}
            style={{
              transform: `translateY(${positionTop}px)`,
              opacity: itemSize > 1 ? 1 : 0
            }}
          >
            {props.item.position}
          </text>
          <ColorCodeText
            colorCodeName={props.item.colorCodeName}
            itemTop={itemTop}
            itemLeft={itemLeft}
            itemWidth={itemWidth}
            itemVerticalSize={itemSize}
            opacity={itemSize > 1 ? 1 : 0}
            isHovered={isHovered}
          />
          <text
            ref={progressStringRef}
            className="grid-row-item-text dark"
            x={progressStringLeft}
            y={0}
            style={{
              transform: `translateY(${itemSize === 1 ? nameTop : progressStringTop}px)`,
              opacity: itemSize > 1 ? 1 : 0
            }}
          >
            {props.item.progressString}
          </text>
          <text
            className="grid-row-item-text dark"
            x={statusLeft}
            y={0}
            style={{
              transform: `translateY(${itemSize === 1 ? nameTop : statusTop}px)`,
              opacity: itemSize > 2 ? 1 : 0
            }}
          >
            {getTaskStatus(props.item.status)}
          </text>
          <text
            ref={restrictionsRef}
            className="grid-row-item-text dark"
            x={restrictionsLeft}
            y={0}
            style={{
              transform: `translateY(${itemSize === 1 ? nameTop : restrictionsTop}px)`,
              opacity: itemSize > 3 ? 1 : 0
            }}
          >
            {`${props.item.restrictions.done}/${props.item.restrictions.toDo}`}
          </text>
        </>
      )}
      <rect className="grid-row-item-transparent" fill="#FF000000"
        x={itemLeft}
        y={0}
        height={0}
        width={itemWidth}
        style={{
          height: props.item.height - 12,
          transform: `translateY(${itemTop}px)`
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </g>
  );
};

export default GanttChartBodyTask;
