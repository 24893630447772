
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'

import history from '../../Helpers/History'

import SessionActions from '../../Redux/Actions/SessionActions'

import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'

import InputFormControl from '../../Components/InputFormControl/InputFormControl'
import TopLoader from '../../Components/TopLoader/TopLoader'

const ResetPage = (props: any) => {

    const token = new URLSearchParams(props.location.search).get('token')

    const resetPasswordStatus = useSelector((state: any) => state.SessionReducer.resetPasswordStatus)
    const dispatch = useDispatch()

    const [ new_pass, setNewPass ] = useState('')
    const [ new_pass2, setNewPass2 ] = useState('')

    const resetPassword = () => {

        const data: any = {
            token,
            new_pass
        }

        dispatch(SessionActions.resetPasswordAction(data))
    }

    if (resetPasswordStatus === 'resetted') {

        Swal.fire({
            title: 'Contraseña restablecida',
            text: 'Su contraseña se restableción con éxito, ingrese con su nueva contraseña.',
            icon: 'success',
            confirmButtonText: '¡Cerrar!',
            width: '500px',
            onClose: () => history.push('/login')
        })
    }

    return (
        <AuthLayout>
            <div className="card 0-hidden">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="p-4">
                            <div className="logo text-center mb-4">
                                <img src="/images/logo.svg" alt=""/>
                            </div>
                            <div>
                                <h1 className="h1-title mb-1 text-14">
                                    Restablecer contraseña
                                </h1>

                                <div>
                                    <div className="form-group">
                                        <InputFormControl
                                            type="password"
                                            className="form-control form-control-rounded"
                                            placeholder="Nueva contraseña"
                                            value={new_pass}
                                            onChange={(value: string) => setNewPass(value)}
                                            // autoFocus
                                        />
                                        {/* <span id="errorUsername" className="error-message">{newPassError}</span> */}
                                    </div>
                                    <div className="form-group">
                                        <InputFormControl
                                            type="password"
                                            className="form-control form-control-rounded"
                                            placeholder="Repetir nueva contraseña"
                                            value={new_pass2}
                                            onChange={(value: string) => setNewPass2(value)}
                                        />
                                        {/* <span id="errorUsername" className="error-message">{newPass2Error}</span> */}
                                    </div>
                                    <button
                                        className="btn btn-rounded btn-block mt-2 btn-handle-reset"
                                        onClick={() => resetPassword()}
                                    >
                                        Enviar
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    <Link to="/login" className="btn btn-rounded btn-light btn-block">
                                        <u>Iniciar sesión</u>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TopLoader show={resetPasswordStatus === 'resetting'} />
        </AuthLayout>
    )
}

export default ResetPage
