
const initialState = {
    progressPercentHistory: {
        weeks: [],
    },
}

export default (state = initialState, { type, payload }: any) => {

    const st = Object.assign({}, state);

    switch(type) {
        case 'SET_PROGRESS_PERCENT_HISTORY':
            st.progressPercentHistory = payload;
            break
    }

    return st
}