
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";

import { cancelMembership, getMembershipsList } from "../../../Services/AdminMembershipsService";

import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";

import Icon from "../../../Components/Icon/Icon";
import MembershipCreator from "./Components/MembershipCreator/MembershipCreator";

const AdminMembershipsPage = () => {

  const [ memberships, setMemberships ] = useState<any[]>([])

  const [ filters, setFilters ] = useState<any>({
    criteria: '',
    status: 'all',
    plan: '',
    startsAt: '',
    endsAt: ''
  })

  const [ showCreator, setShowCreator ] = useState(false)
  const [ showFilters, setShowFilters ] = useState(false)

  const loadMemberships = async () => {

    const { data, error } = await getMembershipsList()

    if (error) {
      console.log(error)
      return
    }

    setMemberships(data)
  }

  const handleCancel = async (id: string) => {

    const { isConfirmed } = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Cancelar membresía',
      cancelButtonText: 'Cerrar'
    })

    if (!isConfirmed) return

    const { data, error } = await cancelMembership(id)

    if (error) {
      console.log(error)
      return
    }

    const newMemberships = memberships.filter((membership: any) => membership._id !== data._id)

    setMemberships(newMemberships)

    loadMemberships()
  }

  const handleMembershipCreated = () => {

    loadMemberships()

    setShowCreator(false)
  }

  useEffect(() => {

    loadMemberships()
  }, [])

  return (
    <>
      <AdminLayout title="Membresías" menu="memberships" submenu="list">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex flex-column flex-lg-row justify-content-between pb-2">
                  <Card.Title className="">Membresías</Card.Title>
                  <div className="d-flex flex-row align-items-center align-self-end">
                    <div className="btn-toolbar order-1">
                      <div className="btn-group mr-1" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-secondary"
                          onClick={() => setShowCreator(true)}
                        >
                          <Icon name="agregar" /> Nuevo
                        </button>
                      </div>
                      <InputGroup>
                        <Form.Control
                          placeholder="Buscar..."
                          value={filters.criteria}
                          onChange={(e: any) => setFilters({ ...filters, criteria: e.target.value })}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              loadMemberships();
                            }
                          }}
                        />
                        <InputGroup.Append>
                          <button className="btn btn-normal"
                            onClick={() => loadMemberships()}
                          >
                            <Icon name="buscar" />
                          </button>
                        </InputGroup.Append>
                      </InputGroup>
                      <div className="btn-group ml-1" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-outline-secondary"
                          onClick={() => setShowFilters(true)}
                        >
                          <Icon name="filtro" /> Filtros
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombres y Apellidos</th>
                        <th scope="col" style={{ width: 200 }}>Plan</th>
                        <th scope="col" style={{ width: 100 }}>Cuentas</th>
                        <th scope="col" style={{ width: 110 }}>Inicia</th>
                        <th scope="col" style={{ width: 110 }}>Expira</th>
                        <th scope="col" style={{ width: 120 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberships.map((membership, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            {membership.user.name} {membership.user.surname}
                            <div>
                              <small className="text-muted">{membership.user.email}</small>
                            </div>
                          </td>
                          <td>{membership.plan.name}</td>
                          <td className="text-right">{membership.aditionalSlots + 1}</td>
                          <td>{moment(membership.createdAt).format('DD/MM/YYYY')}</td>
                          <td>{moment(membership.expiresAt).format('DD/MM/YYYY')}</td>
                          <td>
                            <button type="button" className="btn btn-normal"
                              onClick={() => handleCancel(membership._id)}
                            >
                              <i className="fa fa-fw fa-ban"></i> Cancelar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>

      <MembershipCreator
        show={showCreator}
        onCreated={() => handleMembershipCreated()}
        onHide={() => setShowCreator(false)}
      />
    </>
  )
}

export default AdminMembershipsPage;
