
const initialState = {
  weeklyProgress: {
    loadStatus: 'none',
    result: [],
    error: '',
  },
  tasks: {
    loadStatus: 'none',
    completed: [],
    notCompleted: []
  }
}

const IndicatorsPPCReducer = (state = initialState, { type, payload, error }: any) => {

  const st = { ...state }

  if (type === 'INDICATORS_PPC_LOADING') {

    st.weeklyProgress.loadStatus = 'loading'
  } else if (type === 'INDICATORS_PPC_SUCCESS') {

    st.weeklyProgress.loadStatus = 'loaded'
    st.weeklyProgress.result = payload
  } else if (type === 'INDICATORS_PCC_ERROR') {

    st.weeklyProgress.loadStatus = 'error'
    st.weeklyProgress.error = error
  } else if (type === 'INDICATORS_PPC_TASKS_LOADING') {

    st.tasks.loadStatus = 'loading'
  } else if (type === 'INDICATORS_PPC_TASKS_SUCCESS') {

    st.tasks.loadStatus = 'loaded'
    st.tasks.completed = payload.completed
    st.tasks.notCompleted = payload.notCompleted
  } else if (type === 'INDICATORS_PPC_TASKS_ERROR') {

    st.tasks.loadStatus = 'error'
  } else if (type === 'INDICATORS_PPC_CLEANUP') {

    st.weeklyProgress.loadStatus = 'none'
    st.weeklyProgress.result = []
    st.weeklyProgress.error = ''

    st.tasks.loadStatus = 'none'
    st.tasks.completed = []
    st.tasks.notCompleted = []
  }

  return st
}

export default IndicatorsPPCReducer
