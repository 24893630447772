
import React, { useEffect, useMemo, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import InputFormControl from '../../InputFormControl/InputFormControl'
import { useDispatch } from 'react-redux';
import TeamsService from '../../../Services/TeamsService';
import TeamsActions from '../../../Redux/Actions/TeamsActions';

type Props = {
    show?: boolean;
    onHide: () => void;
    onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
    selected?: any;
    team?:any
}

const TeamEditor = (props: Props) => {

    const [ name, setName ] = useState(props.team?.name || '')
    const [ originalName, setOriginalName ] = useState('');
    const [ editable, setEditable ] = useState(false)

    const dispatch = useDispatch()

    const handleUpdateNameTeam = async () =>{
        console.log(name)
        const {error} =  await TeamsService.updateRow(name, props.team._id)

        if(error) return console.log(error)

        dispatch(TeamsActions.getResultAction(props.team.project))
        props.onHide()
        setEditable(false)

    }

    const resetForm = () => {
        setName('')
        props.onHide()
    }

    const saveGroup = () => {
        props.onHide()
        resetForm()
    }


    const handleClose = () => {
        props.onHide()
    }

    const handleCancel = () => {
        setName(props.team.name)
        setEditable(false)
        props.onHide()
    }

    useEffect(() => {
        if (!props.team) return

        setName(props.team.name)
        setOriginalName(props.team.name);
    }, [ props.team])


    return (
        <Modal show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Body>
                <div>
                    <div className="form-group">
                        <label htmlFor="">Nombre:</label>

                        <InputFormControl
                            value={name}
                            onChange={setName}
                            onEnter={() => saveGroup()}
                            onEscape={() => resetForm()}
                            readOnly={!editable}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    justifyContent: 'space-between'
                }}
            >
                <div className="footer-left">

                        <button className="btn btn-danger"
                            onClick={props.onDelete}
                        >
                            <span className="icon-eliminar"></span> Eliminar
                        </button>

                </div>
                <div className="footer-right">
                    {editable && (
                        <>
                            <button className="btn btn-secondary mr-1"
                                onClick={() => handleCancel()}
                            >
                                <span className="icon-cancelar"></span> Cancelar
                            </button>
                            <button
                                className="btn btn-raised btn-link active"
                                onClick={() => handleUpdateNameTeam()}
                                disabled={name === originalName}
                            >
                                <span className="icon-guardar"></span> Guardar
                            </button>
                        </>
                    )}
                    {!editable && (
                        <>
                            <button className="btn btn-secondary mr-1"
                                onClick={() => handleClose()}
                            >
                                <span className="icon-cancelar"></span> Cerrar
                            </button>
                            <button className="btn btn-raised btn-link active"
                                onClick={() => setEditable(true)}
                            >
                                <span className="icon-editar"></span> Editar
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TeamEditor
