
const initialState = {
    showLoader: false,
    heights: {
        headerHeight: 0,
        submenuHeight: 0,
        contentWrapPaddingTop: 32,
        contentWrapPaddingBottom: 16,
        contentHeaderHeight: 0,
        contentBodyHeight: 0,
        footerHeight: 0
    }
}

const InterfaceReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case 'INTERFACE_SHOW_LOADER':
            return { ...state, showLoader: true }
        case 'INTERFACE_HIDE_LOADER':
            return { ...state, showLoader: false }
        case 'INTERFACE_SET_HEIGHT':

            return {
                ...state,
                heights: { ...state.heights, [action.payload.key]: action.payload.value }
            }
        default:
            return { ...state }
    }
}

export default InterfaceReducer
