
import React, { useEffect, useState } from 'react'

import TeamsHelper from '../../Helpers/TeamsHelper'

import InputFormControl from '../InputFormControl/InputFormControl'
import ResponsibleSearch from '../ResponsibleSearch/ResponsibleSearch'

type Props = {
    responsibles: any
    projectMembers: any[]
    disabled?: boolean
    onChange: (responsibles: any) => void
}

const TaskResponsibleList = (props: Props) => {

    const [ state, setState ] = useState({
        responsibles: []
    })

    const onRemoveResponsible = (responsible: any) => {

        const responsibles: any = state.responsibles

        if (responsibles.includes(responsible)) {

            const i = responsibles.indexOf(responsible)

            responsibles.splice(i, 1)

            setState({ ...state, responsibles })

            props.onChange(TeamsHelper.getResponsiblesIds(responsibles))
        }
    }

    const onAddResponsible = (responsible: any) => {

        const responsibles: any = state.responsibles

        if (!responsibles.includes(responsible)) {

            responsibles.push(responsible)

            setState({ ...state, responsibles })

            props.onChange(responsibles)
        }
    }

    useEffect(() => {

        setState({ ...state, responsibles: props.responsibles })
    }, [ props.responsibles ])

    return (
        <div className="form-group">

            <div className="mb-2">
                {state.responsibles.map((responsible: any, i: number) => (
                    <div key={i} className="input-group">
                        <InputFormControl
                            value={` ${responsible.name} ${responsible.surname}`.toUpperCase()}
                            disabled
                        />
                        <div className="input-group-append">
                            <button className="btn btn-normal"
                                onClick={() => onRemoveResponsible(responsible)}
                                disabled={props.disabled}
                            >
                                <span className="icon-eliminar"></span>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <ResponsibleSearch
                text={(
                    <>
                        <span className="icon-agregar-colaborador"></span> Agregar responsable
                    </>
                )}
                onItemSelected={(responsible: any) => onAddResponsible(responsible)}
                listItems={props.projectMembers}
                checkedItems={TeamsHelper.getResponsiblesIds(state.responsibles)}
                disabled={props.disabled}
            />
        </div>
    )
}

export default TaskResponsibleList
