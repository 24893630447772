
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import { removeRestrictionAction } from "../../../Redux/Actions/TasksActions";

interface Props {
  show: boolean
  title: string;
  restrictions: any[];
  project: any;
  maxRestrictionsAllowed: number;
  onHide: () => void;
}

const TooManyRestrictionsModal = (props: Props) => {

  const dispatch = useDispatch()

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleDelete = async (restriction_id: string) => {

    setProcessing([ ...processing, restriction_id ])
    try {
      await dispatch(removeRestrictionAction(restriction_id))

      setProcessing(processing.filter((id) => id !== restriction_id))
    } catch (error) {
      console.error('Error eliminando restricción:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Tu plan actual solo te permite tener {props.maxRestrictionsAllowed} restricciones.
        </p>
        <p>
          <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
            <i className="fas fa-external-link-alt"></i> Explora nuestros planes
          </a> y mejora tu plan para poder crear más restricciones.
        </p>
        <p>
          O si lo prefieres puedes eliminar restricciones para continuar.
        </p>
        <div className="table-responsive">
          <div className="list-group">
            {props.restrictions.map((restriction) => (
              <div
                key={restriction._id}
                className={`list-group-item`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{restriction.description}</strong>
                    <br />
                    <small className="text-muted">
                      Tarea: <strong>{restriction.task}</strong>
                    </small>
                  </div>
                  <div className="btns-group">
                    {processing.includes(restriction._id) && (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>
                        <span className="d-none d-sm-inline"> Procesando...</span>
                      </span>
                    )}
                    {!processing.includes(restriction._id) && (
                      <>
                        <button className="btn btn-danger"
                          onClick={() => handleDelete(restriction._id)}
                        >
                          <i className="fas fa-trash"></i>
                          <span className="d-none d-sm-inline"> Eliminar</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyRestrictionsModal
