
import React from 'react'

import Icon from '../Icon/Icon'
import InputFormControl from '../InputFormControl/InputFormControl'

import './InputSearchControl.scss'

interface InputSearchControlProps {
    value?: string,
    placeholder?: string,
    onChange?: (value: string) => void,
    className?: string,
    style?: any,
    readOnly?: boolean,
    icon?: string
    onIconClick?: () => void
}

const InputSearchControl = (props: InputSearchControlProps) => {

    const handleIconClick = () => {

        if (props.onIconClick) {
            props.onIconClick()
            return
        }
    }

    const cursor = props.onIconClick ? 'pointer' : 'default'

    return (
        <div className='input-search-control'>
            <InputFormControl
                type="text"
                value={props.value || ''}
                placeholder={props.placeholder || ''}
                onChange={props.onChange || (() => { })}
                style={props.style}
                readOnly={props.readOnly || false}
            />
            <div className='icon-container' onClick={() => handleIconClick()}
                style={{ cursor }}
            >
                {!props.icon && <Icon name="buscar" />}
                {props.icon && <Icon name={props.icon} />}
            </div>
        </div>
    )
}

export default InputSearchControl
