
import { Dispatch } from 'redux'
import TasksService, { removeRestriction, loadTasks, removeUserFromTask, updateRestriction } from '../../Services/TasksService'

export const cleanTasksAction = () => {

    return {
        type: 'TASKS_CLEANUP'
    }
}

export const setTasksAction = (result: any[]) => {

    return {
        type: 'TASKS_LOAD_SUCCESS',
        payload: {
            result
        }
    }
}

const getArchive = (project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'TASKS_LOAD_ARCHIVE_START'
        })

        try {

            const res = await TasksService.getArchive(project_id)

            dispatch({
                type: 'TASKS_GET_ARCHIVE_END',
                content: res.data
            })
        } catch (error) {

            //@ts-ignore
            //todo: remove try catch

            dispatch({
                type: 'TASKS_GET_ARCHIVE_ERROR',
                //@ts-ignore
                error: error.message
            })
        }
    }
}

export const createTaskAction = (taskData: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROGRAMMATION_LOAD_START'
        })

        try {

            const res = await TasksService.createTask(taskData)

            const { task_list, task } = res

            dispatch({
                type: 'PROGRAMMATION_ADD_TASK',
                payload: {
                    list: task_list.list,
                    task: task
                }
            })

            dispatch({
                type: 'DASHBOARD_LOAD_NONE'
            })
            dispatch({
                type: 'MACRO_LOAD_NONE'
            })
            dispatch({
                type: 'LOOKAHEAD_LOAD_NONE'
            })
            dispatch({
                type: 'WEEKLY_LOAD_NONE'
            })
            dispatch({
                type: 'TASKS_LOAD_NONE'
            })
            dispatch({
                type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
            })

            return task
        } catch (error) {

            //@ts-ignore
            //todo: remove try catch
            throw error
        }
    }
}

export const updateTaskAction = (taskData: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROGRAMMATION_LOAD_START'
        })

        try {

            const res = await TasksService.updateTask(taskData)

            const { task_list, task } = res

            dispatch({
                type: 'PROGRAMMATION_UPDATE_TASK',
                payload: {
                    list: task_list.list,
                    task,
                }
            })

            dispatch({
                type: 'DASHBOARD_LOAD_NONE'
            })
            dispatch({
                type: 'MACRO_LOAD_NONE'
            })
            dispatch({
                type: 'LOOKAHEAD_LOAD_NONE'
            })
            dispatch({
                type: 'WEEKLY_LOAD_NONE'
            })
            dispatch({
                type: 'TASKS_LOAD_NONE'
            })
            dispatch({
                type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
            })

            return res
        } catch (error) {

            //@ts-ignore
            //todo: remove try catch

            throw error
        }
    }
}

const completeMany = (tasks: any, project: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROGRAMMATION_LOAD_START'
        })

        const res = await TasksService.completeMany(tasks, project)

        if (res.error) {

            dispatch({
                type: 'MACRO_LOAD_ERROR',
                error: res.error
            })
            return false
        }

        const { tasks: completedTasks } = res.data

        dispatch({
            type: 'PROGRAMMATION_COMPLETE_MANY',
            payload: {
                tasks: completedTasks,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })

        return true
    }
}

const reScheculeMany = (tasks: any) => {

    return async (dispatch: any) => {

        dispatch({ type: 'PROGRAMMATION_LOAD_START' })

        const res = await TasksService.reScheculeMany(tasks)

        if (res.error) {

            dispatch({
                type: 'MACRO_LOAD_ERROR',
                error: res.error
            })
            return false
        }

        const { tasks: reScheduledTasks } = res.data

        dispatch({
            type: 'PROGRAMMATION_RESCHEDULE_MANY',
            payload: {
                tasks: reScheduledTasks,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })

        return true
    }
}

const addRestrictionToMany = (tasks: string[], restriction: string) => {

    return async (dispatch: any) => {

        dispatch({ type: 'MACRO_LOAD_STARTED' })

        const res = await TasksService.addRestrictionToMany(tasks, restriction)

        if (res.error) {

            dispatch({
                type: 'MACRO_LOAD_ERROR',
                error: res.error
            })
            return false
        }

        const { tasks: restrictedTasks } = res.data

        dispatch({
            type: 'PROGRAMMATION_RESTRICT_MANY',
            payload: {
                tasks: restrictedTasks,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })

        return true
    }
}

export const removeResponsibleFromTaskAction = (user_id: string, task_id: string) => {

    return async (dispatch: Dispatch) => {

        const res = await removeUserFromTask(user_id, task_id)

        if (res.error) {

            return
        }

        const { data: { task }} = res

        dispatch({
            type: 'PROGRAMMATION_UPDATE_TASK',
            payload: {
                task,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })
    }
}

export const updateRestrictionAction = (restriction_id: string, checked: boolean) => {

    return async (dispatch: Dispatch) => {

        const res = await updateRestriction(restriction_id, checked)

        if (res.error) return

        const { data: { task }} = res

        dispatch({
            type: 'PROGRAMMATION_UPDATE_TASK',
            payload: {
                task,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })
    }
}

export const removeRestrictionAction = (restriction_id: string) => {

    return async (dispatch: Dispatch) => {

        const res = await removeRestriction(restriction_id)

        if (res.error) {

            return
        }

        const { data: { task }} = res

        dispatch({
            type: 'PROGRAMMATION_UPDATE_TASK',
            payload: {
                task,
            }
        })

        dispatch({
            type: 'DASHBOARD_LOAD_NONE'
        })
        dispatch({
            type: 'MACRO_LOAD_NONE'
        })
        dispatch({
            type: 'LOOKAHEAD_LOAD_NONE'
        })
        dispatch({
            type: 'WEEKLY_LOAD_NONE'
        })
        dispatch({
            type: 'TASKS_LOAD_NONE'
        })
        dispatch({
            type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
        })
    }
}

export const removeTaskAction = (task: any, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROGRAMMATION_LOAD_START'
        })

        try {

            const res = await TasksService.deleteTask(task, project_id)

            const { task_list } = res

            dispatch({
                type: 'PROGRAMMATION_REMOVE_TASK',
                payload: {
                    task_id: task._id,
                    list: task_list.list
                }
            })

            dispatch({
                type: 'DASHBOARD_LOAD_NONE'
            })
            dispatch({
                type: 'MACRO_LOAD_NONE'
            })
            dispatch({
                type: 'LOOKAHEAD_LOAD_NONE'
            })
            dispatch({
                type: 'WEEKLY_LOAD_NONE'
            })
            dispatch({
                type: 'TASKS_LOAD_NONE'
            })
            dispatch({
                type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
            })
            } catch (error) {

            dispatch({
                type: 'TASK_DELETE_ERROR',
                //@ts-ignore
                error: error.message
            })
        }
    }
}

const confirmOrReject = (task_id: string, action: 'confirm' | 'reject') => {

    return async (dispatch: Dispatch) => {

        dispatch({
            type: 'PROGRAMMATION_LOAD_START'
        })

        try {

            const res = await TasksService.confirmOrReject(task_id, action)

            const { task } = res

            dispatch({
                type: 'PROGRAMMATION_CONFIRM_REJECT_TASK',
                payload: {
                    task,
                }
            })

            dispatch({
                type: 'DASHBOARD_LOAD_NONE'
            })
            dispatch({
                type: 'MACRO_LOAD_NONE'
            })
            dispatch({
                type: 'LOOKAHEAD_LOAD_NONE'
            })
            dispatch({
                type: 'WEEKLY_LOAD_NONE'
            })
            dispatch({
                type: 'TASKS_LOAD_NONE'
            })
            dispatch({
                type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
            })

            return true
        } catch (error) {

            //todo: remove try catch

            return false
        }
    }
}

export default {
    getArchive,
    create: createTaskAction,
    update: updateTaskAction,
    completeMany,
    reScheculeMany,
    addRestrictionToMany,
    remove: removeTaskAction,
    confirmOrReject,
}
