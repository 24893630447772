
import * as XLSX from 'xlsx';

const handleAdjustColumnsWidth = (worksheet: any, data: any) => {

  if(!worksheet["!cols"]) worksheet["!cols"] = [];

  const columns = worksheet["!cols"]
  const columnsWidths: number[] = []

  data.forEach((row: any) => {

    Object.keys(row).forEach((key: string, i: number) => {

      const value = row[key] || ''

      if (!columnsWidths[i]) {

        columnsWidths[i] = 0
      }

      const length = value.length > key.length ? value.length : key.length

      if (length > columnsWidths[i]) {

        columnsWidths[i] = length
      }
    })
  })

  columnsWidths.forEach((width: number, i: number) => {

    columns[i] = { wpx: width * 8 }
  })
}

export interface ICreateExcelFileOptions {
  filename?: string;
  adjustColumnsWidth?: boolean;
}

export const createExcelFile = async (data: any, { filename, adjustColumnsWidth }: ICreateExcelFileOptions) => {

  const worksheet = XLSX.utils.json_to_sheet(data);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja 1");

  if (adjustColumnsWidth) {
    handleAdjustColumnsWidth(worksheet, data);
  }

  XLSX.writeFile(workbook, filename || "archivo.xlsx", { compression: true });
}
