
import config from "../config"

import { post } from "./ApiService"

export const assignCNC = async (task_weekly_progress_id: string, cnc_id: string) => {

  const url = config.apiHost + '/resources-cnc/' + task_weekly_progress_id

  return await post(url, { cnc_id }, 'RESOURCES_ASSING_CNC')
}
