
import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as RadarChartRecharts,
  ResponsiveContainer,
  Tooltip
} from "recharts";

const RadarChart = (props: any) => {

  return (
    <div style={{
      height: '300px',
      width: '100%',
    }}>
      <ResponsiveContainer>
        <RadarChartRecharts
          cx="50%"
          cy='50%'
          outerRadius="80%"
          data={props.data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis />
          <Tooltip />
          <Radar name="Suscripciones" dataKey="A" stroke="#663299" fill="#663299" fillOpacity={0.5} />
        </RadarChartRecharts>
      </ResponsiveContainer>
    </div>
  )
}

export default RadarChart;
