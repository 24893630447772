
import React, { useState } from "react";
import { Col, FormControl, FormGroup, FormLabel, Modal, Row } from "react-bootstrap";
import Icon from "../../../../../Components/Icon/Icon";
import moment from "moment";
import { acceptOrder, rejectOrder } from "../../../../../Services/AdminMembershipsService";

import { useDialog } from "../../../../../Hooks/UseDialog";

interface OrdersViewerProps {
  show: boolean;
  order: any;
  onHide: () => void;
  onApproved: () => void;
  onRejected: () => void;
}

const OrdersViewer = (props: OrdersViewerProps) => {

  const { prompt } = useDialog()

  const user = props.order.user || {};
  const plan = props.order.plan || {};

  const [ processing, setProcessing ] = useState(false);
  const [ rejecting, setRejecting ] = useState(false);
  const [ showingPrompt, setShowingPrompt ] = useState(false);

  const [ paymentId, setPaymentId ] = useState('')

  const handleReject = async () => {

    setRejecting(true);
    setShowingPrompt(true);

    const { isAccepted, message } = await prompt({
      title: 'Rechazar orden de compra',
      acceptText: (
        <>
          <Icon name="trash" fas /> Rechazar
        </>
      ),
      cancelText: (
        <>
          <Icon name="times" fas /> Cancelar
        </>
      ),
      acceptBtnVariant: 'danger',
    })

    setShowingPrompt(false);

    if (!isAccepted) {
      setRejecting(false);
      return
    }

    const { error } = await rejectOrder(props.order._id, message)

    if (error) {
      console.log(error)
      return
    }

    setRejecting(false);
    props.onRejected()
  }

  const handleAccept = async () => {

      setProcessing(true);

      const { error } = await acceptOrder(props.order._id, paymentId)

      if (error) {
        console.log(error)
        return
      }

      setProcessing(false);

      props.onApproved()
  }

  return (
    <Modal
      show={props.show && !showingPrompt}
      onHide={() => props.onHide()}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Orden de compra {props.order._id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            {props.order.companyName && (
              <FormGroup>
                <FormLabel className='col-sm-12'>Razón Social</FormLabel>
                <Col sm={7}>
                  <p>{props.order.companyName}</p>
                </Col>
              </FormGroup>
            )}
            {props.order.companyRuc && (
              <FormGroup>
                <FormLabel className='col-sm-12'>RUC</FormLabel>
                <Col sm={7}>
                  <p>{props.order.companyRuc}</p>
                </Col>
              </FormGroup>
            )}
            {props.order.companyAddress && (
              <FormGroup>
                <FormLabel className='col-sm-12'>Dirección</FormLabel>
                <Col sm={7}>
                  <p>{props.order.companyAddress}</p>
                </Col>
              </FormGroup>
            )}
            <FormGroup>
              <FormLabel className='col-sm-12'>Nombres y apellidos</FormLabel>
              <Col sm={7}>
                <p>{user.name} {user.surname}</p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Email</FormLabel>
              <Col sm={12}>
                <p>
                  {user.email} <br />
                  <a href={`mailto:${user.email}`} className="btn btn-normal">
                    <Icon name="envelope" fas /> Enviar correo electrónico
                  </a>
                </p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Teléfono</FormLabel>
              <Col sm={7}>
                <p>
                  {user.phone} <br />
                  <a href={`tel:${user.phone}`} className="btn btn-normal">
                    <Icon name="phone" fas /> Llamar
                  </a>
                </p>
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <FormLabel className='col-sm-12'>Plan</FormLabel>
              <Col sm={7}>
                <p>{plan.name}</p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Renovación</FormLabel>
              <Col sm={7}>
                <p>{props.order.renewalType === 'yearly' ? 'Anual' : 'Mensual'}</p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Usuarios</FormLabel>
              <Col sm={7}>
                <p>{props.order.users}</p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Monto</FormLabel>
              <Col sm={7}>
                <p>
                  <Icon name="dollar-sign" fas />{' '}
                  {Number(props.order.amount).toFixed(2)}
                </p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Fecha de solicitud</FormLabel>
              <Col sm={7}>
                <p>{moment(props.order.createdAt).format('DD/MM/YYYY H:ss a')}</p>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Paga usando</FormLabel>
              <Col sm={7}>
                <FormControl as="select">
                  <option value="creditCard">Culqi</option>
                  {/* <option value="bankTransfer">Transferencia bancaria</option> */}
                </FormControl>
              </Col>
            </FormGroup>
            <FormGroup>
              <FormLabel className='col-sm-12'>Id de Pago</FormLabel>
              <Col sm={10}>
                <FormControl type="text"
                  value={paymentId}
                  onChange={(e: any) => setPaymentId(e.target.value)}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <div className="footer-left">
          <button type="button" className="btn btn-danger"
            onClick={() => handleReject()}
            disabled={rejecting || processing}
          >
            <Icon name="cancelar" /> {!rejecting ? 'Rechazar' : 'Rechazando...'}
          </button>
        </div>
        <div className="footer-right">
          <button type="button" className="btn btn-primary"
            onClick={() => handleAccept()}
            disabled={processing || !paymentId}
          >
            <Icon name="guardar" /> Aprobar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default OrdersViewer;

