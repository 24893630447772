import config from "../config"

export const debug = (title: string, body: any) => {

    if (config.env === 'production') return

    console.group(title)
    console.log(body)
    console.groupEnd()
}

export const log = (...params: any) => {

    if (config.env === 'production') return

    console.log(...params)
}
