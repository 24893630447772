
import React, { memo } from "react";

import { ProgrammationSchema } from "./types";

import GanttChartBodyMilestone from "./GanttChartBodyMilestone";
import GanttChartBodyMeeting from "./GanttChartBodyMeeting";
import GanttChartBodyTask from "./GanttChartBodyTask";
import GroupBar from "./GroupBar";

interface Props {
  item: any
  days: any[]
  dayWidth: number
  rowHeight: number
  useCodeColors: boolean
  schema: ProgrammationSchema
  lineBaseTasks: boolean
  selectedItems: any[]
  onItemClick: (item: any, isItRight: boolean) => void
}

const GanttChartBodyItem = (props: Props) => {

  const {
    item,
  } = props

  const isSelected = props.selectedItems.some((selectedItem) => selectedItem._id === props.item._id)

  return (
    <>
      {item.schema === 'task' && (
        <g className={`gantt-chart-item ${isSelected ? 'active' : ''}`}>
          {item.type === 'hito' && (
            <GanttChartBodyMilestone
              left={item.left}
              top={item.top}
            />
          )}
          {item.type === 'reunion' && (
            <GanttChartBodyMeeting
              left={item.left}
              top={item.top}
            />
          )}
          {(item.type !== 'hito' && item.type !== 'reunion') && (
            <GanttChartBodyTask
              item={item}
              ganttWidth={props.days.length * props.dayWidth}
              useCodeColors={props.useCodeColors}
              schema={props.schema}
              lineBaseTasks={props.lineBaseTasks}
              onClick={props.onItemClick}
            />
          )}
        </g>
      )}
      {item.schema === "group" && (
        <GroupBar
          schema={props.schema}
          item={item}
          selectedItems={props.selectedItems}
          useCodeColors={props.useCodeColors}
          onItemClick={props.onItemClick}
        />
      )}
    </>
  )
}

export default GanttChartBodyItem
