
import { Dispatch } from "redux";
import {
  loadColorCodes,
  loadRelations,
  loadTasklist,
  loadTasks,
  loadUsers,
  loadVersions
} from "../../Services/ProgrammationService";

import { getTasksColorCodes, getTasksUsers } from "../../Helpers/TasksHelper";

export const cleanProgrammationAction = () => {

  return {
    type: 'PROGRAMMATION_CLEANUP'
  }
}

export const loadProgrammationAction = (project_id: string) => {

  return async (dispatch: Dispatch) => {

    dispatch({
      type: 'PROGRAMMATION_LOAD_START'
    })

    const payload: any = {
      list: [],
      tasks: [],
      users: [],
      colorCodes: [],
      relations: [],
      versions: []
    }

    const { data: { taskList }, error: err_taskList} = await loadTasklist(project_id)

    if (err_taskList) {

      dispatch({
        type: 'PROGRAMMATION_LOAD_ERROR',
        error: err_taskList
      })
      return false
    }

    payload.list = taskList.list

    const { data: { tasks }, error : err_tasks } = await loadTasks(project_id)

    if (err_tasks) {

      dispatch({
        type: 'PROGRAMMATION_LOAD_ERROR',
        error: err_tasks
      })
      return false
    }

    if (tasks.length) {

      payload.tasks = tasks

      const users_ids = getTasksUsers(tasks)

      if (users_ids.length) {

        const { data: { users }, error: err_users } = await loadUsers(project_id, users_ids)

        if (err_users) {

          dispatch({
            type: 'PROGRAMMATION_LOAD_ERROR',
            error: err_users
          })
          return false
        }

        payload.users = users
      }

      const color_codes_ids = getTasksColorCodes(tasks)

      if (color_codes_ids.length) {

        const { data: { colorCodes }, error: err_colorcodes } = await loadColorCodes(project_id, color_codes_ids)

        if (err_colorcodes) {

          dispatch({
            type: 'PROGRAMMATION_LOAD_ERROR',
            error: err_colorcodes
          })
          return false
        }

        payload.colorCodes = colorCodes
      }

      const tasks_ids = tasks.map((task: any) => task._id)

      const { data: { relations }, error: err_relations } = await loadRelations(project_id, tasks_ids)

      if (err_relations) {

        dispatch({
          type: 'PROGRAMMATION_LOAD_ERROR',
          error: err_relations
        })
        return false
      }

      payload.relations = relations

      const { data: { versions }, error: err_versions } = await loadVersions(project_id, tasks_ids)

      if (err_versions) {

        dispatch({
          type: 'PROGRAMMATION_LOAD_ERROR',
          error: err_versions
        })
        return false
      }

      payload.versions = versions
    }

    dispatch({
      type: 'PROGRAMMATION_LOAD_END',
      payload,
    })

    dispatch({
      type: 'DASHBOARD_LOAD_NONE'
    })
    dispatch({
      type: 'MACRO_LOAD_NONE'
    })
    dispatch({
      type: 'LOOKAHEAD_LOAD_NONE'
    })
    dispatch({
      type: 'WEEKLY_LOAD_NONE'
    })
    dispatch({
      type: 'TASKS_LOAD_NONE'
    })
    dispatch({
      type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
    })

    return true
  }
}
