
interface State {
    loadResultStatus: string,
    result: any
}

const initialState: State = {
    loadResultStatus: 'none',
    result: []
}

const UsersReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === 'USERS_GET_RESULT_START') {

        st.loadResultStatus = 'loading'
    } else if (action.type === 'USERS_GET_RESULT_END') {
        
        st.loadResultStatus = 'loaded'
        st.result = action.content.data
    } else if (action.type === 'USERS_GET_RESULT_ERROR') {
        
        st.loadResultStatus = action.content.error
    } else if (action.type === 'USERS_GET_RESULT_RESET') {
        
        st.loadResultStatus = 'none'
        st.result = []
    }

    return st
}

export default UsersReducer
