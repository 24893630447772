import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

type File = {
  originalName: string;
  size: number;
  type: string;
  mimeType: string;
  extension: string;
  src: string;
  height?: number;
  width?: number;
  canvas?: string;
};

type Props = {
  folder: string;
  onFileRead: (file: any, err?: any) => void;
};

const ImageCanvasReader = (props: Props) => {
  const fileRef: any = useRef();
  const canvasRef: any = useRef();

  const [file, setFile] = useState<File>();

  const handleFileSelected = (e: any) => {
    const { files } = e.target;

    if (!files.length) return;

    const file = files[0];

    setFile({
      originalName: file.name,
      size: file.size,
      mimeType: "image/png",
      type: props.folder,
      extension: "png",
      src: URL.createObjectURL(file),
    });
  };

  useEffect(() => {
    const drawCanvas = (img: any) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const w = img.width as number;
      const h = img.height as number;

      canvas.width = w;
      canvas.height = h;

      ctx.drawImage(img, 0, 0, w, h, 0, 0, w, h);

      props.onFileRead({
        ...file,
        width: w,
        height: h,
        canvas: canvas.toDataURL(),
      });
    };

    const readImage = (file: any) => {
      const img = new Image();
      img.addEventListener("load", () => {
        drawCanvas(img);
      });

      img.src = file.src;
    };

    if (!file) return;

    readImage(file);
  }, [file]);

  return (
    <>
      <Form.Control
        ref={fileRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelected}
        className="border-0"
      />

      <canvas
        ref={canvasRef}
        className="d-none"
        style={{ height: "256px" }}></canvas>
    </>
  );
};

export default ImageCanvasReader;
