
interface INotCompletedWeeklyProgressState {
  loadStatus: 'none' | 'loading' | 'loaded' | 'error',
  week: number | undefined,
  result: any[]
}

interface IState {
  notCompleted: INotCompletedWeeklyProgressState,
  assigningList: string[]
}

const initialState: IState = {
  notCompleted: {
    loadStatus: 'none',
    week: undefined,
    result: []
  },
  assigningList: [],
}

const ResourcesCNCReducer = (state = initialState, { type, payload, error }: any) => {

  const st = { ...state }

  if (type === 'RESOURCES_CNC_CLEANUP') {

    st.notCompleted.loadStatus = 'none'
    st.notCompleted.result = []

    st.assigningList = []

  } else if (type === 'RESOURCES_CNC_NOT_COMPLETED_LOADING') {

    st.notCompleted.loadStatus = 'loading'
  } else if (type === 'RESOURCES_CNC_NOT_COMPLETED_SUCCESS') {

    st.notCompleted.loadStatus = 'loaded'
    st.notCompleted.week = payload.week
    st.notCompleted.result = payload.notCompleted
  } else if (type === 'RESOURCES_CNC_NOT_COMPLETED_ERROR') {

    st.notCompleted.loadStatus = 'error'
  } else if (type === 'RESOURCES_CNC_ASSIGNING') {

    st.assigningList.push(payload.task_weekly_progress_id)
  } else if (type === 'RESOURCES_CNC_ASSIGNING_SUCCESS') {

    st.notCompleted.result = st.notCompleted.result.map((taskWeeklyProgresses: any) => {

      if (taskWeeklyProgresses._id === payload.data._id) {

        return payload.data
      }

      return taskWeeklyProgresses
    })

    st.assigningList = st.assigningList.filter((id: string) => id === payload.task_weekly_progress_id)
  } else if (type === 'RESOURCES_CNC_ASSIGNING_ERROR') {

    st.assigningList = st.assigningList.filter((id: string) => id === payload.task_weekly_progress_id)
  }

  return st
}

export default ResourcesCNCReducer
