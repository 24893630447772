
import React from 'react'

type Props = {
    baseUrl?: string
    src: string
    onClick: () => void
    disabled?: boolean
}

const Avatar = (props: Props) => {

    return (
        <div data-testid="avatar" className={`avatar ${props.disabled && 'disabled'}`} onClick={() => props.onClick()}>
            {props.src ? (
                <img src={`${props.baseUrl}/${props.src}`} />
            ) : (
                <div className="text">
                    No Avatar
                </div>
            )}
        </div>
    )
}

export default Avatar
