
import React, { useEffect, useRef, useState } from 'react'

import Form from 'react-bootstrap/esm/Form'
import Card from 'react-bootstrap/esm/Card'

import { uploadFile } from './UploadService'
import { Modal } from 'react-bootstrap'

const ImageUploader = (props: any) => {

    const fileRef: any = useRef()

    const [ file, setFile ] = useState('')
    const [ hasFile, setHasFile ] = useState(false)
    const [ fileUploaded, setFileUploaded ] = useState<any>({})
    const [ submitting, setSubmitting ] = useState(false)
    const [ image, setImage ] = useState('http://placehold.jp/32/cdaeeb/663399/512x512.png?text=Haz%20click%20aqu%C3%AD%0Apara%20elegir%20una%20imagen')
    const [ error, setError ] = useState('')

    const openFileSelector = (e: any) => {
        e.preventDefault()

        fileRef.current.click()
    }

    const onFileChange = (file: any) => {

        setFile(file)
    }

    const uploadImage = async () => {

        setSubmitting(true)

        try {

            const res = await uploadFile(props.url, file, props.headers, props.type)

            setFile('')
            setHasFile(false)

            setFileUploaded(res.data.file)

            const file_name = `/${res.data.file.fullPath}`

            setImage(`${props.baseUrl}${file_name}`)

            fileRef.current.value = ''
        } catch (error) {

            if (error instanceof Error) {

                setError(error.message)
            }
        }

        setHasFile(false)
        setSubmitting(false)
    }

    const acceptUpload = () => {

        props.onUploadFile(fileUploaded)
    }

    useEffect(() => {

        if (file) {

            uploadImage()
        }
    }, [ file ])

    useEffect(() => {

        if (props.src) {

            setImage(props.src)
        }
    }, [ props.src ])

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Subir foto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div data-testid="image-uploader" className="modal-uploader">
                    <Form.Control
                        ref={fileRef}
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e: any) => onFileChange(e.target.files[0])}
                    />
                    <Card>
                        <Card.Img variant="top" className="img-background" src={`${image}`} role="button" onClick={(e: any) => openFileSelector(e)} />
                        <div className="img-overlay"></div>
                        <div className="img-rounded">
                            <Card.Img variant="top" src={`${image}`} role="button" onClick={(e: any) => openFileSelector(e)} />
                        </div>
                    </Card>
                    {!error ? null : (
                        <>
                            {error}
                        </>
                    )}
                </div>
            </Modal.Body>
            {fileUploaded._id !== undefined && (
                <Modal.Footer>
                    <div className="text-right">
                        <button className="btn btn-secondary mr-1">
                            <span className="icon-cancelar icon-fix-size"></span> Cancelar
                        </button>
                        <button className="btn btn-primary"
                            onClick={() => acceptUpload()}
                        >
                            <span className="icon-guardar icon-fix-size"></span> Aceptar
                        </button>
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default ImageUploader
