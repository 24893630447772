
import { loadRestrictions } from "../../Services/ResourcesRestrictionsService"

export const cleanResourcesRestrictionsAction = () => {

  return {
    type: 'RESOURCES_RESTRICTIONS_CLEANUP'
  }
}

export const regenerateResourcesRestrictions = () => {

  return {
    type: 'RESOURCES_RESTRICTIONS_LOAD_NONE'
  }
}

export const setResourcesRestrictionsAction = (result: any[]) => {

  return {
    type: 'RESOURCES_RESTRICTIONS_LOAD_SUCCESS',
    payload: {
      result
    }
  }
}

export const setResourcesRestrictionsItemsAction = (items: any[]) => {

  return {
    type: 'RESOURCES_RESTRICTIONS_SET_ITEMS',
    payload: {
      items
    }
  }
}
