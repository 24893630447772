
import React from 'react'

import History from '../../Helpers/History'

const MainLayoutTopBar = (props: any) => {

    const {
        goBack,
        rightBar
    } = props.toolbars

    return (
        <div className="horizontal-bar-wrap">
            <div className="header-topnav">
                <div className="container-fluid">
                    {!goBack ? null : (
                        <div className="float-left">
                            <div className="btn-toolbar mt-2 mb-2">
                                <div className="btn-group">
                                    <button 
                                        className={`btn btn-secondary`}
                                        onClick={() => History.goBack()}
                                    >
                                        <i className="fas fa-arrow-left fa-fw"></i> Regresar
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {!rightBar ? null : (
                        <div className="topnav rtl-ps-none float-right">
                            <div className="btn-toolbar mt-2 mb-2">
                                {rightBar.map((btn: any, i: number) => (
                                    <div key={i} className="btn-group">
                                        <button 
                                            className={`btn ${btn.className}`}
                                            onClick={() => btn.onClick()}
                                        >
                                            {!btn.icon ? null : <i className={btn.icon}></i>} {btn.title}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MainLayoutTopBar
