
import moment from "moment";
import React from "react";

const ChangeDate = ({ name, from, to }: any) => {

  const dateName = () => {

    switch (name) {
      case 'startsAt': return 'inicio'
      case 'endsAt': return 'fin'
      case 'startedAt': return 'inicio real'
      case 'endedAt': return 'fin real'
      case 'releasesAt': return 'liberación'
    }
  }

  if (!from && !to) return null

  return (
    <>
      {!from && (
        <>
          La fecha de {dateName()} se estableció a <span className="change added">{moment(to).format("DD/MM/YYYY")}</span>
        </>
      )}
      {from && (
        <>
          La fecha de {dateName()} cambió de <span className="change removed">{moment(from).format('DD/MM/YYYY')}</span> a <span className="change added">{moment(to).format("DD/MM/YYYY")}</span>
        </>
      )}
    </>
  )
}

export default ChangeDate
