
import { del, get, post, put } from './ApiService'

import config from '../config'

export const loadMacro = async (project_id: string, userFilters?: ITaskListFilters) => {

  const defFilters = {
    user: '',
    state: '',
    team: '',
    group: '',
    code: '',
    type: '',
    start: '',
    end: '',
  }

  const filters = {
    ...defFilters,
    ...userFilters
  }

  const filterParts = Object.entries(filters)
      .filter(([ key, value ]) => value !== '')
      .map(([ key, value ]) => `${key}=${value}`)
      .join('&')

  const url = `${config.apiHost}/task-list/${project_id}/macro?${filterParts}`

  return await get(url, 'GET_MACRO')
}

export const moveItem = async (project_id: string, action: any) => {

  const url = config.apiHost + '/task-list/' + project_id + '/move'

  return await put(url, action, 'MOVE_ITEM')
}

export const createGroup = async (project_id: string, group: any) => {

  const url = config.apiHost + '/task-list/' + project_id + '/group'

  return await post(url, group, 'CREATE_GROUP')
}

export const updateGroup = async (project_id: string, group: any) => {

  const url = `${config.apiHost}/task-list/${project_id}/group/${group._id}`

  return await put(url, group, 'UPDATE_GROUP')
}

export const deleteGroup = async (project_id: string, group_id: string) => {

  const url = `${config.apiHost}/task-list/${project_id}/group/${group_id}`

  return await del(url, 'DELETE_GROUP')
}

export const importFromExcel = async (project_id: string, body: any) => {

  const url = config.apiHost + '/task-list/' + project_id + '/import-from-excel'

  return await post(url, { data: body }, 'IMPORT_FROM_EXCEL')
}
