
export const cleanWeekly = () => {

  return {
    type: 'WEEKLY_CLEANUP'
  }
}

export const regenerateWeekly = () => {

  return {
    type: 'WEEKLY_LOAD_NONE'
  }
}

export const setWeeklyAction = (result: any[], start: string, end: string, week: number, collapsedGroups: string[]) => {

  return {
    type: 'WEEKLY_LOAD_SUCCESS',
    payload: {
      result,
      start,
      end,
      week,
      collapsedGroups,
    }
  }
}

export const setWeeklyCollapsedGroupsAction = (collapsedGroups: string[]) => {

  return {
    type: 'WEEKLY_SET_COLLAPSED_GROUPS',
    payload: {
      collapsedGroups,
    }
  }
}

export const setWeeklyGanttItemsAction = (ganttItems: any[]) => {

  return {
    type: 'WEEKLY_SET_GANTT_ITEMS',
    payload: {
      ganttItems
    }
  }
}
