
import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import config from "../../../config";

import { removeResponsibleFromTaskAction } from "../../../Redux/Actions/TasksActions";

import Icon from "../../Icon/Icon";

interface Props {
  show: boolean
  title: string;
  collaborators: any[];
  maxTasksAllowed: number;
  isMyPlan: boolean;
  onHide: () => void;
}

const TooManyTasksModal = (props: Props) => {

  const dispatch = useDispatch()

  const [ processing, setProcessing ] = useState<string[]>([])

  const handleRemove = async (user_id: string, task_id: string) => {

    setProcessing([ ...processing, task_id ])
    try {
      await dispatch(removeResponsibleFromTaskAction(user_id, task_id))

      setProcessing(processing.filter((id) => id !== task_id))
    } catch (error) {
      console.error('Error eliminando al observador:', error)
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="h4">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          El plan actual solo permite tener a los colaboradores {props.maxTasksAllowed} tareas por proyecto.
        </p>
        {props.isMyPlan && (
          <>
            <p>
              <a href={`${config.accountsHost}/plans`} target="_blank" rel="noreferrer">
                <i className="fas fa-external-link-alt"></i> Explora nuestros planes
              </a> y mejora tu plan para poder agregar más observadores.
            </p>
            <p>
              O si lo prefieres puedes eliminar a los responsables de las tareas para continuar.
            </p>
            <div className="table-responsive">
              {props.collaborators.map((collaborator) => (
                <Fragment key={collaborator.user._id}>
                  <div className="list-group-header">
                    <h5>{collaborator.user.name} {collaborator.user.surname}</h5>
                  </div>
                  <div className="list-group list-group-small">
                    {collaborator.tasks.map((task: any) => (
                      <div
                        key={task._id}
                        className={`list-group-item`}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <strong>{task.name}</strong>
                          </div>
                          <div className="btns-group">
                            {processing.includes(task._id) && (
                              <span>
                                <i className="fas fa-spinner fa-spin"></i>
                                <span className="d-none d-sm-inline"> Procesando...</span>
                              </span>
                            )}
                            {!processing.includes(task._id) && (
                              <>
                                <button className="btn btn-danger"
                                  onClick={() => handleRemove(collaborator.user._id, task._id)}
                                >
                                  <Icon name="eliminar" />
                                  <span className="d-none d-sm-inline"> Eliminar</span>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TooManyTasksModal

