import React from 'react';

import { TChat, TChatMessage, TChatUser } from '../../../Types/Chat';

import { useAuth } from '../../../Hooks/UseAuth';
import { useChat } from '../../../Hooks/UseChat';

import UserAvatar from '../../UserAvatar/UserAvatar';

import './ChatListItem.scss'

interface XChatListItemProps {
  project?: any
  chat: TChat
  active?: boolean
  onClick?: () => void
}

const XChatListItem: React.FC<XChatListItemProps> = ({ chat, onClick, project, active }: XChatListItemProps) => {

  const { userData } = useAuth()

  const { currentChat } = useChat()

  const getPrivateChatUserAvatar = (chat: TChat) => {

    if (chat.user?._id === '1') {
      return <div className={`chat-icon-ia-profile  ${!active ? 'inactive' : ''}`}><i className="fas fa-fw fa-robot fa-sm" ></i></div>
    }

    const userTo = chat.user ? chat.user : chat.users?.find((user: TChatUser) => user._id !== userData?.id)

    if (!userTo) return null

    return <UserAvatar user={userTo} />
  }

  const getPrivateChatUserName = (chat: TChat) => {

    if (chat.user?._id === '1') {
      return <div className="text-ai-name">Pl4nner IA</div >
    }

    const userTo = chat.user ? chat.user : chat.users?.find((user: TChatUser) => user._id !== userData?.id)

    if (!userTo) return ''

    return `${userTo.name} ${userTo.surname}`
  }

  const getLastMessageSenderName = (message: TChatMessage) => {

    if (message.isAutomatic) return 'Pl4nner IA'

    if (message.sender._id === userData?.id) return 'Tú'

    return message.sender.name
  }

  return (
    <>
      <div className={`x-chat-list-item ${(currentChat && currentChat._id === chat._id) || active ? 'active' : ''}`}
        onClick={() => onClick && onClick()}
      >
        <div className='chat-icon'>
          {chat.type === 'project' && (
            <span className={project === userData?.id ? 'icon-mis_proyectos' : 'icon-otros_proyectos'}></span>
          )}
          {chat.type === 'team' && (
            <span className='icon-grupo_equipo'></span>
          )}
          {chat.type === 'private' && getPrivateChatUserAvatar(chat)}
        </div>
        <div className="chat-texts">
          <div className="chat-title">
            {chat.title}
            {(!chat.title && chat.type === 'project') && chat.project?.name}
            {(!chat.title && chat.type === 'team') && chat.team?.name}
            {(!chat.title && chat.type === 'private') && getPrivateChatUserName(chat)}
          </div>
          {chat.type === 'team' && (
            <div className="chat-subtitle">
              <span className="icon-proyecto"></span> {chat.project?.name}
            </div>
          )}
          <div className="chat-last-message">
            {chat.lastMessage && (
              <>
                {getLastMessageSenderName(chat.lastMessage)}: {chat.lastMessage?.content}
              </>
            )}
          </div>
        </div>
        {chat.hasNewMessages && (
          <div className='chat-alert'></div>
        )}
      </div>
    </>
  );
};

export default XChatListItem;
