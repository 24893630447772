import React from 'react';



type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TeamMemberMovedNotification = (props: Props) => {

    if (!props.data.team) {

        return null
    }

    return (
        <div className={`${props.isNotRead ? 'dropdow.data' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>Proyecto {props.data.project.name}</span>
                    {/* <span>{props.notification.userFrom.name} {props.notification.userFrom.surname} le ha asignado un nuevo perfil de usuario </span> */}
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.data.userTo._id === props.data.team_member._id && (
                            <span>{props.data.userFrom.name} {props.data.userFrom.surname} te ha cambiado al equipo <strong style={{ textTransform: 'uppercase' }}>{props.data.team.name}</strong></span>
                        )}
                        {props.data.userTo._id !== props.data.team_member._id && (
                            <span><strong>{props.data.userFrom.name} {props.data.userFrom.surname}</strong> ha cambiado a <strong>{props.data.team_member.name} {props.data.team_member.surname}</strong> al equipo <strong style={{ textTransform: 'uppercase' }}>{props.data.team.name}</strong></span>
                        )}
                        {/* ha cambiado al equipo <strong style={{ textTransform: 'uppercase' }}>{props.notification.team.name}</strong> */}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default TeamMemberMovedNotification