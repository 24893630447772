import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { AnyAbility, defineAbility } from "@casl/ability";
import { useSelector } from "react-redux";

import config from "../config";

import TooManyProjectsModal from "../Components/MembershipModals/TooManyprojectsModal/TooManyprojectsModal";
import TooManyArchivedProjectsModal from "../Components/MembershipModals/TooManyArchivedProjectsModal/TooManyArchivedProjectsModal";
import TooManyTeamsModal from "../Components/MembershipModals/TooManyTeamsModal/TooManyTeamsModal";
import TooManyObserversModal from "../Components/MembershipModals/TooManyObserversModal/TooManyObserversModal";
import TooManyCollaboratorsModal from "../Components/MembershipModals/TooManyCollaboratorsModal/TooManyCollaboratorsModal";
import { getAllRestrictions, getAllUsersTasks } from "../Helpers/TaskListHelper";
import TooManyRestrictionsModal from "../Components/MembershipModals/TooManyRestrictionsModal/TooManyRestrictionsModal";
import TooManyTasksModal from "../Components/MembershipModals/TooManyTasksModal/TooManyTasksModal";
import CantCreateCustomCNCsModal from "../Components/MembershipModals/CantCreateCustomCNCsModal/CantCreateCustomCNCsModal";
import CantWriteToChatModal from "../Components/MembershipModals/CantWriteToChatModal/CantWriteToChatModal";
import TooManyOthersProjectsModal from "../Components/MembershipModals/TooManyOthersProjectsModal/TooManyOthersProjectsModal";

interface PlanContextValue {
  membershipsEnabled: boolean;
  plan: AnyAbility;
  features: any;
  projectFeatures: any,
  projectRestrictions: any;
  tooManyActiveProjects: boolean;
  tooManyArchivedProjects: boolean;
  tooManyOthersProjects: boolean;
  tooManyTeams: boolean;
  tooManyObservers: boolean;
  tooManyCollaborators: boolean;
  tooManyRestrictions: boolean;
  tooManyTasks: boolean;
  updatePlanUserId: (userId: string | null) => void;
  updatePlan: (features: any[]) => void;
  updateProjectPlan: (features: any[]) => void;
  showTooManyProjectsModal: (title: string) => void;
  showTooManyArchivedProjectsModal: (title: string) => void;
  showTooManyOthersProjectsModal: (title: string) => void;
  showTooManyTeamsModal: (title: string) => void;
  showTooManyObserversModal: (title: string, isMyPlan: boolean) => void;
  showTooManyCollaboratorsModal: (title: string, isMyPlan: boolean) => void;
  showTooManyRestrictionsModal: (title: string, project: any, onHide: () => void) => void;
  showTooManyTasksModal: (title: string, isMyPlan: boolean, onHide?: () => void) => void;
  showCantCreateCustomCNCsModal: (title: string) => void;
  showCantWriteToChatModal: (title: string) => void;
}

const PlanContext = createContext<PlanContextValue | undefined>(undefined);

export const PlanProvider = ({ children }: { children: ReactNode }) => {

  const membershipsEnabled = config.memberships?.enabled || false

  const [ plan, setPlan ] = useState<any>(defineAbility(() => {}))

  const active_projects = useSelector((state: any) => state.ProjectsReducer.data.active)
  const archived_projects = useSelector((state: any) => state.ProjectsReducer.data.archived)
  const project_teams = useSelector((state: any) => state.TeamsReducer.result)
  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const macro = useSelector((state: any) => state.MacroReducer)

  const [ tooManyActiveProjects, setTooManyActiveProjects ] = useState<boolean>(false)
  const [ tooManyArchivedProjects, setTooManyArchivedProjects ] = useState<boolean>(false)
  const [ tooManyOthersProjects, setTooManyOthersProjects ] = useState<boolean>(false)
  const [ tooManyTeams, setTooManyTeams ] = useState<boolean>(false)
  const [ tooManyObservers, setTooManyObservers ] = useState<boolean>(false)
  const [ tooManyCollaborators, setTooManyCollaborators ] = useState<boolean>(false)
  const [ tooManyRestrictions, setTooManyRestrictions ] = useState<boolean>(false)
  const [ tooManyTasks, setTooManyTasks ] = useState<boolean>(false)

  const [ planRules, setPlanRules ] = useState<any[]>([])
  const [ projectPlanRules, setProjectPlanRules ] = useState<any[]>([])

  const [ userId, setUserId ] = useState<string | null>(null)
  const [ myFeatures, setMyFeatures ] = useState<any>({})
  const [ projectFeatures, setProjectFeatures ] = useState<any>({})
  const [ projectRestrictions, setProjectRestrictions ] = useState<any | undefined>()
  const [ usersTasks, setUsersTasks ] = useState<any[]>([])

  const [ tooManyProjectsModal, setTooManyProjectsModal ] = useState<any>({
    show: false,
    title: '',
    projects: [],
  })

  const [ tooManyArchivedProjectsModal, setTooManyArchivedProjectsModal ] = useState<any>({
    show: false,
    title: '',
    projects: [],
  })

  const [ tooManyOthersProjectsModal, setTooManyOthersProjectsModal ] = useState<any>({
    show: false,
    title: '',
    projects: [],
  })

  const [ tooManyTeamsModal, setTooManyTeamsModal ] = useState<any>({
    show: false,
    title: '',
    teams: [],
  })

  const [ tooManyObserversModal, setTooManyObserversModal ] = useState<any>({
    show: false,
    title: '',
    observers: [],
    isMyPlan: false,
  })

  const [ tooManyCollaboratorsModal, setTooManyCollaboratorsModal ] = useState<any>({
    show: false,
    title: '',
    collaborators: [],
    isMyPlan: false
  })

  const [ tooManyRestrictionsModal, setTooManyRestrictionsModal ] = useState<any>({
    show: false,
    title: '',
    restrictions: [],
    project: null,
    onHide: () => {},
  })

  const [ tooManyTasksModal, setTooManyTasksModal ] = useState<any>({
    show: false,
    title: '',
    collaborators: [],
    project: null,
    isMyPlan: false
  })

  const [ cantCreateCustomCNCsModal, setCantCreateCustomCNCsModal ] = useState<any>({
    show: false,
    title: '',
  })

  const [ cantWriteToChatModal, setCantWriteToChatModal ] = useState<any>({
    show: false,
    title: '',
  })

  const formatFeatures = (features: any[]) => {

    const newFeatures: any = {}

    features.forEach(({ feature, value }: any) => {

      const featureValue = () => {

        if (feature.type === 'boolean') {

          return value === 'true'
        }

        if (feature.type === 'number') {

          return parseInt(value)
        }

        return value
      }

      newFeatures[feature.code || feature.name] = featureValue()
    })

    return newFeatures
  }

  const updatePlan = ({ features }: any) => {

    if (features) {

      const planFeatures = features.filter((feature: any) => {

        return feature.feature.code === 'max_active_projects' ||
                feature.feature.code === 'max_archived_projects' ||
                feature.feature.code === 'max_shared_projects' ||
                feature.feature.code === 'max_observed_projects' ||
                feature.feature.code === 'access_to_chat'
      })

      const formattedFeatures = formatFeatures(planFeatures)

      setMyFeatures(formattedFeatures)
    }

    setPlanRules([
      { action: 'create', subject: 'Project', inverted: true },
      { action: 'archive', subject: 'Project', inverted: true },
      { action: 'read', subject: 'OthersProject', inverted: true },
      { action: 'write', subject: 'Chat', inverted: true },
    ])
  }

  const updateProjectPlan = ({ features }: any) => {

    if (features) {

      const projectPlanFeatures = features.filter((feature: any) => {

        const validFeatures = [
          'max_teams_per_project',
          'max_observers_per_project',
          'max_assistants_collaborators_per_project',
          'max_restrictions_per_project',
          'max_tasks_per_collaborator_assistant',
          'can_access_to_dashboard',
          'can_create_custom_cncs',
          'access_limit_to_ppc',
          'access_limit_to_cnc',
          'can_access_to_protocols',
          'can_use_ia_assistant',
          'support_type'
        ]

        return validFeatures.includes(feature.feature.code)
      })

      const formattedFeatures = formatFeatures(projectPlanFeatures)

      setProjectFeatures(formattedFeatures)
    }

    setProjectPlanRules([
      { action: 'create', subject: 'Team', inverted: true },
      { action: 'add', subject: 'Observer', inverted: true },
      { action: 'add', subject: 'Collaborator', inverted: true },
      { action: 'add', subject: 'Assistant', inverted: true },
      { action: 'move', subject: 'TeamMember', inverted: true },
      { action: 'remove', subject: 'TeamMember', inverted: true },
      { action: 'add', subject: 'Restriction', inverted: true },
      { action: 'add', subject: 'Responsible', inverted: true },
      { action: 'create', subject: 'CustomCNC', inverted: true },
    ])
  }

  const showTooManyProjectsModal = (title: string) => {

    setTooManyProjectsModal({
      ...tooManyProjectsModal,
      title,
      show: true,
      projects: active_projects.filter((project: any) => project.user === userId)
    })
  }

  const showTooManyArchivedProjectsModal = (title: string) => {

    setTooManyArchivedProjectsModal({
      ...tooManyArchivedProjectsModal,
      title,
      show: true,
      projects: archived_projects
    })
  }

  const showTooManyOthersProjectsModal = (title: string) => {

    setTooManyOthersProjectsModal({
      ...tooManyOthersProjectsModal,
      title,
      show: true,
      projects: active_projects.filter((project: any) => project.user !== userId)
    })
  }

  const showTooManyTeamsModal = (title: string) => {

    setTooManyTeamsModal({
      ...tooManyTeamsModal,
      title,
      show: true,
      teams: project_teams || []
    })
  }

  const showTooManyObserversModal = (title: string, isMyPlan: boolean) => {

    setTooManyObserversModal({ ...tooManyObserversModal, title, isMyPlan, show: true })
  }

  const showTooManyCollaboratorsModal = (title: string, isMyPlan: boolean) => {

    setTooManyCollaboratorsModal({...tooManyCollaboratorsModal, title, isMyPlan, show: true})
  }

  const showTooManyRestrictionsModal = (title: string, project: any, onHide: () => void) => {

      setTooManyRestrictionsModal({
        ...tooManyRestrictionsModal,
        title,
        show: true,
        restrictions: projectRestrictions?.list || [],
        project,
        onHide,
      })
  }

  const showTooManyTasksModal = (title: string, isMyPlan: boolean, onHide?: () => void) => {

    setTooManyTasksModal({
      ...tooManyTasksModal,
      title,
      show: true,
      project,
      isMyPlan,
      onHide: onHide || (() => {}),
    })
  }

  const showCantCreateCustomCNCsModal = (title: string) => {

    setCantCreateCustomCNCsModal({ ...cantCreateCustomCNCsModal, title, show: true })
  }

  const showCantWriteToChatModal = (title: string) => {

    setCantWriteToChatModal({ ...cantWriteToChatModal, title, show: true })
  }

  useEffect(() => {

    const setActiveProjectsPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = planRules.map((rule: any) => {

          if (rule.subject === 'Project' && rule.action === 'create') {

            rule.inverted = false
          }

          return rule
        })

        setPlanRules(newRules)

        setTooManyActiveProjects(false)

        return
      }

      const myProjects = active_projects?.filter((project: any) => project.user === userId)

      const canCreateProject = myFeatures?.max_active_projects === -1 ||
                                myProjects?.length < myFeatures?.max_active_projects

      const newRules = planRules.map((rule: any) => {

        if (rule.subject === 'Project' && rule.action === 'create') {

          rule.inverted = !canCreateProject
        }

        return rule
      })

      setPlanRules(newRules)

      const isThereTooManyActiveProjects = myFeatures?.max_active_projects > -1 &&
                                            myProjects.length > myFeatures?.max_active_projects

      setTooManyActiveProjects(isThereTooManyActiveProjects)
    }

    const setArchivedProjectsPermissions = () => {

      if (!membershipsEnabled) {

          const newRules = planRules.map((rule: any) => {

            if (rule.subject === 'Project' && rule.action === 'archive') {

              rule.inverted = false
            }

            return rule
          })

          setPlanRules(newRules)

          setTooManyArchivedProjects(false)

          return
      }

      const canArchiveProject = myFeatures?.max_archived_projects === -1 ||
                                archived_projects?.length < myFeatures?.max_archived_projects

      const newRules = planRules.map((rule: any) => {

        if (rule.subject === 'Project' && rule.action === 'archive') {

          rule.inverted = !canArchiveProject
        }

        return rule
      })

      setPlanRules(newRules)

      const isThereTooManyArchivedProjects = myFeatures?.max_archived_projects > -1 &&
                                              archived_projects?.length > myFeatures?.max_archived_projects

      setTooManyArchivedProjects(isThereTooManyArchivedProjects)
    }

    const setObservedProjectsPermissions = () => {

      if (!membershipsEnabled) {

        setTooManyOthersProjects(false)

        return
      }

      const othersProjects = active_projects?.filter((project: any) => project.user !== userId)

      const observedProjects = othersProjects.filter((project: any) => project.myProfile === 'observer')
      const sharedProjects = othersProjects.filter((project: any) => ['assistant', 'collaborator'].includes(project.myProfile))

      const observedsLimit = myFeatures.max_observed_projects > -1
      const sharedsLimit = myFeatures.max_shared_projects > -1
      const sumProjects = observedProjects.length + sharedProjects.length
      const sumLimits = myFeatures.max_observed_projects + myFeatures.max_shared_projects

      const isThereTooManyOthersProjects = observedsLimit && sharedsLimit &&
                                            sumProjects > sumLimits

      const isThereTooManySharedProjects = sharedsLimit &&
                                            sharedProjects.length > myFeatures.max_shared_projects

      const canIReadOthersProjects = !isThereTooManyOthersProjects &&  !isThereTooManySharedProjects

      const newRules = planRules.map((rule: any) => {

        if (rule.subject === 'OthersProject' && rule.action === 'read') {

          rule.inverted = !canIReadOthersProjects
        }

        return rule
      })

      setPlanRules(newRules)

      setTooManyOthersProjects(isThereTooManyOthersProjects || isThereTooManySharedProjects)

      if (canIReadOthersProjects) {

        setTooManyOthersProjectsModal({ ...tooManyOthersProjectsModal, show: false })
      }
    }

    if (active_projects && myFeatures) {

      setActiveProjectsPermissions()
      setObservedProjectsPermissions()
    }

    if (archived_projects && myFeatures) setArchivedProjectsPermissions()
  }, [ userId, active_projects, archived_projects, myFeatures ])

  useEffect(() => {

    const canWriteToAnyChat = !membershipsEnabled || myFeatures.access_to_chat === 'Ilimitado'

    const newRules = planRules.map((rule: any) => {

      if (rule.subject === 'Chat' && rule.action === 'write') {

        rule.inverted = false

        if (!canWriteToAnyChat) {

          rule.conditions = { type: { $in: [ 'private' ] } }
        }
      }

      return rule
    })

    setPlanRules(newRules)
  }, [ myFeatures ])

  useEffect(() => {

    const setPlanTeamsPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = projectPlanRules.map((rule: any) => {

          if (rule.subject === 'Team' && rule.action === 'create') {

            rule.inverted = false
          }

          return rule
        })

        setProjectPlanRules(newRules)

        setTooManyTeams(false)

        return
      }

      const canCreateTeam = projectFeatures.max_teams_per_project === -1 ||
                            project_teams.length < projectFeatures.max_teams_per_project

      const newRules = projectPlanRules.map((rule: any) => {

        if (rule.subject === 'Team' && rule.action === 'create') {

          rule.inverted = !canCreateTeam
        }

        return rule
      })

      setProjectPlanRules(newRules)

      const isThereTooManyTeams = projectFeatures.max_teams_per_project > -1 &&
                                  project_teams.length > projectFeatures.max_teams_per_project

      setTooManyTeams(isThereTooManyTeams)
    }

    const setPlanMembersPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = projectPlanRules.map((rule: any) => {

          if (rule.action === 'add' && ['Observer', 'Collaborator', 'Assistant'].includes(rule.subject)) {

            rule.inverted = false
          }

          return rule
        })

        setProjectPlanRules(newRules)

        setTooManyObservers(false)
        setTooManyCollaborators(false)

        return
      }

      if (!projectFeatures.max_observers_per_project) return
      if (!projectFeatures.max_assistants_collaborators_per_project) return

      const observers: any[] = []
      const collaborators: any[] = []
      const assistants: any[] = []

      project_teams?.forEach((team: any) => {

        team.users.forEach((user: any) => {

          if (user.isAssistant) {

            assistants.push(user)
          } else {

            if (user.profile_tag === 'observer') {

              observers.push(user)
            } else if (user.profile_tag === 'collaborator') {

              collaborators.push(user)
            }
          }
        })
      })

      const canAddObservers = projectFeatures.max_observers_per_project === -1 ||
                              observers.length < projectFeatures.max_observers_per_project
      const canAddAssistantsOrCollaborators = projectFeatures.max_assistants_collaborators_per_project === -1 ||
                                              !(collaborators.length + assistants.length) < projectFeatures.max_assistants_collaborators_per_project

      const newRules = projectPlanRules.map((rule: any) => {

        if (rule.action === 'add') {

          if (rule.subject === 'Observer') {

            rule.inverted = !canAddObservers
          } else if (['Collaborator', 'Assistant'].includes(rule.subject)) {

            rule.inverted = !canAddAssistantsOrCollaborators
          }
        }

        return rule
      })

      setTooManyObserversModal({
        ...tooManyObserversModal,
        observers
      })

      setTooManyCollaboratorsModal({
        ...tooManyCollaboratorsModal,
        collaborators: [ ...collaborators, ...assistants ]
      })

      setProjectPlanRules(newRules)

      const isThereTooManyObservers = projectFeatures.max_observers_per_project > -1 &&
                                      observers.length > projectFeatures.max_observers_per_project

      setTooManyObservers(isThereTooManyObservers)

      const isThereTooManyCollaborators = projectFeatures.max_assistants_collaborators_per_project > -1 &&
                                          (collaborators.length + assistants.length) > projectFeatures.max_assistants_collaborators_per_project

      setTooManyCollaborators(isThereTooManyCollaborators)
    }

    if (project_teams && projectFeatures) {

      setPlanTeamsPermissions()
      setPlanMembersPermissions()
    }
  }, [ project_teams, projectFeatures ])

  useEffect(() => {

    const getUsersWithTooManyTasks = (usersTasks: any[]) => {

      const usersWithTooManyTasks = usersTasks.filter((user: any) => {

        return user.tasks.length > projectFeatures.max_tasks_per_collaborator_assistant
      })

      return usersWithTooManyTasks
    }

    const setUsersTasksPermissions = () => {

      const usersTasks = getAllUsersTasks(macro.result)

      const usersWithTooManyTasks = getUsersWithTooManyTasks(usersTasks)

      setUsersTasks(usersTasks)
      setTooManyTasks(usersWithTooManyTasks.length > 0)

      setTooManyTasksModal({
        ...tooManyTasksModal,
        collaborators: usersWithTooManyTasks,
        project: project
      })

      // INFO: auto close modal if no users have too many tasks
      if (!usersWithTooManyTasks.length) {

        setTooManyTasksModal({ ...tooManyTasksModal, show: false })
      }
    }

    const setRestrictionsPermissions = () => {

      const list = getAllRestrictions(programmation.tasks)

      setTooManyRestrictionsModal({
        ...tooManyRestrictionsModal,
        restrictions: list
      })

      setProjectRestrictions({
        list
      })
    }

    if (!programmation.tasks.length) return

    setUsersTasksPermissions()
    setRestrictionsPermissions()

  }, [ userId, programmation.tasks ])

  useEffect(() => {

    const setRestrictionsPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = projectPlanRules.map((rule: any) => {

          if (rule.subject === 'Restriction' && rule.action === 'add') {

            rule.inverted = false
          }

          return rule
        })

        setProjectPlanRules(newRules)

        setTooManyRestrictions(false)

        return
      }

      const canAddRestriction = projectFeatures.max_restrictions_per_project === -1 ||
        projectRestrictions.list.length < projectFeatures.max_restrictions_per_project

      const newRules = projectPlanRules.map((rule: any) => {

        if (rule.subject === 'Restriction' && rule.action === 'add') {

          rule.inverted = !canAddRestriction
        }

        return rule
      })

      setProjectPlanRules(newRules)

      const isThereTooManyRestrictions = projectFeatures.max_restrictions_per_project > -1 &&
      projectRestrictions.list.length > projectFeatures.max_restrictions_per_project

      setTooManyRestrictions(isThereTooManyRestrictions)
    }

    if (!projectRestrictions || !projectFeatures) return

    setRestrictionsPermissions()
  }, [ projectFeatures, projectRestrictions ])

  useEffect(() => {

    const setCustomCNCsPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = projectPlanRules.map((rule: any) => {

          if (rule.subject === 'CustomCNC' && rule.action === 'create') {

            rule.inverted = false
          }

          return rule
        })

        setProjectPlanRules(newRules)

        return
      }

      const canCreateCustomCNC = projectFeatures.can_create_custom_cncs

      const newRules = projectPlanRules.map((rule: any) => {

        if (rule.subject === 'CustomCNC' && rule.action === 'create') {

          rule.inverted = !canCreateCustomCNC
        }

        return rule
      })

      setProjectPlanRules(newRules)
    }

    if (!projectFeatures) return

    setCustomCNCsPermissions()
  }, [ projectFeatures ])

  useEffect(() => {

    setTooManyProjectsModal({
      ...tooManyProjectsModal,
      projects: active_projects.filter((project: any) => project.user === userId)
    })

  }, [ active_projects ])

  useEffect(() => {

    setTooManyArchivedProjectsModal({
      ...tooManyArchivedProjectsModal,
      projects: archived_projects
    })

  }, [ archived_projects ])

  useEffect(() => {

    if (project.plan) {

      updateProjectPlan(project.plan)
    }
  }, [ project._id ])

  useEffect(() => {

    if (!project_teams) return

    setTooManyTeamsModal({
      ...tooManyTeamsModal,
      teams: project_teams
    })
  }, [ project_teams ])

  useEffect(() => {

    const setMaxTasksPermissions = () => {

      if (!membershipsEnabled) {

        const newRules = projectPlanRules.map((rule: any) => {

          if (rule.subject === 'Responsible' && rule.action === 'add') {

            rule.inverted = false
          }

          return rule
        })

        setProjectPlanRules(newRules)

        return
      }

      const usersToDisable: any[] = []

      usersTasks.forEach((user: any) => {

        if (user.tasks.length >= projectFeatures.max_tasks_per_collaborator_assistant) {

          usersToDisable.push(user)
        }
      })

      const newRules = projectPlanRules.map((rule: any) => {

        if (rule.subject === 'Responsible' && rule.action === 'add') {

          rule.inverted = false

          rule.conditions = { userId: { $nin: usersToDisable.map((user) => user.user._id) }}
        }

        return rule
      })

      setProjectPlanRules(newRules)
    }

    if (!usersTasks) return

    setMaxTasksPermissions()
  }, [ usersTasks ])

  useEffect(() => {

    plan.update([ ...planRules, ...projectPlanRules ])
  }, [ planRules, projectPlanRules ])

  const providerValue: PlanContextValue = {
    membershipsEnabled,
    plan,
    features: myFeatures,
    projectFeatures,
    projectRestrictions,
    tooManyActiveProjects,
    tooManyArchivedProjects,
    tooManyOthersProjects,
    tooManyTeams,
    tooManyObservers,
    tooManyCollaborators,
    tooManyRestrictions,
    tooManyTasks,
    updatePlanUserId: setUserId,
    updatePlan,
    updateProjectPlan,
    showTooManyProjectsModal,
    showTooManyArchivedProjectsModal,
    showTooManyOthersProjectsModal,
    showTooManyTeamsModal,
    showTooManyObserversModal,
    showTooManyCollaboratorsModal,
    showTooManyRestrictionsModal,
    showTooManyTasksModal,
    showCantCreateCustomCNCsModal,
    showCantWriteToChatModal,
  }

  return (
    <PlanContext.Provider value={providerValue}>
      {children}

      <TooManyProjectsModal
        {...tooManyProjectsModal}
        maxProjectsAllowed={myFeatures?.max_active_projects || 0}
        onHide={() => setTooManyProjectsModal({ ...tooManyProjectsModal, show: false })}
      />

      <TooManyArchivedProjectsModal
        {...tooManyArchivedProjectsModal}
        maxProjectsAllowed={myFeatures?.max_archived_projects || 0}
        onHide={() => setTooManyArchivedProjectsModal({ ...tooManyArchivedProjectsModal, show: false })}
      />

      <TooManyOthersProjectsModal
        {...tooManyOthersProjectsModal}
        maxObservedProjectsAllowed={myFeatures?.max_observed_projects || 0}
        maxSharedProjectsAllowed={myFeatures?.max_shared_projects || 0}
        onHide={() => setTooManyOthersProjectsModal({ ...tooManyOthersProjectsModal, show: false })}
      />

      <TooManyTeamsModal
        {...tooManyTeamsModal}
        maxTeamsAllowed={projectFeatures?.max_teams_per_project || 0}
        onHide={() => setTooManyTeamsModal({ ...tooManyTeamsModal, show: false })}
        onContinue={(teamsIds: string[]) => {}}
      />

      <TooManyObserversModal
        {...tooManyObserversModal}
        maxObserversAllowed={projectFeatures?.max_observers_per_project || 0}
        onHide={() => setTooManyObserversModal({ ...tooManyObserversModal, show: false })}
        onContinue={() => {}}
      />

      <TooManyCollaboratorsModal
        {...tooManyCollaboratorsModal}
        maxCollaboratorsAllowed={projectFeatures?.max_assistants_collaborators_per_project || 0}
        onHide={() => setTooManyCollaboratorsModal({...tooManyCollaboratorsModal, show: false })}
        onContinue={() => {}}
      />

      <TooManyRestrictionsModal
        {...tooManyRestrictionsModal}
        maxRestrictionsAllowed={projectFeatures?.max_restrictions_per_project || 0}
        onHide={() => {

          setTooManyRestrictionsModal({ ...tooManyRestrictionsModal, show: false })

          if (tooManyRestrictionsModal.onHide) tooManyRestrictionsModal.onHide()
        }}
      />

      <TooManyTasksModal
        {...tooManyTasksModal}
        maxTasksAllowed={projectFeatures?.max_tasks_per_collaborator_assistant || 0}
        onHide={() => setTooManyTasksModal({ ...tooManyTasksModal, show: false })}
      />

      <CantCreateCustomCNCsModal
        { ...cantCreateCustomCNCsModal }
        onHide={() => setCantCreateCustomCNCsModal({ ...cantCreateCustomCNCsModal, show: false })}
      />

      <CantWriteToChatModal
        { ...cantWriteToChatModal }
        onHide={() => setCantWriteToChatModal({ ...cantWriteToChatModal, show: false })}
      />
    </PlanContext.Provider>
  )
}

export const usePlan = (): PlanContextValue => {

  const context = useContext(PlanContext)

  if (context === undefined) {
    throw new Error('usePlan must be used within a PlanProvider')
  }

  return context
}
