
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";

interface Props {
  featuresGroups: any
  onSelect: (feature: any) => void
}

const FeaturesSelector = (props: Props) => {

  const features = useMemo(() => {
    const features: any[] = []

    Object.keys(props.featuresGroups).forEach((key: string) => {
      features.push(...props.featuresGroups[key])
    })

    return features
  }, [props.featuresGroups])

  const handleSelect = (e: any) => {

    const feature = features.find((feature: any) => feature._id === e.target.value)

    props.onSelect(feature)
  }

  return (
    <Form.Control as="select" onChange={handleSelect}>
      <option>Seleccione una característica</option>
      <optgroup label="Proyectos">
        {props.featuresGroups.proyectos.map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
      <optgroup label="Equipos">
        {props.featuresGroups.equipos.map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
      <optgroup label="Programación y Tareas">
        {props.featuresGroups['programacion-tareas'].map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
      <optgroup label="Mejora Continua y Dashboard">
        {props.featuresGroups['mc-dashboard'].map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
      <optgroup label="Asistente IA y Chat">
        {props.featuresGroups['ia-chat'].map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
      <optgroup label="Soporte">
        {props.featuresGroups['soporte'].map((feature: any, i: number) => (
          <option key={i} value={feature._id}>{feature.name}</option>
        ))}
      </optgroup>
    </Form.Control>
  )
}

export default FeaturesSelector;
