import React, { useEffect, useState } from "react"
import { Nav, Tab, } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux"
import { BarChart, GaugeChart, LineChart,} from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, } from 'echarts/components';
import { CanvasRenderer, SVGRenderer, } from 'echarts/renderers';

import config from '../../config'
import navigation from "../../navigation";

import { useAuth } from "../../Hooks/UseAuth";

import ProjectsActions from "../../Redux/Actions/ProjectsActions";
import InterfaceActions from "../../Redux/Actions/InterfaceActions";
import { loadIndicatorsPPCTasksAction, loadIndicatorsPPCWeeklyProgressAction } from "../../Redux/Actions/IndicatorsPPCActions";

import MainLayout from "../../Components/MainLayout/MainLayout"
import WeeklyLineCard from "../../Components/Charts/Echarts/WeeklyLineCard/WeeklyLineCard";

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, GaugeChart, LineChart, SVGRenderer]
);

const ProjectIndicatorsPPCPage = (props: any) => {

    const project_id = props.match.params._id

    const { userData } = useAuth()

    const dispatch = useDispatch()

    const project = useSelector((state: any) => state.ProjectsReducer.rowData)
    const loadProjectStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
    const indicatorsPPC = useSelector((state: any) => state.IndicatorsPPCReducer)

    const [ tab, setTab ] = useState('list')
    const [ taskTab, setTaskTab ] = useState('completed')

    const [ promedio, setPromedio ] = useState<number>(0)

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'indicators',
        'ppc'
    )

    const getData1 = () => {

        if (!indicatorsPPC.weeklyProgress.result.length) return 0

        const lastWeek = indicatorsPPC.weeklyProgress.result[ indicatorsPPC.weeklyProgress.result.length - 1 ]

        return lastWeek.percent.toFixed(0) || 0
    }

    const optionGauge1 = {
        series: [
            {
                type: 'gauge',
                axisLine: {
                    lineStyle: {
                        width: 25,
                        color: [
                            [0.3, '#67e0e3'],
                            [0.7, '#37a2da'],
                            [1, '#fd666d']
                        ]
                    }
                },
                pointer: {
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                        color: '#fff',
                        width: 4
                    }
                },
                axisLabel: {
                    color: 'auto',
                    distance: 30,
                    fontSize: 10
                },
                detail: {
                    valueAnimation: true,
                    formatter: '{value} %',
                    color: 'auto'
                },
                data: [{ value: getData1() }]
            }
        ]
    };

    const optionGauge2 = {
        series: [
            {
                type: 'gauge',
                axisLine: {
                    lineStyle: {
                        width: 25,
                        color: [
                            [0.3, '#67e0e3'],
                            [0.7, '#37a2da'],
                            [1, '#fd666d']
                        ]
                    }
                },
                pointer: {
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                        color: '#fff',
                        width: 4
                    }
                },
                axisLabel: {
                    color: 'auto',
                    distance: 30,
                    fontSize: 10
                },
                detail: {
                    valueAnimation: true,
                    formatter: '{value} %',
                    color: 'auto'
                },
                data: [
                    {
                        value: promedio
                    }
                ]
            }
        ]
    };

    const loadProject = async () => {

        await dispatch(ProjectsActions.getRowAction(project_id))
    }

    const loadProgressPercentHistory = async () => {

        await dispatch(loadIndicatorsPPCWeeklyProgressAction(project._id))
    }

    const loadTasks = async () => {

        await dispatch(loadIndicatorsPPCTasksAction(project._id, moment().subtract(1, 'w').format('YYYYWW')))
    }

    const getWeekTitle = () => {

        if (!indicatorsPPC.weeklyProgress.result.length) return null

        const weekNumber = indicatorsPPC.weeklyProgress.result[indicatorsPPC.weeklyProgress.result.length - 1]

        const week = moment().week(weekNumber.number)

        return (
            <span>
                Semana {indicatorsPPC.weeklyProgress.result.length} - Del {week.startOf('week').format('DD/MM/YYYY')} al {week.endOf('week').format('DD/MM/YYYY')}
            </span>
        )
    }

    useEffect(() => {

        if (loadProjectStatus !== 'none') return

        loadProject()
    }, [ loadProjectStatus ])

    useEffect(() => {

        if (loadProjectStatus !== 'loaded') return

        if (indicatorsPPC.weeklyProgress.loadStatus === 'none') {

            loadProgressPercentHistory()
        }

        if (indicatorsPPC.tasks.loadStatus === 'none') {

            loadTasks()
        }

    }, [ loadProjectStatus, indicatorsPPC.weeklyProgress.loadStatus, indicatorsPPC.tasks.loadStatus ])

    useEffect(() => {

        const projectLoaded = loadProjectStatus == 'loaded'
        const weeklyProgressLoaded = indicatorsPPC.weeklyProgress.loadStatus === 'loaded'
        const tasksLoaded = indicatorsPPC.tasks.loadStatus === 'loaded'

        if (!projectLoaded || !weeklyProgressLoaded || !tasksLoaded) {

            dispatch(InterfaceActions.showLoader())
        } else {

            dispatch(InterfaceActions.hideLoader())
        }
    }, [ loadProjectStatus, indicatorsPPC.weeklyProgress.loadStatus, indicatorsPPC.tasks.loadStatus ])

    useEffect(() => {

        if (indicatorsPPC.weeklyProgress.loadStatus !== 'loaded') return

        let suma = 0

        indicatorsPPC.weeklyProgress.result.map((item: any) => {

            suma += item.percent
        })

        const prom: number = Math.trunc(suma / indicatorsPPC.weeklyProgress.result.length)

        setPromedio(prom)
    }, [ indicatorsPPC.weeklyProgress.loadStatus, indicatorsPPC.weeklyProgress.result ])

    return (
        <MainLayout
            username={`${userData?.name} ${userData?.surname}`}
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
            rightTitle={project.shortName}
            avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
        >
            <div className='row'>
                <div className='col-12 pb-3'>
                    <div className='page-content'>
                        <div className='page-content-body'>
                            <div className='ul-widget__head'>
                                <div className='ul-widget__head-label flex-grow-1'>
                                    <h3 className='ul-widget__head-title'>
                                        Dashboard
                                        | <small style={{ fontSize: '70%' }}>
                                            {getWeekTitle()}
                                        </small>
                                    </h3>
                                </div>
                                <div className="ul-widget__head-nav">
                                    <Tab.Container>
                                        <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                                            <Nav.Item as="li">
                                                <Nav.Link active={tab === 'list'} onClick={() => setTab('list')}>
                                                    <span className="icon-ppc_indicador"></span>
                                                    PPC
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item as="li">
                                                <Nav.Link active={tab === 'configuration'} onClick={() => setTab('configuration')}>
                                                    <span className="icon-configuracion"></span>
                                                    Configuracion
                                                </Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </Tab.Container>
                                </div>
                            </div>
                            <div className='ul-widget__body'>
                                <Tab.Content className="no-padding">
                                    <Tab.Pane active={tab === 'list'}>
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <WeeklyLineCard
                                                    title='Progreso semanal'
                                                    tooltip={{
                                                        formatter: (items: any) => `Progreso: ${items[0].value.toFixed(0)}%`,
                                                    }}
                                                    data={indicatorsPPC.weeklyProgress.result}
                                                    styles={{
                                                        lineColor: '#ff5721',
                                                        itemColor: '#ff5721',
                                                        areaGradientColors: ['#ff572190', '#fff'],
                                                        axisXFormatter: '{value}',
                                                        axisYFormatter: '{value}%',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-6 col-xl-4 mt-4'>
                                                <div className='card mb-4'>
                                                    <div className='card-body'>
                                                        <div className='card-tittle'>Porcentaje de Plan Completado | Semana {indicatorsPPC.weeklyProgress.result.length}</div>
                                                        <ReactEChartsCore
                                                            echarts={echarts}
                                                            option={optionGauge1}
                                                            notMerge={true}
                                                            lazyUpdate={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-xl-4 mt-4'>
                                                <div className='card mb-4'>
                                                    <div className='card-body'>
                                                        <div className='card-tittle'>Porcentaje de Plan Completado del Proyecto</div>
                                                        <ReactEChartsCore
                                                            echarts={echarts}
                                                            option={optionGauge2}
                                                            notMerge={true}
                                                            lazyUpdate={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-xl-4 mt-4'>
                                                <div className='card h-100'>
                                                    <div className='card-body'>
                                                        <div className='ul-widget__head'>
                                                            <div className='ul-widget__head-label'>
                                                                <h5 className='ul-widget__head-title'>Tareas Semana {indicatorsPPC.weeklyProgress.result.length}</h5>
                                                            </div>
                                                            <div className='ul-widget__head-toolbar'>
                                                                <Tab.Container>
                                                                    <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tab-line">
                                                                        <Nav.Item as="li">
                                                                            <Nav.Link active={taskTab === 'completed'} onClick={() => setTaskTab('completed')}>
                                                                                Completadas
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item as="li">
                                                                            <Nav.Link active={taskTab === 'not_completed'} onClick={() => setTaskTab('not_completed')}>
                                                                                No Completadas
                                                                            </Nav.Link>
                                                                        </Nav.Item>
                                                                    </Nav>
                                                                </Tab.Container>
                                                            </div>
                                                        </div>
                                                        <div className='ul-widget__body'>
                                                            <Tab.Content>
                                                                <Tab.Pane active={taskTab === 'completed'}>
                                                                    <div className="ul-widget-1">
                                                                        {indicatorsPPC.tasks.completed.map((taskWeeklyProgress: any, i: number) => (
                                                                            <div key={i} className='ul-widget4__item ul-widget4__users'>
                                                                                <div className='ul-widget2__info ul-widget4__users-info'>
                                                                                    <a className='ul-widget2__tittle' href="#">
                                                                                        {taskWeeklyProgress.task.name}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane active={taskTab === 'not_completed'}>
                                                                    <div className="ul-widget-1">
                                                                        {indicatorsPPC.tasks.notCompleted.map((taskWeeklyProgress: any, i: number) => (
                                                                            <div key={i} className='ul-widget4__item ul-widget4__users'>
                                                                                <div className='ul-widget2__info ul-widget4__users-info'>
                                                                                    <a className='ul-widget2__tittle' href="#">
                                                                                        {taskWeeklyProgress.task.name}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane>

                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default ProjectIndicatorsPPCPage
