
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import FeaturesSelector from "../FeaturesSelector/FeaturesSelector";

interface Props {
  show: boolean
  row: any
  featuresGroups: any
  onHide: () => void
  onSave: (row: any) => void
}

const PlansEditor = (props: Props) => {

  const [ tab, setTab ] = React.useState<string>('generals')

  const [ row, setRow ] = React.useState<any>(props.row)

  const [ features, setFeatures ] = React.useState<any[]>(row.features)

  const [ selectingFeature, setSelectingFeature ] = useState<boolean>(false)

  const handleSelectFeature = (feature: any) => {

    setFeatures([...features, { feature, value: '' }])

    setSelectingFeature(false)
  }

  const handleSetFeatureValue = (index: number, value: any) => {

    const newFeatures = features.map((feature: any, i: number) => {

      if (i === index) {

        feature.value = value
      }

      return feature
    })

      setFeatures(newFeatures)
  }

  const validate = (): boolean => {

    return true
  }

  const handleSave = () => {

    if (!validate()) return

    row.features = features

    props.onSave(row)
  }

  const handleCancel = () => {

      setRow(props.row)

      setFeatures(props.row.features)

      setSelectingFeature(false)

      props.onHide()
  }

  useEffect(() => {

    console.log({ row: props.row })

    setRow(props.row)

    setFeatures(props.row.features)

    setSelectingFeature(false)

    setTab('generals')
  }, [ props.row ])

  return (

    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Body>
        <Tab.Container defaultActiveKey={tab}>
          <div className="ul-widget__head">
              <div className="ul-widget__head-label flex-grow-1">
                <h4 className={`ul-widget__head-title`}>
                  {!row.name && (row._id ? 'Editar plan' : 'Nuevo plan')}
                  {row.name && row.name}
                </h4>
              </div>
              <div className="ul-widget__head-nav">
                <Nav variant="tabs" className={`nav-`}>
                  <Nav.Item>
                    <Nav.Link eventKey='generals' >
                      <i className="fas fa-fw fa-home"></i>
                      <span className="ocultar-en-pantallas-pequeñas"> General</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='features'>
                      <i className="fas fa-fw fa-tasks"></i>
                      <span className="ocultar-en-pantallas-pequeñas"> Características</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
          </div>
          <div className="ul-widget__body">
            <Tab.Content>
              <Tab.Pane eventKey={'generals'}>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm={3}>Nombre</Form.Label>
                  <Col sm={8}>
                    <Form.Control type="text" placeholder="Ingrese el nombre del plan"
                      value={row.name}
                      onChange={(e: any) => setRow({ ...row, name: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm={3}>Código</Form.Label>
                  <Col sm={8}>
                    <Form.Control type="text" placeholder="Ingrese el nombre del plan"
                      value={row.code}
                      onChange={(e: any) => setRow({ ...row, code: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDescription">
                  <Form.Label column sm={3}>Descripción</Form.Label>
                  <Col md={8}>
                    <Form.Control as="textarea" rows={3} placeholder="Ingrese la descripción del plan"
                      value={row.description}
                      onChange={(e: any) => setRow({ ...row, description: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formMonthlyPrice">
                  <Form.Label column sm={3}>Precio mensual</Form.Label>
                  <Col sm={4}>
                    <Form.Control type="number" placeholder="0" style={{ textAlign: 'right' }}
                      value={row.monthlyRegularPrice}
                      onChange={(e: any) => setRow({ ...row, monthlyRegularPrice: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDiscountPrice">
                  <Form.Label column sm={3}>Precio con dcto.</Form.Label>
                  <Col sm={4}>
                    <Form.Control type="number" placeholder="0" style={{ textAlign: 'right' }}
                      value={row.monthlyDiscountPrice}
                      onChange={(e: any) => setRow({ ...row, monthlyDiscountPrice: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formYearlyPrice">
                  <Form.Label column sm={3}>Precio anual</Form.Label>
                  <Col sm={4}>
                    <Form.Control type="number" placeholder="0" style={{ textAlign: 'right' }}
                      value={row.yearlyPrice}
                      onChange={(e: any) => setRow({ ...row, yearlyPrice: e.target.value })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDiscountPercent">
                  <Form.Label column sm={3}>% de descuento</Form.Label>
                  <Col sm={4}>
                    <Form.Control type="number" placeholder="0" style={{ textAlign: 'right' }}
                      value={row.yearlyDiscountPercent}
                      onChange={(e: any) => setRow({ ...row, yearlyDiscountPercent: e.target.value })}
                    />
                  </Col>
                </Form.Group>
              </Tab.Pane>
              <Tab.Pane eventKey={'features'}>
                {features.map((f: any, i: number) => (
                  <Form.Group key={i} as={Row}>
                    <Form.Label column sm={6}>{f.feature.name}</Form.Label>
                    <Col sm={4}>
                      {f.feature.type === 'boolean' && (
                        <Form.Control as="select" autoFocus
                          value={f.value}
                          onChange={(e: any) => handleSetFeatureValue(i, e.target.value)}
                        >
                          <option value="">Seleccione</option>
                          <option value="true">Sí</option>
                          <option value="false">No</option>
                        </Form.Control>
                      )}
                      {f.feature.type === 'number' && (
                        <Form.Control type="number" autoFocus placeholder="0" style={{ textAlign: 'right' }}
                          value={f.value}
                          onChange={(e: any) => handleSetFeatureValue(i, e.target.value)}
                        />
                      )}
                      {f.feature.type === 'string' && (
                        <Form.Control type="text" autoFocus placeholder=""
                          value={f.value}
                          onChange={(e: any) => handleSetFeatureValue(i, e.target.value)}
                        />
                      )}
                      {f.feature.type === 'enum' && (
                        <Form.Control as="select" autoFocus
                          value={f.value}
                          onChange={(e: any) => handleSetFeatureValue(i, e.target.value)}
                        >
                          <option value="">Seleccione una opción</option>
                          {f.feature.enumOptions.map((option: any, i: number) => (
                            <option key={i} value={option}>{option}</option>
                          ))}
                        </Form.Control>
                      )}
                    </Col>
                    <Col sm={2}>
                      <button className="btn btn-normal"
                        onClick={() => setFeatures(features.filter((nf: any) => nf._id !== f._id))}
                      >
                        <i className="fas fa-fw fa-trash"></i>
                      </button>
                    </Col>
                  </Form.Group>
                ))}
                {selectingFeature && (
                  <FeaturesSelector
                    featuresGroups={props.featuresGroups}
                    onSelect={(feature: any) => handleSelectFeature(feature)}
                  />
                )}
                <Form.Group as={Row}>
                  <Col sm={12}>
                    <button className="btn btn-normal"
                      onClick={() => setSelectingFeature(true)}
                    >
                      <i className="fas fa-fw fa-plus"></i> Agregar característica
                    </button>
                  </Col>
                </Form.Group>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleCancel()}>
          <i className="fas fa-fw fa-times"></i> Cancelar
        </button>
        <button className="btn btn-primary"
          onClick={() => handleSave()}
        >
          <i className="fas fa-fw fa-save"></i> Guardar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlansEditor
