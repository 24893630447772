
import React, { useMemo } from "react";

import { ProgrammationSchema } from "./types";

import { getRelationsFromItems } from "./GanttHelper";

import GanttChartBodyArrow from "./GanttChartBodyArrow";
import GanttChartBodyGrid from "./GanttChartBodyGrid";
import GanttChartBodyItem from "./GanttChartBodyItem";

type Props = {
  days: any[];
  weeks: any[];
  items: any[];
  selectedItems: any[];
  dayWidth: number;
  rowHeight: number;
  height: number;
  showVerticalBorders: boolean;
  useCodeColors: boolean;
  schema: ProgrammationSchema;
  lineBaseTasks: boolean
  onItemClick: (item: any, isItRight: boolean) => void
  onItemHover: (itemIndex: number) => void
}

const GanttChartBody = (props: Props) => {

  const relations = useMemo(() => getRelationsFromItems(props.items), [ props.items, props.dayWidth ])

  return (
    <svg
      style={{
        height: props.height,
        width: (props.days.length * props.dayWidth),
        transition: 'height .3s ease'
      }}
    >
      <GanttChartBodyGrid
        days={props.days}
        items={props.items}
        height={props.height - 1}
        dayWidth={props.dayWidth}
        rowHeight={props.rowHeight}
        selectedItems={props.selectedItems}
        onItemHover={props.onItemHover}
        showVerticalBorders={props.showVerticalBorders}
      />
      {relations.map((relation: any, i: number) => (
        <GanttChartBodyArrow
          key={`${relation._id}-${i}`}
          fromTop={relation.fromTop * 32}
          fromLeft={relation.fromLeft}
          toTop={relation.toTop * 32}
          toLeft={relation.toLeft}
          type={relation.type}
        />
      ))}
      {props.items.map((item) => (
        <GanttChartBodyItem
          key={item._id}
          item={item}
          days={props.days}
          dayWidth={props.dayWidth}
          rowHeight={props.rowHeight}
          useCodeColors={props.useCodeColors}
          schema={props.schema}
          lineBaseTasks={props.lineBaseTasks}
          selectedItems={props.selectedItems}
          onItemClick={props.onItemClick}
        />
      ))}
    </svg>
  )
}

export default GanttChartBody
