/*eslint "array-callback-return": "off" */

import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'

import navigation from '../../navigation'

import { useAuth } from '../../Hooks/UseAuth'

import ProjectsActions from '../../Redux/Actions/ProjectsActions'
import TeamsActions from '../../Redux/Actions/TeamsActions'
import TasksActions from '../../Redux/Actions/TasksActions'
import InterfaceActions from '../../Redux/Actions/InterfaceActions'

import MainLayout from '../../Components/MainLayout/MainLayout'

import './calendar.css'

const moment = require('moment')

const localizer = momentLocalizer(moment)

const ProjectProgrammationMacroPage = (props: any) => {

    const project_id = props.match.params._id

    const dispatch = useDispatch()

    const { userData } = useAuth()

    const project = useSelector((state: any) => state.ProjectsReducer.rowData)
    const macro = useSelector((state: any) => state.MacroReducer)

    const [ state, setState ] = useState({
        tasksForCalendar: [],
        tasksForEditor: [],
        groupsForTasks: [],
        selectedTask: {},
        showEditTask: false
    })

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'programmation',
        'calendar'
    )

    const loadProject = () => {

        if (!project._id) dispatch(ProjectsActions.getRowAction(props.match.params._id))
    }

    const loadTeams = () => {

        if (project._id) dispatch(TeamsActions.getResultAction(project_id))
    }

    // DEPRECATED: implement load programmation instead
    const loadTaskList = async () => {

        // dispatch(InterfaceActions.showLoader())

        // const loaded = await dispatch(loadMacroAction(project_id))

        // if (!loaded) {

        //   Toast.show('!Upss¡', (
        //     <>
        //       Hubo un problema al cargar las tareas
        //       <button className='btn btn-primary'
        //         onClick={() => loadTaskList()}
        //       >
        //         <Icon name="sync" fas fw /> Reintentar
        //       </button>
        //     </>
        //   ), {
        //     type: 'default',
        //     position: 'bottom-right',
        //     autoClose: false,
        //     closeOnClick: false,
        //     closeButton: false,
        //     draggable: false,
        //   });
        //   return
        // }

        // Toast.close()
    }

    const getStatusName = (status: string) => {

        switch (status) {
            case 'atrasada': return 'Atrasada'
            case 'pendiente': return 'Pendiente'
            case 'liberada': return 'Liberada'
            case 'completada': return 'Completada'
            case 'activa': return 'Activa'
            case 'activa_atrasada': return 'Activa atrasada'
        }
    }

    const getBgColor = (status: string) => {

        switch (status) {
            case 'atrasada': return '#F00'
            case 'pendiente': return '#093763'
            case 'liberada': return '#200ab9'
            case 'completada': return '#b7b7b7'
            case 'activa': return '#34a854'
            case 'activa_atrasada': return '#980001'
        }
    }

    const getTasksFromList = () => {

        if (!macro.result.length) return

        const tasksForCalendar: any = getTasksForCalendar(macro.result)
        const tasksForEditor: any = getTasksForEditor(macro.result)
        const groupsForTasks: any = getGroupsForTasks()

        setState({
            ...state,
            tasksForCalendar,
            tasksForEditor,
            groupsForTasks,
        })
    }

    const getTasksForCalendar = (list: any[]) => {

        const tasks: any = []

        list.map((item: any) => {

            if (item.type === 'task') {

                const task = item.task

                tasks.push({
                    _id: task._id,
                    itemType: 'task',
                    title: task.name,
                    allDay: true,
                    start: moment(task.startsAt, moment.ISO_8601).toDate(),
                    end: moment(task.endsAt, moment.ISO_8601).add(1, 'd').toDate(),
                    bgColor: getBgColor(task.status),
                    data: task
                })
            } else {

                const subTasks = getTasksForCalendar(item.group.items)

                tasks.push(...subTasks)
            }
        })

        return tasks
    }

    const getTasksForEditor = (list: any[]) => {

        const ganttItems: any = []

        list.map((itemLevel0: any) => {

            if (itemLevel0.type === 'task') {

                const task = itemLevel0.task

                task.group_id = ''

                task.bgColor = getBgColor(task.status)
                task.startsAt = moment(task.startsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                task.endsAt = moment(task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

                const ganttItem = {
                    _id: itemLevel0._id,
                    type: 'task',
                    task
                }

                ganttItems.push(ganttItem)
            } else {

                const groupItems: any = []

                itemLevel0.group.items.map((itemLevel1: any) => {

                    if (itemLevel1.type === 'task') {

                        const task = itemLevel1.task

                        task.group_id = itemLevel0.group._id

                        task.bgColor = getBgColor(task.status)
                        task.startsAt = moment(task.startsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        task.endsAt = moment(task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

                        const groupItem = {
                            _id: itemLevel1._id,
                            type: 'task',
                            task
                        }

                        groupItems.push(groupItem)
                    } else {

                        const subGroupItems: any = []

                        itemLevel1.group.items.map((itemLevel2: any) => {

                            const task = itemLevel2.task

                            task.group_id = itemLevel1.group._id

                            task.bgColor = getBgColor(task.status)
                            task.startsAt = moment(task.startsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                            task.endsAt = moment(task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

                            const subGroupItem = {
                                _id: itemLevel2._id,
                                type: 'task',
                                task
                            }

                            subGroupItems.push(subGroupItem)
                        })

                        const groupItem = {
                            _id: itemLevel1.group._id,
                            list_item_id: itemLevel1._id,
                            name: itemLevel1.group.name,
                            type: 'group',
                            items: subGroupItems,
                        }

                        groupItems.push(groupItem)
                    }
                })

                const ganttItem = {
                    _id: itemLevel0.group._id,
                    list_item_id: itemLevel0._id,
                    name: itemLevel0.group.name,
                    type: 'group',
                    items: groupItems
                }

                ganttItems.push(ganttItem)
            }
        })

        return ganttItems
    }

    const getGroupsForTasks = () => {

        const groups: any = []

        macro.result.map((itemLevel0: any) => {

            if (itemLevel0.type === 'group') {

                groups.push({
                    _id: itemLevel0.group._id,
                    name: itemLevel0.group.name,
                })

                itemLevel0.group.items.map((itemLevel1: any) => {

                    if (itemLevel1.type === 'group') {

                        groups.push({
                            _id: itemLevel1.group._id,
                            name: `-- ${itemLevel1.group.name}`,
                        })
                    }
                })
            }
        })

        return groups
    }

    const selectTask = (task: any) => {

        const _task = JSON.parse(JSON.stringify(task))

        setState({
            ...state,
            selectedTask: _task,
            showEditTask: true
        })
    }

    const saveTask = (task: any) => {

        // task.project = project._id

        // dispatch(InterfaceActions.showLoader())

        // dispatch(TasksActions.create(task_list._id, task))
    }

    const updateTask = (task: any, i?: number) => {

        dispatch(InterfaceActions.showLoader())

        dispatch(TasksActions.update(task))
    }

    useEffect(loadProject, [])
    useEffect(loadTeams, [ project._id ])

    useEffect(() => {

        if (macro.loadStatus === 'none') {

            loadTaskList()
        }
    }, [ project._id, macro.loadStatus ])

    useEffect(() => {

        getTasksFromList()

        dispatch(InterfaceActions.hideLoader())
    }, [ macro.result ])

    return (
        <MainLayout
            username={`${userData?.name} ${userData?.surname}`}
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
            rightTitle={project.shortName}
        >
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <h5 className="card-title">Programación - Calendario</h5>
                            <div>
                            <Calendar
                                localizer={localizer}
                                events={state.tasksForCalendar}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={(e: any) => selectTask(e.data)}
                                popup={true}
                                style={{ height: 600 }}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default ProjectProgrammationMacroPage
