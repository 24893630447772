
const initialState = {
    result: []
}

const NotificationsReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === 'NOTIFICATIONS_LOAD_RESULT_END') {

        st.result = action.content.data
    }

    return st
}

export default NotificationsReducer
