
import { ColorCode } from "../../Types/Tasks";
import { COLOR_CODE_ACTIONS } from "../Actions/ColorCodeActions";

type ColorCodeState = {
  data: ColorCode[];
}

const initialState: ColorCodeState = {
  data: [],
}

const ColorCodeReducer = (state = initialState, { type, payload, error }: any) => {

  const st = { ...state };

  switch (type) {
    case COLOR_CODE_ACTIONS.GET_RESULT_END:
      st.data = payload
      break;
    case COLOR_CODE_ACTIONS.CREATE_END:
      st.data.push(payload)
      break;
    case COLOR_CODE_ACTIONS.UPDATE_END:
      // reemplazar color code de la lista de st.data
      st.data.forEach(payload)
      break;
    
  }

  return st
}

export default ColorCodeReducer
