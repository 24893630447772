
import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../../../../Hooks/UseAuth";

import navigation from "../../../../../navigation";

import { getDelayData, getProgressData } from "../../../../../Helpers/DashboardHelper";
import { getYMDFromDate } from "../../../../../Helpers/DateFunctions";

import ProjectsActions from "../../../../../Redux/Actions/ProjectsActions";
import { loadProgrammationAction } from "../../../../../Redux/Actions/ProgrammationActions";

import MainLayout from "../../../../../Components/MainLayout/MainLayout";
import MainLayoutContentHeader from "../../../../../Components/MainLayout/MainLayoutContentHeader";
import MainLayoutContentBody from "../../../../../Components/MainLayout/MainLayoutContentBody";
import Icon from "../../../../../Components/Icon/Icon";
import MyAreaChart, { IChartSerie } from "../../../../../Components/Charts/ReCharts/AreaChart/AreaChart";
import { setDashboardDelayDataAction, setDashboardProgressDataAction } from "../../../../../Redux/Actions/DashboardActions";
import InterfaceActions from "../../../../../Redux/Actions/InterfaceActions";

const ProjectDashboardPage = (props: any) => {

  const dispatch = useDispatch()

  const { ability, userData } = useAuth()

  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const dashboard = useSelector((state: any) => state.DashboardReducer)

  const project_id = props.match.params._id

  const [ menu, submenu ] = navigation.getNavigation(
    project_id,
    'home',
    'dashboard'
  )

  const [ tab, setTab ] = useState('summary')

  const colors = [
    {
      stroke: '#663299',
      fill: '#66329990'
    },
    {
      stroke: '#ff5721',
      fill: '#ff572190'
    }
  ]

  const nonWorkingDays = useMemo(() => {

    return project.nonWorkingDays.map((day: string) => {

      const date = new Date(day)

      return getYMDFromDate(date)
    })
  }, [ project.nonWorkingDays ])

  const series = useMemo(() => {

    const series: IChartSerie[] = []

    dashboard.progressData.forEach((item: any) => {

      Object.keys(item).forEach((key: string, i: number) => {

        if (key !== 'name' && key !== 'week') {

          if (series.find(serie => serie.name === key)) return

          series.push({
            name: key,
            color: colors ? colors[i - 1] : {
              stroke: '#828282',
              fill: '#82828290'
            }
          })
        }
      })
    })

    return series
  }, [ dashboard.progressData ])

  const loadProject = async () => {

    await dispatch(ProjectsActions.getRowAction(project_id))
  }

  const loadProgrammation = async () => {

    dispatch(loadProgrammationAction(project_id))
  }

  const loadDelayData = () => {

    const { delayedTasks, estimatedTotalDelay } = getDelayData(programmation.tasks, nonWorkingDays)

    const delayData = {
      tasks: delayedTasks,
      estimatedTotal: estimatedTotalDelay
    }

    dispatch(setDashboardDelayDataAction(delayData))
  }

  const loadProgressData = async () => {

    const options = {
      project,
      tasks: JSON.parse(JSON.stringify(programmation.tasks)),
      nonWorkingDays,
      versions: programmation.versions
    }

    const progressData = getProgressData(options)

    dispatch(setDashboardProgressDataAction(progressData.weeks))
  }

  const getEstimatedDelayColorClass = () => {

    if (dashboard.delayData.estimatedTotal < 0)
      return 'text-color-atrasada'

    return 'text-color-liberada'
  }

  const getDelayedTasksColorClass = () => {

    if (dashboard.delayData.tasks === 0)
      return 'text-color-completada'

    return 'text-color-atrasada'
  }

  useEffect(() => {

    if (!project._id) {

      loadProject()
    }
  }, [ project._id ])

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      if (project.ability) ability.update(project.ability)

      if (programmation.loadStatus === 'none') {

        loadProgrammation()
      }
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  useEffect(() => {

    if (programmation.loadStatus === 'loaded') {

      if (dashboard.loadDelayStatus !== 'loading') {

        loadDelayData()
      }
    }
  }, [ programmation.loadStatus, dashboard.loadDelayStatus ])

  useEffect(() => {

    if (programmation.loadStatus === 'loaded') {

      if (dashboard.loadProgressStatus === 'none') {

        loadProgressData()
      }
    }
  }, [ programmation.loadStatus, dashboard.loadProgressStatus ])

  useEffect(() => {

    const delayIsLoaded = dashboard.loadDelayStatus === 'loaded'
    const progressIsLoaded = dashboard.loadProgressStatus === 'loaded'

    if (!delayIsLoaded || !progressIsLoaded) {

      dispatch(InterfaceActions.showLoader())
    } else {

      dispatch(InterfaceActions.hideLoader())
    }

  }, [ dashboard.loadDelayStatus, dashboard.loadProgressStatus ])

  return (
    <MainLayout
      menuBar={menu}
      subMenuBar={submenu}
    >
      <MainLayoutContentHeader>
        <div className='ul-widget__head-label flex-grow-1'>
          <h3 className='ul-widget__head-title'>Dashboard</h3>
        </div>
        <div className="ul-widget__head-nav">
          <Tab.Container>
            <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
              <Nav.Item as="li">
                <Nav.Link active={tab === 'summary'} onClick={() => setTab('summary')}>
                  <span className="icon-datos_proyecto"></span> <span className="hide-on-mobile"> Resumen</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Tab.Content className="no-padding">
          <Tab.Pane active={tab === 'summary'}>
            <Row>
              <Col md={3}>
                <Card className="card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <Card.Body className="text-center">
                    <Icon name={`tareas ${getEstimatedDelayColorClass()}`}
                      style={{ fontSize: '4rem' }}
                    />
                    <div className="content ml-3" style={{ maxWidth: 'none'}}>
                      <p className="text-muted mt-2 mb-0">
                        Desviación Estimada del Proyecto
                      </p>
                      <p className={`text-24 line-height-1 mb-0 ${dashboard.delayData.estimatedTotal < 0 ? 'text-color-atrasada' : 'text-color-liberada'}`}>
                        {dashboard.delayData.estimatedTotal} días
                      </p>
                      <p className="text-primary text-1 line-height-1 mb-2">
                        <small>
                          {dashboard.delayData.estimatedTotal < 0 && '* Proyecto atrasado'}
                          {dashboard.delayData.estimatedTotal > 0 && '* Proyecto adelantado'}
                          {dashboard.delayData.estimatedTotal === 0 && '* Proyecto nivelado'}
                        </small>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <Card.Body className="text-center">
                    <Icon name={`estado_atrasada ${getDelayedTasksColorClass()}`}
                      style={{ fontSize: '4rem' }}
                    />
                    <div className="content ml-3" style={{ maxWidth: 'none'}}>
                      <p className="text-muted mt-2 mb-0">
                        Tareas atrasadas
                      </p>
                      <p className={`${getDelayedTasksColorClass()} text-30 line-height-1 mb-2`}>
                        {dashboard.delayData.tasks}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title className="mb-3">Avance de Proyecto</Card.Title>
                    <MyAreaChart
                      data={dashboard.progressData}
                      series={series}
                      colors={[
                        {
                          stroke: '#663299',
                          fill: '#66329990'
                        },
                        {
                          stroke: '#ff5721',
                          fill: '#ff572190'
                        }
                      ]}
                      customTooltip={({ active, payload, label }: any) => {

                        if (!active || !payload || !payload.length) return null

                        return (
                          <Card className="custom-tooltip"
                            style={{ minWidth: '200px' }}
                          >
                            <Card.Header>
                              <Card.Title className="mb-0">Semana {label.split(' ')[1]}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <ul style={{ padding: '0 0 0 10px', margin: 0 }}>
                                {payload.map((item: any, i: number) => (
                                  <li key={i} style={{
                                    color: item.stroke,
                                    // marginBottom: '5px'
                                  }}>
                                    <Icon name="dot" far />
                                    <span>
                                      {series[i].name}
                                    </span>
                                    <span>: {item.value}%</span>
                                  </li>
                                ))}
                              </ul>
                            </Card.Body>
                          </Card>
                        )
                      }}
                      strokeWidth={2}
                      useGradients
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </MainLayoutContentBody>
    </MainLayout>
  );
}

export default ProjectDashboardPage;


