
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useChat } from '../../Hooks/UseChat'

import InterfaceActions from '../../Redux/Actions/InterfaceActions'


import NotificationsDialog from '../NotificationsDialog/NotificationsDialog'
import ChatPage from '../../Pages/ChatPage/ChatPage'

type Props = {
    subTitle?: string,
    rightTitle?: string,
    project?: any,
    notifications?: any,
    submenu?: any,
    isChatPage?: boolean,
}

const MainLayoutMenubar = (props: Props) => {

    const ref = useRef(null)

    const { enabled: chatsEnabled, newChats, currentChat } = useChat()

    const dispatch = useDispatch()

    const [ showNotifications, setShowNotifications ] = React.useState(false)

    const submenu = props.submenu || []

    useLayoutEffect(() => {

        const saveHeight = () => {

            if (ref && ref.current) {

                const current: any = ref.current

                dispatch(InterfaceActions.setDimension('submenuHeight', current.offsetHeight))
            }
        }

        saveHeight()

        window.addEventListener('resize', saveHeight)

        return () => window.removeEventListener('resize', saveHeight)
    }, [])

    const {
        notifications,
    } = props

    const getNotRead = () => {

        if (!notifications) {

            return 0
        }

        return notifications.filter((notification: any) => notification.status === 'not_read').length
    }

    const notRead = getNotRead()

    const isProgrammation = () => submenu[ 0 ]?.icon === "icon-macro";

    return (
        <>
            <div className="horizontal-bar-wrap">
                <div ref={ref} className="header-topnav">
                    <div className="submenu-bar">
                        {!submenu.length ? null : (
                            <div className="submenu-left">
                                <ul className="submenu submenu-left">
                                    {submenu.map((item: any, i: number) => (
                                        <li key={i}>
                                            {item.disabled ? (
                                                <span className={`submenu-span ${!item.active ? '' : 'active'}`}>
                                                    <span className="submenu-icon">
                                                        <div className="svg-icon-menu" style={{
                                                            display: 'inline-block',
                                                        }}>
                                                            <div className="svg-icon-container">
                                                                <span className={`${item.icon}${item.active ? '_full' : ''}`}></span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span className="d-none d-md-inline">{item.text}</span>
                                                </span>
                                            ) : (
                                                <Link className={`submenu-link ${!item.active ? '' : 'active'}`} to={item.path}>
                                                    <span className="submenu-icon">
                                                        <div className="svg-icon-menu" style={{
                                                            display: 'inline-block',
                                                        }}>
                                                            <div className="svg-icon-container">
                                                                <span className={`${item.icon}${item.active ? '_full' : ''}`}></span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span className="d-none d-md-inline">{item.text}</span>
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="submenu-right">
                            <div className={isProgrammation() ? "subtitle-programattion" : "subtitle"}>
                                {props.rightTitle}
                            </div>
                            <div className="header-dropdowns">

                                <div
                                    className="right-dropdown svg-icon-menu cursor-pointer"
                                    style={{ paddingTop: 6 }}
                                    onClick={() => setShowNotifications(true)}
                                >

                                    <div className="svg-icon-container"
                                        style={{
                                            position: 'relative', // Agregamos posición relativa al contenedor
                                            width: 26,
                                            height: 26, // Añadimos altura para asegurar que el badge tenga espacio
                                            margin: 0,
                                        }}
                                    >

                                        {!notRead ? null : (
                                            <span
                                                className="badge badge-primary"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    cursor: 'pointer',
                                                    fontSize: 9
                                                }}
                                            >
                                                {notRead}
                                            </span>
                                        )}
                                        <span className="icon-notificaciones"></span>
                                    </div>
                                </div>
                                {chatsEnabled && (
                                    <Link to={`/chat${currentChat ? '/' + currentChat._id: ''}`}>
                                        <div className={`right-dropdown svg-icon-menu cursor-pointer ${props.isChatPage ? 'active' : ''}`}>
                                            <div className="svg-icon-container"
                                                style={{
                                                    position: 'relative', // Agregamos posición relativa al contenedor
                                                    width: 26,
                                                    height: 26, // Añadimos altura para asegurar que el badge tenga espacio
                                                    margin: 0,
                                                }}
                                            >
                                                {newChats > 0 && (
                                                    <span
                                                        className="badge badge-primary"
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0',
                                                            right: '0',
                                                            cursor: 'pointer',
                                                            fontSize: 9
                                                        }}
                                                    >
                                                        {newChats}
                                                    </span>
                                                )}

                                                <span className={`icon-mensajes${props.isChatPage ? '_full' : ''}`}></span>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NotificationsDialog
                show={showNotifications}
                title="Notifications"
                notifications={props.notifications}
                onHide={() => setShowNotifications(false)}

            />
        </>
    )
}

export default MainLayoutMenubar
