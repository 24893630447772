import config from "../config";
import { EMethods, request } from "./ApiService";

export type FeedbackData = {
  subject: string;
  message: string;
  image: string;
  url: string;
};

export const sendFeedback = async (data: FeedbackData) => {


  try {
    const res = await request(
      EMethods.post,
      "SEND_FEEDBACK",
      `${config.apiHost}/feedback`,
      data
    );

    if (!res.data.success) throw new Error(res.data.error);

    return res.data;
  } catch (error) {

    return { error: error instanceof Error ? error.message: '' }
  }
}
