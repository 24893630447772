import React from "react"
import { countryNameRecord, CountryCode } from "../../Constants/countries"

interface Props {
  code: CountryCode
  showName?: boolean
}

const CountryFlagEmoji = ({ code, showName }: Props) => {

  const getCountryFlagEmoji = (countryCode: CountryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt(0))
    return String.fromCodePoint(...codePoints)
  }

  const title = code ? countryNameRecord[code] || code : undefined
  return (
    <span role="img" aria-labelledby={title} title={title}>
      {code ? getCountryFlagEmoji(code) : "🏳"}
      {showName && ` ${title}`}
    </span>
  )
}

export default CountryFlagEmoji
