
import React, { useEffect, useState } from 'react'

import RelationItem from './RelationItem'

type Relation = {
    type: string,
    from_task: string,
    to_task: string,
}

type Props = {
    taskList: any,
    items: Relation[],
    task?: any,
    isNewTask?: boolean,
    onChange?: any,
    readOnly?: boolean,
}

const getEmptyItem = (task_id: string): Relation => {

    return {
        type: '',
        from_task: task_id ? task_id : '',
        to_task: task_id ? '' : 'new',
    }
}

const getTasksOptionsFromTaskList = (taskList: any, task: any, level: number) => {

    const lvl = level || 0

    const getLevelIndentationString = () => {

        let indentation = ''

        for (let i = 0; i < lvl; i++) {

            indentation += '-- '
        }

        return indentation
    }

    const options: any = []

    //TODO: move task if group id is changed
    taskList.map((item: any) => {

        if (item.type === 'task') {

            options.push({
                value: item.task._id,
                label: `${getLevelIndentationString()} ${task._id === item.task._id ? '➤ ' : ''}${item.task.name}`,
            })
        } else {

            options.push({
                value: item.group._id,
                label: `${getLevelIndentationString()} ${item.group.name}`,
                isGroup: true,
            })

            const subOptions = getTasksOptionsFromTaskList(item.group.items, task, lvl + 1)

            options.push(...subOptions)

            if (!task._id && item.group._id === task.groupId) {

                options.push({
                    value: 'new',
                    label: `-- ➤ ${task.name || '(Tarea sin nombre)'}`,
                })
            }
        }
    })

    if (!task._id && !task.groupId) {

        options.push({
            value: 'new',
            label: `-- ➤ ${task.name || '(Tarea sin nombre)'}`,
        })
    }

    return options
}

const RelationsList = (props: Props) => {

    const [ tasksOptions, setTasksOptions ] = useState([])

    const addEmptyItem = () => {

        const items = [ ...props.items ]

        items.push(getEmptyItem(props.task?._id))

        props.onChange(items)
    }

    const updateItem = (item: any) => {

        const items = [ ...props.items ]

        items.map((_item: any) => {

            if (item._id === _item._id) {

                return item
            }

            return _item
        })

        props.onChange(items)
    }

    const deleteItem = (i: number) => {

        const items: any = props.items.filter((item: any, j: number) => j !== i)

        props.onChange(items)
    }

    useEffect(() => {

        setTasksOptions(getTasksOptionsFromTaskList(props.taskList, props.task, 0))
    }, [ props.taskList, props.task ])

    return (
        <div data-testid="relations-list">
            {props.items.map((item: any, i: number) => (
                <RelationItem key={i}
                    testId={`relation-item-${i}`}
                    item={item}
                    tasksOptions={tasksOptions}
                    task={props.task}
                    onChange={(item: any) => updateItem(item)}
                    onDelete={() => deleteItem(i)}
                    readOnly={props.readOnly}
                />
            ))}
            <button data-testid="add-button" className="btn btn-normal mb-1"
                onClick={() => addEmptyItem()}
                disabled={props.readOnly}
            >
                <span className="icon-agregar"></span> Agregar relación
            </button>
        </div>
    )
}

export default RelationsList
