/*eslint array-callback-return: "off"*/

export const getAllMembersFromProject = (teams: any) => {

    if (!teams) return []

    const users_ids: any = []
    const users: any = []

    teams.map((team: any, i: number) => {

        team.users.map((user: any, j: number) => {

            if (!users_ids.includes(user._id)) {

                users_ids.push(user._id)

                user.team = team

                users.push(user)
            }
        })
    })

    return users
}

export const getMemberFromList = (list: any, _id: string) => {

    const found = list.filter((item: any) => item._id === _id)

    if (!found.length) return undefined

    return found[0]
}

export const getTaskResponsibles = (allMembers: any, responsibles_ids: any) => {


    if (!responsibles_ids) return []

    const result = responsibles_ids.map((_id: any) => {

        const rows = allMembers.filter((member: any) => member._id === _id)

        if (rows.length) return rows[0]
    })

    return result
}

export const getResponsiblesIds = (responsibles: any) => {

    const ids: any = []

    responsibles.map((responsible: any) => {

        if (responsible) {

            ids.push(responsible._id)
        }
    })

    return ids
}

export const isValidEmail = (email: string) => {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export default {
    getAllMembersFromProject,
    getMemberFromList,
    getTaskResponsibles,
    getResponsiblesIds,
    isValidEmail,
}
