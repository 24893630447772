
import React from 'react'
import Icon from './Components/Icon/Icon';

export interface Submenu {
  text: string;
  path: string;
  icon: React.ReactNode;
  active: boolean;
}

export interface Menu {
  text: string;
  textWidth: number;
  path: string;
  icon: React.ReactNode;
  active: boolean;
  submenu?: Submenu[];
  dropdown?: Submenu[];
  dropdownIcon?: React.ReactNode;
  disabled?: boolean;
}

interface Navigation {
  menu: Menu[];
  submenu?: Submenu[];
  dropdown?: Submenu[];
  dropdownIcon?: React.ReactNode;
}

export const getAdminNavigation = (active_menu?: string, active_submenu?: string): Navigation => {

  const menu: Menu[] = [
    {
      text: 'Dashboard',
      textWidth: 80,
      path: `/admin`,
      icon: <Icon name="dashboard" width={26} svg />,
      active: active_menu === 'dashboard',
    },
    {
      text: 'Membresías',
      textWidth: 80,
      path: `/admin/memberships`,
      icon: <Icon name="memberships" width={26} svg />,
      active: active_menu === 'memberships',
      submenu: [
        {
          text: 'Membresías',
          path: `/admin/memberships`,
          icon: <Icon name="memberships_list" width={18} svg />,
          active: active_submenu === 'list',
        },
        {
          text: 'Solicitudes',
          path: `/admin/memberships/orders`,
          icon: <Icon name="shopping-cart" fas style={{ marginRight: 4 }} />,
          active: active_submenu === 'orders',
        },
      ],
      dropdownIcon: <Icon name="cogs" fas />,
      dropdown: [
        {
          text: 'Planes',
          path: `/admin/memberships/plans`,
          icon: <Icon name="plans" width={18} svg />,
          active: active_submenu === 'plans',
        },
        {
          text: 'Características',
          path: `/admin/memberships/features`,
          icon: <Icon name="features" width={18} svg />,
          active: active_submenu === 'features',
        },
      ],
    },
    {
      text: 'Usuarios',
      textWidth: 80,
      path: `/admin/users`,
      icon: <Icon name="users" width={18} svg />,
      active: active_menu === 'users',
    },
    {
      text: 'Proyectos',
      textWidth: 80,
      path: `/admin/projects`,
      icon: <Icon name="projects" width={18} svg />,
      active: active_menu === 'projects',
    },
  ]

  const submenu = menu.find(item => item.active)?.submenu || []

  const dropdown = menu.find(item => item.active)?.dropdown || []

  const dropdownIcon = menu.find(item => item.active)?.dropdownIcon

  return { menu, submenu, dropdown, dropdownIcon }
}
