
import UsersService from '../../Services/UsersService'

const TAG = 'UsersService'

enum ETypes {
    GET_RESULT_START = 'USERS_GET_RESULT_START',
    GET_RESULT_END = 'USERS_GET_RESULT_END',
    GET_RESULT_ERROR = 'USERS_GET_RESULT_ERROR',
    GET_RESULT_RESET = 'USERS_GET_RESULT_RESET',
}

const searchAction = (criteria: string, team_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: ETypes.GET_RESULT_START
        })

        try {

            const res = await UsersService.search({ criteria, team_id })

            dispatch({
                type: ETypes.GET_RESULT_END,
                content: {
                    data: res.data
                }
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: ETypes.GET_RESULT_ERROR,
                content: {
                    error: error instanceof Error ? error.message : ''
                }
            })
        }
    }
}

const getUsersResetAction = () => {

    return {
        type: 'USERS_GET_RESULT_RESET'
    }
}

export default {
    searchAction,
    getUsersResetAction,
}
