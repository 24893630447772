
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import './InputFormControl.scss'

type Props = {
  id?: string,
  type?: string,
  pattern?: string,
  placeholder?: string,
  defaultValue?: string,
  value?: any,
  min?: number,
  max?: number,
  onBlur?: any,
  onChange?: any,
  onEscape?: any,
  onEnter?: any,
  onRemove?: any,
  readOnly?: any
  disabled?: any
  className?: string,
  style?: any,
  autoFocus?: any
  testId?: string,
}

interface TextInputFormRef {
  setFocus: () => void;
}

const InputFormControl = forwardRef<TextInputFormRef, Props>((props, ref) => {

  const inputRef = useRef<HTMLInputElement | null>(null);

  const detectEnterOrEscape = (e: any) => {

    if (e.key === 'Enter' || e.keyCode === 13) {

      if (typeof props.onEnter === 'function') {

        props.onEnter()
      }
    } else if (e.key === 'Escape' || e.keyCode === 27) {

      if (typeof props.onEscape === 'function') {

        props.onEscape(e)
      }
    }
  }

  const handleChange = (value: any) => {

    props.onChange(value)
  }

  const handleSetFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({
    setFocus: handleSetFocus
  }));

  useEffect(() => {
      // Enfocar en el último input
      const lastInput = document.querySelector('.form-control:last-child') as HTMLInputElement
      if (lastInput) {
        lastInput.focus()
      }
    }, [])
  
    useEffect(() => {
      handleSetFocus();
  }, []);
  

  const { onChange, onRemove, readOnly, disabled, onEnter, onEscape, testId, value, ...rest } = props

  return (
    <>
      {!onRemove && (
        <input
          className="form-control"
          ref={inputRef}
          autoFocus={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => detectEnterOrEscape(e)}
          onWheel={(e: any) => e.currentTarget.blur()}
          readOnly={readOnly}
          disabled={disabled}
          value={typeof value !== 'undefined' ? value : ''}
          {...rest}
          data-testid={testId || 'input-form-control'}
        />
      )}
      {onRemove && (
        <div className="input-group">
          <input className="form-control"
            ref={inputRef}
            autoFocus={true}
            value={typeof value !== 'undefined' ? value : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => detectEnterOrEscape(e)}
            onWheel={(e: any) => e.currentTarget.blur()}
            readOnly={readOnly}
            disabled={disabled}
            {...rest}
            data-testid={props.testId || 'input-form-control'}
          />
          <div className="input-group-append">
            <button className="btn-remove"
              onClick={() => onRemove()}
              disabled={disabled || readOnly}
            >
              <i className="fas fa-fw fa-times"></i>
            </button>
          </div>
        </div>
      )}
    </>
  )
})

InputFormControl.displayName = 'InputFormControl'

export default InputFormControl
