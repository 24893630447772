import moment from "moment"

export const getTaskStatus = (status: any) => {

    switch (status) {
        case 'activa':
            return 'Activa'
        case 'pendiente':
            return 'Pendiente'
        case 'liberada':
            return 'Liberada'
        case 'completada':
            return 'Completada'
        case 'atrasada':
            return 'Atrasada'
        case 'activa_atrasada':
        case 'activa-atrasada':
            return 'Activa atrasada'
        default:
            return ''
    }
}

export const getUser = (users_arr: any, user_id: string) => {

    const matches = users_arr.filter((user: any) => user._id === user_id)

    if (matches.length) {

        return matches[0]
    }

    return null
}

export const getUsers = (users_arr: any, users_ids: any) => {

    const users: any = []

    if (!users_ids) {

        return users
    }

    users_ids.map((user_id: string) => {

        const user: any = getUser(users_arr, user_id)

        if (user) {

            users.push(user)
        }
    })

    return users
}

export const getGroupOptionsFromTaskList = (taskList: any, lvl: number) => {

    const getLevelIndentation = () => {

        let indentation = ''

        for (let i = 0; i < lvl; i++) {

            indentation += '-- '
        }

        return indentation
    }

    const options: any = []

    taskList?.map((item: any, i: number) => {

        if (item.type === 'group') {

            options.push({
                value: item.group._id,
                label: `${getLevelIndentation()} ${item.group.name}`
            })

            const subOptions = getGroupOptionsFromTaskList(item.group.items, lvl + 1)

            options.push(...subOptions)
        }
    })

    return options
}

export const getTaskMonths = (task: any) => {

    const months:any = []

    const start = moment(task.startsAt)
    const end = moment(task.endsAt)

    months.push(start.format('YYYYMM'))

    if (start.isSame(end, 'years') && start.isSame(end, 'months')) {

        // console.log('mismo año, mismo mes')
    } else {

        while(end.isAfter(start.add(1, 'month'), 'months')) {

            months.push(start.format('YYYYMM'))
        }
    }

    return months
}

export const getResponsibles = (task: any, users: any) => {

    if (!task.responsibles || !task.responsibles.length) {

        return ''
    }

    const responsibles = users.filter((user: any) => task.responsibles.includes(user._id))

    return responsibles.map((user: any) => {

        return `${user.name.charAt(0)}${user.surname.charAt(0)}`
    })
}

export const checkRelations = (relations: any[], task_id: string) => {

    let exists = true

    relations.map((rel: any) => {

        if (rel.from_task !== task_id && rel.to_task !== task_id) {

            exists = false
        }
    })

    return exists
}

export const getTasksUsers = (tasks: any[]) => {

    const users_ids: string[] = []

    tasks.forEach((task: any) => {

        task.comments.forEach((comment: any) => {

            if (!users_ids.includes(comment.user)) {

                users_ids.push(comment.user)
            }
        })

        task.responsibles.forEach((responsible: string) => {

            if (!users_ids.includes(responsible)) {

                users_ids.push(responsible)
            }
        })

        task.restrictions.forEach((restriction: any) => {

            if (!users_ids.includes(restriction.createdBy)) {

                users_ids.push(restriction.createdBy)
            }
        })
    })

    return users_ids
}

export const getTasksColorCodes = (tasks: any[]) => {

    const color_codes_ids: any[] = []

    tasks.forEach((task: any) => {

        if (task.colorCode) {

            if (!color_codes_ids.includes(task.colorCode)) {

                color_codes_ids.push(task.colorCode)
            }
        }
    })

    return color_codes_ids
}
