
export enum TaskTypes {
    partida = 'Partida',
    tarea = 'Tarea',
    diseño = 'Diseño',
    reunion = 'Reunión',
    hito = 'Hito',
}

export enum TaskStatus {
    activa = 'Activa',
    pendiente = 'Pendiente',
    liberada = 'Liberada',
    completada = 'Completada',
    atrasada = 'Atrasada',
    activa_atrasada = 'Activa atrasada',
}

export type ColorCode = {
    _id?: string,
    name: string,
    color: string,
}

export interface Task  {
    _id?: string,
    name: string,
    groupId: string,
    type: string,
    startsAt?: string,
    endsAt?: string,
    executeDays?: number,
    releasesAt?: string,
    restrictions: any,
    responsibles: any,
    relations: any,
    colorCodeId?: string,
    status?: TaskStatus,
    unit?: number | string,
    metered?: number | string,
    performance?: number | string,
    crewNumber?: number | string,
    estimatedDays?: number | string,
    project_id?: string,
    progress?: number,
}

export interface TaskWithId extends Task {
    _id: string,
    progress: number,
    confirmed: boolean,
}

export type Group = {
    _id?: string,
    name: string,
    items: any[],
}

export const newTask: Task = {
    _id: undefined,
    name: '',
    groupId: '',
    type: 'actividad',
    startsAt: '',
    endsAt: '',
    executeDays: 0,
    releasesAt: '',
    restrictions: [],
    responsibles: [],
    relations: [],
    colorCodeId: undefined,
}
