
const styles = {
    title: {
        fontSize: '14px' as const,
        fontWeight: 'bold' as const,
        textAlign: 'left' as const
    },
    content: {
        fontSize: '12px' as const,
        textAlign: 'left' as const
    }
}

export default styles
