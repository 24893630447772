
import React from 'react'
import InputFormControl from '../InputFormControl/InputFormControl'

type Item = {
    description: string,
    checked: boolean,
}

type Props = {
    item: Item,
    readOnly?: boolean,

    onChange: any,
    onDelete: any,
    testId?: string,
}

const CheckListItem = (props: Props) => {

    const testId = props.testId

    const onToggleCheck = (checked: boolean) => {

        const item = props.item

        item.checked = checked

        props.onChange(item)
    }

    return (
        <div data-testid={testId} className="input-group mb-2">
            <div className="input-group-prepend">
                <div className="input-group-text">
                    <input
                        data-testid={`${testId}-checkbox`}
                        type="checkbox"
                        checked={props.item.checked}
                        onChange={e => onToggleCheck(e.target.checked)}
                        disabled={props.readOnly}
                    />
                </div>
            </div>
            <InputFormControl
                value={props.item.description}
                // onChange={(value: string) => props.onChange(value)}
                readOnly
                style={{
                    height: 34
                }}
            />
            <div className="input-group-append">
                <button
                    data-testid={`${testId}-delete-button`}
                    className="btn btn-normal"
                    onClick={() => props.onDelete()}
                    disabled={props.readOnly}
                >
                    <span className="icon-eliminar"></span>
                </button>
            </div>
        </div>
    )
}

export default CheckListItem
