
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'

const TeamSelector = (props: any) => {

    const [ teamSelected, setTeamSelected ] = useState(props.teamSelected)

    const cancel = () => {

        setTeamSelected(props.teamSelected)

        props.onHide()
    }

    const accept = () => {

        props.onSelectTeam(teamSelected)
    }

    useEffect(() => {

        setTeamSelected(props.teamSelected)
    }, [ props.show ])

    return (
        <Modal
            show={props.show}
            onHide={() => cancel()}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Cambiar de equipo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="list-group">
                    {props.teams && props.teams.map((team: any) => (
                        <li key={team._id}
                            className={`list-group-item list-group-item-action cursor-pointer ${team._id === teamSelected._id && 'active'}`}
                            onClick={() => setTeamSelected(team)}
                        >
                            <i className="fas fa-fw fa-users"></i> {team.name}
                        </li>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary"
                    onClick={() => cancel()}
                >
                    <span className="icon-cancelar"></span> Cancelar
                </button>
                <button className="btn btn-primary"
                    onClick={() => accept()}
                >
                    <span className="icon-guardar"></span> Aceptar
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default TeamSelector
