
import NotificationsActions from './NotificationsActions'
import ProjectsActions from './ProjectsActions'

import TeamsService from '../../Services/TeamsService'

const TAG = 'TeamsService'



export enum TEAMS_ACTION_TYPES {
    CLEAR_DATA = 'TEAMS_CLEAR_DATA',
    SET_RESULT = 'TEAMS_SET_RESULT',

    GET_RESULT_START = 'TEAMS_GET_RESULT_START',
    GET_RESULT_ERROR = 'TEAMS_GET_RESULT_ERROR',
    CREATE_ROW_START = 'TEAMS_CREATE_ROW_START',
    CREATE_ROW_END = 'TEAMS_CREATE_ROW_END',
    CREATE_ROW_ERROR = 'TEAMS_CREATE_ROW_ERROR',
    DELETE_ROW_START = 'TEAMS_DELETE_ROW_START',
    DELETE_ROW_END = 'TEAMS_DELETE_ROW_END',
    DELETE_ROW_ERROR = 'TEAMS_DELETE_ROW_ERROR',
    MAIL_INVITE_USER_START = 'TEAMS_MAIL_INVITE_USER_START',
    MAIL_INVITE_USER_END = 'TEAMS_MAIL_INVITE_USER_END',
    MAIL_INVITE_USER_ERROR = 'TEAMS_MAIL_INVITE_USER_ERROR',
    ADD_ME_START = 'TEAMS_ADD_ME_START',
    ADD_ME_END = 'TEAMS_ADD_ME_END',
    ADD_ME_ERROR = 'TEAMS_ADD_ME_ERROR',
    INVITE_USER_START = 'TEAMS_INVITE_USER_START',
    INVITE_USER_END = 'TEAMS_INVITE_USER_END',
    INVITE_USER_ERROR = 'TEAMS_INVITE_USER_ERROR',
    ADD_USER_START = 'TEAMS_ADD_USER_START',
    ADD_USER_END = 'TEAMS_ADD_USER_END',
    ADD_USER_ERROR = 'TEAMS_ADD_USER_ERROR',
    REMOVE_USER_START = 'TEAMS_REMOVE_USER_START',
    REMOVE_USER_END = 'TEAMS_REMOVE_USER_END',
    REMOVE_USER_ERROR = 'TEAMS_REMOVE_USER_ERROR',
}

const clearDataAction = () => {

    return {
        type: TEAMS_ACTION_TYPES.CLEAR_DATA
    }
}

const getResultAction = (project: string) => {

    return async (dispatch: any) => {

        const res = await TeamsService.getResult(project)

        dispatch({
            type: TEAMS_ACTION_TYPES.SET_RESULT,
            content: {
                data: res.data
            }
        })

        return res
    }
}

const createRowAction = (name: string, project: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.CREATE_ROW_START
        })

        try {

            await TeamsService.createRow(name, project)

            dispatch({
                type: TEAMS_ACTION_TYPES.CREATE_ROW_END
            })

            dispatch(getResultAction(project))
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: TEAMS_ACTION_TYPES.CREATE_ROW_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const deleteRowAction = (team_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.DELETE_ROW_START
        })

        try {

            await TeamsService.deleteRow(team_id)

            dispatch({
                type: TEAMS_ACTION_TYPES.DELETE_ROW_END
            })

            dispatch(getResultAction(project_id))
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: TEAMS_ACTION_TYPES.DELETE_ROW_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const mailInviteUserAction = (email: string, team_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.MAIL_INVITE_USER_START
        })

        try {

            await TeamsService.mailInviteUser(email, team_id)

            dispatch({
                type: TEAMS_ACTION_TYPES.MAIL_INVITE_USER_END,
            })

            dispatch(getResultAction(project_id))
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: TEAMS_ACTION_TYPES.MAIL_INVITE_USER_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const addMeAction = (team_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.ADD_ME_START
        })

        const res: any = await TeamsService.addMe(team_id)

        if (res.success) {

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_ME_END,
            })

            dispatch(getResultAction(project_id))
        } else {

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_ME_ERROR,
                content: {
                    error: res.message
                }
            })
        }
    }
}

const inviteUserAction = (user_id: string, team_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.INVITE_USER_START
        })

        const res: any = await TeamsService.inviteUser(user_id, team_id)

        if (res.success) {

            dispatch({
                type: TEAMS_ACTION_TYPES.INVITE_USER_END,
            })

            dispatch(getResultAction(project_id))

            return true
        } else {

            dispatch({
                type: TEAMS_ACTION_TYPES.INVITE_USER_ERROR,
                content: {
                    error: res.message
                }
            })

            return false
        }
    }
}

const acceptInviteAction = (invite_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.ADD_USER_START
        })

        try {

            await TeamsService.acceptInvite(invite_id)

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_USER_END,
            })

            dispatch(getResultAction(project_id))
            dispatch(NotificationsActions.loadResult())
            dispatch(ProjectsActions.getResultAction())
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_USER_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const rejectInviteAction = (invite_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.ADD_USER_START
        })

        try {

            await TeamsService.rejectInvite(invite_id)

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_USER_END,
            })

            dispatch(getResultAction(project_id))
            dispatch(NotificationsActions.loadResult())
            dispatch(ProjectsActions.getResultAction())
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: TEAMS_ACTION_TYPES.ADD_USER_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const removeUserAction = (user_id: string, team_id: string, project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: TEAMS_ACTION_TYPES.REMOVE_USER_START
        })

        try {

            await TeamsService.removeUser(user_id, team_id)

            dispatch({
                type: TEAMS_ACTION_TYPES.REMOVE_USER_END,
            })

            dispatch(getResultAction(project_id))
        } catch (error) {

            //todo: remove try catch
            const { message, status } =

            dispatch({
                type: TEAMS_ACTION_TYPES.REMOVE_USER_ERROR,
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const moveUserAction = (project_id: string, user_id: string, from_team: string, to_team: string) => {

    return async (dispatch: any) => {

        try {

            await TeamsService.moveUser(user_id, from_team, to_team)

            dispatch(getResultAction(project_id))
        } catch (error) {

            //todo: remove try catch
        }
    }
}


//NUEVO CODIGO
const toggleShowOnlyMyTasks = (project_id: string, user_id: string, showOnlyMyTasks: boolean) => {

    return async (dispatch: any) => {
        try {
            await TeamsService.hideTasks(project_id, user_id, showOnlyMyTasks)

            dispatch(getResultAction(project_id))

        } catch (error) {
            //todo: remove try catch
        }
    }
}

//NUEVO CODIGO

export default {
    clearDataAction,
    getResultAction,
    createRowAction,
    deleteRowAction,
    addMeAction,
    mailInviteUserAction,
    inviteUserAction,
    acceptInviteAction,
    rejectInviteAction,
    removeUserAction,
    moveUserAction,
    toggleShowOnlyMyTasks
}
