import moment from "moment"

import { getYMDFromDate } from "../../Helpers/DateFunctions"

export const daysFullNames = [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo' ]
export const daysShortNames = [ 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom' ]
export const daysFirstLetters = [ 'L', 'M', 'X', 'J', 'V', 'S', 'D' ]

export const getTableWidth = (columns: any) => {

  if (!columns || !columns.length) return 0

  const anchos = columns.map((column: any) => column.width)

  return anchos.reduce((suma: number, valor: number) => suma + valor)
}

export const getRowClassName = (isSelected: boolean, item: any) => {

  let className = "tr gantt-row cursor-pointer"

  if (!isSelected || !item) {

    return className
  }

  if (isSelected) {

      className += " active"
  }

  if (item.progress === 100 && !item.confirmed) {

      className += ' needs-confirmation'
  }

  return className
}

export const getGanttDaysAndWeeks = (startDay: any, endDay: any, nonWorkingDays: string[], hideNonWorkingDays: boolean) => {


  const days: any[] = []

  const weeks: any[] = []

  const day = moment(startDay)

  const today = moment()

  let week: number

  while (day.isSameOrBefore(endDay)) {

    week = day.isoWeek()

    const isNonWorkingDay = nonWorkingDays.includes(day.format('YYYY-MM-DD'))

    if (!isNonWorkingDay || (isNonWorkingDay && !hideNonWorkingDays)) {

          days.push({
            moment: day.clone(),
            isToday: day.isSame(today, 'day'),
            isWeekend: day.isoWeekday() === 6 || day.isoWeekday() === 7,
            isNonWorkingDay,
            week,
            longWeek: day.clone().format('YYYYWW'),
            weekDay: day.isoWeekday(),
            weekDayName: day.format('dddd'),
            weekDayNameFirstLetter: daysFirstLetters[day.isoWeekday() - 1],
            monthDay: day.date(),
            year: day.year(),
            ISOString: day.toISOString(),
            YMDString: day.format('YYYY-MM-DD'),
          })

          if (!weeks.map((w: any) => w.number).includes(week)) {

            weeks.push({
              number: week,
              long: day.clone().format('YYYYWW'),
              start: day.clone().format('D MMM YYYY'),
              days: [ day.clone() ]
            })
          } else {

              weeks.find((w: any) => w.number === week).days.push(day.clone())
          }
    }

    day.add(1, 'day')
  }

  return [ days, weeks ]
}

export const getItemDaysBefore = (chartStart: string, itemStart: string, nonWorkingDays: string[], hideNonWorkingDays: boolean) => {
  if (!chartStart || !itemStart) return 0;

  const chartStartDate = new Date(chartStart);
  const itemStartDate = new Date(itemStart);
  const nonWorkingDaysSet = new Set(nonWorkingDays);

  let days = 0;

  const numDays = Math.floor((itemStartDate.getTime() - chartStartDate.getTime()) / (1000 * 60 * 60 * 24));

  for (let i = 0; i < numDays; i++) {
    const currentDate = new Date(chartStartDate.getTime() + i * (1000 * 60 * 60 * 24));
    const isNonWorkingDay = nonWorkingDaysSet.has(getYMDFromDate(currentDate));

    if (!isNonWorkingDay || (isNonWorkingDay && !hideNonWorkingDays)) {
      days += 1;
    }
  }

  return days;
};

export const getItemWidth = (itemStart: string,
                              itemEnd: string,
                              nonWorkingDays: string[],
                              hideNonWorkingDays: boolean) => {

  const day = new Date(itemStart)
  const endDay = new Date(itemEnd)

  let days = 0

  while(day <= endDay) {

    const isNonWorkingDay = nonWorkingDays.includes(day.toISOString().split('T')[0])

    if (!isNonWorkingDay || (isNonWorkingDay && !hideNonWorkingDays)) {
      days += 1
    }

    day.setDate(day.getDate() + 1)
  }

  return days
}

export const getRelationsFromItems = (items: any[]) => {

    const relations: any = []

    const relationsIds: string[] = []

    items.forEach((item: any) => {

      if (item.relations) {

        item.relations.forEach((relation: any) => {

          if (!relationsIds.includes(relation._id)) {

            let fromTop
            let toTop
            const fromTask = items.find((item: any, i: number) => {

              if (item._id === relation.from_task) {

                fromTop = i
                return true
              }
            })
            const toTask = items.find((item: any, i: number) => {

              if (item._id === relation.to_task) {

                toTop = i
                return true
              }
            })

            if (fromTask && toTask) {

              relationsIds.push(relation._id)

              const isHitoOrReunion = fromTask.type === 'hito' || fromTask.type === 'reunion'

              if (isHitoOrReunion || relation.type === 'start_to_start') {

                relations.push({
                  _id: relation._id,
                  fromLeft: fromTask.left,
                  fromTop,
                  toLeft: toTask.left,
                  toTop,
                  type: isHitoOrReunion ? 'diamond_to_start' : 'start_to_start',
                })
              } else if (relation.type === 'end_to_start') {

                relations.push({
                  _id: relation._id,
                  fromLeft: fromTask.left + fromTask.width,
                  fromTop,
                  toLeft: toTask.left,
                  toTop,
                  type: 'end_to_start',
                })
              }
            }
          }
        })
      }
    })

    return relations
}

export const getSVGTextWidth = (text: string, style: any) => {

  let svg: any = document.getElementById('fake-svg-text')
  let textElement: any = document.getElementById('fake-svg-text-element')

  if (!svg) {

    svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.id = 'fake-svg-text';
    svg.style.position = 'absolute';
    svg.style.top = '-100%';
    textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.id = 'fake-svg-text-element';
  }


  for (const key in style) {

    if (key === 'fontSize') {

      textElement.style.fontSize = style[key]
    } else if (key === 'fontWeight') {

      textElement.style.fontWeight = style[key]
    }
  }

  textElement.textContent = text;
  svg.appendChild(textElement);
  document.body.appendChild(svg);

  const width = textElement.getBBox().width;


  return width
}
