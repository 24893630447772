
import React, { Fragment } from "react";

type Props = {
  from: any,
  to: any
}

const ChangeRestrictions = ({ from, to }: Props) => {

  const checkIsDeleted = (restriction: any) => {

    let existsInNewList = false

    to.forEach((restrictionFrom: any) => {

      if (restrictionFrom._id === restriction._id) {

        existsInNewList = true
      }
    })

    return !existsInNewList
  }

  const checkIsAdded = (restriction: any) => {

    let existsInOldList = false

    from.forEach((restrictionTo: any) => {

      if (restrictionTo._id === restriction._id) {

        existsInOldList = true
      }
    })

    return !existsInOldList
  }

  const checkIsUpdated = (restriction: any) => {

    let isUpdated = false

    from.forEach((restrictionFrom: any) => {

      if (restrictionFrom._id === restriction._id) {

        const isDescriptionUpdated = restrictionFrom.description !== restriction.description
        const isCheckedUpdated = restrictionFrom.checked !== restriction.checked

        if (isDescriptionUpdated || isCheckedUpdated) {

          isUpdated = true
        }
      }
    })

    return isUpdated
  }

  const getAllRestrictions: any = () => {

    from = from || []
    to = to || []

    const list: any = []

    from.forEach((restriction: any, i: number) => {

      if (checkIsDeleted(restriction)) {

        list.push({ action: 'deleted', ...restriction })
      }
    })

    to.forEach((restriction: any, i: number) => {

      if (checkIsAdded(restriction)) {

        list.push({ action: 'added', ...restriction })
      }
    })

    to.forEach((new_estriction: any, i: number) => {

      if (checkIsUpdated(new_estriction)) {

        const old_restriction = from.find((restriction: any) => restriction._id === new_estriction._id)

          list.push({ action: 'updated', old: {...old_restriction}, new: {...new_estriction} })
      }
    })

    return list
  }

  return (
    <ul className="change-ul">
      {getAllRestrictions().map((restriction: any, i: number) => (
        <Fragment key={i}>
          {restriction.action === 'deleted' && (
            <li>
              Se eliminó la restricción{' '}
              <span className="change removed">
                {restriction.checked ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}{' '}
                {restriction.description}
              </span>
            </li>
          )}
          {restriction.action === 'added' && (
            <li>
              Se agregó la restricción{' '}
              <span className="change added">
                {restriction.checked ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}{' '}
                {restriction.description}
              </span>
            </li>
          )}
          {restriction.action === 'updated' && (
            <li>
              Se modificó la restricción de{' '}
              <span className="change removed">
                {restriction.old.checked ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}{' '}
                {restriction.old.description}
              </span>
              a{' '}
              <span className="change added">
                {restriction.new.checked ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>}{' '}
                {restriction.new.description}
              </span>
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  )
}

export default ChangeRestrictions
