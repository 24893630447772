import React from 'react';
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createMongoAbility } from "@casl/ability"

import * as serviceWorker from './serviceWorker';

// import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"

import store from './Redux/store'

import { AbilityContext } from './Casl/Casl';

import { FeedbackProvider } from './Hooks/UseFeedback';

import App from './App';

import FeedbackForm from './Components/FeedbackForm/FeedbackForm';
import { AuthProvider } from './Hooks/UseAuth';
import { ChatProvider } from './Hooks/UseChat';
import { PlanProvider } from './Hooks/UsePlan';
import { DialogsProvider } from './Hooks/UseDialog';
import { ContextMenuProvider } from './Hooks/UseContextMenu';

const ability = createMongoAbility()

// const firebaseConfig = {
//   apiKey: "AIzaSyC5o5h_mRq8kdQnIdPBgA8T77RlW87FNc0",
//   authDomain: "pl4nner-e3061.firebaseapp.com",
//   projectId: "pl4nner-e3061",
//   storageBucket: "pl4nner-e3061.appspot.com",
//   messagingSenderId: "909301340819",
//   appId: "1:909301340819:web:a04f28f6d92e1db5cdcb66",
//   measurementId: "G-1WN0ZETYWF"
// }

// const fbApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(fbApp)

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <DialogsProvider>
        <ContextMenuProvider>
          <FeedbackProvider>
            <PlanProvider>
              <AuthProvider>
                <ChatProvider>
                  <App />
                  <FeedbackForm />
                </ChatProvider>
              </AuthProvider>
            </PlanProvider>
          </FeedbackProvider>
        </ContextMenuProvider>
      </DialogsProvider>
    </AbilityContext.Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
