
import { request, EMethods } from './ApiService'

import config from '../config'

const getProgressPercentHistory = async (project_id: string) => {

    const url = config.apiHost + '/indicators-ppc/progress-percent-history?project_id=' + project_id

    const response = await request(
        EMethods.get,
        'INDICATORS_GET_PROGRESS_PERCENT_HISTORY',
        url,
    )

    return response.data
}

const getCNCsHistory = async (project_id: string) => {

    const url = config.apiHost + '/indicators-cnc/cncs-history?project_id=' + project_id

    try {

        const { data } = await request(
            EMethods.get,
            'INDICATORS_GET_CNCS_HISTORY',
            url,
        )

        return { data: data.data }
    } catch (error) {

        return { error }
    }
}

const getWeeklyCNCs = async (project_id: string) => {

    const url = config.apiHost + '/indicators-cnc/weekly-cncs?project_id=' + project_id

    try {

        const { data } = await request(
            EMethods.get,
            'INDICATORS_GET_WEEKLY_CNCS',
            url,
        )

        return { data: data.data }
    } catch (error) {

        return { error }
    }
}

const getGroupedCNCTypesByWeek = async (project_id: string) => {

    const url = config.apiHost + '/indicators-cnc/grouped-cnc-types-by-week?project_id=' + project_id

    try {

        const { data } = await request(
            EMethods.get,
            'INDICATORS_GET_GROUPED_CNCS_BY_WEEK',
            url,
        )

        return { data: data.data }
    } catch (error) {

        return { error }
    }
}

export default {
    getProgressPercentHistory,
    getCNCsHistory,
    getWeeklyCNCs,
    getGroupedCNCTypesByWeek,
}
