
import React from 'react'
import History from '../../Helpers/History'

import { connect } from 'react-redux'
import SessionActions from '../../Redux/Actions/SessionActions'

const mapStateToProps = (state: any) => {

    return {
        confirmStatus: state.SessionReducer.confirmStatus,
    }
}

const mapDispatchToProps = (dispatch: any) => {

    return{
        doConfirm(token: string) {

            dispatch(SessionActions.confirmAction({ token }))
        }
    }
}

const ConfirmPage = (props: any) => {

    const token = new URLSearchParams(props.location.search).get('token')

    if (props.confirmStatus === 'none' && token) {

        props.doConfirm(token)
    }

    if (props.confirmStatus === 'confirm_ended') {

        History.push('/login?confirmed')
        return null
    }

    return (
        <div>
            {props.confirmStatus === 'confirm_ended' ? null : props.confirmStatus}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPage)
