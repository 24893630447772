
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/esm/Form'

import './ImageCropper.scss'

const getEmptyFile = () => {

    return {
        originalName: '',
        extension: '',
        size: 0,
        src: '',
    }
}

const ImageCropper = (props: any) => {

    const fileRef: any = useRef()
    const imgRef: any = useRef()
    const canvasRef: any = useRef()

    const [ file, setFile ] = useState(getEmptyFile())
    const [ saving, setSaving ] = useState(false)

    const triggerInputClick = () => {

        fileRef.current.click()
    }

    const onFileSelected = (e: any) => {

        const files = e.target.files

        if (files.length) {

            const _file = files[0]

            const fileSelected = {
                originalName: _file.name,
                extension: 'png',
                size: _file.size,
                type: 'avatar',
                mimeType: 'image/png',
                src: URL.createObjectURL(_file),
            }

            setFile(fileSelected)
        }
    }

    const drawCanvas = (img: any) => {

        let w = img.width
        let h = img.height

        let x = 0
        const y = 0

        if (w > h) {

            x = ((w - h) / 2)
            w = h
        } else if (h > w) {

            h = w
        }

        const context = canvasRef.current.getContext('2d')
        context.drawImage(img, x, y, w, h, 0, 0, 512, 512)
    }

    const loadImage = () => {

        const img = new Image()
        img.addEventListener('load', () => {

            imgRef.current.src = file.src

            drawCanvas(img)
        })

        img.src = file.src
    }

    const handleCancel = () => {

        setFile(getEmptyFile())
        props.onHide()
    }

    const handleAccept = () => {

        const data = {
            ...file,
            canvas: canvasRef.current.toDataURL(),
        }

        uploadImage(data)
    }

    const uploadImage = async (data: any) => {

        setSaving(true)

        try {

            const res = await axios.post(props.apiUrl, data, { headers: props.headers })

            if (!res.data.success) {

                throw new Error(res.data.error)
            }

            setSaving(false)

            setFile(getEmptyFile())
            props.onUploadFile(res.data.file)
        } catch (error) {

            setSaving(false)

        }
    }

    useEffect(() => {

        loadImage()
    }, [file.src])

    return (
        <Modal
            show={props.show}
            onHide={() => handleCancel()}
            // size="lg"
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Subir foto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div data-testid="image-cropper" className="image-cropper">
                    <Form.Control
                        ref={fileRef}
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e: any) => onFileSelected(e)}
                    />
                    {!file.src ? (
                        <div className="cropper-default-text"
                            onClick={() => triggerInputClick()}
                        >
                            Haz clic aquí para elegir una imagen
                        </div>
                    ) : (
                        <div className="target-container">
                            <img ref={imgRef} className="bg-image" />
                            <div className="bg-overlay"></div>
                            <canvas id="cropper-target"
                                ref={canvasRef}
                                className="cropper-target"
                                height="512"
                                width="512"
                            ></canvas>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!file.src ? (
                    <button className="btn btn-secondary">
                        Cerrar
                    </button>
                ) : (
                    <>
                        <button className="btn btn-secondary"
                            onClick={() => handleCancel()}
                        >
                            Cancelar
                        </button>
                        <button className="btn btn-primary"
                            onClick={() => handleAccept()}
                        >
                            Aceptar
                        </button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default ImageCropper
