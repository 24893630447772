
import React from 'react'

const DropdownToggle = (props: any) => {

    const showDefaultToggle = () => {

        return (
            <div onClick={(e) => props.onClick(e)}>
                Default Toggle
            </div>
        )
    }

    const click = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (props.disabled) {

            return
        }

        props.onClick()
    }

    return (
        <div
            className={`${props.className} ${props.disabled ? 'disabled' : ''}`}
            onClick={(e) => click(e)}
        >
            {!props.children ? showDefaultToggle() : props.children}
        </div>
    )
}

export default DropdownToggle
