
import React from 'react'
import config from '../../config'

import './UserAvatar.scss'

type Props = {
    user: any
    size?: 'xs' | 'sm' | 'lg'
}

const UserAvatar = (props: Props) => {

    if (!props.user || !props.user.name || !props.user.surname) {

        return null
    }

    return (
        <>
            {props.user.avatar === '' ? (
                <span data-testid="user-avatar-text" className={`user-avatar as-text ${props.size}`} title={`${props.user.name} ${props.user.surname}`.toUpperCase()}>
                    {props.user.name.charAt(0) + props.user.surname.charAt(0)}
                </span>
            ) : (
                <div data-testid="user-avatar-image" className={`user-avatar as-image ${props.size}`} title={`${props.user.name} ${props.user.surname}`.toUpperCase()}>
                    <img src={`${config.apiHost}/${props.user.avatar}`} style={{ borderRadius: '50%' }} />
                </div>
            )}
        </>
    )
}

export default UserAvatar
