
const config = {
    env: process.env.REACT_APP_ENV || 'production',
    apiHost: process.env.REACT_APP_API_HOST,
    accountsHost: process.env.REACT_APP_ACCOUNTS_HOST || 'http://localhost:4000',
    chatHost: process.env.REACT_APP_API_HOST || '',
    autoLoadNotifications: process.env.REACT_APP_AUTOLOAD_NOTIFICATIONS === 'true',
    corsDomain: process.env.REACT_APP_CORS_DOMAIN || 'localhost',
    cookiesDuration: Number(process.env.REACT_APP_COOKIES_DURATION_DAYS) || 1,
    memberships:{
        enabled: process.env.REACT_APP_MEMBERSHIPS_ENABLED === 'true',
    },
    chat: {
        enabled: process.env.REACT_APP_CHAT_ENABLED === 'true',
        pl4nnerBot: {
            enabled: process.env.REACT_APP_CHATBOT_ENABLED === 'true',
        }
    }
}

export default config
