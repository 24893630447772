
import React, { ReactNode, createContext, useContext, useState } from "react";
import Prompt from "../Components/Prompt/Prompt";

interface IPromptProps {
  title: string;
  acceptText?: any;
  acceptBtnVariant?: string;
  cancelText?: any;
  cancelBtnVariant?: string;
}

interface PromptResult {
  isAccepted: boolean;
  message: string;
}

interface DialogsContextValue {
  prompt: (props: IPromptProps) => Promise<PromptResult>;
}

const DialogsContext = createContext<DialogsContextValue | undefined>(undefined)

interface DialogsProviderProps {
  children: ReactNode;
}

export const DialogsProvider = ({ children }: DialogsProviderProps) => {

  const [ promptData, setPromptData ] = useState({
    show: false,
    title: '',
    acceptText: '',
    acceptBtnVariant: 'primary',
    cancelText: '',
    cancelBtnVariant: 'secondary',
    onAccept: (message: string) => console.log('Prompt accepted:', message),
    onCancel: () => console.log('Prompt canceled')
  })

  const handlePromptCancel = () => {

    setPromptData({
      ...promptData,
      title: '',
      show: false
    })

    promptData.onCancel()
  }

  const handlePromptAccept = (message: string) => {

    setPromptData({
      ...promptData,
      title: '',
      show: false
    })

    promptData.onAccept(message)
  }

  const prompt = ({ title, acceptText, acceptBtnVariant, cancelText, cancelBtnVariant }: IPromptProps): Promise<PromptResult> => {

    acceptText = acceptText || 'Aceptar'
    cancelText = cancelText || 'Cancelar'

    return new Promise((resolve) => {

      setPromptData({
        ...promptData,
        show: true,
        title,
        acceptText,
        acceptBtnVariant: acceptBtnVariant || promptData.acceptBtnVariant,
        cancelText,
        cancelBtnVariant: cancelBtnVariant || promptData.cancelBtnVariant,
        onAccept: (message: string) => {
          resolve({
            isAccepted: true,
            message
          })
        },
        onCancel: () => {
          resolve({
            isAccepted: false,
            message: ''
          })
        }
      })
    })
  }

  const value: DialogsContextValue = {
    prompt,
  }

  return (
    <DialogsContext.Provider value={value}>
      {children}

      <Prompt
        show={promptData.show}
        title={promptData.title}
        acceptText={promptData.acceptText}
        acceptBtnVariant={promptData.acceptBtnVariant}
        cancelText={promptData.cancelText}
        cancelBtnVariant={promptData.cancelBtnVariant}
        onHide={() => handlePromptCancel()}
        onAccept={(message: string) => handlePromptAccept(message)}
      />
    </DialogsContext.Provider>
  )
}

export const useDialog = (): DialogsContextValue => {

  const context = useContext(DialogsContext)

  if (!context) {
    throw new Error('useDialog must be used within a DialogsProvider')
  }

  return context
}

