
const initialState = {
  settings: {
    termsVersion: '',
    policiesVersion: '',
  },
  requestAcceptTermsAndPolicies: false
}

type Action = {
  type: string,
  payload: any
}

const ApplicationReducer = (state = initialState, { type, payload }: Action) => {

  const st = { ...state }

  if (type === 'APPLICATION_GET_SETTINGS') {

    st.settings.termsVersion = payload.settings.termsVersion
    st.settings.policiesVersion = payload.settings.policiesVersion

    st.requestAcceptTermsAndPolicies = payload.requestAcceptTermsAndPolicies
  }

  return st
}

export default ApplicationReducer

