
export const getTasksOptionsFromTaskList = (taskList: any, task_id = '', level = 0) => {

  const options: any = []

  const getIndentationString = () => {

    let indentation = ''

    for (let i = 0; i < level; i++) {

      indentation += '-- '
    }

    return indentation
  }

  taskList.map((item: any) => {

      if (item.type === 'task') {

          options.push({
              value: item.task._id,
              label: `${getIndentationString()} ${task_id === item.task._id ? '➤ ' : ''}${item.task.name}`,
          })
      } else {

          options.push({
              value: item.group._id,
              label: `${getIndentationString()} ${item.group.name}`,
              isGroup: true,
          })

        const subOptions = getTasksOptionsFromTaskList(item.group.items, task_id, level + 1)

        options.push(...subOptions)
      }
  })

  return options
}

export const getAllRestrictions = (tasks: any[]) => {

    const restrictions: any = []

    tasks.forEach((task: any) => {

        task.restrictions?.forEach((restriction: any) => {

            const found = restrictions.find((rest: any) => restriction._id === rest._id)

            if (!found) {

                restrictions.push({
                    ...restriction,
                    task: task.name
                })
            }
        })
    })

    return restrictions
}

export const getUnresolvedRestrictions = (list: any[]) => {

    const restrictions: any = []

    if (!list) return restrictions

    list.map((item: any) => {

        if (item.type === 'task') {

            item.task.restrictions.map((restriction: any) => {

                if (!restrictions.includes(restriction)) {

                    if (!restriction.checked) restrictions.push({ ...restriction, task: item.task.name })
                }
            })
        } else {

            item.group.items.map((item: any) => {

                if (item.type === 'task') {

                    item.task.restrictions.map((restriction: any) => {

                        if (!restrictions.includes(restriction)) {

                            if (!restriction.checked) restrictions.push({ ...restriction, task: item.task.name })
                        }
                    })
                } else {

                    item.group.items.map((item: any) => {

                        item.task.restrictions.map((restriction: any) => {

                            if (!restrictions.includes(restriction)) {

                                if (!restriction.checked) restrictions.push({ ...restriction, task: item.task.name })
                            }
                        })
                    })
                }
            })
        }
    })

    return restrictions
}

export const getAllUserTasks = (list: any[], user_id: string) => {

    // if user exists in task.responsibles, add task to userTasks
    const userTasks: any = []

    if (!list) return userTasks

    list.map((item: any) => {

        if (item.type === 'task') {

            if (item.task.responsibles.find((user: any) => user._id === user_id)) {

                userTasks.push(item.task)
            }
        } else {

            item.group.items.map((item: any) => {

                if (item.type === 'task') {

                    if (item.task.responsibles.find((user: any) => user._id === user_id)) {

                        userTasks.push(item.task)
                    }
                } else {

                    item.group.items.map((item: any) => {

                        if (item.task.responsibles.find((user: any) => user._id === user_id)) {

                            userTasks.push(item.task)
                        }
                    })
                }
            })
        }
    })

    return userTasks
}

export const getAllUsersTasks = (list: any[]) => {

    const users: any[] = []

    if (!list) return users

    // if user exists in task.responsibles, add task to users => user.tasks array
    // task.responsibles is a users array
    list.map((item: any) => {

        if (item.type === 'task') {

            item.task.responsibles?.map((user: any) => {

                if (!users.find((u: any) => u.user._id === user._id)) {

                    users.push({ user, tasks: [item.task] })
                } else {

                    users.find((u: any) => u.user._id === user._id).tasks.push(item.task)
                }
            })
        } else {

            item.group.items.map((item: any) => {

                if (item.type === 'task') {

                    item.task.responsibles.map((user: any) => {

                        if (!users.find((u: any) => u.user._id === user._id)) {

                            users.push({ user, tasks: [item.task] })
                        } else {

                            users.find((u: any) => u.user._id === user._id).tasks.push(item.task)
                        }
                    })
                } else {

                    item.group.items.map((item: any) => {

                        if (item.type === 'task') {

                            item.task.responsibles.map((user: any) => {

                                if (!users.find((u: any) => u.user._id === user._id)) {

                                    users.push({ user, tasks: [item.task] })
                                } else {

                                    users.find((u: any) => u.user._id === user._id).tasks.push(item.task)
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    return users
}

export const isTaskInList = (list: any[], task_id: string) => {

    let found = false

    list.forEach((item: any) => {

        if (item.type === 'task') {

            if (item.task === task_id) {

                found = true
            }
        } else {

            const subFound = isTaskInList(item.group.items, task_id)

            if (subFound) {

                found = true
            }
        }
    })

    return found
}
