import React from 'react'

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TaskNotification = (props: Props) => {

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>{props.data.title}</span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.data.type === 'task_created' && (
                            <span>Se ha creado <strong style={{ textTransform: 'uppercase' }}>{props.data.task?.name}</strong> como nueva tarea</span>
                        )}
                        {props.data.type === 'task_updated' && (
                            <span>La tarea <strong style={{ textTransform: 'uppercase' }}>{props.data.task.name}</strong> ha sido actualizada</span>
                        )}
                        {props.data.type === 'task_confirmed' && (
                            <span>La finalización de la tarea <strong style={{ textTransform: 'uppercase' }}>{props.data.task.name}</strong>  ha sido confirmada</span>
                        )}
                        {props.data.type === 'task_rejected' && (
                            <span>La finalización de la tarea <strong style={{ textTransform: 'uppercase' }}>{props.data.task.name}</strong>  ha sido rechazada</span>
                        )}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default TaskNotification