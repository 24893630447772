
import React, { useState } from 'react'

export type Props = {
    position: number,
    frameLeft: number,
    frameWidth: number,
    minTableWidthPercent: number,
    maxTableWidthPercent: number,
    tableWidth: number,

    onScroll: (position: number) => void
    onScrollEnded: () => void
}

const Divisor = (props: Props) => {

    const pagePaddingLeft = 32

    const [ dragging, setDragging ] = useState(false)

    const tableMaxPercent = parseInt(`${props.tableWidth * 100 / props.frameWidth}`, 10)

    const updateDivisorPosition = (mousePosition: number) => {

        const left = mousePosition - props.frameLeft

        let porcentaje = 100 * left / props.frameWidth

        const min = props.minTableWidthPercent
        let max = tableMaxPercent < props.maxTableWidthPercent ? tableMaxPercent : props.maxTableWidthPercent

        if (min > max) {

            max = min
        }

        if (porcentaje < min) {

            porcentaje = min
        } else if (porcentaje > max) {

            porcentaje = max
        }

        props.onScroll(porcentaje)
    }

    const onMouseDown = () => {

        setDragging(true)
    }

    const onMouseMove = (e: any) => {

        if (dragging) {

            updateDivisorPosition(e.pageX - pagePaddingLeft)
        }
    }

    const onTouchMove = (e: any) => {

        if (dragging) {

            updateDivisorPosition(e.targetTouches[0].pageX - pagePaddingLeft)
        }
    }

    const onMouseUp = (e: any) => {

        setDragging(false)

        props.onScrollEnded()
    }

    return (
        <>
            <div className="gantt-divisor-backdrop"
                style={{
                    display: dragging ? 'block' : 'none'
                }}
                onMouseMove={(e: any) => onMouseMove(e)}
                onMouseUp={(e: any) => onMouseUp(e)}
                onTouchMove={onTouchMove}
                onTouchEnd={(e: any) => onMouseUp(e)}
            ></div>
            <div className="gantt-divisor"
                onMouseDown={(e: any) => onMouseDown()}
                onMouseUp={(e: any) => onMouseUp(e)}
                onTouchStart={(e: any) => onMouseDown()}
                onTouchMove={onTouchMove}
                onTouchEnd={(e: any) => onMouseUp(e)}
                onContextMenu={(e: any) => e.preventDefault()}
            >
                <div className="divisor-bar">
                    <div className="divisor-dots">
                        <div className="divisor-dot"></div>
                        <div className="divisor-dot"></div>
                        <div className="divisor-dot"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Divisor
