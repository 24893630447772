
import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Modal } from "react-bootstrap";

interface IPromptProps {
  show: boolean;
  title: string;
  acceptText: string;
  acceptBtnVariant: string;
  cancelText: string;
  cancelBtnVariant: string;
  onHide: () => void;
  onAccept: (input: string) => void;
}

const Prompt = (props: IPromptProps) => {

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [ input, setInput ] = useState('')


  const handleCancel = () => {

    setInput('')
    props.onHide()
  }

  const handleAccept = () => {

    props.onAccept(input)

    setInput('')
    props.onHide()
  }

  useEffect(() => {

    if (!inputRef.current || !props.show) return

    inputRef.current.focus()
  }, [ inputRef.current, props.show ])

  return (
    <Modal
      show={props.show}
      onHide={() => handleCancel()}
      centered
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <textarea
            ref={inputRef}
            className="form-control" rows={3}
            autoFocus={props.show}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn btn-${props.cancelBtnVariant}`} onClick={() => handleCancel()}>
          {props.cancelText}
        </button>
        <button className={`btn btn-${props.acceptBtnVariant}`} onClick={() => handleAccept()}
          disabled={!input}
        >
          {props.acceptText}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Prompt;
