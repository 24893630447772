
import React, { Fragment } from "react";
import SelectFormControl from "../SelectFormControl/SelectFormControl";
import { v4 as uuid } from "uuid";

type Props = {
  value: any,
  task: any,
  tasksOptions: any,
  emptyValue: string,
  onChange: any,
  disabled?: boolean,
}

const RelationsTaskSelector = (props: Props) => {

  return (
    <SelectFormControl
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      <option value="">{props.emptyValue}</option>
      {props.tasksOptions.map((option: any, i: number) => (
        <Fragment key={`${uuid()}-${i}`}>
          {option.isGroup && (
            <optgroup key={option.value} label={option.label}></optgroup>
          )}
          {!option.isGroup && (
            <option key={i} value={option.value}>{option.label}</option>
          )}
        </Fragment>
      ))}
    </SelectFormControl>
  )
}

export default RelationsTaskSelector;
