import { loadTasks, loadWeeklyProgress } from "../../Services/IndicatorsPPCService"

export const loadIndicatorsPPCWeeklyProgressAction = (project_id: string) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'INDICATORS_PPC_LOADING'
    })

    const { error, data } = await loadWeeklyProgress(project_id)

    if (error) {

      dispatch({
        type: 'INDICATORS_PPC_ERROR',
        error
      })
      return false
    }

    const { data: { weeks } } = data

    dispatch({
      type: 'INDICATORS_PPC_SUCCESS',
      payload: weeks
    })

    return true
  }
}

export const loadIndicatorsPPCTasksAction = (project_id: string, week: string) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'INDICATORS_PPC_TASKS_LOADING'
    })
    dispatch({
      type: 'RESOURCES_CNC_NOT_COMPLETED_LOADING'
    })

    const { error, data } = await loadTasks(project_id, week)

    if (error) {

      dispatch({
        type: 'INDICATORS_PPC_TASKS_ERROR',
        error
      })
      dispatch({
        type: 'RESOURCES_CNC_NOT_COMPLETED_ERROR',
        error
      })
      return false
    }

    const { data: { completed, notCompleted } } = data

    dispatch({
      type: 'INDICATORS_PPC_TASKS_SUCCESS',
      payload: {
        completed,
        notCompleted
      }
    })
    dispatch({
      type: 'RESOURCES_CNC_NOT_COMPLETED_SUCCESS',
      payload: {
        notCompleted,
        week,
      }
    })

    return true
  }
}

export const cleanIndicatorsPPCAction = () => {

  return {
    type: 'INDICATORS_PPC_CLEANUP'
  }
}
