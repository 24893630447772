
import React from 'react'
import SelectFormControl from '../SelectFormControl/SelectFormControl'

import RelationsTaskSelector from './RelationsTaskSelector'

type Props = {
    testId?: string,
    item: any,
    tasksOptions: any,
    task: any,
    onChange?: any,
    onDelete?: any,
    readOnly?: boolean,
}

const RelationItem = (props: Props) => {

  const { item } = props

    const handleChange = (field: string, value: any) => {

        if (field === 'type') {

            item.type = value
        } else if (field === 'from_task') {

            item.from_task = value
        } else if (field === 'to_task') {

            item.to_task = value
        }

        props.onChange(item)
    }

    const handleDelete = () => {

        props.onDelete()
    }

    return (
        <div data-testid={props.testId || 'relation-item'} className="input-group mb-2">
            <SelectFormControl
                value={item.type}
                onChange={(type: string) => handleChange('type', type)}
                disabled={props.readOnly}
            >
                <option value="">Tipo de relación</option>
                <option value="start_to_start">Inicio a inicio</option>
                <option value="end_to_start">Fin a inicio</option>
            </SelectFormControl>
            <RelationsTaskSelector
                value={item.from_task}
                onChange={(task_id: string) => handleChange('from_task', task_id)}
                disabled={props.readOnly}
                task={props.task}
                tasksOptions={props.tasksOptions}
                emptyValue="Seleccione tarea origen"
            />
            <RelationsTaskSelector
                value={item.to_task}
                onChange={(task_id: string) => handleChange('to_task', task_id)}
                disabled={props.readOnly}
                task={props.task}
                tasksOptions={props.tasksOptions}
                emptyValue="Seleccione tarea destino"
            />
            <div className="input-group-append">
                <button data-testid={`${props.testId}-delete-button`} className="btn btn-normal" type="button"
                    onClick={() => handleDelete()}
                    disabled={props.readOnly}
                >
                    <span className="icon-eliminar"></span>
                </button>
            </div>
        </div>
    )
}

export default RelationItem
