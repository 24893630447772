
const initialState = {
    loadResultStatus: 'none',
    result: [],
    loadResultError: '',
    loadArchiveStatus: 'none',
    archive: [],
    createStatus: 'none',
    createdRow: {},
    updateStatus: 'none',
    deleteStatus: '',
}

const TasksReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    switch(action.type) {
        case 'TASKS_LOAD_NONE': {
            st.loadResultStatus = 'none'
            break
        }
        case 'TASKS_CLEANUP': {
            st.loadResultStatus = 'none'
            st.result = []
            break
        }
        case 'TASKS_LOAD_STARTED': {
            st.loadResultStatus = 'loading'
            break
        }
        case 'TASKS_LOAD_SUCCESS': {
            st.loadResultStatus = 'loaded'
            st.result = action.payload.result
            st.loadResultError = ''
            break
        }
        case 'TASKS_LOAD_ERROR': {
            st.loadResultStatus = 'error'
            st.loadResultError = action.error
            break
        }
        case 'TASKS_GET_ARCHIVE_START':
            st.loadArchiveStatus = 'loading'
            st.archive = []
            break
        case 'TASKS_GET_ARCHIVE_END':
            st.loadArchiveStatus = 'loaded'
            st.archive = action.content
            break
        case 'TASKS_GET_ARCHIVE_ERROR':
            st.loadArchiveStatus = action.error
            st.archive = []
            break
        case 'TASK_CREATE_START':
            st.createStatus = 'creating'
            st.createdRow = {}
            break
        case 'TASK_CREATE_END':
            st.createStatus = 'created'
            st.createdRow = action.content
            break
        case 'TASK_CREATE_ERROR':
            st.createStatus = action.error
            st.createdRow = {}
            break
        case 'TASK_UPDATE_START':
            st.updateStatus = 'updating'
            break
        case 'TASK_UPDATE_END':
            st.updateStatus = 'updated'
            break
        case 'TASK_UPDATE_ERROR':
            st.updateStatus = action.error
            break
        case 'TASK_DELETE_START':
            st.deleteStatus = 'deleting'
            break
        case 'TASK_DELETE_END':
            st.deleteStatus = 'deleted'
            break
        case 'TASK_DELETE_ERROR':
            st.deleteStatus = action.error
            break
    }

    return st
}

export default TasksReducer
