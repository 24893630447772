
import { TChat, TChatMessage, IChatProjectsTree } from "../../Types/Chat";

type TChatState = {
  last: TChat[];
  newChats: number;
  chat: TChat | null;
  messages: TChatMessage[];
  projectsTree: IChatProjectsTree
}

type Action = {
  type: string,
  payload: any
}

const initialState: TChatState = {
  last: [],
  newChats: 0,
  chat: null,
  messages: [],
  projectsTree: []
};

const ChatReducer = (state = initialState, { type, payload }: Action) => {

  const st = { ...state }

  if (type === 'CHAT_SET_LAST') {

    st.last = payload.last
  } else if (type === 'CHAT_SET_MESSAGES') {

    st.messages = payload.messages.reverse()
  } else if (type === 'CHAT_ADD_MESSAGE') {

    st.messages.unshift(payload.message)
  } else if (type === 'CHAT_SET_NEW_CHATS') {

    st.newChats = payload.newChats
  } else if (type === 'CHAT_DECREASE_NEW_CHAT') {

    st.newChats -= 1
  } else if (type === 'CHAT_SET_CHAT') {

    st.chat = payload.chat
  } else if (type === 'SET_PROJECTS_TREE') {

    st.projectsTree = payload.projectsTree
  }

  return st
}

export default ChatReducer
