import { EMethods, get, request } from "./ApiService"
import config from "../config"

export const getLastChatsFromServer = async () => {

  try {
    const { data } = await request(
      EMethods.get,
      "GET_LAST_CHATS",
      `${config.chatHost}/chat/last`
    )

    return { data }
  } catch (error) {

    let message = 'Something went wrong'

    if (error instanceof Error) {

      message = error.message
    }

    return { error: message }
  }
}

export const getProjectsTreeData = async () => {

  try {
    const { data } = await request(
      EMethods.get,
      "GET_PROJECT_CHAT_TREE",
      `${config.chatHost}/chat/projects-tree`
    )

    return { data }
  } catch (error) {

    let message = 'Something went wrong'

    if (error instanceof Error) {

      message = error.message
    }

    return { error: message }
  }
}

export const getChatProjectTasks = async (project_id: string) => {

  const url = `${config.apiHost}/chat/project/${project_id}/tasks`

  return await get(url, 'CHAT_GET_PROJECT_TASKS')
}

export const executeCommand = async (chat_id: string, chat_message_id: string, command: string, params: any) => {

  try {
    const { data } = await request(
      EMethods.post,
      "EXECUTE_COMMAND",
      `${config.chatHost}/chat/command`,
      {
        command,
        chat_id,
        chat_message_id,
        params
      }
    )

    return { data }
  } catch (error) {

    let message = 'Something went wrong'

    if (error instanceof Error) {

      message = error.message
    }

    return { error: message }
  }
}
