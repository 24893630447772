
import { request, EMethods } from './ApiService'

import { ProjectType } from '../Types/Interfaces'

import config from '../config'

const getResult = async () => {

    const url = config.apiHost + '/projects'

    try {

        const response = await request(EMethods.get, 'PROJECTS_LOAD_RESULT', url, null)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const getRow = async (_id: string) => {

    const url = config.apiHost + '/projects/' + _id

    const response = await request(EMethods.get, 'PROJECTS_LOAD_ROW', url, null)

    return response.data

    // try {
    // } catch (error) {

    //     //todo: remove try catch

    //     throw error
    // }
}

const create = async (data: ProjectType) => {

    const url = config.apiHost + '/projects'

    try {

        const response = await request(
            EMethods.post,
            'PROJECTS_CREATE',
            url,
            data
        )

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const update = async (data: any) => {

    const url = config.apiHost + '/projects/' + data._id

    try {

        const response = await request(
            EMethods.put,
            'PROJECT_UPDATE',
            url,
            data
        )

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const addAssistant = async (project_id: any, user_id: any) => {

    const url = config.apiHost + '/projects/' + project_id + '/assistants'

    try {

        const res = await request(EMethods.post, 'PROJECT_ADD_ASSISTANT', url, {
            user_id
        })

        return res.data
    } catch (error) {

        throw error
    }
}

const removeAssistant = async (project_id: any, user_id: any) => {

    const url = config.apiHost + '/projects/' + project_id + '/assistants/' + user_id

    try {

        const res = await request(EMethods.delete, 'PROJECT_REMOVE_ASSISTANT', url)

        return res.data
    } catch (error) {

        throw error
    }
}

const del = async (project_id: any) => {

    const url = config.apiHost + '/projects/' + project_id

    try {

        const res = await request(EMethods.delete, 'PROJECT_DELETE', url)

        return res.data
    } catch (error) {

        throw error
    }
}

const abandon = async (project_id: any) => {

    const url = `${config.apiHost}/projects/${project_id}/abandon`

    try {

        const res = await request(EMethods.get, 'PROJECT_ABANDON', url)

        return res.data
    } catch (error) {

        throw error
    }
}

const delegate = async (project_id: any, asistenteSeleccionadoId: any) => {

    const url = `${config.apiHost}/projects/${project_id}/delegate`

    try {

        const res = await request(EMethods.put, 'PROJECT_DELEGATE', url, {
            user: asistenteSeleccionadoId
        })

        return res.data
    } catch (error) {

        throw error
    }
}


const updateSettings = async (settings_id: string, settings: any) => {

    const url = `${config.apiHost}/user-project-settings/${settings_id}`

    try {

        const res = await request(EMethods.put, 'USER_PROJECT_SETTINGS_UPDATE', url, settings)

        return res.data
    } catch (error) {

        throw error
    }
}

const archiveProject = async (project_id: any) => {
    const url = `${config.apiHost}/projects/${project_id}/archive`;

    try {
        const res = await request(EMethods.get, 'PROJECT_ARCHIVE', url);
        return res.data;
    } catch (error) {
        throw error;
    }
};


// chat/projects-tree

const restoreProject = async (project_id: any) => {

    const url = `${config.apiHost}/projects/${project_id}/restore`;

    try {
        const res = await request(EMethods.get, 'PROJECT_RESTORE', url)

        return res.data
    } catch (error) {

        throw error
    }
}

export default {
    getResult,
    getRow,
    create,
    update,
    addAssistant,
    removeAssistant,
    del,
    abandon,
    updateSettings,
    delegate,
    archiveProject,
    restoreProject,
}
