import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import config from '../config';

import { useAuth } from './UseAuth';

import History from '../Helpers/History';
import { TChat, TChatMessage, IChatProjectsTree } from '../Types/Chat';

import { getLastChatsFromServer, getProjectsTreeData } from '../Services/ChatService';


import {
  addMessageAction,
  setLastChatsAction,
  setMessagesAction,
  placeAtFirstPosition,
  setNewChatsAction,
  setChatAction,
  addChatAction,
  setChatTreeData,
  decreaseNewChatAction,
  deleteChatAction,
} from '../Redux/Actions/ChatActions';

export type ChatData = [ string, string ];

interface ChatContextProps {
  enabled: boolean;
  connected: boolean;
  lastChats: any[];
  newChats: number;
  currentChat: any,
  messages: TChatMessage[];
  projectsTree: IChatProjectsTree
  connect: () => void;
  joinChat: (chat_data: ChatData) => void;
  unsetCurrentChat: () => void;
  sendMessage: (message: string) => void;
  sendAutomaticMessage: (message: string) => void;
  readMessage: (message: any) => void;
  disconnect: () => void;
  // project
  setActiveProject: (project_id?: string) => void;
  // admin
  adminDashboardData: any
  joinAdminHomeChannel: () => void;
}

const URL = config.chatHost;

export const socket = io(URL, {
  autoConnect: false,
});

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider = ({ children }: { children: ReactNode }) => {

  const dispatch = useDispatch()

  const { authToken } = useAuth()

  const isChatEnabled = config.chat.enabled

  const { mine, other } = useSelector((state: any) => state.ProjectsReducer.data)

  const [ connected, setConnected ] = useState(false)

  const [ adminDashboardData, setAdminDashboardData ] = useState<any>({
    users_connected: [],
    projects_working: [],
  })

  const {
    last: lastChats,
    newChats,
    chat: currentChat,
    messages,
    projectsTree,
  } = useSelector((state: any) => state.ChatReducer)

  const connect = () => {

    socket.connect()
  }

  const sendMessage = (message: string) => {

    const chat_id = currentChat._id

    socket.emit('chat-message', { chat_id, message })
  }

  const sendAutomaticMessage = (message: string) => {

    const chat_id = currentChat._id

    socket.emit('chat-automatic-message', { chat_id, message })
  }


  const readMessage = (message: any) => {

    socket.emit('read-message', message._id)

    dispatch(decreaseNewChatAction())
  }

  const disconnect = () => {

    socket.disconnect()
  }

  const joinChat = (chat_data: ChatData) => {

    const [ type, id ] = chat_data

    if (type === 'project') {

      socket.emit('join-project-chat', id)
    } else if (type === 'team') {

      socket.emit('join-team-chat', id)
    } else if (type === 'private') {

      socket.emit('join-private-chat', id)
    } else {

      socket.emit('join-chat', id)
    }
  }

  const setActiveProject = (project_id?: string) => {

    socket.emit('set-active-project', project_id)
  }

  const joinAdminHomeChannel = () => {

      socket.emit('join-admin-home-channel')
  }

  const unsetCurrentChat = () => {

    dispatch(setChatAction(null))
    dispatch(setMessagesAction([]))
  }

  const contextValue: ChatContextProps = {
    enabled: isChatEnabled,
    connected,
    lastChats,
    newChats,
    currentChat,
    messages,
    projectsTree,
    connect,
    joinChat,
    unsetCurrentChat,
    sendMessage,
    sendAutomaticMessage,
    readMessage,
    disconnect,

    setActiveProject,

    adminDashboardData,
    joinAdminHomeChannel,
  }

  useEffect(() => {

    if (authToken && isChatEnabled) {

      socket.auth = { token: authToken }

      socket.connect()
    }

    return () => {

        socket.disconnect()
    }
  }, [ authToken ])

  useEffect(() => {

    const getLastChats = async () => {

      const { data, error } = await getLastChatsFromServer()

      if (error) return

      dispatch(setLastChatsAction(data.lastChats))
    }

    const getProjectsTree = async () => {
      const { data, error } = await getProjectsTreeData()

      if (error) return

      dispatch(setChatTreeData(data.data))
    }

    socket.on('connect', () => {
      setConnected(true)

      getLastChats()
      getProjectsTree()
    })

    socket.on('disconnect', () => {
      setConnected(false)
    })

    socket.on('chat', (chat: any, messages: TChatMessage[]) => {

      History.push(`/chat/${chat._id}`)

      dispatch(setChatAction(chat))
      dispatch(addChatAction(chat))

      dispatch(setMessagesAction(messages))
    })

    socket.on('unread-messages', (messages: number) => {

      dispatch(setNewChatsAction(messages))
    })
    socket.on('users-connected', (users: any) => {

      const projectsArr: any[] = []

      users.forEach((user: any) => {

        user.sockets.forEach((socket: any) => {

          const exists = projectsArr.find((project: any) => project._id === socket.project_id)

          if (socket.project_id) {

            if (!exists) {

              projectsArr.push({
                _id: socket.project_id,
                name: socket.project_name,
                user: socket.project_user,
                users: [ user.user_id ],
              })
            } else {

              exists.users.push(user.user_id)
            }
          }
        })
      })

      setAdminDashboardData({
        ...adminDashboardData,
        users_connected: users,
        projects_working: projectsArr,
      })
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {

    const getProjectsTree = async () => {
      const { data, error } = await getProjectsTreeData()

      if (error) return

      dispatch(setChatTreeData(data.data))
    }

    if (connected) getProjectsTree()
  }, [ connected, mine, other ])

  useEffect(() => {

    const handleChatMessage = (message: any, chat: TChat) => {

      dispatch(placeAtFirstPosition(chat))

      if (message.chat !== currentChat._id) return

      dispatch(addMessageAction(message))
    }

    const handleChatNotification = (chat: TChat) => {

      chat.hasNewMessages = true

      dispatch(placeAtFirstPosition(chat))
      dispatch(setNewChatsAction(newChats + 1))
    }

    const handleChatDeleted = (chat_id: string) => {

      console.log('chat deleted', chat_id)

      dispatch(deleteChatAction(chat_id))

      window.history.replaceState(null, '', '/chat');

      // show a sweet alert with a deleted chat message
      Swal.fire({
        title: 'Chat eliminado',
        text: 'El chat ha sido eliminado',
        icon: 'info',
        confirmButtonText: 'Ok',
      })
    }

    socket.on('chat-message', handleChatMessage)
    socket.on('chat-notification', handleChatNotification)

    socket.on('chat-deleted', handleChatDeleted)

    return () => {
      socket.off('chat-message', handleChatMessage)
      socket.off('chat-notification', handleChatNotification)
      socket.off('chat-deleted', handleChatDeleted)
    }
  }, [ currentChat, newChats ])

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatContextProps => {

  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
}
