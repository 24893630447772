import { AxiosError } from "axios"

import config from "../config"

import { EMethods, get, request } from "./ApiService"

export const getUsers = async (criteria: string, page: number, limit: number, exclude_not_verified = false) => {

  const url = `${config.apiHost}/admin/users?criteria=${encodeURIComponent(criteria)}&page=${page}&limit=${limit}&exclude_not_verified=${exclude_not_verified}`

  try {
    const res = await request(EMethods.get, 'ADMIN_USERS', url)

    const { rows, total } = res.data

    return { data: rows, total }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const saveUser = async (row: any) => {

    const url = `${config.apiHost}/admin/users` + (row._id ? '/' + row._id : '')

    const method = row._id ? EMethods.put : EMethods.post

    try {
      const res = await request(method, 'ADMIN_USERS', url, row)

      return { data: res.data }
    } catch (error) {

      return { error: error instanceof AxiosError ? error.response?.data.error : '' }
    }
}

export const getUsersToExport = async (criteria: string) => {

  const url = `${config.apiHost}/admin/users/export?criteria=${encodeURIComponent(criteria)}`

  try {
    const { data: { data } } = await request(EMethods.get, 'ADMIN_USERS', url)

    return { data }
  } catch (error) {

    return { error }
  }
}

export const sendConfirmationEmail = async (user_id: any): Promise<true | { error: string}> => {

  const url = `${config.apiHost}/admin/users/send-confirmation-email/${user_id}`

  const { error } = await get(url, 'ADMIN_USERS_SEND_CONFIRMATION_EMAIL')

  if (error) return { error }

  return true
}
