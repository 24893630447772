
import moment, { Moment } from 'moment'

export const statusColors: any = {
    atrasada: '#F03C3C',
    pendiente: '#FAEC44',
    liberada: '#3C6FFA',
    completada: '#B7B7B7',
    activa: '#42E36F',
    'activa-atrasada': '#F0913E',
}

export const statusStrings: any = {
    atrasada: 'Atrasada',
    pendiente: 'Pendiente',
    liberada: 'Liberada',
    completada: 'Completada',
    activa: 'Activa',
    'activa-atrasada': 'Activa atrasada',
}

export const noCode = {
    code: 'Sin código',
    color: '#808080'
}

const getBarText = (item: any) => {

    const code = item.task.colorCode ? item.task.colorCode.code : ''

    const lookahead_percent = item.task.lookahead_percent
    const weekly_percent = item.task.weeklyPercent?.toFixed(1)

    if (lookahead_percent && lookahead_percent < 100) {

        return `${code} ${item.task.name} (${item.task.progress}% / ${lookahead_percent}%)`
    } else if (weekly_percent && weekly_percent < 100) {

        return `${code} ${item.task.name} (${item.task.progress}% / ${weekly_percent}%)`
    } else {

        return `${code} ${item.task.name} (${item.task.progress}%)`
    }
}

export const getMacroTaskItem: any = (item: any, useCodeColors: boolean, stretchUpToday: boolean = true) => {

    let color = '#000000'

    if (item.task.type !== 'hito') {

        color = !useCodeColors ? statusColors[item.task.status] : (item.task.colorCode ? item.task.colorCode.color : noCode.color)
    }

    let startsAt = ''
    let startTime = null
    let endsAt = ''

    const execute_percent = '100%'

    if (item.task.type === 'hito') {

        startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')

    } else {

        if (item.task.startsAt && item.task.endsAt) {

            startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')
            startTime = moment(item.task.startsAt).format('HH:mm')

            endsAt = moment(item.task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

            if (item.task.startedAt) {

                startsAt = moment(item.task.startedAt).format('YYYY-MM-DD')
                startTime = moment(item.task.startsAt).format('HH:mm')

                endsAt = moment(item.task.startedAt, 'YYYY-MM-DD')
                            .add(item.task.executeDays - 1, 'days')
                            .format('YYYY-MM-DD')

                if (item.task.endedAt) {

                    endsAt = moment(item.task.endedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
            }
        }

        if (item.task.status === 'activa-atrasada') {

            const today = moment()

            if (today.isAfter(endsAt) && stretchUpToday) {

                endsAt = moment().format('YYYY-MM-DD')
            }
        }
    }

    return {
        _id: item._id,
        pos: item.pos,
        type: 'task',
        task_id: item.task._id,
        task_type: item.task.type,
        title: item.task.name,
        bar_text: getBarText(item),
        bar_short_text: getBarText(item),
        color,
        comments: item.task.comments,
        status: item.task.type === 'hito' ? '' : statusStrings[item.task.status],
        executeDays: item.task.executeDays,
        lookahead_days: item.task.lookahead_days,
        execute_percent,
        startsAt,
        startTime,
        endsAt,
        releasesAt: !item.task.releasesAt ? null : moment(item.task.releasesAt, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        taskStart: item.task.startsAt,
        taskEnd: item.task.endsAt,
        realStart: item.task.startedAt,
        realEnd: item.task.endedAt,
        unit: item.task.unit,
        metered: item.task.metered,
        performance: item.task.performance,
        crew_number: item.task.crewNumber,
        estimated_days: item.task.estimatedDays,
        relations: item.task.relations,
        restrictions: item.task.restrictions,
        responsibles: item.task.responsibles,
        colorCode: item.task.colorCode,
        progress: item.task.progress,
        confirmed: item.task.confirmed,
        _task: JSON.parse(JSON.stringify(item.task)),
        weekStart: item.task.weekStart,
        weekEnd: item.task.weekEnd,
    }
}

export const getMacroBar = (item: any, ganttStart: string, ganttEnd: string, nonWorkingDays: any[], stretchUpToday?: boolean) => {

    let endsAt = getBarEnd(item._task, ganttEnd, nonWorkingDays)

    if (item._task.status === 'activa-atrasada') {
        endsAt = moment().format('YYYY-MM-DD')
    }

    const startsAt = getBarStart(item._task, ganttStart)
    const beforeDays = getBeforeBarDays(ganttStart, item.startsAt, nonWorkingDays)
    const days = getBarDays(startsAt, endsAt, nonWorkingDays)

    const before_days = beforeDays.filter((d: any) => d.type === 'working-day')
    const periodDays = days.filter((d: any) => d.type === 'working-day')

    const beforePercent: number = ((before_days.length * 100) / item.executeDays)
    const periodPercent: number = ((periodDays.length * 100) / item.executeDays)

    const periodProgress = item._task.progress - beforePercent

    let progress = 0

    if (periodProgress >= periodPercent) {

        progress = 100
    } else if (periodProgress <= 0) {

        progress = 0
    } else {

        progress = ((periodProgress * 100) / periodPercent)
    }

    const bar = {
        text: getMacroBarText(item._task.colorCode?.code, item._task.name, progress),
        startsAt,
        endsAt,
        periodPercent,
        periodDays,
        progress,
        days,
    }

    return bar
}

export const getMacroBarText = (code: string = '', name: string, progress: number) => {

    return `${code} ${name} (${progress.toFixed(0)}%)`
}

export const getLookaheadTaskItem: any = (item: any, useCodeColors: boolean, stretchUpToday: boolean = true) => {

    let color = '#000000'

    if (item.task.type !== 'hito') {

        color = !useCodeColors ? statusColors[item.task.status] : (item.task.colorCode ? item.task.colorCode.color : noCode.color)
    }

    let startsAt = ''
    let startTime = null
    let endsAt = ''

    let execute_percent = '100%'

    if (item.task.type === 'hito') {

        startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')

    } else {

        if (item.task.startsAt && item.task.endsAt) {

            startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')
            startTime = moment(item.task.startsAt).format('HH:mm')

            endsAt = moment(item.task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

            if (item.task.startedAt) {

                startsAt = moment(item.task.startedAt).format('YYYY-MM-DD')
                startTime = moment(item.task.startsAt).format('HH:mm')

                endsAt = moment(item.task.startedAt, 'YYYY-MM-DD')
                            .add(item.task.executeDays - 1, 'days')
                            .format('YYYY-MM-DD')

                if (item.task.endedAt) {

                    endsAt = moment(item.task.endedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
            }
        }

        if (item.task.status === 'activa-atrasada') {

            const today = moment()

            if (today.isAfter(endsAt) && stretchUpToday) {

                endsAt = moment().format('YYYY-MM-DD')
            }
        }
    }

    if (item.task.lookahead_percent) {

        execute_percent = `${item.task.lookahead_percent}%`
    }

    return {
        _id: item._id,
        pos: item.pos,
        type: 'task',
        task_id: item.task._id,
        task_type: item.task.type,
        title: item.task.name,
        bar_text: getBarText(item),
        bar_short_text: getBarText(item),
        color,
        comments: item.task.comments,
        status: item.task.type === 'hito' ? '' : statusStrings[item.task.status],
        executeDays: item.task.executeDays,
        lookahead_days: item.task.lookahead_days,
        execute_percent,
        startsAt,
        startTime,
        endsAt,
        releasesAt: !item.task.releasesAt ? null : moment(item.task.releasesAt, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        taskStart: item.task.startsAt,
        taskEnd: item.task.endsAt,
        realStart: item.task.startedAt,
        realEnd: item.task.endedAt,
        unit: item.task.unit,
        metered: item.task.metered,
        performance: item.task.performance,
        crew_number: item.task.crewNumber,
        estimated_days: item.task.estimatedDays,
        relations: item.task.relations,
        restrictions: item.task.restrictions,
        responsibles: item.task.responsibles,
        colorCode: item.task.colorCode,
        progress: item.task.progress,
        confirmed: item.task.confirmed,
        weekStart: item.task.weekStart,
        weekEnd: item.task.weekEnd,
        _task: JSON.parse(JSON.stringify(item.task)),
    }
}

export const getLookaheadBar = (item: any, ganttStart: string, ganttEnd: string, nonWorkingDays: any[]) => {

    const startsAt = getBarStart(item._task, ganttStart)
    const endsAt = getBarEnd(item._task, ganttEnd, nonWorkingDays)
    const beforeDays = getBeforeBarDays(ganttStart, item.startsAt, nonWorkingDays)
    const days = getBarDays(startsAt, endsAt, nonWorkingDays)

    const before_days = beforeDays.filter((d: any) => d.type === 'working-day')
    const periodDays = days.filter((d: any) => d.type === 'working-day')

    const beforePercent: number = ((before_days.length * 100) / item.executeDays)
    const periodPercent: number = ((periodDays.length * 100) / item.executeDays)

    const periodProgress = item._task.progress - beforePercent

    let progress = 0

    if (periodProgress >= periodPercent) {

        progress = 100
    } else if (periodProgress <= 0) {

        progress = 0
    } else {

        progress = ((periodProgress * 100) / periodPercent)
    }

    const bar = {
        text: getLookaheadBarText(item._task.colorCode?.code, item._task.name, periodPercent, progress),
        startsAt,
        endsAt,
        periodPercent,
        periodDays,
        progress,
        days,
    }

    return bar
}

export const getLookaheadBarText = (code: string = '', name: string, percent: number, progress: number) => {

    if (percent == 100) {

        return `${code} ${name} (${progress.toFixed(0)}%)`
    } else {

        return `${code} ${name} (${progress.toFixed(0)}% / ${percent.toFixed(0)}%)`
    }
}

export const getWeeklyBar = (item: any, weekStart: string, weekEnd: string, nonWorkingDays: any[]) => {

    const startsAt = getBarStart(item._task, weekStart)
    const endsAt = getBarEnd(item._task, weekEnd, nonWorkingDays)
    const beforeDays = getBeforeBarDays(weekStart, item.startsAt, nonWorkingDays)
    const days = getBarDays(startsAt, endsAt, nonWorkingDays)

    const before_days = beforeDays.filter((d: any) => d.type === 'working-day')
    const periodDays = days.filter((d: any) => d.type === 'working-day')

    const beforePercent: number = ((before_days.length * 100) / item.executeDays)
    const periodPercent: number = ((periodDays.length * 100) / item.executeDays)

    const periodProgress = item._task.progress - beforePercent

    let progress = 0

    if (periodProgress >= periodPercent) {

        progress = 100
    } else if (periodProgress <= 0) {

        progress = 0
    } else {

        progress = ((periodProgress * 100) / periodPercent)
    }

    const bar = {
        text: getWeeklyBarText(item._task.colorCode?.code, item._task.name, periodPercent, progress),
        startsAt,
        endsAt,
        periodPercent,
        periodDays,
        progress,
        days,
    }

    return bar
}

export const getWeeklyBarText = (code: string = '', name: string, percent: number, progress: number) => {

    if (percent == 100) {

        return `${code} ${name} (${progress.toFixed(0)}%)`
    } else {

        return `${code} ${name} (${progress.toFixed(0)}% / ${percent.toFixed(0)}%)`
    }
}

export const getBarStart = (task: any, start: string) => {

    let taskStart: Moment = moment.utc(task.startsAt)
    const ganttStart: Moment = moment(start)

    if (task.startedAt) {

        taskStart = moment.utc(task.startedAt)
    }

    const barStart = taskStart.isBefore(ganttStart, 'd') ? ganttStart : taskStart

    return barStart.format("YYYY-MM-DD")
}

export const getBarEnd = function (task: any, end: string, nonWorkingDays: any[]) {

    let day: Moment = moment.utc(task.endsAt)
    const ganttEnd: Moment = moment(end)

    if (task.startedAt) {

        let days2Add = task.executeDays - 1

        day = moment.utc(task.startedAt)

        while (days2Add > 0) {

            day.add(1, 'd')

            const exists = nonWorkingDays.find((d: any) => moment.utc(d).isSame(day, 'day'))

            if (!exists) {

                days2Add--
            }
        }
    }

    const barEnd = day.isAfter(ganttEnd, 'd') ? ganttEnd : day

    return barEnd.format("YYYY-MM-DD")
}

export const getBarDays = (start: string, end: string, nonWorkingDays: any[]) => {

    const day = moment(start)

    const days: any[] = []

    while (day.isSameOrBefore(end)) {

        const exists = nonWorkingDays.find((d: any) => moment.utc(d).isSame(day, 'day'))

        days.push({
            date: day.format("YYYY-MM-DD"),
            type: exists ? 'non-working-day' : 'working-day'
        })
        day.add(1, 'days')
    }

    return days
}

export const getBeforeBarDays = (ganttStart: string, itemStart: string, nonWorkingDays: any[]) => {

    const day = moment(itemStart)

    const days: any[] = []

    while (day.isBefore(ganttStart, 'd')) {

        const exists = nonWorkingDays.find((d: any) => moment.utc(d).isSame(day, 'day'))

        days.push({
            date: day.format("YYYY-MM-DD"),
            type: exists ? 'non-working-day' : 'working-day'
        })
        day.add(1, 'days')
    }

    return days
}

export const getWeeklyTaskItem: any = (item: any, useCodeColors: boolean, stretchUpToday: boolean = true) => {

    let color = '#000000'

    if (item.task.type !== 'hito') {

        color = !useCodeColors ? statusColors[item.task.status] : (item.task.colorCode ? item.task.colorCode.color : noCode.color)
    }

    let startsAt = ''
    let startTime = null
    let endsAt = ''

    let execute_percent = '100%'

    if (item.task.type === 'hito') {

        startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')

    } else {

        if (item.task.startsAt && item.task.endsAt) {

            startsAt = moment(item.task.startsAt).format('YYYY-MM-DD')
            startTime = moment(item.task.startsAt).format('HH:mm')

            endsAt = moment(item.task.endsAt, 'YYYY-MM-DD').format('YYYY-MM-DD')

            if (item.task.startedAt) {

                startsAt = moment(item.task.startedAt).format('YYYY-MM-DD')
                startTime = moment(item.task.startsAt).format('HH:mm')

                endsAt = moment(item.task.startedAt, 'YYYY-MM-DD')
                            .add(item.task.executeDays - 1, 'days')
                            .format('YYYY-MM-DD')

                if (item.task.endedAt) {

                    endsAt = moment(item.task.endedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
            }
        }

        if (item.task.status === 'activa-atrasada') {

            const today = moment()

            if (today.isAfter(endsAt) && stretchUpToday) {

                endsAt = moment().format('YYYY-MM-DD')
            }
        }
    }

    if (item.task.weekly_percent) {

        execute_percent = `${item.task.weekly_percent}%`
    }

    return {
        _id: item._id,
        pos: item.pos,
        type: 'task',
        task_id: item.task._id,
        task_type: item.task.type,
        title: item.task.name,
        bar_text: getBarText(item),
        bar_short_text: getBarText(item),
        color,
        comments: item.task.comments,
        status: item.task.type === 'hito' ? '' : statusStrings[item.task.status],
        executeDays: item.task.executeDays,
        lookahead_days: item.task.lookahead_days,
        execute_percent,
        startsAt,
        startTime,
        endsAt,
        releasesAt: !item.task.releasesAt ? null : moment(item.task.releasesAt, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        taskStart: item.task.startsAt,
        taskEnd: item.task.endsAt,
        realStart: item.task.startedAt,
        realEnd: item.task.endedAt,
        unit: item.task.unit,
        metered: item.task.metered,
        performance: item.task.performance,
        crew_number: item.task.crewNumber,
        estimated_days: item.task.estimatedDays,
        relations: item.task.relations,
        restrictions: item.task.restrictions,
        responsibles: item.task.responsibles,
        colorCode: item.task.colorCode,
        progress: item.task.progress,
        confirmed: item.task.confirmed,
        weekStart: item.task.weekStart,
        weekEnd: item.task.weekEnd,
        _task: JSON.parse(JSON.stringify(item.task)),
    }
}

export const getGroupItem: any = (group: any, parent: any, useCodeColors: boolean) => {

    let startsAt: any = null

    return {
        _id: group._id,
        pos: group.pos,
        type: 'group',
        parent_id: !parent ? null : parent.group._id,
        group_id: group.group._id,
        title: group.group.name,
        collapseStatus: '',
        items: group.group.items.map((item: any) => {

            if (item.type === 'task') {

                const taskStart = moment(item.task.startedAt ? item.task.startedAt : item.task.startsAt)

                if (!startsAt || taskStart.isBefore(startsAt)) {

                    startsAt = taskStart
                }

                item.color = !useCodeColors ? statusColors[item.task.status] : (item.task.colorCode ? item.task.colorCode.color : noCode.color)

                // item.task.startsAt = !item.task.startsAt ? null : moment(item.task.startsAt, moment.ISO_8601).format('YYYY-MM-DD')
                // item.task.endsAt = !item.task.endsAt ? null : moment(item.task.endsAt, moment.ISO_8601).format('YYYY-MM-DD')

                item.bar_short_text = item.task.colorCode ? item.task.colorCode.code : ''
            }

            return item
        }),
    }
}

export const getMacroGroupItem: any = (group: any, parent: any, useCodeColors: boolean) => {

    let startsAt: any = null

    return {
        _id: group._id,
        pos: group.pos,
        type: 'group',
        parent_id: !parent ? null : parent.group._id,
        group_id: group.group._id,
        title: group.group.name,
        collapseStatus: '',
        items: group.group.items.map((item: any) => {

            if (item.type === 'task') {

                const taskStart = moment(item.task.startedAt ? item.task.startedAt : item.task.startsAt)

                if (!startsAt || taskStart.isBefore(startsAt)) {

                    startsAt = taskStart
                }

                item.color = !useCodeColors ? statusColors[item.task.status] : (item.task.colorCode ? item.task.colorCode.color : noCode.color)

                item.bar_short_text = item.task.colorCode ? item.task.colorCode.code : ''
            }

            return item
        }),
    }
}

export default {
    getMacroTaskItem,
    getMacroBar,
    getLookaheadTaskItem,
    getLookaheadBar,
    getLookaheadBarText,
    getWeeklyTaskItem,
    getWeeklyBar,
    getWeeklyBarText,
    getBarStart,
    getBarEnd,
    getBarDays,
    getBeforeBarDays,
    getGroupItem,
    getMacroGroupItem,
}
