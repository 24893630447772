
import React, { useLayoutEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import InterfaceActions from '../../Redux/Actions/InterfaceActions'

const MainLayoutContentHeader = ({ children }: any) => {

    const ref = useRef(null)

    const dispatch = useDispatch()

    useLayoutEffect(() => {

        const saveHeight = () => {

            if (ref && ref.current) {

                const current: any = ref.current

                dispatch(InterfaceActions.setDimension('contentHeaderHeight', current.offsetHeight))
            }
        }

        saveHeight()

        window.addEventListener('resize', saveHeight)

        return () => window.removeEventListener('resize', saveHeight)
    }, [])

    return (
        <div ref={ref} className="ul-widget__head">
            {children}
        </div>
    )
}

export default MainLayoutContentHeader
