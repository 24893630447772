
const initialState = {
    registerStatus: 'none',
    confirmStatus: 'none',
    requestRecoveryStatus: 'none',
    resetPasswordStatus: 'none',
    updateStatus: 'none',
    changePasswordStatus: 'none',
}

const SessionReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === 'SESSION_REGISTER_START') {

        st.registerStatus = 'register_started'
    } else if (action.type === 'SESSION_REGISTER_END') {

        st.registerStatus = 'register_ended'
    } else if (action.type === 'SESSION_REGISTER_ERROR') {

        st.registerStatus = action.content.error
    } else if (action.type === 'SESSION_REGISTER_RESET') {

        st.registerStatus = 'none'
    } else if (action.type === 'SESSION_CONFIRM_START') {

        st.confirmStatus = 'confirm_started'
    } else if (action.type === 'SESSION_CONFIRM_END') {

        st.confirmStatus = 'confirm_ended'
    } else if (action.type === 'SESSION_CONFIRM_ERROR') {

        st.confirmStatus = action.content.error
    } else if (action.type === 'SESSION_CONFIRM_RESET') {

        st.confirmStatus = 'none'
    } else if (action.type === 'SESSION_REQUEST_RECOVERY_START') {

        st.requestRecoveryStatus = 'requesting'
    } else if (action.type === 'SESSION_REQUEST_RECOVERY_END') {

        st.requestRecoveryStatus = 'requested'
    } else if (action.type === 'SESSION_REQUEST_RECOVERY_ERROR') {

        st.requestRecoveryStatus = action.content.error
    } else if (action.type === 'SESSION_RESET_PASSWORD_START') {

        st.resetPasswordStatus = 'resetting'
    } else if (action.type === 'SESSION_RESET_PASSWORD_END') {

        st.resetPasswordStatus = 'resetted'
    } else if (action.type === 'SESSION_RESET_PASSWORD_ERROR') {

        st.resetPasswordStatus = action.content.error
    } else if (action.type === 'SESSION_CHANGE_PASSWORD_START') {

        st.changePasswordStatus = 'changing'
    } else if (action.type === 'SESSION_CHANGE_PASSWORD_END') {

        st.changePasswordStatus = 'changed'
    } else if (action.type === 'SESSION_CHANGE_PASSWORD_ERROR') {

        st.changePasswordStatus = 'error'
    }

    return st
}

export default SessionReducer
