import React from 'react';

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const InviteNotification = (props: Props) => {

    if (!props.data.team) {

        return null
    }

    return (
        <div
            className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>Únete al equipo</span>
                    {props.data.status === 'read' ? null : (
                        <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                    )}
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        <strong>{props.data.userFrom.name} {props.data.userFrom.surname}</strong> te ha invitado
                        al equipo <strong>{props.data.team.name}</strong> del
                        proyecto <strong>{props.data.project.shortName}</strong>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default InviteNotification