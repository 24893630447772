
import React, { Children } from 'react'
import InputFormControl from '../InputFormControl/InputFormControl'

type Props = {
    id?: string,
    testId?: string,
    value: string,
    onChange: any,
    disabled?: any,

    children?: any,
}

const SelectFormControl = (props: Props) => {

    if (props.disabled) {

        let selected = ''

        React.Children.toArray(props.children).map((optionItem: any) => {

            const optionValue = optionItem.props.value

            const optionChildren = optionItem.props.children

            if (optionValue === props.value) {

                selected = optionChildren
            } else if (!optionValue) {

                if (typeof optionValue === 'undefined') {

                    Children.toArray(optionChildren).map((childChild: any) => {

                        const childChildValue = childChild.props?.value // todo fix in project info page

                        if (childChildValue === props.value) {

                            //TODO: fix this
                            // selected = childChild.props.children?.join('')
                            selected = childChild.props.children
                        }
                    })
                }
            }
        })

        return (
            <InputFormControl
                data-testid={`disabled-${props.testId}` || 'disabled-select-form-control'}
                value={selected}
                readOnly
            />
        )
    } else {

        const {
            onChange,
            children,
            ...rest
        } = props

        return (
            <select className="form-control"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
                {...rest}
            >
                {props.children}
            </select>
        )
    }
}

export default SelectFormControl
