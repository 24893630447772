
import React, { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";
import { Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteMembershipsFeature, getMembershipsFeatures, saveMembershipsFeature } from "../../../Services/AdminMembershipsService";
import Swal from "sweetalert2";

const AdminMembershipsFeaturesPage = () => {

  const [ features, setFeatures ] = useState<any[]>([])
  const [ showEditor, setShowEditor ] = useState(false)

  const [ row, setRow ] = useState<any>({})

  const handleSave = async () => {
    const { data, error } = await saveMembershipsFeature(row)

    if (error) {
      console.log(error)
      return
    }

    const newFeature = features.find((feature: any) => feature._id === data._id)

    if (!newFeature) {
      setFeatures([ ...features, data ])
    } else {

      const newFeatures = features.map((feature: any) => {
        if (feature._id === data._id) {
          return data
        }

        return feature
      })

      setFeatures(newFeatures)
    }

    setRow({})

    setShowEditor(false)
  }

  const handleDelete = async (id: string) => {

    const { value } = await Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    })

    if (!value) return

    const { data, error } = await deleteMembershipsFeature(id)

    if (error) {
      console.log(error)
      return
    }

    const newFeatures = features.filter((feature: any) => feature._id !== data._id)

    setFeatures(newFeatures)
  }

  useEffect(() => {
    const getFeatures = async () => {

      const { data }  = await getMembershipsFeatures()

      setFeatures(data)
    }

    getFeatures()
  }, [])

  return (
    <>
      <AdminLayout title="Membresías" menu="memberships" submenu="features">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between pb-2">
                  <Card.Title className="">Características</Card.Title>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary"
                      onClick={() => setShowEditor(true)}
                    >
                      <i className="fa fa-fw fa-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombre / Descripción</th>
                        <th scope="col" style={{ width: 200 }}>Grupo</th>
                        <th scope="col" style={{ width: 200 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {features.map((feature: any, i: number) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <strong>{feature.name}</strong>
                            <br />
                            {feature.description}
                            <br />
                            <small>Code: {feature.code}</small>
                          </td>
                          <td>{feature.group}</td>
                          <td>
                            <button className="btn btn-sm btn-primary"
                              onClick={() => {
                                setRow(feature)
                                setShowEditor(true)
                              }}
                            >
                              <i className="fas fa-fw fa-edit"></i> Editar
                            </button>
                            <button className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(feature._id)}
                            >
                            <i className="fas fa-fw fa-trash"></i> Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>

      <Modal
        show={showEditor}
        onHide={() => setShowEditor(false)}
        dialogClassName="membership-feature-editor"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{row._id ? 'Editar' : 'Crear'} característica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="formName">
            <Form.Label column sm={3}>Nombre</Form.Label>
            <Col sm={8}>
              <Form.Control type="text"
                placeholder="Ingrese el nombre del plan"
                value={row.name}
                onChange={(e: any) => setRow({ ...row, name: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formCode">
            <Form.Label column sm={3}>Código</Form.Label>
            <Col sm={8}>
              <Form.Control type="text"
                placeholder="Ingrese el código del plan"
                value={row.code}
                onChange={(e: any) => setRow({ ...row, code: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formDescription">
            <Form.Label column sm={3}>Descripción</Form.Label>
            <Col md={8}>
              <Form.Control as="textarea"
                rows={3}
                placeholder="Ingrese la descripción del plan"
                value={row.description}
                onChange={(e: any) => setRow({ ...row, description: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formName">
            <Form.Label column sm={3}>Grupo</Form.Label>
            <Col sm={8}>
              <Form.Control type="text"
                placeholder="Ingrese el nombre del nombre del grupo"
                value={row.group}
                onChange={(e: any) => setRow({ ...row, group: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formName">
            <Form.Label column sm={3}>Tipo</Form.Label>
            <Col sm={8}>
              <Form.Control as="select"
                value={row.type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setRow({ ...row, type: e.target.value })}
              >
                <option value="">Seleccione un tipo</option>
                <option value="number">Numérico</option>
                <option value="string">Alfanumérico</option>
                <option value="boolean">Booleano (Verdadero / Falso)</option>
                <option value="enum">Seleccionable</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {row.type === 'enum' && (
            <Form.Group as={Row} controlId="formName">
              <Form.Label column sm={3}>Opciones</Form.Label>
              <Col sm={8}>
                <Form.Control as="textarea"
                  autoFocus
                  value={row.enumOptions?.join('\n')}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setRow({ ...row, enumOptions: e.target.value.split('\n') })}
                />
              </Col>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowEditor(false)}>
            <i className="fas fa-fw fa-times"></i> Cancelar
          </button>
          <button className="btn btn-primary" onClick={() => handleSave()}>
            <i className="fas fa-fw fa-save"></i> Guardar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdminMembershipsFeaturesPage;
