
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Notification from "../DropdownNotifications/Notification";

import './NotificationsDialog.scss'

interface INotification {

}

export interface INotificationsDialogProps {
  show: boolean;
  title: string;
  notifications: INotification[];
  onHide: () => void;
}

const NotificationsDialog = (props: INotificationsDialogProps) => {



  return (
    <div >
      <Modal show={props.show} onHide={props.onHide} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Notificaciones</Modal.Title>
        </Modal.Header>

        <div className="notifications-dialog-list custom-scrollbar">
          {props.notifications.map((notification: any, index: number) => (
            <Notification key={index}
              project_id={notification.project_id}
              notification={notification}
              onClick={() => props.onHide()}
            />
          ))}
        </div>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal></div>
  )
}

export default NotificationsDialog;

