
import React, { createContext, useState } from "react";

import ContextMenu from "../Components/ContextMenu/ContextMenu";

export interface IContextMenuCoordinates {
  x: number;
  y: number;
}

export interface IContextMenuOption {
  label?: string
  action?: () => void
  icon?: string | React.ReactNode
  separator?: boolean
  disabled?: boolean
  items?: IContextMenuOption[]
}

interface ContextMenuProps {
  show: (options: IContextMenuOption[]) => void
  hide: () => void

  options: IContextMenuOption[]
}

const ContextMenuContext = createContext<ContextMenuProps | undefined>(undefined);

interface ContextMenuProviderProps {
  children: React.ReactNode;
}

export const ContextMenuProvider = ({ children }: ContextMenuProviderProps) => {

  const [ show, setShow ] = useState(false)
  const [ options, setOptions ] = useState<IContextMenuOption[]>([])
  const [ top, setTop ] = useState(0)
  const [ left, setLeft ] = useState(0)

  const handleShowCM = (options: IContextMenuOption[]) => {

    const { clientX, clientY } = window.event as MouseEvent

    setShow(true)
    setOptions(options)
    setTop(clientY)
    setLeft(clientX)
  }

  const handleHideCM = () => {

    setShow(false)
    setOptions([])
  }

  const value = {
    show: handleShowCM,
    hide: handleHideCM,
    options
  }

  return (
    <ContextMenuContext.Provider value={value}>
      {children}
      <ContextMenu
        show={show}
        top={top}
        left={left}
        options={options}
        onHide={handleHideCM}
      />
    </ContextMenuContext.Provider>
  )
}

export const useContextMenu = (): ContextMenuProps => {

  const context = React.useContext(ContextMenuContext)

  if (context === undefined) {
    throw new Error('useContextMenu must be used within a ContextMenuProvider')
  }

  return context
}
