
import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

import { useAuth } from '../Hooks/UseAuth'

interface PrivateRouteProps extends RouteProps {
    component: any
}

const PrivateRoute = ({ component: Component, ...rest}: PrivateRouteProps) => {

    const { isAuthenticated } = useAuth()

    if (isAuthenticated === undefined) return null

    return (
        <Route {...rest} render={props => (
            isAuthenticated
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
}

export default PrivateRoute
