
import React, { useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InterfaceActions from '../../Redux/Actions/InterfaceActions'

type Props = {
    autoSize?: boolean,
    children: React.ReactNode,
}

const MainLayoutContentBody = ({ autoSize, children }: Props) => {

    return (
        <div className="ul-widget__body">
            {children}
        </div>
    )
}

export default MainLayoutContentBody
