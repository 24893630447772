
import React, { useRef } from "react";

interface Props {
  value: string;
  width: number;
  onResizeStart: (columnLeft: number) => void;
  onResizeEnd: () => void;
}

const ColumnCell = (props: Props) => {

  const ref = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent) => {

    const columnLeft = ref.current!.offsetLeft

    props.onResizeStart(columnLeft)
  }

  return (
    <div
      ref={ref}
      className="data-grid__header__row__cell resizable"
      onMouseUp={(e) => props.onResizeEnd()}
      style={{ width: props.width }}
    >
      {props.value}
      <div className="resizer"
        onMouseDown={handleMouseDown}
      ></div>
    </div>
  )
}

export default ColumnCell;
