
import React, { useEffect, useState } from "react";

interface Strings {
  fixed: boolean
  name: string
  from: any
  to: any

}

type Props = {
  name: string,
  from: any,
  to: any,
}

const ChangeString = (props: Props) => {

  const [ strings, setStrings ] = useState<Strings>({
    fixed: false,
    name: '',
    from: '',
    to: '',
  })

  const fixStrings = () => {

    let name = 'unnamed'
    let from = props.from
    let to = props.to

    switch (props.name) {
      case 'name': {
        name = 'El nombre'
        break
      }
      case 'type': {

        name = 'El tipo de tarea'
        break
      }
      case 'progress': {

        name = 'El progreso'
        from = props.from || props.from == 0 ? `${props.from}%` : null
        to = props.to || props.to == 0 ? `${props.to}%` : null
        break
      }
      case 'colorCode': {

        name = 'El código de color'
        from = props.from ? props.from.name : null
        to = props.to ? props.to.name : null
        break
      }
      case 'executeDays': {

        name = 'Días de ejecución'
        break
      }
      case 'unit': {

        name = 'La unidad'
        break
      }
      case 'metered': {

        name = 'El metrado'
        break
      }
      case 'performance': {

        name = 'El rendimiento'
        break
      }
      case 'crewNumber': {

        name = 'El número de cuadrilla'
        break
      }
      case 'estimatedDays': {

        name = 'Días estimados'
        break
      }
    }

    setStrings({
      ...strings,
      fixed: true,
      name,
      from,
      to,
    })
}

  useEffect(() => {

    fixStrings()
  }, [])

  if (!props.from && !props.to) return null

  if (!strings.fixed) return null

  return (
    <>
      {(strings.from || strings.from === 0 || strings.from === '') ? (
        <>
          {strings.name} cambió de <span className="change removed">{strings.from}</span> a <span className="change added">{strings.to}</span>
        </>
      ) : (
        <>
          {strings.name} se estableció a <span className="change added">{strings.to}</span>
        </>
      )}
    </>
  )
}

export default ChangeString
