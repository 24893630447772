
const initialState = {
  loadStatus: 'none',
  result: [],
  loadItemsStatus: 'none',
  items: [],
  error: ''
}

const ResourcesRestrictionsReducer = (state = initialState, { type, payload, error}: any) => {

  const st = { ...state }

  if (type === 'RESOURCES_RESTRICTIONS_LOAD_NONE') {

    st.loadStatus = 'none'

  } else if (type === 'RESOURCES_RESTRICTIONS_LOAD_STARTED') {

    st.loadStatus = 'loading'
  } else if (type === 'RESOURCES_RESTRICTIONS_LOAD_SUCCESS') {

    st.loadStatus = 'loaded'
    st.result = payload.result
    st.error = ''
    st.loadItemsStatus = 'none'
  } else if (type === 'RESOURCES_RESTRICTIONS_LOAD_ERROR') {

    st.loadStatus = 'error'
    st.error = error
  } else if (type === 'RESOURCES_RESTRICTIONS_SET_ITEMS') {

    st.items = payload.items
    st.loadItemsStatus = 'loaded'
  } else if (type === 'RESOURCES_RESTRICTIONS_CLEANUP') {

    st.result = []
    st.loadStatus = 'none'
    st.items = []
    st.loadItemsStatus = 'none'
  }

  return st
}

export default ResourcesRestrictionsReducer
