
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useAuth } from '../../Hooks/UseAuth'
import { useChat } from '../../Hooks/UseChat'
import { useFeedback } from '../../Hooks/UseFeedback'

import InterfaceActions from '../../Redux/Actions/InterfaceActions'

import InputSearchControl from '../InputSearchControl/InputSearchControl'
import OptionsDialog from '../OptionsDialog/OptionsDialog'

import './MainLayoutHeader.scss'

type Props = {
    username?: string,
    avatar?: string,
    title?: string,
    project?: any,
    menuBar?: any,
    hideMainMenu?: boolean,
    notifications: any,
    toggleFullscreen?: any,
    isProfilePage?: boolean,
}

const MainLayoutHeader = (props: Props) => {

    const ref = useRef(null)

    const { ability, logout } = useAuth()

    const { unsetCurrentChat } = useChat()

    const { show: showFeedbackForm } = useFeedback()

    const history = useHistory()

    const location = useLocation().pathname;

    const dispatch = useDispatch()

    const [ showUserDialog, setShowUserDialog ] = useState(false)

    const userDialogOptions = useMemo(() => {

        const options: any[] = [
            {
                icon: <span className="icon-usuario"></span>,
                label: 'Mi Perfil',
                onClick: () => {
                    history.push(`/profile${props.project && props.project._id ? `?project_id=${props.project._id}` : ''}`)
                }
            },
            {
                icon: <span className="icon-mensajes"></span>,
                label: 'Dejar feedback',
                onClick: () => showFeedbackForm()
            }
        ]

        if (ability.can('manage', 'admin')) {

            options.push({
                icon: <i className="fas fa-cogs"></i>,
                label: 'Administración',
                color: 'var(--color-green)',
                onClick: () => {
                    history.push('/admin')
                }
            })
        }

        options.push({
            icon: <span className="icon-cancelar"></span>,
            color: 'var(--color-danger)',
            label: 'Cerrar sesión',
            onClick: () => handleLogout()
        })

        return options
    }, [])

    const handleLogout = async () => {

        const { isConfirmed } = await Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Deseas cerrar sesión?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, cerrar sesión',
            cancelButtonText: 'Cancelar'
        })

        if (!isConfirmed) return

        unsetCurrentChat()

        logout()
    }

    useLayoutEffect(() => {

        const saveHeight = () => {

            if (ref && ref.current) {

                const current: any = ref.current

                dispatch(InterfaceActions.setDimension('headerHeight', current.offsetHeight))
            }
        }

        saveHeight()

        window.addEventListener('resize', saveHeight)

        return () => window.removeEventListener('resize', saveHeight)
    }, [])

    return (
        <>
            <div ref={ref} className="main-header">
                <div className="logo logo-left">
                    <Link to="/">
                        <img src="/svg-icons/logo.svg" style={{
                            width: 130
                        }} />
                    </Link>
                </div>
                <div className="d-none d-xl-block" style={{ flex: 1 }}>
                    <div style={{
                        width: 260
                    }}>
                        <InputSearchControl
                            placeholder='Buscar...'
                            readOnly={true}
                        />
                    </div>
                </div>
                {props.menuBar && !props.hideMainMenu && (
                    <>
                        <div className="d-none d-lg-block text-right" style={{ flex: 1 }}>
                            <ul className="project-menu">
                                {props.menuBar.map((menuItem: any, i: number) => (
                                    <li key={i}>
                                        {menuItem.disabled && (
                                            <span className="svg-icon-menu cursor-pointer">
                                                <div className="svg-icon">
                                                    <div className="svg-icon-container">
                                                        <span className={`main-menu-icon ${menuItem.icon}${menuItem.active ? '_full' : ''}`}></span>
                                                    </div>
                                                </div>
                                                <span className={`svg-icon-text width-${menuItem.textWidth}`}>
                                                    {menuItem.text}
                                                </span>
                                            </span>
                                        )}
                                        {!menuItem.disabled && (
                                            <Link
                                                className={`svg-icon-menu ${menuItem.active && 'active'}`}
                                                to={menuItem.path}
                                            >
                                                <div className="svg-icon">
                                                    <div className="svg-icon-container">
                                                        <span className={`main-menu-icon ${menuItem.icon}${menuItem.active ? '_full' : ''}`}></span>
                                                    </div>
                                                </div>
                                                <span className={`svg-icon-text width-${menuItem.textWidth}`}>
                                                    {menuItem.text}
                                                </span>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
                <div className="logo logo-right"
                >
                    <div
                        className="btn-user-dialog cursor-pointer"
                        onClick={() => setShowUserDialog(true)}
                    >
                        {location == '/profile' && (
                            <span className="username">
                                {props.username}
                            </span>
                        )}
                        {!props.avatar && (
                            <span
                                className={`${props.isProfilePage || showUserDialog ? 'icon-usuario_full' : 'icon-usuario'}`}
                            ></span>
                        )}
                        {props.avatar && (
                            <img src={props.avatar}
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%'
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>


            <OptionsDialog
                show={showUserDialog}
                title={props.username || 'Usuario'}
                closeButton
                onHide={() => setShowUserDialog(false)}
                options={userDialogOptions}
            />

        </>
    )
}

export default MainLayoutHeader
