
import React, { useEffect } from "react";
import { Col, FormGroup, FormLabel, Modal, Row } from "react-bootstrap";
import moment, { Moment } from "moment";

import { createNewMembership, getMembershipsPlans } from "../../../../../Services/AdminMembershipsService";

import Icon from "../../../../../Components/Icon/Icon";
import InputFormControl from "../../../../../Components/InputFormControl/InputFormControl";
import UsersSearch from "../UsersSearch/UsersSearch";
import SelectFormControl from "../../../../../Components/SelectFormControl/SelectFormControl";

interface MembershipCreatorProps {
  show: boolean
  onCreated: (membership: any) => void
  onHide: () => void
}

const MembershipCreator = (props: MembershipCreatorProps) => {

  const [ creating, setCreating ] = React.useState(false)

  const [ plans, setPlans ] = React.useState<any[]>([])

  const [ user, setUser ] = React.useState<any>(null)
  const [ plan, setPlan ] = React.useState('')
  const [ renewalType, setRenewalType ] = React.useState('')
  const [ startDate, setStartDate ] = React.useState(moment())
  const [ endDate, setEndDate ] = React.useState<Moment | undefined>()
  const [ users, setUsers ] = React.useState(1)

  const handleChangeStartDate = (date: string) => {

    setStartDate(moment(date))
  }

  const handleCancel = () => {

    setUser(null)
    setPlan('')
    setRenewalType('')
    setStartDate(moment())
    setEndDate(undefined)
    setUsers(1)

    props.onHide()
  }

  const handleSave = async () => {

    if (!user || !plan || !renewalType || !endDate || !users) return

    setCreating(true)

    const membership = {
      user: user._id,
      plan,
      renewalType,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
      users,
    }

    const { error} = await createNewMembership(membership)

    setCreating(false)

    if (error) {

      console.log(error)
      return
    }

    props.onCreated(membership)
  }

  useEffect(() => {

    if (!startDate || !renewalType) return

    setEndDate(startDate.clone().add(1, renewalType === 'yearly' ? 'year' : 'month'))
  }, [startDate, renewalType ])

  useEffect(() => {

    const getPlans = async () => {

      const { data }  = await getMembershipsPlans()

      setPlans(data)
    }

    getPlans()
  }, [])

  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Crear nueva membresía</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Usuario
          </FormLabel>
          <Col sm={7}>
            <UsersSearch
              selected={user}
              onSelect={(user) => setUser(user)}
            />
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Plan
          </FormLabel>
          <Col sm={7}>
            <SelectFormControl
              value={plan}
              onChange={(value: string) => setPlan(value)}
            >
              <option value="" disabled>Selecciona un plan</option>
              {plans.map((plan) => (
                <option key={plan._id} value={plan._id}>{plan.name}</option>
              ))}
            </SelectFormControl>
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Periodo
          </FormLabel>
          <Col sm={7}>
            <SelectFormControl
              value={renewalType}
              onChange={(value: string) => setRenewalType(value)}
            >
              <option value="" disabled>Selecciona un periodo</option>
              <option value="monthly">Mensual</option>
              <option value="yearly">Anual</option>
            </SelectFormControl>
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Fecha de inicio
          </FormLabel>
          <Col sm={7}>
            <InputFormControl
              type="date"
              value={startDate.format('YYYY-MM-DD')}
              onChange={(value: string) => handleChangeStartDate(value)}
            />
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Fecha de fin
          </FormLabel>
          <Col sm={7}>
            <InputFormControl
              type="date"
              value={endDate?.format('YYYY-MM-DD')}
              onChange={() => {}}
            />
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <FormLabel className='col-sm-3'>
            Usuarios
          </FormLabel>
          <Col sm={2}>
            <InputFormControl
              type="number"
              value={users}
              onChange={(value: string) => setUsers(parseInt(value))}
            />
          </Col>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleCancel}>
          <Icon name="times" fas /> Cancelar
        </button>
        <button className="btn btn-primary" onClick={() => handleSave()}>
          {!creating && (
            <>
              <Icon name="save" fas /> Crear
            </>
          )}
          {creating && (
            <>
              <Icon name="spinner" fas spin /> Creando...
            </>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MembershipCreator;

