import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { defineAbility } from "@casl/ability";
import { useDispatch } from "react-redux";

import config from "../config";
import History from "../Helpers/History";

import { usePlan } from "./UsePlan";

import SessionService from "../Services/SessionService";

import ProjectsActions from "../Redux/Actions/ProjectsActions";

export interface SessionData {
  id: string;
  name: string;
  surname: string;
  email: string;
  account: string;
  phone: string;
  workPhone: string;
  address: string;
  avatar: string;
}

export interface UpdateData {
  name: string;
  surname: string;
  phone: string;
  workPhone: string;
  address: string;
  avatar: string;
}

export interface LoginData {
  username: string;
  password: string;
}

interface AuthContextProps {
  authToken: string | null;
  isAuthenticated: boolean | undefined;
  userData: SessionData | null;
  ability: any;
  loginError: string | null;
  login: (loginData: LoginData) => Promise<any>;
  logout: () => void;
  update: (data: UpdateData) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {

  const dispatch = useDispatch()

  const [ ability, setAbility ] = useState<any>(defineAbility(() => { }))

  const { updatePlanUserId, updatePlan } = usePlan()

  const token = Cookies.get('authToken') || null


  const [ authToken, setAuthToken ] = useState<string | null>(token)
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean | undefined>(undefined)
  const [ gettingUserData, setGettingUserData ] = useState<boolean>(false)
  const [ userData, setUserData ] = useState<SessionData | null>(null)
  const [ loginError, setLoginError ] = useState<string | null>(null)

  const update = async (data: UpdateData) => {

    const { error } = await SessionService.update(data)

    if (error) return

    const newUserData = userData as SessionData

    setUserData({
      ...newUserData,
      name: data.name,
      surname: data.surname,
      phone: data.phone,
      workPhone: data.workPhone,
      address: data.address,
      avatar: data.avatar,
    })
  }

  const login = async (loginData: LoginData) => {

    const { data: userData, ability: user_ability, plan, token, error } = await SessionService.login(loginData)

    if (error) {

      setLoginError(error)
      return
    }

    ability.update(user_ability)

    updatePlanUserId(userData._id)

    Cookies.set('authToken', token, {
      expires: config.cookiesDuration,
      domain: config.corsDomain
    })

    setUserData({
      id: userData._id,
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      account: userData.account,
      phone: userData.phone,
      workPhone: userData.workPhone,
      address: userData.address,
      avatar: userData.avatar,
    })
    setAuthToken(token)
    if (plan) updatePlan(plan)
    setIsAuthenticated(true)

    History.replace('/')
  }

  const logout = () => {

    dispatch(ProjectsActions.clearAllProjects())

    Cookies.remove('authToken')
    localStorage.clear()
    setIsAuthenticated(false)
    setAuthToken(null)
    setUserData(null)

    History.replace('/login')
  }

  const getUserData = async () => {

    if (gettingUserData) return

    setGettingUserData(true)

    const {
      data,
      ability: user_ability,
      plan,
      error
    } = await SessionService.getUserData()

    if (error) return

    ability.update(user_ability)

    updatePlanUserId(data._id)

    setUserData({
      id: data._id,
      name: data.name,
      surname: data.surname,
      email: data.email,
      account: data.account,
      phone: data.phone,
      workPhone: data.workPhone,
      address: data.address,
      avatar: data.avatar,
    })

    if (plan) updatePlan(plan)

    setIsAuthenticated(true)

    setGettingUserData(false)
  }

  useEffect(() => {

    if (!authToken) {

      setIsAuthenticated(false)
    }

    if (authToken && !userData) getUserData()
  }, [ authToken, userData, getUserData ])

  const value = {
    authToken,
    isAuthenticated,
    userData,
    ability,
    loginError,
    login,
    logout,
    update,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextProps => {

  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}
