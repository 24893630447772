
import React from 'react'

import styles from './ToastMsgStyles'

const ToastMsg = (props: any) => {

    return (
        <div>
            <div style={styles.title}>{props.title}</div>
            <div style={styles.content}>{props.content}</div>
        </div>
    )
}
 
export default ToastMsg
