
import React from 'react'

type Props = {
  days: any[]
  weeks: any[]
  dayWidth: number
}

const GanttChartHeader = (props: Props) => {

  return (
    <>
      <div className="tr"
          style={{
            width: (props.days.length * props.dayWidth) + 17
        }}
      >
        {props.weeks.map((week: any, i: number) => (
          <div key={i}
            className="th-week th"
            style={{
              width: week.days.length * props.dayWidth
            }}
          >
            {week.start}
          </div>
        ))}
      </div>
      <div className="tr"
        style={{
          width: (props.days.length * props.dayWidth) + 17
        }}
      >
        {props.days.map((day: any, i: number) => (
          <div key={i}
            className={[
              'th-day',
              'th',
              day.isNonWorkingDay ? 'th-dark' : '',
              day.isToday ? 'th-today' : ''
            ].join(' ')}
            style={{
              width: props.dayWidth
            }}
          >
            {day.weekDayNameFirstLetter}
          </div>
        ))}
      </div>
      <div className="tr"
        style={{
          width: (props.days.length * props.dayWidth) + 17
        }}
      >
        {props.days.map((day: any, i: number) => (
          <div key={i}
            className={[
              'th-day-month',
              'th',
              day.isNonWorkingDay ? 'th-dark' : '',
              day.isToday ? 'th-today' : ''
            ].join(' ')}
            style={{
              width: props.dayWidth
            }}
          >
            {day.monthDay}
          </div>
        ))}
      </div>
    </>
  )
}

export default GanttChartHeader
