
import { Dispatch } from 'redux'
import NotificationsService from '../../Services/NotificationsService'

const TAG = 'NotificationsActions'

const loadResult = () => {

    return async (dispatch: Dispatch) => {

        try {

            const result = await NotificationsService.getResult()

            dispatch({
                type: 'NOTIFICATIONS_LOAD_RESULT_END',
                content: {
                    data: result.data
                }
            })
        } catch (error) {

            const errMessage = error instanceof Error ? error.message : error

            //todo: remove try catch
        }
    }
}

const setNotifications = (notifications: any) => {

    return {
        type: 'NOTIFICATIONS_LOAD_RESULT_END',
        content: {
            data: notifications
        }
    }
}

const setRead = (notification_id: string) => {

    return async (dispatch: any) => {

        try {

            await NotificationsService.setRead(notification_id)

            dispatch(loadResult())
        } catch (error) {

            //todo: remove try catch
        }
    }
}

export default {
    loadResult,
    setNotifications,
    setRead,
}
