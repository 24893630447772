import { AxiosError } from 'axios'

import config from '../config'

import { request, EMethods } from './ApiService'

import { LoginData, RegisterData, ConfirmData, ReConfirmData } from '../Types/Interfaces'

const login = async (loginData: LoginData) => {

    const url = config.apiHost + '/account/login'

    try {

        const res = await request(EMethods.post, 'SESSION_LOGIN', url, loginData)

        const { data, ability, plan, token } = res.data

        return { data, ability, plan, token }
    } catch (error) {

        return { error: error instanceof AxiosError ? error.response?.data.error : 'unknown error'}
    }
}

const register = async (data: RegisterData) => {

    const url = config.apiHost + '/account/register'

    try {

        const response = await request(EMethods.post, 'SESSION_REGISTER', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const getUserData = async () => {

    const url = config.apiHost + '/account/data'

    try {

        const res = await request(EMethods.get, 'SESSION_GET_USER_DATA', url)

        const { data, ability, plan } = res.data

        return { data, ability, plan }
    } catch (error) {

        return { error: error instanceof AxiosError ? error.response?.data.error : '' }
    }
}

const confirm = async (data: ConfirmData) => {

    const url = config.apiHost + '/account/confirm'

    try {

        const response = await request(EMethods.post, 'SESSION_CONFIRM', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const reConfirm = async (data: ReConfirmData) => {

    const url = config.apiHost + '/account/reconfirm'

    try {

        const response = await request(EMethods.post, 'SESSION_RECONFIRM', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const requestRecovery = async (data: any) => {

    const url = config.apiHost + '/account/request-recovery'

    try {

        const response = await request(EMethods.post, 'SESSION_REQUEST_RECOVERY', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const resetPassword = async (data: any) => {

    const url = config.apiHost + '/account/reset-password'

    try {

        const response = await request(EMethods.post, 'SESSION_RESET_PASSWORD', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const update = async (data: any) => {

    const url = `${config.apiHost}/account/update`

    try {

        const res = await request(EMethods.put, 'SESSION_UPDATE', url, data)

        return { data: res.data }
    } catch (error) {

        return { error: error instanceof AxiosError ? error.response?.data.error : '' }
    }
}

const changePassword = async (actual: string, password: string) => {

    const url = `${config.apiHost}/account/change-password`

    try {

        const res = await request(EMethods.put, 'SESSION_CHANGE_PASSWORD', url, { actual, password })

        return res.data
    } catch (error) {

        throw error
    }
}

const acceptTermsAndPolicies = async (terms: boolean, policies: boolean) => {

    const url = config.apiHost + '/account/accept-terms-and-policies'

    try {

      const { data } = await request(EMethods.put, 'ACCOUNT_ACCEPT_TERMS_AND_POLICIES', url, {
        terms,
        policies
      })

      return { data }
    } catch (error) {

      return { error }
    }
}


const deleteAccount = async () => {

    const url = `${config.apiHost}/account`

    try {

        const { data } = await request(EMethods.delete, 'SESSION_DELETE_ACCOUNT', url)

        return { data }
    } catch (error) {

        return { error: error instanceof AxiosError ? error.response?.data.error : '' }
    }
}

export default {
    login,
    register,
    getUserData,
    confirm,
    reConfirm,
    requestRecovery,
    resetPassword,
    update,
    changePassword,
    acceptTermsAndPolicies,
    deleteAccount,
}
