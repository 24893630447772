interface IState {
  loadStatus: 'none' | 'loading' | 'loaded' | 'error'
  result: any[]
  week: number | undefined
  start: string
  end: string
  collapsedGroups: string[]
  loadGanttItemsStatus: 'none' | 'loading' | 'loaded'
  ganttItems: any[]
  error: string
}

const initialState: IState = {
  loadStatus: 'none',
  result: [],
  week: undefined,
  start: '',
  end: '',
  collapsedGroups: [],
  loadGanttItemsStatus: 'none',
  ganttItems: [],
  error: ''
}

const WeeklyReducer = (state: IState = initialState, { type, payload, error }: any) => {

  const st = { ...state }

  if (type === 'WEEKLY_LOAD_NONE') {

    st.loadStatus = 'none'

  } else if (type === 'WEEKLY_LOAD_STARTED') {

    st.loadStatus = 'loading'

  } else if (type === 'WEEKLY_LOAD_SUCCESS') {

    st.loadStatus = 'loaded'
    st.week = payload.week
    st.result = payload.result
    st.start = payload.start
    st.end = payload.end
    st.collapsedGroups = payload.collapsedGroups
    st.loadGanttItemsStatus = 'none'
  } else if (type === 'WEEKLY_LOAD_ERROR') {

    st.loadStatus = 'error'
    st.error = error
  } else if (type === 'WEEKLY_SET_COLLAPSED_GROUPS') {

    st.collapsedGroups = payload.collapsedGroups
    st.loadGanttItemsStatus = 'none'
  } else if (type === 'WEEKLY_SET_GANTT_ITEMS') {

    st.ganttItems = payload.ganttItems
    st.loadGanttItemsStatus = 'loaded'
  } else if (type === 'WEEKLY_CLEANUP') {

    st.loadStatus = 'none'
    st.result = []
    st.start = ''
    st.end = ''
    st.loadGanttItemsStatus = 'none'
    st.ganttItems = []
  }

  return st
}

export default WeeklyReducer
