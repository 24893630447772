
import React, { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import moment from "moment";
import Icon from "../../../Components/Icon/Icon";
import { getOrdersList } from "../../../Services/AdminMembershipsService";
import OrdersViewer from "./Components/OrdersViewer/OrdersViewer";

const periods: any = {
  monthly: 'Mensual',
  yearly: 'Anual'
}

const AdminMembershipsOrdersPage = () => {

  const [ orders, setOrders ] = useState<any[]>([])

  const [ filters, setFilters ] = useState({
    criteria: '',
    status: 'pending',
    plan: '',
    startsAt: '',
    endsAt: ''
  })

  const [ order, setOrder ] = useState<any>({})
  const [ showViewer, setShowViewer ] = useState(false)

  const loadOrders = async () => {

    const { data, error } = await getOrdersList(filters)

    if (error) {
      console.log(error)
      return
    }

    setOrders(data)
  }

  const handleOpenOrder = (order: any) => {

    if (order) {
      setOrder(order)
      setShowViewer(true)
    }
  }

  const handleOnOrderRejected = async () => {

    loadOrders()
  }

  useEffect(() => {

    loadOrders()
  }, [])

  return (
    <>
      <AdminLayout title="Solicitudes" menu="memberships" submenu="orders">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex flex-column flex-lg-row justify-content-between pb-2">
                  <Card.Title className="">Solicitudes de membresía</Card.Title>
                  <div className="d-flex flex-row align-items-center align-self-end">
                    <div className="btn-toolbar order-1">
                      <InputGroup>
                        <Form.Control
                          placeholder="Buscar..."
                          value={filters.criteria}
                          onChange={(e: any) => setFilters({ ...filters, criteria: e.target.value })}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              loadOrders();
                            }
                          }}
                        />
                        <InputGroup.Append>
                          <button className="btn btn-normal"
                            onClick={() => loadOrders()}
                          >
                            <Icon name="buscar" />
                          </button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombres y Apellidos</th>
                        <th scope="col" style={{ width: 200 }}>Plan</th>
                        <th scope="col" style={{ width: 110 }}>Periodo</th>
                        <th scope="col" style={{ width: 100 }}>N. cuentas</th>
                        <th scope="col" style={{ width: 100 }}>Monto</th>
                        <th scope="col" style={{ width: 110 }}>Fec. solicitud</th>
                        <th scope="col" style={{ width: 120 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order: any, index: number) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            {order.companyName && (
                              <>
                                <span className="nowrap">
                                  <Icon name="building" fas fw />{' '}
                                  <strong>{order.companyName}</strong>
                                </span>
                                <div>
                                  <small className="nowrap">
                                    <Icon name="user-tie" fas fw />{' '}
                                    {order.user.name} {order.user.surname}
                                  </small>
                                </div>
                              </>
                            )}
                            {!order.companyName && (
                              <span className="nowrap">
                                <Icon name="user-tie" fas fw />{' '}
                                <strong>{order.user.name} {order.user.surname}</strong>
                              </span>
                            )}
                            <div>
                              <small className="text-muted nowrap">
                                <Icon name="envelope" fas fw />{' '}
                                {order.user.email}
                              </small>
                            </div>
                            {order.user.phone && (
                              <div>
                                <small className="text-muted nowrap">
                                  <Icon name="phone" fas fw />{' '}
                                  {order.user.phone}
                                </small>
                              </div>
                            )}
                          </td>
                          <td>{order.plan.name}</td>
                          <td>{periods[order.renewalType]}</td>
                          <td className="text-right">{order.users}</td>
                          <td className="text-right">
                            <Icon name="dollar-sign" fas />{' '}
                            {Number(order.amount).toFixed(2)}
                          </td>
                          <td>{moment(order.createdAt).format('DD/MM/YYYY')}</td>
                          <td>
                            <button type="button" className="btn btn-normal"
                              onClick={() => handleOpenOrder(order)}
                            >
                              <Icon name="folder-open" fas fw /> Abrir
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>

      <OrdersViewer
        show={showViewer}
        order={order}
        onHide={() => setShowViewer(false)}
        onApproved={() => {
          setShowViewer(false)
          loadOrders()
        }}
        onRejected={() => {
          setShowViewer(false)
          loadOrders()
        }}
      />
    </>
  );
}

export default AdminMembershipsOrdersPage;
