
import { request, EMethods } from './ApiService'

import config from '../config'

const getResult = async () => {

    const url = config.apiHost + '/notifications'

    const response = await request(EMethods.get, 'NOTIFICATIONS_LOAD_RESULT', url)

    return response.data
}

const setRead = async (notification_id: string) => {

    const url = `${config.apiHost}/notifications/${notification_id}/set-read`

    const res = await request(EMethods.put, 'NOTIFICATION_SET_READ', url)

    return res.data
}

export default {
    getResult,
    setRead,
}
