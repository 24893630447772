
import React from "react"
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

const WeeklyLineCard = (props: any) => {

  const {
    title,
    tooltip,
    data,
    styles,
  } = props

  const defTooltipFormatter = (items: any) => `${items[0].value}`

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: true
      },
      formatter: tooltip?.formatter ?? defTooltipFormatter
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map((item: any, i: number) => i + 1),
      axisLabel: {
        formatter: styles.axisXFormatter
      },
    },
    yAxis: {
      axisLabel: {
        formatter: styles.axisYFormatter
      },
      type: 'value'
    },
    series: [
      {
          data: data.map((item: any) => item.percent),
          type: 'line',
          smooth: true,
          lineStyle: {
              normal: {
                  color: styles.lineColor,
                  width: 2,
              }
          },
          itemStyle: {
              color: styles.itemColor,
          },
          areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                      offset: 0,
                      color: styles.areaGradientColors[0]
                  },
                  {
                      offset: 1,
                      color: styles.areaGradientColors[1]
                  }
              ])
          },
      }
  ]
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className='card-title'>{title}</div>
        <ReactEChartsCore
          echarts={echarts}
          option={option}
        />
      </div>
    </div>
  )
}

export default WeeklyLineCard
