
export interface ITimeExtension {
    _id?: string
    days: number | undefined
    reason: string
}

export enum ProjectDefaults {
    settings_workTimeStart = '07:30',
    settings_workTimeEnd = '17:00'
}
