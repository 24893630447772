
import React from "react";

type Props = {
  day: any;
  left: number;
  height: number;
  width: number;
  showVerticalBorders: boolean;
}

const GanttChartBodyGridColumn = (props: Props) => {

  const {
    day,
    showVerticalBorders,
  } = props

  const getClassName = () => {

    let className = ''

    if (day.isToday) {

      className = 'grid-today'
    } else if (day.isNonWorkingDay) {

      className = 'grid-sunday'
    } else {

      className = 'grid-day'
    }

    if (showVerticalBorders) {

      className += ' with-border'
    }

    return className
  }

  if (!day.isNonWorkingDay && !day.isToday && !showVerticalBorders) return null

  return (
    <>
      <rect
        className={getClassName()}
        x={props.left}
        y={0}
        height={props.height}
        width={props.width}
      ></rect>
      {showVerticalBorders && (
        <line
          className="grid-col-line"
          x1={props.left}
          y1={0}
          x2={props.left}
          y2={props.height}
        />
      )}
    </>
  )
}

export default GanttChartBodyGridColumn
