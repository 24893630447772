import { AxiosError } from "axios"

import config from "../config"

import { EMethods, request } from "./ApiService"

export const getMembershipsList = async () => {

    const url = config.apiHost + '/admin/memberships'

    try {
      const res = await request(EMethods.get, 'ADMIN_MEMBERSHIPS', url)

      const { rows } = res.data

      return { data: rows }
    } catch (error) {

      return { error: error instanceof AxiosError ? error.response?.data.error : '' }
    }
}

export const getOrdersList = async (filters: any) => {

  const urlFilters = new URLSearchParams(filters).toString()

  const url = config.apiHost + '/admin/memberships/orders?' + urlFilters

  try {
    const { data: { rows }} = await request(EMethods.get, 'ADMIN_MEMBERSHIPS', url)

    return { data: rows }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const rejectOrder = async (id: string, reason: string) => {

  const url = config.apiHost + '/admin/memberships/orders/' + id + '/reject'

  const rejectData = {
    reason,
  }

  try {
    const { data: { row }} = await request(EMethods.put, 'ADMIN_MEMBERSHIPS', url, rejectData)

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const acceptOrder = async (id: string, paymentId: string) => {

  const url = config.apiHost + '/admin/memberships/orders/' + id + '/accept'

  const acceptData = {
    paymentId,
  }

  try {
    const { data: { row }} = await request(EMethods.put, 'ADMIN_MEMBERSHIPS', url, acceptData)

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const createNewMembership = async (data: any) => {

  const url = config.apiHost + '/admin/memberships'

  try {
    const res = await request(EMethods.post, 'ADMIN_MEMBERSHIPS', url, data)

    const { row } = res.data

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const cancelMembership = async (id: string) => {

  const url = config.apiHost + '/admin/memberships/' + id

  try {
    const res = await request(EMethods.delete, 'ADMIN_MEMBERSHIPS', url)

    const { row } = res.data

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const getMembershipsFeatures = async () => {

  const url = config.apiHost + '/admin/memberships/features'

  try {
    const res = await request(EMethods.get, 'ADMIN_MEMBERSHIPS_FEATURES', url)

    const { rows } = res.data

    return { data: rows }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const saveMembershipsFeature = async (data: any) => {

  const url = config.apiHost + '/admin/memberships/features' + (data._id ? '/' + data._id : '')

  const method = data._id ? EMethods.put : EMethods.post

  try {
    const res = await request(method, 'ADMIN_MEMBERSHIPS_FEATURES', url, data)

    const { row } = res.data

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const deleteMembershipsFeature = async (id: string) => {

  const url = config.apiHost + '/admin/memberships/features/' + id

  try {
    const res = await request(EMethods.delete, 'ADMIN_MEMBERSHIPS_FEATURES', url)

    const { row } = res.data

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const getMembershipsPlans = async () => {

  const url = config.apiHost + '/admin/memberships/plans'

  try {
    const res = await request(EMethods.get, 'ADMIN_MEMBERSHIPS_PLANS', url)

    const { rows } = res.data

    return { data: rows }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}

export const saveMembershipsPlan = async (data: any) => {

  const url = config.apiHost + '/admin/memberships/plans' + (data._id ? '/' + data._id : '')

  const method = data._id ? EMethods.put : EMethods.post

  try {
    const res = await request(method, 'ADMIN_MEMBERSHIPS_PLANS', url, data)

    const { row } = res.data

    return { data: row }
  } catch (error) {

    return { error: error instanceof AxiosError ? error.response?.data.error : '' }
  }
}
