
import { Dispatch } from "redux";
import { getApplicationSettings } from "../../Services/ApplicationService";

export const getApplicationSettingsAction = () => {

  return async (dispatch: Dispatch) => {

    const { data, error } = await getApplicationSettings()

    if (error) {

      return { error }
    }

    dispatch({
      type: 'APPLICATION_GET_SETTINGS',
      payload: {
        settings: {
          termsVersion: data.data.termsVersion,
          policiesVersion: data.data.policiesVersion,
        },
        requestAcceptTermsAndPolicies: data.requestAcceptTermsAndPolicies
      }
    })

    return { data: data.data }
  }
}
