
import React from 'react'

import './TopLoader.css'

type Props = {
    show: boolean,
}

const TopLoader = (props: Props) => {

    // if (props.show) {

    //     document.body.classList.add('modal-open')
    // } else {

    //     document.body.classList.remove('modal-open')
    // }

    return (
        <>
            {!props.show ? null : (
                <>
                    <div className="top-loader-bg"></div>
                    <div className="top-loader-container">
                        <div className="top-loader-h">
                            <div data-testid="top-loader" className="top-loader">
                                <div className="spinner spinner-primary"></div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default TopLoader
