
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";

import { getMembershipsFeatures, getMembershipsPlans, saveMembershipsPlan } from "../../../Services/AdminMembershipsService";

import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";

import PlansEditor from "./Components/PlansEditor/PlansEditor";

import './plan-editor.scss'

const defRow = {
  name: '',
  code: '',
  description: '',
  monthlyRegularPrice: 0,
  monthlyDiscountPrice: 0,
  yearlyPrice: 0,
  yearlyDiscountPercent: 0,
  features: [],
}

const AdminMembershipsPlansPage = () => {

  const [ plans, setPlans ] = useState<any[]>([])
  const [ featuresGroups, setFeaturesGroups ] = useState<any>({
    proyectos: [],
    equipos: [],
    'programacion-tareas': [],
    'mc-dashboard': [],
    'ia-chat': [],
    soporte: [],
  })
  const [ showEditor, setShowEditor ] = useState(false)

  const [ row, setRow ] = useState<any>(defRow)

  const handleSave = async (row: any) => {

    const { data, error } = await saveMembershipsPlan(row)

    if (error) {
      console.log(error)
      return
    }

    const newPlan = plans.find((plan: any) => plan._id === data._id)

    if (!newPlan) {
      setPlans([ ...plans, data ])
    } else {

      const newPlans = plans.map((plan: any) => {
        if (plan._id === data._id) {
          return data
        }

        return plan
      })

      setPlans(newPlans)
    }

    setRow(defRow)

    setShowEditor(false)
  }

  const handleCancel = async () => {

    setRow(defRow)

    setShowEditor(false)
  }

  useEffect(() => {
    const getPlans = async () => {

      const { data }  = await getMembershipsPlans()

      setPlans(data)
    }

    const getFeatures = async () => {

      const { data }  = await getMembershipsFeatures()

      const groups: any = {
        proyectos: [],
        equipos: [],
        'programacion-tareas': [],
        'mc-dashboard': [],
        'ia-chat': [],
        soporte: [],
      }

      data.forEach((feature: any) => {

        const group = groups[feature.group]

        if (group) {
          group.push(feature)
        }
      })

      setFeaturesGroups(groups)
    }

    getPlans()
    getFeatures()
  }, [])

  return (
    <>
      <AdminLayout title="Membresías" menu="memberships" submenu="plans">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between pb-2">
                  <Card.Title className="">Planes</Card.Title>
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary"
                      onClick={() => setShowEditor(true)}
                    >
                      <i className="fa fa-fw fa-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombre / Descripción</th>
                        <th scope="col" style={{ width: 130 }}>Precio mensual</th>
                        <th scope="col" style={{ width: 130 }}>Precio con dcto.</th>
                        <th scope="col" style={{ width: 120 }}>Precio anual</th>
                        <th scope="col" style={{ width: 100 }}>% de dcto.</th>
                        <th scope="col" style={{ width: 260 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plans.map((plan: any, i: number) => (
                        <tr key={i}>
                          <td>1</td>
                          <td>
                            <strong>
                              {plan.name}
                            </strong>
                          </td>
                          <td align="right">$ {plan.monthlyRegularPrice}</td>
                          <td align="right">$ {plan.monthlyDiscountPrice}</td>
                          <td align="right">$ {plan.yearlyPrice}</td>
                          <td align="right">{plan.yearlyDiscountPercent} %</td>
                          <td>
                            <button className="btn btn-sm btn-primary"
                              onClick={() => {
                                setRow(plan)
                                setShowEditor(true)
                              }}
                            >
                              <i className="fas fa-fw fa-edit"></i> Editar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>

      <PlansEditor
        show={showEditor}
        row={row}
        featuresGroups={featuresGroups}
        onHide={() => handleCancel()}
        onSave={(row) => handleSave(row)}
      />
    </>
  )
}

export default AdminMembershipsPlansPage;
