
import React from "react";

import "./AuthLayout.scss";

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {

  return (
    <div className="auth-layout-wrap">
      <div className="auth-content">
        {children}
      </div>

      <footer>
        <section className='element-options'>
          <div data-id="7e0e3ba" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;<i className=\&quot;fas fa-caret-down\&quot;><\/i>&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
            <div className="">
              <nav className="politicas" style={{ width: '100%' }}>
                <ul id="menu-1-7e0e3ba" className="elementor-nav-menu" data-smartmenus-id="16957433742329077"><li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-984"><a href="https://pl4nner.com/privacidad-y-terminos/politicas-de-privacidad/" className="elementor-item">Políticas de privacidad,</a></li>
                  <li className=""><a href="https://pl4nner.com/privacidad-y-terminos/acuerdo-de-licencia-de-usuario-final/" className="elementor-item">EULA,</a></li>
                  <li className=""><a href="https://pl4nner.com/privacidad-y-terminos/descargo-de-responsabilidad/" className="elementor-item">Descargo de responsabilidad</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <div className='section-right'>
          <section className="element-redes" data-id="393912e" data-element_type="section">
            <div className="element-file">
              <div className='element-icons-file'>
                <div className="" data-id="665266c" data-element_type="column">
                  <div className="logos">
                    <a href="https://www.instagram.com/pl4nner/">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACS0lEQVR4nKWWz0uUYRDH31g3T2qZSaxuRtDBCiIEQxIShLoWGgZREEaHjm1JHYMuUbcOJnmrvyGpU8eu4iW0gn6cSm0rIwvtE2PfZ5menncD9wsD+52ZZ+Z5ZuZ5n80yB2AncB14CrwEliVV/kXV2ReAJ8AE0Olj+uDngBUaxwpwMQ5+wTnMAleAE0CfZB+wNyGmPwKcBG4Bb12c8RB8F/BVytvAlmyTALYCD91JOjLVPOx8U8GBy8Bz4DhQBN4o5tVMDTVU/hOkCHRLipEtDMEz8ZviM0ZeiQzlBC4D09EkVaUry+ce8B24JH5afvNGFkX2J4IPAkt1JsZsg4l1R4PdyE+R3YmdL8lm0zECtEhsal7IZhvsjtb2y/bZyC+RnsjpgfTvgPbELre7sZyKbH1hkoysxwmAAvBJ+tF02zf8RuVjt7ng9IekXzWyJrLHOdikBLTVSdDq/GplAg5It+YT+BN0uYXb6iRoc35dTt/rE4QSlaMS2bENI3USWOPRMBQSJ1j1Ta7tQE5T0s8DOxLB22UzTEa2g77JP+IeuDItyvYeGLNyScakM3wEStHaw35MP4j0JnY5oAB5MNtAYt2xULpMj4VhOKfOJeB+dKPt92S880RvFozMiNxIOUeNL0lqDU0BuKuYj41cE5kDmrIGATQDrxWzYopO4IsU040k4c+D8yg0eOPBkeG8q6+N3h19cofds5knQ8AprQmffrtbZ+Ls9uhb1kZhA3A274j2t6Wixtt02T0I98Tjm/vLYmKntjXWz7++vL8BPr0x503DrWYAAAAASUVORK5CYII="></img>
                    </a>
                  </div>
                </div>
                <div className="" data-id="cc6a122" data-element_type="column">
                  <div className="logos">
                    <a href="https://www.linkedin.com/company/90747702/admin/feed/posts/">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVR4nL2Wu0oDQRSGB2sFK8s8QPQlLH0BkSSdWNj6MBLfwdraJl6eQAVFLIMaFfaSze3TIbMwGc4al5zxhyn2P/zn2zOzLGOME9ABboGM1ZUBN0C77F9Czoinrj9JbLWMGzG2roxwJhZ8CBwDD0qg1IJ89YF17+wawESDZILn3sJXMof1Y4ByYNuD7GlAJJBVApwDF1rbVgWqq2fgErirAyqEMxoDr4L/AuwGXhO4/gtoJDQcAW+CvxN6zt8AntRAVsAmsGUCAUfLQGMhVADvgr8PDF3uJKg1NEH3Xu4xqK0B099AE6GhfeuB4CdeLhHqaV1QUQFKvVyuARrGAE0rQB//AcpjgGYVoE8NULZgyIGvFUGpEf5Ng2DN3JL8UsvqPQtqE18H5ZjdiJDTcE9b9raieIG0veaT/AC+AQa24ISZ7hdVAAAAAElFTkSuQmCC"></img>
                    </a>
                  </div>
                </div>
                <div className="" data-id="1a1dfb4" data-element_type="column">
                  <div className="logos">
                    <a href="https://www.facebook.com/pl4nner">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCklEQVR4nO3dP0hVYRzG8SeoSWhRg4agLRsibQqagqCiJaK25obGxibX0hqagmoTEZeGaGwOIkgjN9HNhkhrqaA/33jhJHK5N+/1vvee33l9PnBG9X3fr+85h3P1XqkN4AzwAHgPbGK5pLVcBmaBqXZr3xriCLAI/Mk2BOskrfECMNYpxklgveOX26CsAROtMUaB1YH9SNtN2gjjO4Ok05TVa37nBdzXjPqlBpMpyMO6R2LbZlTdhlkMSynIVt2jsG2bKYgF4iDBOEgwDhKMgwTjIME4SDD7OchXYAV4Bbysnuf1eqTvkdV+CvIReAxcBY4pA+BD7kHuhyArwA3gUI4IDrJ3P4G7wEENiHdI974DlwcVwkF6f13hmobAO6Q7j4YRw0G68wk47CBxTA8rhndId9eO4w4Sx/IwY3iH7O6Jg8Ry20FiOZf5dDQC3AKeA2+At22Ob7knUdKzrBMZY1wBNuqYRElBjmaKcRP4VdckSgoykiHGKeBHnZMoKciBDEFe1D2JYoKo/xhj1SP7eudBIdR/kPME4CAV4DoBOEilepm3dg5ScZDcv1l9chAHacunrIp3SGbqk4M4SFs+ZVW8Q/5vo8PrDx0P9Qm40OvPbHNslbpDptVAwOt+J+4gmaSnzcAXBwki/YtDjlODd0gmwCUHCQS44yCBAM8cpLA7LF9Dgt1hOUiwOywHyQS46CAF3mF5h2QCPC09yEqv76qQYVHP7vHdHBarNyUoOkjPMgTxX53kJAeJRQ4SixwkFjlILHKQWOQgschBYpGDxCIHiUUOEoscJBY5SCxykFjkILHIQWKRg8QiB4lFDhKLHCQWOUgscpBY5CCxyEFikYPEIgeJRQ4SixwkFjlILHKQWOQgsaigIJsUQGUE+ZwGskwBVEaQd2kgsxRAZQS5lwYyVX2gVqOp+UF+A6f/DWaBhlPzg8ztHMwosEqDqdlB1oHx1gFNAGs0lJobZLXjxzVVn6Ex38RripoXJK3xXDo7dTO4SWAGWEr3xjSAmhEkrWVa0/vbF/AWfwHGUeBWM7lpiQAAAABJRU5ErkJggg=="></img>
                    </a>
                  </div>
                </div>
                <div className="" data-id="f085ce8" data-element_type="column">
                  <div className="logos">
                    <a href="https://www.youtube.com/@app.pl4nner">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEcklEQVR4nO2dWahWVRTHT1rRtaKciqIJ62JW5PTSJGXzg0EDFakVmBgWkj6UUkTRQ0FhIlE0SERFQfXSQ1JUL2WT5kMpFkFpqalNanbN0PrFuq2P9r3cL+93028P5/+D/fYNa+0/55x91lp77aoSQgghhBBCCCGEEEIIIQYEcDAwFDgWGAWcCUz0cQ5wcTCuAa71MR2Y1eK4Lvj+lOB3Jwf/aaPTbRlhtmUpLXAUcAZwKXATcBewEFgCvAq8DawAvgK2ANvJi93AL8BGYA3wMfAW8ArwFPAwcCdwI3CJz8XIdk3+6cBs4EngQ2BH7NlKmN+AT4CngTl2Be4rEQ4ArgY+je1hAXwB3AwMHqgYJwDvxPaiQJYDo1sVYwKwObblBbMNuKC/YowBfo5tcQ3YCZy1NzGG+IpItIeN/7nE9mWcaC9LmolxjF9Gor3ssZfSvgS5u82GiH9Z2JcgeteIx/reYhwI7IpokIDhoSCjNSPROTcU5KrY1ghmhoLcowlJ6MEOvBjbGsFSrbDSYk0oyI+xrRHsaIhxCPCXJiQJhpogp5A2u6gPYytP9qdMJ/A89WBK5Qn6lOnwW+tFwCrKZrY5uoAMBAlCPHO8EqRE7jUnHyETQRoAw4DFHrouiUfNuWfITJBe6WarkyqF58yp18hUkAbAFcBa8ud1c+ZdMhfEsM8B8zMv4HvfHFlJAYI0AE7K4Kpvxmpz4GsKEqQBcCHwOXnxTeWlKMUJYgCDvAj8B/JgfZWBsR3V/8S3Piz2avaU2WLGbqVwQRoApwJvki5bKy+dr4UgGSyTu8y4P6iZIAkvk3ebYX9SQ0EaACf6bq8kkCAwHHg8lbhYlUECqKPaD3jkeFZiq8zuW9av1O+hPhn4jEQf6j9Rn2Xv8YlnH7uXvd9T/ovhEOB+4HcyeDFcR8GC8M87R+o+9gid2HbdEoOLE4EPyDC4+BFlhd+P9k4Sqb9fNQ2/L6WMBNVBwB2+5ThX3suh0LqjH2JYM5nVFJLCfYx8ixw6vRFMUUUOD5BfGdChvoxNPcrQKovMubnkUyg3CJhRcNuP+8zJ68mjlPRs771VMrdV3sEtZU72ZmE5LmMHVGxt8Z2U6aI+jDNBBmeQ/K8LwxoPy+9iWyLoCpeRucV8SuTLUJAXYlsj6LEtWp2AEmsccGVsawQ9Wmuo+UxizWfUniml9kwuihqYpdLAzAWxskoRh0XNUp+pF16XyJ6mna6BB2NbV0Oe7VOMoCI89SqUktiw17NKPNydUr1rfVuNB6KMBzbFtrhgrDXIpH6JEYhynI6r2C8ss7tQS2IEouhAl32HVdvfYHUBAxKjD3FOA24FnrCCrgw2i8Zku6c0LPV8e3cmsB0AR3gDmPOBqV7B8pCfU/US8Ia1i/AN/Ov83plTw/+dbvO33qtrmfv0svtovs4DpvmeE5uLI6scAQ7z/eOjfIwPjrA7Lzje7rLg2Dsbt7RwZN7MXt+9PPjdScH/TQjsMJsOjz0/QgghhBBCCCGEEEIIIaqM+RsMmBK6ga7+ngAAAABJRU5ErkJggg=="></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='element-copy'>
            <div className="" data-id="3d7f4c0" data-element_type="widget" data-widget_type="text-editor.default">
              <div>
                Copyright © Pl4nner 2023
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  )
}

export default AuthLayout;

