
type Submenu = {
    text: string;
    path: string;
    icon: string;
    active: boolean;
}

type Menu = {
    text: string;
    textWidth: number;
    path: string;
    icon: string;
    active: boolean;
    submenu?: Submenu[];
    disabled?: boolean;
}

type Navigation = [
    Menu[],
    Submenu[]
]

const getNavigation = (project_id: string, active_menu: string, active_submenu: string): Navigation => {

    let menu = [
        {
            text: 'Proyecto',
            textWidth: 55,
            path: `/project/${project_id}/`,
            icon: `icon-proyecto`,
            active: active_menu === 'home',
            submenu: [
                {
                    text: 'Dashboard',
                    path: `/project/${project_id}/`,
                    icon: `icon-dashboard`,
                    active: active_submenu === 'dashboard',
                },
                {
                    text: 'Información',
                    path: `/project/${project_id}/info`,
                    icon: `icon-informacion`,
                    active: active_submenu === 'info',
                },
                // {
                //     text: 'Alertas',
                //     path: `/project/${project_id}/alerts`,
                //     icon: `icon-alertas`,
                //     active: active_submenu === 'alerts',
                //     disabled: true,
                // },
            ],
        },
        {
            text: 'Equipos',
            textWidth: 50,
            path: `/project/${project_id}/teams`,
            icon: `icon-equipos`,
            active: active_menu === 'teams',
        },
        {
            text: 'Programación',
            textWidth: 86,
            path: `/project/${project_id}/programmation-macro`,
            icon: `icon-programacion`,
            active: active_menu === 'programmation',
            submenu: [
                {
                    text: 'Plan Macro',
                    path: `/project/${project_id}/programmation-macro`,
                    icon: `icon-macro`,
                    active: active_submenu === 'macro',
                },
                {
                    text: 'Plan Lookahead',
                    path: `/project/${project_id}/programmation-lookahead`,
                    icon: `icon-lookahead`,
                    active: active_submenu === 'lookahead',
                },
                {
                    text: 'Plan Semanal',
                    path: `/project/${project_id}/programmation-weekly`,
                    icon: `icon-semanal`,
                    active: active_submenu === 'weekly',
                },
                // {
                //     text: 'Calendario',
                //     path: `/project/${project_id}/programmation-calendar`,
                //     icon: `icon-calendario`,
                //     active: active_submenu === 'calendar',
                //     disabled: true,
                // },
            ],
        },
        {
            text: 'Tareas',
            textWidth: 41,
            path: `/project/${project_id}/tasks`,
            icon: `icon-tareas`,
            active: active_menu === 'tasks',
            submenu: [
                {
                    text: 'Tablero',
                    path: `/project/${project_id}/tasks`,
                    icon: `icon-tablero`,
                    active: active_submenu === 'board',
                },
                {
                    text: 'Base de datos',
                    path: `/project/${project_id}/tasks-archive`,
                    icon: `icon-base_de_datos`,
                    active: active_submenu === 'archive',
                },
            ],
        },
        {
            text: 'Recursos',
            textWidth: 57,
            path: `/project/${project_id}/resources/restrictions`,
            icon: `icon-recursos`,
            active: active_menu === 'resources',
            submenu: [
                {
                    text: 'Restricciones',
                    path: `/project/${project_id}/resources/restrictions`,
                    icon: `icon-restricciones_recurso`,
                    active: active_submenu === 'restrictions',

                },
                {
                    text: 'CNC',
                    path: `/project/${project_id}/resources/cnc`,
                    icon: `icon-cnc_recurso`,
                    active: active_submenu === 'cnc',
                },
                // {
                //     text: 'Carta Balance',
                //     path: `/project/${project_id}/resources/balance-chart`,
                //     icon: `icon-carta_balance`,
                //     active: active_submenu === 'balance-chart',
                //     disabled: true,
                // },
                // {
                //     text: 'Control HH',
                //     path: `/project/${project_id}/resources/control-hh`,
                //     icon: `icon-control_horas`,
                //     active: active_submenu === 'control-hh',
                //     disabled: true,
                // },
            ],
        },
        // {
        //     text: 'Protocolos',
        //     textWidth: 67,
        //     path: `/project/${project_id}/protocols`,
        //     icon: `icon-protocolos`,
        //     active: active_menu === 'protocols',
        //     disabled: true,
        // },
        {
            text: 'Indicadores',
            textWidth: 72,
            path: `/project/${project_id}/indicators/ppc`,
            icon: `icon-indicadores`,
            active: active_menu === 'indicators',
            submenu: [
                // {
                //     text: 'Dashboard',
                //     path: `/project/${project_id}/indicators/dashboard`,
                //     icon: `icon-dashboard`,
                //     active: active_submenu === 'dashboard',
                //     disabled: true,
                // },
                {
                    text: 'PPC',
                    path: `/project/${project_id}/indicators/ppc`,
                    icon: `icon-ppc_indicador`,
                    active: active_submenu === 'ppc',
                },
                {
                    text: 'CNC',
                    path: `/project/${project_id}/indicators/cnc`,
                    icon: `icon-cnc_recurso`,
                    active: active_submenu === 'cnc',
                },
                // {
                //     text: 'Recursos',
                //     path: `/project/${project_id}/indicators/resources`,
                //     icon: `icon-recursos_indicador`,
                //     active: active_submenu === 'resources',
                //     disabled: true,
                // },
                // {
                //     text: 'Protocolos',
                //     path: `/project/${project_id}/indicators/protocols`,
                //     icon: `icon-protocolos_indicador`,
                //     active: active_submenu === 'protocols',
                //     disabled: true,
                // },
            ],
        },
    ]

    let submenu = menu.find(item => item.active)?.submenu || []

    if (active_menu === 'homepage') {

        menu = []

        submenu = [
            {
                text: 'Proyectos Activos',
                path: `/`,
                icon: `icon-mis_proyectos`,
                active: active_submenu === 'my_projects',
            },
            {
                text: 'Archivados',
                path: `/other-projects`,
                icon: `icon-otros_proyectos`,
                active: active_submenu === 'other_projects',
            },
        ]
    }

    return [ menu, submenu ]
}

export default {
    getNavigation,
}
