
import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

import { TaskTypes } from '../../Types/Tasks'
import { TaskStatus } from '../../Types/Tasks'

import TeamsHelper from '../../Helpers/TeamsHelper'
import { getGroupOptionsFromTaskList } from '../../Helpers/TasksHelper'

import Dropdown from '../Dropdown/Dropdown'
import InputFormControl from '../InputFormControl/InputFormControl'

import 'react-datepicker/dist/react-datepicker.css'

type Filters = {
    user: string
    state: string
    team: string
    group: string
    code: string
    type: string
    start: string
    end: string
}

type Props = {
    show: boolean
    type: 'macro' | 'lookahead' | 'weekly' | 'tasks' | 'restrictions'
    filters: Filters
    onChange: (filters: any) => void
    onHide: () => void
}

const TaskFilters = (props: Props) => {

    const teams = useSelector((state: any) => state.TeamsReducer.result)
    const taskList = useSelector((state: any) => {

        switch (props.type) {
            case 'macro':
                return state.MacroReducer.result
            case 'lookahead':
                return state.LookaheadReducer.result
            case 'weekly':
                return state.WeeklyReducer.result
            case 'tasks':
                return state.TasksReducer.result
            case 'restrictions':
                return state.ResourcesRestrictionsReducer.result
        }
    })
    const colorCodes = useSelector((st: any) => st.ColorCodes.data)

    const users = useMemo(() => TeamsHelper.getAllMembersFromProject(teams), [teams])

    const [ groupOptions, setGroupOptions] = useState<Array<{ value: string; label: string }>>([])

    const {
        start,
        end,
    } = props.filters

    const user = props.filters.user && typeof props.filters.user === 'string' ? props.filters.user.split(',') : []
    const state = props.filters.state && typeof props.filters.state === 'string' ? props.filters.state.split(',') : []
    const team = props.filters.team && typeof props.filters.team === 'string' ? props.filters.team.split(',') : []
    const group = props.filters.group && typeof props.filters.group === 'string' ? props.filters.group.split(',') : []
    const code = props.filters.code && typeof props.filters.code === 'string' ? props.filters.code.split(',') : []
    const type = props.filters.type && typeof props.filters.type === 'string' ? props.filters.type.split(',') : []

    const [ usersSelected, setUsersSelected ] = useState<string[]>(user)
    const [ statesSelected, setStatesSelected ] = useState<string[]>(state)
    const [ teamsSelected, setTeamsSelected ] = useState<string[]>(team)
    const [ groupsSelected, setGroupsSelected ] = useState<string[]>(group)
    const [ codesSelected, setCodesSelected ] = useState<string[]>(code)
    const [ typesSelected, setTypesSelected ] = useState<string[]>(type)
    const [ startDate, setStartDate ] = useState<string>(start)
    const [ endDate, setEndDate ] = useState<string>(end)

    const [showCodesDropdown, setShowCodesDropdown] = useState(false)
    const [showUsersDropdown, setShowUsersDropdown] = useState(false)
    const [showStatesDropdown, setShowStatesDropdown] = useState(false)
    const [showTeamsDropdown, setShowTeamsDropdown] = useState(false)
    const [showGroupsDropdown, setShowGroupsDropdown] = useState(false)
    const [showTypesDropdown, setShowTypesDropdown] = useState(false)

    const getUsersSelected = () => {

        if (usersSelected.length > 1) return `${usersSelected.length} seleccionados`

        if (usersSelected.length === 1) {

            const userSelected = users.find((user: any) => user._id === usersSelected[0])

            return `${userSelected?.name} ${userSelected?.surname}`
        }

        return 'Responsable'
    };

    const getStateLabel = (stateKey: any) => {
        switch (stateKey) {
            case 'activa_atrasada':
                return 'Activas atrasadas'
            default:
                return stateKey.charAt(0).toUpperCase() + stateKey.slice(1) + 's'
        }
    }

    const getStateName = () => {

        if (statesSelected.length > 1) return `${statesSelected.length} seleccionados`

        if (statesSelected.length === 1) {

            const stateKey = Object.keys(TaskStatus).find((stateKey: any) => stateKey === statesSelected[0])

            if (stateKey) {

                switch(stateKey) {
                    case 'activa_atrasada':
                        return 'Activas atrasadas'
                    default:
                        return stateKey.charAt(0).toUpperCase() + stateKey.slice(1) + 's'
                    }
            }
        }

        return 'Estado'
    }

    const getTeamsSelected = () => {

        if (teamsSelected.length > 1) return `${teamsSelected.length} seleccionados`

        if (teamsSelected.length === 1) {

            const teamSelected = teams.find((team: any) => team._id === teamsSelected[0])

            return teamSelected?.name
        }

        return 'Equipo'
    }

    const getGroupsSelected = () => {

        if (groupsSelected.length > 1) return `${groupsSelected.length} seleccionados`

        if (groupsSelected.length === 1) {

            const groupSelected =  groupOptions.find((group: any) => group.value === groupsSelected[0])

            return groupSelected?.label
        }

        return 'Grupo'
    }

    const getCodesSelected = () => {

        if (codesSelected.length > 1) return `${codesSelected.length} seleccionados`

        if (codesSelected.length === 1) {

            const codeSelected = colorCodes.find((code: any) => code._id === codesSelected[0])

            return codeSelected?.name
        }

        return 'Código'
    }

    const getTypesSelected = () => {

        if (typesSelected.length > 1) return `${typesSelected.length} seleccionados`

        if (typesSelected.length === 1) {

            const typeKey = Object.keys(TaskTypes).find((typeKey: any) => typeKey === typesSelected[0])

            if (typeKey) {

                switch (typeKey) {
                    case 'reunion': return 'Reunión'
                    default: return typeKey.charAt(0).toUpperCase() + typeKey.slice(1)
                }
            }
        }

        return 'Tipo'
    }

    const selectUser = (user_id: string) => {

        setUsersSelected((prevUsersSelected) => {

            return prevUsersSelected.includes(user_id)
                ? prevUsersSelected.filter((id) => id !== user_id)
                : [...prevUsersSelected, user_id]
        })
    }

    const selectState = (state: any) => {

        setStatesSelected((prevStatesSelected) => {

            return prevStatesSelected.includes(state)
                ? prevStatesSelected.filter((state) => state !== state)
                : [...prevStatesSelected, state]
        })
    }

    const selectTeam = (team_id: string) => {

        setTeamsSelected((prevTeamsSelected) => {

            return prevTeamsSelected.includes(team_id)
                ? prevTeamsSelected.filter((id) => id !== team_id)
                : [...prevTeamsSelected, team_id]
        })
    }

    const selectGroup = (group: any) => {

        setGroupsSelected((prevGroupsSelected) => {

            return prevGroupsSelected.includes(group)
                ? prevGroupsSelected.filter((group) => group !== group)
                : [...prevGroupsSelected, group]
        })
    }

    const selectCode = (code_id: string) => {

        setCodesSelected((prevCodesSelected) => {

            return prevCodesSelected.includes(code_id)
                ? prevCodesSelected.filter((id) => id !== code_id)
                : [...prevCodesSelected, code_id]
        })
    };

    const selectType = (selectedType: any) => {

        setTypesSelected((prevTypesSelected) => {

            return prevTypesSelected.includes(selectedType)
                ? prevTypesSelected.filter((type) => type !== selectedType)
                : [...prevTypesSelected, selectedType]
        })
    }

    const handleClear = () => {

        setUsersSelected([])
        setStatesSelected([])
        setTeamsSelected([])
        setGroupsSelected([])
        setCodesSelected([])
        setTypesSelected([])
        setStartDate('')
        setEndDate('')

        props.onChange({
            ...props.filters,
            user: '',
            state: '',
            team: '',
            group: '',
            code: '',
            type: '',
            start: '',
            end: '',
        })
    }

    const handleApply = () => {

        props.onChange({
            ...props.filters,
            user: usersSelected.join(','),
            state: statesSelected.join(','),
            team: teamsSelected.join(','),
            group: groupsSelected.join(','),
            code: codesSelected.join(','),
            type: typesSelected.join(','),
            start: startDate,
            end: endDate,
        })
    }

    useEffect(() => {

        if (!taskList.length) return

        setGroupOptions(getGroupOptionsFromTaskList(taskList, 0))
    }, [ taskList ])

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header>
                <div className="d-flex flex-row w-100">
                    <div className="flex-grow-1">
                        <Modal.Title>Filtros</Modal.Title>
                    </div>
                    <div>
                        Mantener filtros
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Dropdown
                            show={showUsersDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">{getUsersSelected()}</span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            }
                            onToggle={(show: boolean) => setShowUsersDropdown(show)}
                            disabled={teamsSelected.length > 0}
                        >
                            {users.map((user: any, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${usersSelected.includes(user._id) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer', textTransform: 'capitalize' }}
                                    onClick={() => selectUser(user._id)}
                                >
                                    {user.name} {user.surname} {usersSelected.includes(user._id) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="col">
                        <Dropdown
                            show={showStatesDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">{getStateName()}</span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            }
                            onToggle={(show: boolean) => setShowStatesDropdown(show)}
                        >
                            {Object.keys(TaskStatus).map((stateKey: string, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${statesSelected.includes(stateKey) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer', textTransform: 'capitalize' }}
                                    onClick={() => selectState(stateKey)}
                                >
                                    {getStateLabel(stateKey)} {statesSelected.includes(stateKey) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Dropdown
                            show={showTeamsDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">{getTeamsSelected()}</span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            }
                            onToggle={(show: boolean) => setShowTeamsDropdown(show)}
                            disabled={usersSelected.length > 0}
                        >
                            {teams?.map((team: any, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${teamsSelected.includes(team._id) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                    onClick={() => selectTeam(team._id)}
                                >
                                    {team.name} {teamsSelected.includes(team._id) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="col">
                        <Dropdown
                            show={showGroupsDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">{getGroupsSelected()}</span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            }
                            onToggle={(show: boolean) => setShowGroupsDropdown(show)}
                            disabled={codesSelected.length > 0}
                        >
                            { groupOptions.map((group: any, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${groupsSelected.includes(group.value) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                    onClick={() => selectGroup(group.value)}
                                >
                                    {group.label} {groupsSelected.includes(group.value) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Dropdown
                            show={showCodesDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={(
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">
                                        {getCodesSelected()}
                                    </span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            )}
                            onToggle={(show: boolean) => setShowCodesDropdown(show)}
                            disabled={groupsSelected.length > 0}
                        >
                            {colorCodes.map((code: any, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${codesSelected.includes(code._id) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                    onClick={() => selectCode(code._id)}
                                >
                                    {code.name} {codesSelected.includes(code._id) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="col">
                        <Dropdown
                            show={showTypesDropdown}
                            toggleClass="btn btn-dropdown text-left w-100"
                            contentClass="dropdown-menu"
                            toggle={
                                <div className="d-flex flex-row">
                                    <span className="flex-grow-1">{getTypesSelected()}</span>
                                    <span>
                                        <i className="fas fa-fw fa-caret-down"></i>
                                    </span>
                                </div>
                            }
                            onToggle={(show: boolean) => setShowTypesDropdown(show)}
                        >
                            {Object.keys(TaskTypes).map((typeKey: string, i: number) => (
                                <button
                                    key={i}
                                    className={`dropdown-item ${typesSelected.includes(typeKey) && 'active'}`}
                                    style={{ outline: 'none', cursor: 'pointer' }}
                                    onClick={() => selectType(typeKey)}
                                >
                                    {Object.values(TaskTypes)[i]} {typesSelected.includes(typeKey) && <span>&#10003;</span>}
                                </button>
                            ))}
                        </Dropdown>
                    </div>
                </div>
                <div className="row">
                    {['macro', 'tasks', 'restrictions'].includes(props.type) && (
                        <div className="col">
                            <InputFormControl
                                type="date"
                                className='btn btn-dropdown text-left w-100'
                                value={startDate}
                                onChange={(date: string) => setStartDate(date)}
                            />
                        </div>
                    )}
                    {['macro', 'tasks', 'restrictions'].includes(props.type) && (
                        <div className="col">
                            <InputFormControl
                                type="date"
                                className='btn btn-dropdown text-left w-100'
                                value={endDate}
                                onChange={(date: string) => setEndDate(date)}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="text-right">
                    <button className="btn btn-secondary mr-1"
                        onClick={() => handleClear()}
                    >
                        <span className="icon-anular"></span> Limpiar
                    </button>
                    <button className="btn btn-secondary mr-1"
                        onClick={() => props.onHide()}
                    >
                        <span className="icon-cancelar"></span> Cerrar
                    </button>
                    <button className="btn btn-primary"
                        onClick={() => handleApply()}
                    >
                        <span className="icon-aceptar"></span> Aplicar
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TaskFilters
