
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import TasksActions from "../../Redux/Actions/TasksActions";

import InputFormControl from "../InputFormControl/InputFormControl";

interface AddRestrictionToManyProps {
  show: boolean;
  tasks: any[]
  onCancel: () => void;
  onSaved: () => void;
}

const AddRestrictionToMany = (props: AddRestrictionToManyProps) => {

  const {
    tasks,
  } = props

  const dispatch = useDispatch()

  const [ description, setDescription ] = useState('')

  const [ saving, setSaving ] = useState(false)

  const handleSubmit = async () => {

    if (!description) return

    setSaving(true)

    const tasks_ids = tasks.map(task => task._id)

    await dispatch(TasksActions.addRestrictionToMany(tasks_ids, description))

    setSaving(false)
    setDescription('')

    props.onSaved()
  }

  const handleCancel = () => {

    setDescription('')

    props.onCancel()
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onCancel}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header>
        <Modal.Title>
          Agregar restricción
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            Se agregará una restricción a las siguientes tareas:
          </p>
          <ul>
            {tasks.map((task, index) => (
              <li key={index}>
                {task.name}
              </li>
            ))}
          </ul>
          <div className="form-group">
            <label htmlFor="">Restricción:</label>
            <InputFormControl
              value={description}
              onChange={setDescription}
              // onEnter={() => saveGroup()}
              // onEscape={() => resetForm()}
              readOnly={saving}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={handleCancel}>
          <span className="icon-cancelar"></span> Cancelar
        </Button>
        <Button className="btn-primary" onClick={() => handleSubmit()} disabled={!description || saving}>
          <span className="icon-guardar" ></span> {saving ? 'Guardando...' : 'Guardar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddRestrictionToMany;
