
import React, { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'

const BasicColumnCard = (props: any) => {

  const {
    title,
    data,
  } = props

  const options = {
    chart: {
      height: 360
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent']
    },
    xaxis: {
      categories: data.map((week: any) => `Sem ${week.week}`),
    },
    fill: {
      opacity: 1
    },
  }

  const series = useMemo(() => {

    const items: any = []

    data.forEach((week: any, i: number) => {

        week.groups.forEach((group: any) => {

          const item = items.find((item: any) => item.name === group.label)

          if (item) {

            item.data.push(group.value)
          } else {

            items.push({
              name: group.label,
              data: [group.value]
            })
          }
        })
    })

    return items
  }, [ data ])

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title mb-3">{title}</div>
        <div className="">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={360}
        />
        </div>
      </div>
    </div>
  )
}

export default BasicColumnCard
