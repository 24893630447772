
import React from "react"
import CommentItem from "../CommentsList/CommentItem";

type Props = {
  from: any,
  to: any
}

const ChangeList = ({ from, to }: Props) => {

  const newComments: any = () => {

    from = from || []

    const comments = []

    for (let i = to.length - from.length; i <= to.length; i++) {

      comments.push(to[i - 1])
    }

    return comments
  }

  return (
    <>
      {newComments().map((comment: any, i: number) => (
        <CommentItem key={i} comment={comment} hideDate />
      ))}
    </>
  )
}

export default ChangeList
