
import config from "../config"
import { get } from "./ApiService"

export const getDashboardMemberships = async () => {

  const url = `${config.apiHost}/admin/dashboard/memberships`

  const { data, error } = await get(url, 'ADMIN_DASHBOARD_MEMBERSHIPS')

  if (error) return { error }

  return { data }
}



