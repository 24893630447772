
import React, { useEffect, useRef, useState } from "react";

import { ProgrammationSchema } from "./types";

import { getTaskStatus } from "../../Helpers/TasksHelper";

import ColorCodeText from "./GanttChart/Bars/GroupTaskBar/ColorCodeText";


interface Props {
  schema: ProgrammationSchema,
  bar: any;
  height: number;
  itemSize: number;
  top: number;
  isParentCollapsed: boolean;
  useCodeColors: boolean;
  isSelected: boolean
  onClick: (item: any, isItRight: boolean) => void
}

const GroupBarTask = (props: Props) => {

  const {
    schema,
    bar,
    height,
    itemSize,
    top,
    isParentCollapsed,
  } = props

  const textRef: any = useRef()
  const positionRef: any = useRef()
  const colorRef: any = useRef()
  const progressStringRef: any = useRef();
  const restrictionsRef: any = useRef();

  const [ positionWidth, setPositionWidth ] = useState(0);
  const [ colorWidth, setColorWidth ] = useState(0);
  const [ progressStringWidth, setProgressStringWidth ] = useState(0);
  const [ restrictionsWidth, setRestrictionsWidth ] = useState(0);
  const [ isHovered, setIsHovered ] = useState(false);

  const nameTop = top + 14;
  const nameLeft = bar.left + 10;
  const positionTop = nameTop
  const positionLeft = bar.left + bar.width - positionWidth - 4
  const colorTop = ((nameTop) + (itemSize * 32) / 2) - 12
  const colorLeft = bar.left + (bar.width / 2) - (colorWidth / 2)
  const progressStringTop = colorTop + 12
  let progressStringLeft = colorLeft + colorWidth - progressStringWidth
  const statusTop = top + height - 8
  const statusLeft = nameLeft;
  const restrictionsTop = statusTop
  const restrictionsLeft = bar.left + (bar.width / 2) - (restrictionsWidth / 2)

  if (progressStringLeft < colorLeft) progressStringLeft = colorLeft

  const getBarColor = () => {

    let color = `var(--progress-color-${props.bar.status})`

    if (props.useCodeColors) {

      color = `${props.bar.colorCodeColor || '#808080'}90`
    }

    return color
  }

  const handleClick = (e: any) => {

    props.onClick(props.bar, false)
  }

  const handleRightClick = (e: any) => {
    e.preventDefault();

    props.onClick(props.bar, true)
  }

  useEffect(() => {

    if (!positionRef.current) return

    setPositionWidth(positionRef.current.getComputedTextLength());
  }, [ positionRef.current ])

  useEffect(() => {

    if (!colorRef.current) return

    setColorWidth(colorRef.current.getComputedTextLength());
  }, [ colorRef.current ])

  useEffect(() => {

    if (!progressStringRef.current) return

    setProgressStringWidth(progressStringRef.current.getComputedTextLength());
  }, [ progressStringRef.current ])

  useEffect(() => {

    if (!restrictionsRef.current) return

    setRestrictionsWidth(restrictionsRef.current.getComputedTextLength());
  }, [ restrictionsRef.current ])

  return (
    <g className={`gantt-chart-mini-item ${props.isSelected ? 'active' : ''}`}
      onClick={handleClick}
      onContextMenu={handleRightClick}
    >
      <rect
        className="grid-row-item"
        fill={getBarColor()}
        x={bar.left + 4}
        y={0}
        height={0}
        width={bar.width - 8}
        style={{
          height: isParentCollapsed ? height : 0,
          transform: `translateY(${isParentCollapsed ? top : top + 6}px)`,
          opacity: isParentCollapsed ? 1 : 0
        }}
      />
      <text
        ref={textRef}
        className="grid-row-item-text dark"
        x={bar.left + 10}
        y={0}
        style={{
          transform: `translateY(${top + 14}px)`,
          opacity: isParentCollapsed && itemSize === 1 ? 1 : 0,
        }}
      >
        {bar.colorCodeName}
      </text>
      <text
        ref={textRef}
        className="grid-row-item-text dark"
        x={bar.left + 10}
        y={0}
        style={{
          transform: `translateY(${nameTop}px)`,
          opacity: isParentCollapsed && itemSize > 1 ? 1 : 0
        }}
      >
        {bar.name}
      </text>
      {schema === 'weekly' && (
        <>
          <text
            ref={positionRef}
            className="grid-row-item-text dark"
            x={positionLeft}
            y={0}
            style={{
              transform: `translateY(${positionTop}px)`,
              opacity: isParentCollapsed && itemSize > 1 ? 1 : 0
            }}
          >
            {bar.position}
          </text>
          <ColorCodeText
            colorCodeName={bar.colorCodeName}
            itemLeft={bar.left}
            itemTop={top}
            itemWidth={bar.width}
            itemVerticalSize={itemSize}
            opacity={isParentCollapsed && itemSize > 1 ? 1 : 0}
            isHovered={isHovered}
          />
          <text
            ref={progressStringRef}
            className="grid-row-item-text dark"
            x={progressStringLeft}
            y={0}
            style={{
              transform: `translateY(${isParentCollapsed && itemSize === 1 ? nameTop : progressStringTop}px)`,
              opacity: isParentCollapsed && itemSize > 1 ? 1 : 0
            }}
          >
            {bar.progressString}
          </text>
          <text
            className="grid-row-item-text dark"
            x={statusLeft}
            y={0}
            style={{
              transform: `translateY(${isParentCollapsed && itemSize === 1 ? nameTop : statusTop}px)`,
              opacity: isParentCollapsed && itemSize > 2 ? 1 : 0
            }}
          >
            {getTaskStatus(bar.status)}
          </text>
          <text
            ref={restrictionsRef}
            className="grid-row-item-text dark"
            x={restrictionsLeft}
            y={0}
            style={{
              transform: `translateY(${isParentCollapsed && itemSize === 1 ? nameTop : restrictionsTop}px)`,
              opacity: isParentCollapsed && itemSize > 3 ? 1 : 0
            }}
          >
            {`${bar.restrictions?.done}/${bar.restrictions?.toDo}`}
          </text>
        </>
      )}
      <rect
        className="grid-row-item-transparent"
        fill="#FFFFFF00"
        x={bar.left + 4}
        y={0}
        height={0}
        width={bar.width - 8}
        style={{
          height: isParentCollapsed ? height : 0,
          transform: `translateY(${isParentCollapsed ? top : top + 6}px)`,
          opacity: isParentCollapsed ? 1 : 0
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </g>
  )
}

export default GroupBarTask
