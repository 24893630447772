import React, { useEffect } from "react";
import moment from 'moment';

import { useAuth } from "../../../Hooks/UseAuth";
import { useChat } from "../../../Hooks/UseChat";

import { TChatMessage, TChatUser } from "../../../Types/Chat";

import "./ChatAutomaticMessage.scss";

export interface XChatMessageProps {
  message: TChatMessage;
}

const ChatAutomaticMessage = ({ message }: XChatMessageProps) => {

  const { userData } = useAuth()

  const { readMessage } = useChat();

  const myId = userData?.id

  useEffect(() => {

    if (!myId) return

    const isViewedByMe = message.viewedBy.find((user: TChatUser) => user._id === myId);

    if (!isViewedByMe) readMessage(message)
  }, [ message ]);

  return (
    <div className="chat-automatic-message">
      <div className="message-container">
        <div className="message-header">
          <div>
            <i className="fas fa-fw fa-robot"></i> Pl4nner IA
          </div>
          <div className="message-time">
            {moment(message.createdAt).format('HH:mm')}
          </div>
        </div>
        <div className="message-content">{message.content}</div>
      </div>
    </div>
  );
};

export default ChatAutomaticMessage;




