
import React, { useEffect, useRef, useState } from "react";
import { GanttColumn } from "./types";

type Props = {
  showVerticalBorders: boolean
  column: GanttColumn
  item: any
  isRowActive: boolean

  onToggleCollapse: (group_id: string) => void
}

const GanttTableBodyCell = (props: Props) => {

  const input_ref: any = useRef()

  const [ editing, setEditing ] = useState(false)
  const [ cell, setCell ] = useState('')

  const getClassName = () => {

    let class_name = 'content'

    if (props.item.schema === 'group') {

        class_name += ' group'
    }

    if (props.column.field === 'name') {

        class_name += ' editable'
    }

    return class_name
  }

  const getPadding = () => {

    let left = 4

    if (props.column.field === 'name') {

        left += props.item.level * 16
    }

    return left
  }

  const handleClick = (e: any) => {

    if (!props.isRowActive) {

        return
    }

    // if (props.column.field === 'name') {

    //     e.preventDefault()
    //     e.stopPropagation()
    // }

    // if (props.column.field === 'name') {

    //     clicks += 1

    //     setTimeout(() => {

    //         if (clicks === 1 && props.column.onClick) {

    //             props.column.onClick(props.item)
    //         } else  if (clicks === 2 && props.column.editable) {

    //             setEditing(true)

    //             if (input_ref && input_ref.current) {

    //                 input_ref.current.focus()
    //             }
    //         }

    //         clicks = 0
    //     }, 200)
    // }
  }

  const toggleCollapse = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    props.onToggleCollapse(props.item._id)
}

  useEffect(() => {

    if (props.item[props.column.field] !== cell) {

      setCell(props.item[props.column.field])
    }
  }, [ props.item ])

  return (
    <div
      className={`td ${props.showVerticalBorders ? 'with-border': ''}`}
      style={{
        width: props.column.width,
        paddingLeft: getPadding()
      }}
    >
      {props.column.field === 'name' && props.item.schema === 'task' && (
        <span className="fake-icon"></span>
      )}
      {props.column.field === 'name' && props.item.schema === 'group' && (
        <span
          className={`icon ${props.item.isCollapsed ? 'collapsed' : 'expanded'}`}
          onClick={(e: any) => toggleCollapse(e)}
          style={{
            height: 21,
          }}
        >
          ‣
        </span>
      )}
      {!editing && (
        <span className={getClassName()}
          style={{
            width: `calc(100% - 24px)`
          }}
          onClick={(e: any) => handleClick(e)}
        >
          {props.column.render ? props.column.render(cell, props.item) : cell}
        </span>
      )}
      {editing && (
        <input
          ref={input_ref}
          className="content"
          type="text"
          value={cell}
          // onChange={onChange}
          // onKeyDown={onKeyDown}
          // onKeyUp={onKeyUp}
          // onBlur={() => handleBlur()}
          onClick={(e: any) => e.stopPropagation()}
        />
      )}
    </div>
  )
}

export default GanttTableBodyCell
