
import React from 'react'
import Swal from 'sweetalert2'

import { useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

import TeamsActions from '../../Redux/Actions/TeamsActions'
import NotificationsActions from '../../Redux/Actions/NotificationsActions'
import moment from 'moment'
import DefaultNotification from './Notifications/DefaultNotification'
import TaskNotification from './Notifications/TaskNotification'
import TeamMemberRemovedNotification from './Notifications/TeamMemberRemovedNotification'
import TeamMemberMovedNotification from './Notifications/TeamMemberMovedNotification'
import TeamMemberAddedNotification from './Notifications/TeamMemberAddedNotification'
import TeamUpdatedNotification from './Notifications/TeamUpdatedNotification'
import DeleteProjectNotification from './Notifications/DeleteProjectNotification'
import AbandonNotification from './Notifications/AbandonNotification'
import InviteNotification from './Notifications/InviteNotification'
import TaskResponsableNotification from './Notifications/TaskResponsableNotification'
import AssistantProfileNotification from './Notifications/AssistantProfileNotification'
import CncAnalisisNotification from './Notifications/CncAnalisisNotification'
import PpcSemanalNotification from './Notifications/PpcSemanalNotificacion'
import TaskNotConfirmedMultiNotification from './Notifications/TaskNotConfirmedMultiNotification'
import TaskNotConfirmedNotification from './Notifications/TaskNotConfirmedNotification'
import TaskReleaseDateNotification from './Notifications/TaskReleaseDateNotification'
import TaskAssignedDelayNotification from './Notifications/TaskAssignedDelayNotification'
import AccountDeletedNotification from './Notifications/AccountDeletedNotification'
import ChatInvitationNotification from './Notifications/ChatInvitationNotification'

type Props = {
    project_id: string,
    notification: any,
    onClick: any,
}

const Notification = (props: Props) => {

    const isNotRead = props.notification.status === 'not_read';

    const history = useHistory()

    const dispatch = useDispatch()

    const {
        notification,
    } = props

    const getTimeDifference = (date: any) => {
        const difference =
            moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
                moment(date, "DD/MM/YYYY HH:mm:ss")
            ) / 1000;

        if (difference < 60) return `${Math.floor(difference)} seconds`;
        else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
        else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
        else if (difference < 86400 * 30)
            return `${Math.floor(difference / 86400)} dias`;
        else if (difference < 86400 * 30 * 12)
            return `${Math.floor(difference / 86400 / 30)} meses`;
        else return `${(difference / 86400 / 30 / 12).toFixed(1)} años`;
    }

    const goToProject = async () => {

        let page = ''

        let reload = false

        let getOutOfProject = false

        const project_id = props.project_id

        await dispatch(NotificationsActions.setRead(props.notification._id))

        props.onClick()

        switch (props.notification.type) {
            case 'project_updated':
                page = '/info'
                break
            // falta un case profile_updated_to_assistant
            case 'team_updated':
            case 'team_member_added':
            case 'team_member_moved':
            case 'account_deleted_by_user':
                page = `/teams/${props.notification.team._id}`

                if (props.project_id) {

                    if (project_id !== props.notification.project._id) {

                        reload = true
                    }
                }
                break
            case 'team_member_removed':

                if (props.notification.userTo._id !== props.notification.team_member._id) {
                    page = `/teams/${props.notification.team._id}`
                }
                else { getOutOfProject = true }

                break
            case 'assistant_profile_assigned':
            case 'assistant_profile_removed':
                page = `/teams`
                break
            case 'task_created':
            case 'task_assigned':
            case 'task_unassign':
            case 'task_updated':
            case 'task_confirmed':
            case 'task_rejected':
            case 'task_assigned_delay':
            case 'task_release_date':
            case 'task_not_confirmed':
            case 'task_not_confirmed_weekly':
            case 'task_release_date_to_assistant':

                page = `/programmation-macro?task_id=${props.notification.task._id}`

                if (props.project_id) {

                    if (project_id !== props.notification.project._id) {

                        reload = true
                    }
                }
                break
            case 'ppc_too_low':
                page = '/indicators/ppc'
                break
            case 'cnc_not_completed':
                page = '/resources/cnc'
                break
            default:
                page = ''
        }

        let url = '/'

        if (!getOutOfProject) {

            url = `/project/${props.notification.project._id}${page}`
        }

        if (reload) {

            window.location.href = url
        } else {

            history.push(url)
        }
    }

    const goToChat = () => {

        history.push(`/chat/${props.notification.chat}`)
    }

    const getIcon = () => {

        let icon;


        switch (props.notification.type) {

            case 'ppc_too_low':
            case 'cnc_not_completed':
            case 'project_updated':
                icon = <span className={`main-menu-icon ${isNotRead ? 'icon-proyecto_full' : 'icon-proyecto'}`}></span>;
                break;
            case 'team_updated':
            case 'team_member_added':
            case 'team_member_moved':
            case 'team_member_removed':
            case 'assistant_profile_assigned':
            case 'assistant_profile_removed':
                icon = <span className={`main-menu-icon ${isNotRead ? 'icon-equipos_full' : 'icon-equipos'}`}></span>;
                break;
            case 'task_created':
            case 'task_assigned':
            case 'task_unassign':
            case 'task_updated':
            case 'task_confirmed':
            case 'task_rejected':
            case 'task_assigned_delay':
            case 'task_release_date':
            case 'task_not_confirmed':
            case 'task_not_confirmed_weekly':
                icon = <span className={`main-menu-icon ${isNotRead ? 'icon-tareas_full' : 'icon-tareas'}`}></span>;
                break;
            case 'added_to_project_chat':
                icon = <span className={`main-menu-icon ${isNotRead ? 'icon-mensajes_full' : 'icon-mensajes'}`}></span>;
                break;
            default:
                icon = <span className={`main-menu-icon ${isNotRead ? 'icon-equipos_full' : 'icon-equipos'}`}></span>;
        }

        return icon
    }

    const openInvite = async () => {

        if (props.notification.status === 'read') {

            return
        }

        const result = await Swal.fire({
            title: '¿Deseas unirte al equipo?',
            html: `<strong>${props.notification.userFrom.name} ${props.notification.userFrom.surname}</strong>
                    te invitó al equipo <strong>${props.notification.team.name}</strong>
                    del proyecto <strong>${props.notification.project.shortName}</strong>`,
            text: '',
            icon: 'question',
            confirmButtonText: 'Aceptar',
            // showDenyButton: true,
            // denyButtonText: 'Rechazar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            width: '500px',
        })

        if (result.isConfirmed) {
            const page = `/teams/${props.notification.team._id}`;
            const project_id = props.notification.project._id;

            // Realiza las acciones que necesites hacer después de la confirmación

            dispatch(TeamsActions.acceptInviteAction(
                props.notification.invite,
                project_id
            ));

            const url = `/project/${project_id}${page}`;
            history.push(url);
        }


    }

    const openAbandon = async () => {

        if (props.notification.status === 'read') {

            return
        }

        dispatch(NotificationsActions.setRead(props.notification._id))
    }

    const openDeletedProject = async () => {

        if (props.notification.status === 'read') {

            return
        }

        dispatch(NotificationsActions.setRead(props.notification._id))
    }

    const getNotification = () => {

        switch (notification.type) {


            case 'invite':
                return <InviteNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => openInvite()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'abandon_project':
                return <AbandonNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => openAbandon()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'delete_project':
                return <DeleteProjectNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => openDeletedProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'team_updated':
                return <TeamUpdatedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'team_member_added':
                return <TeamMemberAddedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'team_member_moved':
                return <TeamMemberMovedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'team_member_removed':
                return <TeamMemberRemovedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_assigned':
                return <TaskResponsableNotification action={'assigned'} isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_unassign':
                return <TaskResponsableNotification action={'removed'} isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'user_not_more_responsible':
                return <TaskResponsableNotification action={'not_more_responsible'} isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_created':
            case 'task_updated':
            case 'task_confirmed':
            case 'task_rejected':
                return <TaskNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'assistant_profile_assigned':
                return <AssistantProfileNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} action={'assigned'} />
            case 'assistant_profile_removed':
                return <AssistantProfileNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} action={'removed'} />
            case 'ppc_too_low':
                return <PpcSemanalNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'cnc_not_completed':
                return <CncAnalisisNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_assigned_delay':
            case 'task_assigned_delay_to_assistant':
                return <TaskAssignedDelayNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_release_date':
            case 'task_release_date_to_assistant':
                return <TaskReleaseDateNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_not_confirmed':
                return <TaskNotConfirmedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'task_not_confirmed_weekly':
                return <TaskNotConfirmedMultiNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'account_deleted_by_user':
                return <AccountDeletedNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            case 'added_to_project_chat':
                return <ChatInvitationNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToChat()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
            default:
                return <DefaultNotification isNotRead={isNotRead} icon={getIcon()} data={notification} onClick={() => goToProject()} timeDifference={getTimeDifference(new Date(notification.createdAt))} />
        }

    }

    return (
        <>
            {getNotification()}
        </>
    )
}

export default Notification
