
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'

import config from '../../../config'
import navigation from '../../../navigation'
import { Can } from '../../../Casl/Casl'
import { TaskWithId } from '../../../Types/Tasks'

import { useAuth } from '../../../Hooks/UseAuth'
import { usePlan } from '../../../Hooks/UsePlan'
import { useQuery } from '../../../Hooks/UseQuery'
import { IContextMenuOption, useContextMenu } from '../../../Hooks/UseContextMenu'

import {
  getCollapsedGroups,
  getGanttItems,
  getItemFromTaskList,
  getNonWorkingDays,
  saveCollapsedGroups,
  IGetItemsListOptions,
  getGroupTasks,
  getGroupFromList,
  getMacroResult,
  filterByUsers,
  filterByColorCodes,
  filterByStatus,
  filterByGroups,
  filterByTypes,
  filterByDate
} from '../../../Helpers/ProgrammationHelper'
import TeamsHelper from '../../../Helpers/TeamsHelper'
import History from '../../../Helpers/History'

import ProjectsActions from '../../../Redux/Actions/ProjectsActions'
import TeamsActions from '../../../Redux/Actions/TeamsActions'
import TasksActions from '../../../Redux/Actions/TasksActions'
import ColorCodeActions from '../../../Redux/Actions/ColorCodeActions'
import {
  createGroupAction,
  deleteGroupAction,
  moveItemAction,
  setMacroCollapsedGroupsAction,
  setMacroAction,
  setMacroGanttItems,
  updateGroupAction
} from '../../../Redux/Actions/MacroActions'
import InterfaceActions from '../../../Redux/Actions/InterfaceActions'
import { loadProgrammationAction } from '../../../Redux/Actions/ProgrammationActions'

import { getStatusName } from '../../../Components/TaskManager/TaskEditorHelper'
import MainLayout from '../../../Components/MainLayout/MainLayout'
import MainLayoutContentHeader from '../../../Components/MainLayout/MainLayoutContentHeader'
import MainLayoutContentBody from '../../../Components/MainLayout/MainLayoutContentBody'
import GroupEditor from '../../../Components/GroupEditor/GroupEditor'
import TaskCreator from '../../../Components/TaskManager/TaskCreator'
import TaskEditor from '../../../Components/TaskManager/TaskEditor'
import TaskFilters from '../../../Components/TaskFilters/TaskFilters'
import UserAvatar from '../../../Components/UserAvatar/UserAvatar'
import { GanttColumn } from '../../../Components/GantV2/types'
import Gantt from '../../../Components/GantV2/Gantt'
import TasksImporter from '../../../Components/TasksImporter/TasksImporter'
import Icon from '../../../Components/Icon/Icon'
import AddRestrictionToMany from '../../../Components/AddRestrictionToMany/AddRestrictionToMany'
import ReScheduleMany from '../../../Components/ReScheduleMany/ReScheduleMany'

const MacroPage = (props: any) => {

  const sidebarRefBtn = useRef<HTMLButtonElement>(null)

  const project_id = props.match.params._id

  const query = useQuery()
  const task_id = query.get("task_id") || '';

  const { ability, userData } = useAuth()
  const {
    plan,
    tooManyRestrictions,
    tooManyTasks,
    showTooManyRestrictionsModal,
    showTooManyTasksModal
  } = usePlan()
  const { show: showContextMenu } = useContextMenu()

  const dispatch = useDispatch()

  const projectLoadStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)
  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const teams = useSelector((state: any) => state.TeamsReducer.result)
  const programmation = useSelector((state: any) => state.ProgrammationReducer)
  const macro = useSelector((state: any) => state.MacroReducer)

  const [ shownTooManyRestrictionsModal, setShownTooManyRestrictionsModal ] = useState(!tooManyRestrictions)

  const { teamsMembersIds, me } = useMemo(() => {

    const users = TeamsHelper.getAllMembersFromProject(teams)
    const usersIds = users.map((user: any) => user._id)

    const me = users.find((user: any) => user._id === userData?.id)

    return { teamsMembers: users, teamsMembersIds: usersIds, me }
  }, [ teams ])

  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    'programmation',
    'macro'
  )

  const [ task, setTask ] = useState<TaskWithId | undefined>()
  const [ group, setGroup ] = useState({}) //TODO: use undefined instead of empty object
  const [ parentId, setParentId ] = useState('')

  const nonWorkingDays = useMemo(() => {

    if (!project._id) return []

    return getNonWorkingDays(project.startsAt, project.endsAt, project)
  }, [ project._id ])

  const [ showTaskCreator, setShowTaskCreator ] = useState(false)
  const [ showTaskEditor, setShowTaskEditor ] = useState(false)
  const [ showGroupEditor, setShowGroupEditor ] = useState(false)
  const [ showTaskFilters, setShowTaskFilters ] = useState(false)
  const [ showTasksImporter, setShowTasksImporter ] = useState(false)

  const savedFilters = localStorage.getItem(`${project_id}-macro-filters`)

  const [ filters, setFilters ] = useState(savedFilters ? JSON.parse(savedFilters) : {
    user: '',
    state: '',
    team: '',
    group: '',
    code: '',
    type: '',
    start: '',
    end: '',
  })

  const [ mySettings, setMySettings ] = useState(project?.mySettings)
  const [ showSidebar, setShowSidebar ] = useState(false)

  const [ showAddRestrictionModal, setShowAddRestrictionModal ] = useState(false)
  const [ restrictionTasks, setRestrictionTasks ] = useState<any[]>([])

  const [ showReScheduleModal, setShowReScheduleModal ] = useState(false)
  const [ reScheduleTasks, setReScheduleTasks ] = useState<any[]>([])

  const columns: GanttColumn[] = [
    {
      label: 'Tipo',
      field: 'type',
      width: 50,
      render: (field: any) => !field ? null : field.substring(0, 1).toUpperCase()
    },
    {
      label: 'Código',
      field: 'colorCodeName',
      width: 60,
    },
    {
      label: 'Id',
      field: 'position',
      width: 100,
    },
    {
      label: 'Título',
      field: 'name',
      width: 300,
    },
    {
      label: 'Estado de actividad',
      field: 'status',
      width: 100,
      render: (field: any) => getStatusName(field)
    },
    {
      label: 'Inicio programado',
      field: 'startsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: 'Fin programado',
      field: 'endsAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: 'Responsables',
      field: 'responsibles',
      width: 80,
      render: (field: any, item: any) => {

        const isField = !!field
        const isFieldArray = isField && Array.isArray(field)

        if (isField && isFieldArray) {

          const responsibles = field.filter((user: any) => teamsMembersIds.includes(user._id))

          return responsibles.map((user: any) => <UserAvatar key={user._id} user={user} size="xs" />)
        }

        return null
      }
    },
    {
      label: 'Inicio real',
      field: 'startedAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
    {
      label: 'Fin real',
      field: 'endedAt',
      width: 80,
      render: (field: any) => field ? moment(field).format('DD/MM/YYYY') : null
    },
  ]

  const loadProject = async () => {

    await dispatch(ProjectsActions.getRowAction(project_id))
  }

  const loadTeams = async () => {

    await dispatch(TeamsActions.getResultAction(project_id))
  }

  const loadProgrammation = async () => {

    await dispatch(loadProgrammationAction(project_id))
  }

  const loadMacro = async (withFilters?: any) => {

    if (programmation.loadStatus !== 'loaded') return

    const options = {
      ...programmation,
      list: JSON.parse(JSON.stringify(programmation.list)),
      tasks: JSON.parse(JSON.stringify(programmation.tasks)),
      filters
    }

    let macroResult = getMacroResult(options)

    const newFilters = withFilters || filters

    if (newFilters.team) {

      const teams_ids = newFilters.team.split(',')

      const teamsFound = teams.filter((team: any) => teams_ids.includes(team._id))

      const users_ids: string[] = []

      teamsFound.forEach((team: any) => {

        team.users.forEach((user: any) => {

          if (!users_ids.includes(user._id))
            users_ids.push(user._id)
        })
      })

      macroResult = filterByUsers(macroResult, users_ids)
    } else if (newFilters.user)
      macroResult = filterByUsers(macroResult, newFilters.user.split(','))

    if (newFilters.group) {

      macroResult = filterByGroups(macroResult, newFilters.group.split(','))
    }else if (newFilters.code)
      macroResult = filterByColorCodes(macroResult, newFilters.code.split(','))

    if (newFilters.type)
      macroResult = filterByTypes(macroResult, newFilters.type.split(','))

    if (newFilters.state)
      macroResult = filterByStatus(macroResult, newFilters.state.split(','))

    if (newFilters.start || newFilters.end)
      macroResult = filterByDate(macroResult, newFilters.start, newFilters.end)

    const collapsedGroups = getCollapsedGroups(project_id, 'macro')

    dispatch(setMacroAction(macroResult, project.startsAt, project.endsAt, collapsedGroups))
  }

  const loadColorCodes = async () => {

    await dispatch(ColorCodeActions.getResult(project._id))
  }

  const hasActiveFilters = () => {

    const values = Object.values(filters)
    const found = values.findIndex(f => f !== '')

    return !!(found >= 0)
  }

  const handleChangeFilters = (filters: any) => {

    localStorage.setItem(`${project_id}-macro-filters`, JSON.stringify(filters))

    loadMacro(filters)
    setFilters(filters)
  }

  const handleNewTaskButton = async () => {

    if (!macro.ganttItems.length) {

      const prompt = await Swal.fire({
        title: '¿Seguro que deseas continuar?',
        text: 'Al crear la primera tarea, se desactivará la modificación del calendario del proyecto.',
        icon: 'question',
        confirmButtonText: 'Crear una tarea',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        width: '400px',
      })

      if (!prompt.isConfirmed) return
    }

    setShowTaskCreator(true)
  }

  const onTaskCreated = async (task: any) => {

    // await loadMacro()

    // History.push(`/project/${project._id}/programmation-macro?task_id=${task._id}`)

    setShowTaskCreator(false)
  }

  const onTaskUpdated = async () => {

    History.push(`/project/${project._id}/programmation-macro`)

    setShowTaskEditor(false)
  }

  const handleToggleCollapse = async (group_id: string) => {

    const collapsedGroups = getCollapsedGroups(project_id, 'macro')

    if (collapsedGroups.includes(group_id)) {

      collapsedGroups.splice(collapsedGroups.indexOf(group_id), 1)
    } else {

      collapsedGroups.push(group_id)
    }

    saveCollapsedGroups(project_id, 'macro', collapsedGroups)

    dispatch(setMacroCollapsedGroupsAction(collapsedGroups))
  }

  const handleShowWorkflows = (groups: string[]) => {

    saveCollapsedGroups(project_id, 'macro', groups)

    dispatch(setMacroCollapsedGroupsAction(groups))
  }

  const onSaveGroup = async (group_id: string, name: string, parent_id: string) => {

    //TODO: bring creation/updation logic into group editor and rename it to onGroupSaved
    if (!group_id) {

      await dispatch(createGroupAction(project_id, { name, parent_id }))
    } else {

      await dispatch(updateGroupAction(project_id, { _id: group_id, name, parent_id }))
    }

    setGroup({})
    setParentId('')
  }

  const onConfirmOrRejectTask = async (task_id: string, action: 'confirm' | 'reject') => {

    await dispatch(TasksActions.confirmOrReject(task_id, action))

    setShowTaskEditor(false)
  }

  const onDeleteTask = async (task: any) => {

    await dispatch(TasksActions.remove(task, project._id))

    History.push(`/project/${project._id}/programmation-macro`)

    setShowTaskEditor(false)
  }

  const handleEditItem = async (item_id: string) => {

    const { item, parent } = getItemFromTaskList(macro.result, item_id)

    if (item.items) {

      setGroup(item || {})
      setParentId(parent?._id || '')
      setShowGroupEditor(true)
    } else {

      setTask(item)
      setGroup(parent || {})
      setParentId(parent?._id || '')
      setShowTaskEditor(true)

      History.push(`/project/${project._id}/programmation-macro?task_id=${item._id}`)
    }
  }

  const onDeleteGroup = async (group_id: string) => {

    await dispatch(deleteGroupAction(project_id, group_id))
  }

  const handleCloseTaskEditor = () => {

    History.push(`/project/${project._id}/programmation-macro`)

    setTask(undefined)
    setGroup({})
    setShowTaskEditor(false)
  }

  const handleUpdateSetting = async (key: string, value: any) => {

    let field = key

    if (field === 'dayWidth') {

      field = 'ganttMacroColumnSize'
    } else if (field === 'hideNonWorkingDays') {

      field = 'ganttMacroHideNonWorkingDays'
    } else if (field === 'showVerticalBorders') {

      field = 'ganttMacroShowVerticalBorders'
    } else if (field === 'columnsHidden') {

      field = 'ganttHideMacroFields'
    } else if (field === 'divisorPosition') {

      field = 'ganttMacroDivisorPosition'
    }

    await updateSettings({
      ...mySettings,
      [ field ]: value
    })
  }

  const updateSettings = async (settings = mySettings) => {

    let areDifferent = false

    for (const key in settings) {

      if (key !== 'setted' && settings[ key ] !== project.mySettings[ key ]) {

        areDifferent = true
      }
    }

    if (areDifferent) {

      await dispatch(ProjectsActions.updateSettings(project.mySettings._id, settings))
    }

    setMySettings(settings)
  }

  const toggleSidebar = (target?: any) => {

    if (!target) {

      if (showSidebar) updateSettings()

      setShowSidebar(!showSidebar)
    } else {

      if (sidebarRefBtn.current) {

        const isButtonClicked = sidebarRefBtn.current.contains(target)

        if (!isButtonClicked) {

          // trigger click because if not mySettings is not updated
          sidebarRefBtn.current.click()
        }
      }
    }
  }

  const handleTaskListImported = () => {

    loadProgrammation()

    setShowTasksImporter(false)
  }

  const handleUpdatePosition = async (action: any) => {

    if (me?.profile_tag === 'observer') return

    dispatch(moveItemAction(project_id, action))
  }

  const canUpdateSelectedItems = (selectedItems: any[]) => {

    const isPl4nner = ability.can('manage', 'all')
    const isAssistant = project.assistants?.includes(userData?.id)

    const isCollaboratorInEveryTask = selectedItems.every((item: any) => item.responsibles?.map((r: any) => r._id).includes(userData?.id))

    return isPl4nner || isAssistant || isCollaboratorInEveryTask
  }

  const canReScheduleSelectedItems = (selectedItems: any[]) => {

    if (!canUpdateSelectedItems(selectedItems)) return false

    return selectedItems.some((item: any) => item.completed) === false
  }

  const handleCompleteMany = async (selectedItems: any[]) => {

    if (!selectedItems.length) return

    const isPlural = selectedItems.length > 1

    const prompt = await Swal.fire({
      title: '¿Seguro que deseas continuar?',
      text: `Vas a completar ${selectedItems.length} tarea${isPlural ? 's' : ''}.`,
      icon: 'question',
      confirmButtonText: 'Completar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      width: '400px',
    })

    if (!prompt.isConfirmed) return

    const tasksIds = selectedItems.map((item: any) => item._id)

    await(dispatch(TasksActions.completeMany(tasksIds, project._id)))

    // loadMacro()
  }

  const handleAddRestrictionToMany = (selectedItems: any[]) => {

    if (!selectedItems.length) return

    setRestrictionTasks(selectedItems)
    setShowAddRestrictionModal(true)
  }

  const handleReScheduleMany = (selectedItems: any[]) => {

    if (!selectedItems.length) return

    setReScheduleTasks(selectedItems)
    setShowReScheduleModal(true)
  }

  const onRestrictionAdded = async () => {

      // loadMacro()
      setShowAddRestrictionModal(false)
  }

  const onReScheduledTasks = async () => {

    // loadMacro()
    setShowReScheduleModal(false)
  }

  const handleItemsRightClick = (selectedItems: any[]) => {

    if (!selectedItems.length) return

    let isGroup = false

    let childTasks: any[] = []

    if (selectedItems.length === 1 && selectedItems[0].schema === 'group') {

      isGroup = true

      const { group } = getGroupFromList(macro.result, selectedItems[0]._id, 0)

      if (!group) return

      childTasks = getGroupTasks(group.items)
    }

    const options: IContextMenuOption[] = []

    options.push({
      label: 'Completar',
      icon: <Icon name="check" fas fw />,
      action: () => {

        handleCompleteMany(!isGroup ? selectedItems : childTasks)
      },
      disabled: !canUpdateSelectedItems(!isGroup ? selectedItems : childTasks)
    })

    options.push({
      label: 'Reprogramar',
      icon: <Icon name="calendario" style={{ fontSize: 20, verticalAlign: -4 }} />,
      action: () => {

        handleReScheduleMany(!isGroup ? selectedItems : childTasks)
      },
      disabled: !canReScheduleSelectedItems(selectedItems)
    })

    options.push({
      label: 'Agregar Restricciones',
      icon: <Icon name="restricciones" style={{ fontSize: 20, verticalAlign: -4 }} />,
      action: () => {

        handleAddRestrictionToMany(!isGroup ? selectedItems : childTasks)
      },
      disabled: !canUpdateSelectedItems(!isGroup ? selectedItems : childTasks)
    })

    options.push({
      label: 'Agregar Responsables',
      icon: <Icon name="responsables" style={{ fontSize: 20, verticalAlign: -4 }} />,
      action: () => {

        console.log('edit')
      },
      // disabled: !canUpdateSelectedItems(selectedItems)
      disabled: true
    })

    options.push({
      label: 'Mover al grupo',
      icon: <Icon name="folder-open" fas fw />,
      action: () => {

        console.log('edit')
      },
      items: [
        {
          label: 'Grupo 1',
          action: () => {

            console.log('edit')
          },
          disabled: !canUpdateSelectedItems(selectedItems)
        },
        {
          label: 'Grupo 2',
          action: () => {

            console.log('edit')
          },
          disabled: !canUpdateSelectedItems(selectedItems)
        },
        {
          label: 'Grupo 3',
          action: () => {

            console.log('edit')
          },
          disabled: !canUpdateSelectedItems(selectedItems)
        },
      ],
      disabled: true
    })

    if (ability.can('delete', 'task')) {

        options.push({
          separator: true
        })

        options.push({
          label: 'Eliminar',
          icon: <Icon name="trash" fas fw />,
          action: () => {

            console.log('delete')
          },
          // disabled: !ability.can('delete', 'task')
          disabled: true
        })
    }

    if (options.length) {

      showContextMenu(options)
    }
  }

  useEffect(() => {

    if (projectLoadStatus === 'none') loadProject()

    if (projectLoadStatus === 'loaded') {

      loadTeams()
      loadColorCodes()

      setMySettings(project.mySettings)
    }
  }, [ projectLoadStatus ])

  useEffect(() => {

    if (projectLoadStatus === 'loaded') {

      if (project.ability) ability.update(project.ability)

      if (programmation.loadStatus === 'none') {

        loadProgrammation()
      }
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  useEffect(() => {

    if (programmation.loadStatus === 'loaded') {

      if (macro.loadStatus === 'none') {

        loadMacro()
      }
    }
  }, [ programmation.loadStatus, macro.loadStatus ])

  useEffect(() => {

    if (macro.loadStatus !== 'loaded') return
    if (macro.loadGanttItemsStatus !== 'none') return

    const options: IGetItemsListOptions = {
      itemsList: macro.result,
      collapsedGroups: macro.collapsedGroups,
      schema: 'macro',
      nonWorkingDays,
      stretchUpToday: mySettings?.stretchUpToday,
      showOnlyThisUserTasks: project.mySettings.showOnlyMyTasks ? userData?.id : undefined,
    }

    const ganttItems = getGanttItems(options);

    dispatch(setMacroGanttItems(ganttItems))
  }, [ macro.loadGanttItemsStatus, macro.result, mySettings ]);

  useEffect(() => {

    if (macro.result.length && task_id) {

      handleEditItem(task_id)
    }
  }, [ macro.result, task_id ])

  useEffect(() => {

    if (tooManyRestrictions) {

      setShownTooManyRestrictionsModal(false)

      showTooManyRestrictionsModal('Hay demasiadas restricciones en este proyecto', project, () => setShownTooManyRestrictionsModal(true))
    }

    if (tooManyTasks) {

      showTooManyTasksModal('Hay colaboradores con demasiadas tareas en este proyecto', project.user === userData?.id)
    }
  }, [ tooManyRestrictions, tooManyTasks ])

  useEffect(() => {

    const projectLoaded = projectLoadStatus === 'loaded'
    const programmationLoaded = programmation.loadStatus === 'loaded' || programmation.loadStatus === 'error'

    if (!projectLoaded || !programmationLoaded) {

      dispatch(InterfaceActions.showLoader())
    } else {

      dispatch(InterfaceActions.hideLoader())
    }
  }, [ projectLoadStatus, programmation.loadStatus ])

  return (
    <MainLayout
      project={project}
      avatar={userData?.avatar ? `${config.apiHost}/${userData?.avatar}` : ''}
      username={`${userData?.name} ${userData?.surname}`}
      menuBar={menu}
      rightTitle={project.shortName}
      subMenuBar={submenu}
    >
      <MainLayoutContentHeader>
        <div className="ul-widget__head-label flex-grow-1">
          <h3 className="ul-widget__head-title">
            Programación - Plan Macro
          </h3>
        </div>
        <div className="ul-widget__head-toolbar">
          <div className="text-right">
            <button className={`btn btn-link btn-link-header ${programmation.loadStatus === 'loading' ? 'active' : ''} d-inline-block`}
              onClick={() => loadProgrammation()}
              disabled={programmation.loadStatus === 'loading'}
            >
              <Icon name="sync" fas fw spin={macro.loadStatus === 'loading'} />
              <span className="d-none d-sm-inline"> Recargar</span>
            </button>
            <Can I="create" a="task" ability={ability}>
              <button className="btn btn-link btn-link-header"
                onClick={() => setShowTasksImporter(true)}
                disabled={macro.ganttItems.length > 0 || hasActiveFilters()}
              >
                <span className="icon-agregar_tarea"></span>
                <span className="d-none d-sm-inline"> Importar</span>
              </button>
              <button className="btn btn-link btn-link-header"
                onClick={() => handleNewTaskButton()}
              >
                <span className="icon-agregar_tarea"></span>
                <span className="d-none d-sm-inline"> Nueva tarea</span>
              </button>
            </Can>
            <Can I="create" a="task" ability={ability}>
              <button className="btn btn-link btn-link-header"
                onClick={() => setShowGroupEditor(true)}
              >
                <span className="icon-agregar_grupo_tareas"></span>
                <span className="d-none d-sm-inline"> Nuevo grupo</span>
              </button>
            </Can>
            <button className={`btn btn-link btn-link-header ${hasActiveFilters() ? 'active' : ''}`}
              onClick={() => setShowTaskFilters(true)}
            >
              <span className="icon-filtro"></span>
              <span className="d-none d-sm-inline"> Filtro</span>
            </button>
            <button className={`btn btn-link btn-link-header ${showSidebar ? 'active' : ''}`}
              ref={sidebarRefBtn}
              onClick={() => toggleSidebar()}
            >
              <span className="icon-configuracion"></span>
              <span className="d-none d-sm-inline"> Configuración</span>
            </button>
          </div>
        </div>
      </MainLayoutContentHeader>
      <MainLayoutContentBody>
        <Card className='table-card'>
          {project._id && (
            <Gantt
              schema="macro"
              start={project.startsAt}
              end={project.endsAt}
              items={macro.ganttItems}
              columns={columns}
              nonWorkingDays={nonWorkingDays}
              dayWidth={mySettings?.ganttMacroColumnSize || 40}
              rowHeight={32}
              maxRowHeight={32}
              hideNonWorkingDays={mySettings?.ganttMacroHideNonWorkingDays || false}
              showVerticalBorders={mySettings?.ganttMacroShowVerticalBorders || false}
              useCodeColors={mySettings?.useCodeColors || false}
              stretchUpToday={mySettings?.stretchUpToday || false}
              columnsHidden={mySettings?.ganttHideMacroFields || []}
              divisorPosition={mySettings?.ganttMacroDivisorPosition}
              showSidebar={showSidebar}
              onSelectItem={handleEditItem}
              onToggleCollapse={handleToggleCollapse}
              onShowWorkflows={(groups: string[]) => handleShowWorkflows(groups)}
              onClickOutside={toggleSidebar}
              onUpdateSetting={handleUpdateSetting}
              onUpdateOrder={handleUpdatePosition}
              sortEnabled={me?.profile_tag !== 'observer'}
              lineBaseTasks={mySettings?.lineBaseTasks }
              onItemsRightClick={handleItemsRightClick}
            />
          )}
        </Card>
      </MainLayoutContentBody>

      <TaskFilters
        show={showTaskFilters}
        type="macro"
        filters={filters}
        onChange={handleChangeFilters}
        onHide={() => setShowTaskFilters(false)}
      />

      <GroupEditor
        show={showGroupEditor}
        taskList={macro.result}
        group={group}
        parentId={parentId}
        onSubmit={(group_id: string, name: string, parent_id: string) => onSaveGroup(group_id, name, parent_id)}
        onDelete={(group_id: string) => onDeleteGroup(group_id)}
        onHide={() => {

          setShowGroupEditor(false)

          setGroup({})
          setParentId('')
        }}
      />

      <TaskCreator
        show={showTaskCreator}
        taskList={macro.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => setShowTaskCreator(false)}
        onCreated={onTaskCreated}
      />

      <TaskEditor
        show={showTaskEditor && (!tooManyRestrictions || shownTooManyRestrictionsModal)}
        task={task}
        group={group}
        taskList={macro.result}
        nonWorkingDays={nonWorkingDays}
        project={project}
        projectMembers={TeamsHelper.getAllMembersFromProject(teams)}
        projectStartDate={project.startsAt}
        projectEndDate={project.endsAt}
        onHide={() => handleCloseTaskEditor()}
        onUpdated={onTaskUpdated}
        onConfirmOrReject={onConfirmOrRejectTask}
        onDelete={(data: any) => onDeleteTask(data)}
        canConfirmOrReject={ability.can('confirm', 'task')}
        canDelete={ability.can('delete', 'task')}
        canUpdate={() => {

          const isPl4nner = ability.can('manage', 'all')
          const isAssistant = project.assistants?.includes(userData?.id)
          const isCollaborator = task?.responsibles.map((r: any) => r._id).includes(userData?.id)

          return isPl4nner || isAssistant || isCollaborator
        }}
      />

      <TasksImporter
        show={showTasksImporter}
        projectId={project._id}
        onHide={() => setShowTasksImporter(false)}
        onImported={handleTaskListImported}
      />

      <AddRestrictionToMany
        show={showAddRestrictionModal}
        tasks={restrictionTasks}
        onCancel={() => setShowAddRestrictionModal(false)}
        onSaved={() => onRestrictionAdded()}
      />

      <ReScheduleMany
        show={showReScheduleModal}
        tasks={reScheduleTasks}
        nonWorkingDays={nonWorkingDays}
        onCancel={() => {

          setReScheduleTasks([])
          setShowReScheduleModal(false)
        }}
        onSaved={() => onReScheduledTasks()}
      />
    </MainLayout>
  )
}

export default MacroPage
