
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import config from '../../config'
import navigation from '../../navigation'

import { useAuth } from '../../Hooks/UseAuth'

import GanttHelper from '../../Helpers/GanttHelper'

import InterfaceActions from '../../Redux/Actions/InterfaceActions'
import ProjectsActions from '../../Redux/Actions/ProjectsActions'
import TasksActions from '../../Redux/Actions/TasksActions'

import MainLayout from '../../Components/MainLayout/MainLayout'
import UserAvatar from '../../Components/UserAvatar/UserAvatar'
import LastChanges from '../../Components/LastChanges/LastChanges'

const ProjectTasksArchivePage = (props: any) => {

    const dispatch = useDispatch()

    const { userData } = useAuth()

    const project = useSelector((state: any) => state.ProjectsReducer.rowData)
    const tasksReducer = useSelector((state: any) => state.TasksReducer)

    const [ taskEditor, setTaskEditor ] = useState({
        show: false,
        task: {
            versions: []
        },
    })


    const[ showLastChanges, setShowLastChanges ] = useState({
        show: false,
        versions: [],
    })


    const [ taskList, setTaskList ] = useState([])

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'tasks',
        'archive'
    )

    const loadProject = async () => {

        dispatch(InterfaceActions.showLoader())

        await dispatch(ProjectsActions.getRowAction(props.match.params._id))
    }

    const loadArchive = async () => {

        await dispatch(TasksActions.getArchive(props.match.params._id))

        dispatch(InterfaceActions.hideLoader())
    }

    const getTasks = () => {

        const tasks: any = []

        tasksReducer.archive.map((_task: any) => {

            const task: any = GanttHelper.getMacroTaskItem({ task: _task}, false)

            task.versions = _task.versions

            tasks.push(task)
        })

        setTaskList(tasks)
    }

    const getRowStyles = (task: any) => {

        const styles: any = {}

        if (task._task.deleted) {

            // styles['backgroundColor'] = 'var(--gantt-day-sunday-border)'
            styles['color'] = 'var(--gantt-day-sunday-border)'
        }

        return styles
    }

    const getTitleStyles = (task: any) => {

        const styles: any = {}

        if (task._task.deleted) {

            styles['textDecoration'] = 'line-through'
        }

        return styles
    }


    const showDiffLastChanges = (task: any) => {

        setShowLastChanges({ ...showLastChanges, versions: task._task.versions, show: true })
    }

    useEffect(() => {

        if (!project._id) {

            loadProject()
        } else {

            loadArchive()
        }
    }, [ project ])

    useEffect(() => {

        getTasks()
    }, [ tasksReducer.archive ])

    return (
        <MainLayout
            username={`${userData?.name} ${userData?.surname}`}
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
            rightTitle={project.shortName}
            avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
        >
            <div className="row">
                <div className="col-12 pb-3">
                    <div className="page-content">
                        <div className="page-content-body">
                            <div className="ul-widget__head">
                                <div className="ul-widget__head-label flex-grow-1">
                                    <h3 className="ul-widget__head-title">
                                        Base de datos
                                    </h3>
                                </div>
                            </div>
                            <div className="ul-widget__body">
                                <Card className='table-card'>
                                    <div>
                                        <div className="table-responsive">
                                            <table className="table card-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ minWidth: 20 }}>#</th>
                                                        <th style={{ minWidth: 120 }}>Estado</th>
                                                        <th>Nombre</th>
                                                        <th style={{ minWidth: 360 }}>Responsables</th>
                                                        <th>Ultima actualización</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {taskList.map((task: any, i: number) => (
                                                        <tr key={`${task._id}${i}`} style={getRowStyles(task)}>
                                                            <td>
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                {task._task.deleted && 'Eliminada'}
                                                                {!task._task.deleted && (
                                                                    <span className={`text-color-${task._task.status}`}>
                                                                        {task.status}
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="archive-task-name"
                                                                    style={getTitleStyles(task)}
                                                                >
                                                                    {task.title} {task.task_type === 'hito' && '(Hito)'}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {task.responsibles.map((user: any, j: number) => (
                                                                    <UserAvatar key={j} user={user} size="sm" />
                                                                ))}
                                                            </td>
                                                            <td>
                                                                <button className={`btn btn-link btn-link-header`}
                                                                    onClick={() => showDiffLastChanges(task)}
                                                                >
                                                                    <span className="icon-filtro"></span>
                                                                    <span className="d-none d-md-inline"> {moment(task._task.updatedAt).format('DD/MM/YYYY h:mm a')}</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showLastChanges.show && (
                <LastChanges
                    show={showLastChanges.show}
                    versions={showLastChanges.versions}
                    onHide={() => setShowLastChanges({ ...showLastChanges, versions: [], show: false })}
                />
            )}
        </MainLayout>
    )
}

export default ProjectTasksArchivePage


