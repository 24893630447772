
import React, { useEffect, useMemo, useState } from 'react'
import { Col, FormGroup, FormLabel, Modal, Nav, Row, Tab } from 'react-bootstrap'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { ColorCode, Task, TaskTypes } from '../../Types/Tasks'

import { checkRelations } from '../../Helpers/TasksHelper'
import { getDiffDays } from '../../Helpers/DateFunctions'

import { usePlan } from '../../Hooks/UsePlan'

import TasksActions from '../../Redux/Actions/TasksActions'

import CheckList from '../CheckList/CheckList'
import ColorCodeSelector from '../ColorCodeSelector/ColorCodeSelector'
import GroupSelector from '../GroupSelector/GroupSelector'
import InputDateTime from '../InputDateTime/InputDateTime'
import InputFormControl from '../InputFormControl/InputFormControl'
import RelationsList from '../RelationsList/RelationsList'
import SelectFormControl from '../SelectFormControl/SelectFormControl'
import TaskResponsibleList from '../TaskResponsibleList/TaskResponsibleList'

import './TaskManager.scss'

type Props = {
    show: boolean,
    taskList?: any,
    nonWorkingDays: any[],
    project?: any,
    projectMembers: any,
    projectStartDate: string,
    projectEndDate: string,
    onCreated: (task: any) => void,
    onHide: any,
}

const TaskCreator = (props: Props) => {

    const dispatch = useDispatch()

    const { plan } = usePlan()

    const [ nameFocused, setNameFocused ] = useState(false)

    const [ name, setName ] = useState('')
    const [ groupId, setGroupId ] = useState('')
    const [ type, setType ] = useState('partida')
    const [ startDate, setStartDate ] = useState('')
    const [ startTime, setStartTime ] = useState(props.project.settings?.workTimeStart || '08:00')
    const [ endDate, setEndDate ] = useState('')
    const [ endTime, setEndTime ] = useState(props.project.settings?.workTimeEnd || '16:00')

    const executeDays = useMemo(() => {

        if (!startDate || !endDate) return 0

        return getDiffDays(startDate, endDate, props.nonWorkingDays)
    }, [ startDate, endDate ])

    const [ releaseDate, setReleaseDate ] = useState('')
    const [ colorCode, setColorCode ] = useState<ColorCode | undefined>()
    const [ showColorCodeSelector, setShowColorCodeSelector ] = useState(false)

    const [ responsibles, setResponsibles ] = useState<any[]>([])
    const [ restrictions, setRestrictions ] = useState<any[]>([])
    const [ relations, setRelations ] = useState<any[]>([])

    const [ taskUnit, setTaskUnit ] = useState('')
    const [ taskMetered, setTaskMetered ] = useState(0)
    const [ taskPerformance, setTaskPerformance ] = useState(0)
    const [ taskCrewNumber, setTaskCrewNumber ] = useState(0)
    const [ taskEstimatedDays, setTaskEstimatedDays ] = useState(0)

    const [ tab, setTab ] = useState('generals')

    const [ creating, setCreating ] = useState(false)

    const [ isCheckListFocused, setIsCheckListFocused ] = useState<boolean>(false);


    const handleChangeStart = (datetime: string) => {

        const datetimeObj = moment(datetime, 'YYYY-MM-DD HH:mm:ss');

        setStartDate(datetimeObj.format('YYYY-MM-DD'));
        setStartTime(datetimeObj.format('HH:mm:ss'));
        setReleaseDate(datetimeObj.format('YYYY-MM-DD'));

        handleChangeEnd(`${datetimeObj.format('YYYY-MM-DD')} ${endTime}`);
    }

    const handleChangeEnd = (datetime: string) => {

        const datetimeObj = moment(datetime, 'YYYY-MM-DD HH:mm:ss');

        setEndDate(datetimeObj.format('YYYY-MM-DD'))
        setEndTime(datetimeObj.format('HH:mm:ss'))
    }

    const handleClose = () => {
        unsetTask()
        setIsCheckListFocused(false);
        props.onHide()
    }

    const isValidTask = (): boolean => {

        if (name === '') {

            alert('Ingresa el nombre de la tarea')
            return false
        }

        const taskStartsBeforeProjectStarts = moment(startDate).isBefore(props.projectStartDate,'day')
        const taskEndsAfterProjectEnds = moment(endDate).isAfter(props.projectEndDate, 'day')

        if (taskStartsBeforeProjectStarts || taskEndsAfterProjectEnds) {
            alert('El proyecto no acepta ese rango de fechas.')
            return false
        }

        if (!checkRelations(relations, 'new')) {

            alert('Todas las relaciones deben ser con esta tarea.')
            return false
        }

        if (type === 'hito' || type === 'reunion') {

            if (startDate === '') {

                type === 'hito' && alert('Ingresa la fecha del hito correctamente.')
                type === 'reunion' && alert('Ingresa la fecha de la reunión correctamente.')
                return false
            }
        } else {

            if (startDate === '' || endDate === '') {

                alert('Ingresa las fechas correctamente.')
                return false
            }
        }

        return true
    }

    const handleCreateTask = async () => {

        if (!isValidTask()) return

        const startsAt = `${startDate} ${startTime}`
        const endsAt = `${endDate} ${endTime}`

        const task: Task = {
            name,
            startsAt,
            endsAt,
            groupId,
            type,
            responsibles: responsibles.map((res: any) => res._id),
            restrictions,
            relations,
            releasesAt: releaseDate,
            executeDays,
            colorCodeId: colorCode?._id,
            unit: taskUnit,
            metered: taskMetered,
            performance: taskPerformance,
            crewNumber: taskCrewNumber,
            estimatedDays: taskEstimatedDays
        }

        task.project_id = props.project?._id

        setCreating(true)

        try {

            const newTask = await dispatch(TasksActions.create(task))

            unsetTask()

            props.onCreated(newTask)
        } catch (error) {

            //TODO: show error message to user
        }

        setCreating(false)
        setIsCheckListFocused(false);

    }

    const unsetTask = () => {

        setName('')
        setNameFocused(false)
        setGroupId('')
        setType('partida')
        setStartDate('')
        setEndDate('')
        setReleaseDate('')
        setColorCode(undefined)
        setResponsibles([])
    }


    useEffect(() => {

        if (taskMetered && taskPerformance && taskCrewNumber) {

            let estimatedDays = taskMetered / (taskPerformance * taskCrewNumber)

            if (parseInt(`${estimatedDays}`, 10) !== estimatedDays) {

                estimatedDays = (parseInt(`${estimatedDays}`, 10) + 1) || 0
            }

            setTaskEstimatedDays(estimatedDays)
        } else {

            setTaskEstimatedDays(0)
        }
    }, [ taskMetered, taskPerformance, taskCrewNumber ])


    const handleCheckListFocus = (): void => {
        setIsCheckListFocused(true);
    };

    return (
        <>
            <Modal
                show={props.show && !showColorCodeSelector}
                autoFocus
                onEntered={() => setNameFocused(true)}
                onHide={isCheckListFocused ? undefined : handleClose}
                dialogClassName="modal-task-editor"
                backdrop="static"
                keyboard={true}
            >
                <Modal.Body>
                    <div onKeyDown={(e) => e.keyCode === 13 && handleCreateTask()}>
                        <Tab.Container defaultActiveKey='generals' >
                            <div className="ul-widget__head">
                                <div className="ul-widget__head-label flex-grow-1">
                                    <h4 className="ul-widget__head-title">Crear nueva tarea</h4>
                                </div>
                                <div className="ul-widget__head-nav">
                                    <Nav variant='tabs' className='nav-status-completada'
                                        activeKey={tab}
                                        defaultActiveKey='relations'
                                        onSelect={(k) => setTab(k || '')}
                                    >
                                        <Nav.Item>
                                            <Nav.Link eventKey='generals'>
                                                <span className="fas fa-home"></span> General
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey='restrictions' disabled={type === 'hito' || type === 'reunion'}
                                                style={{
                                                    color: type === 'hito' || type === 'reunion' ? '#ccc' : '#000',
                                                    cursor: type === 'hito' || type === 'reunion' ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                <span className="icon-restricciones"></span> Restricciones
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey='relations'>
                                                <span className="icon-relaciones"></span> Relaciones
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey='responsibles' disabled={type === 'hito' || type === 'reunion'}
                                                style={{
                                                    color: type === 'hito' || type === 'reunion' ? '#ccc' : '#000',
                                                    cursor: type === 'hito' || type === 'reunion' ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                <span className="icon-responsables"></span> Responsables
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey='metered' disabled={type === 'hito' || type === 'reunion'}
                                                style={{
                                                    color: type === 'hito' || type === 'reunion' ? '#ccc' : '#000',
                                                    cursor: type === 'hito' || type === 'reunion' ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                <span className="icon-metrados"></span> Metrado
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="ul-widget__status-bar status-completada"></div>
                            <div className="ul-widget__body" >

                                <Tab.Content
                                >
                                    <Tab.Pane eventKey='generals'
                                    >

                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Nombre
                                            </FormLabel>
                                            <Col sm={7}>
                                                <InputFormControl
                                                    autoFocus={!nameFocused}
                                                    placeholder="Ingresa el nombre de la tarea"
                                                    value={name}
                                                    onChange={setName}
                                                    onBlur={() => setNameFocused(true)}
                                                    readOnly={creating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Grupo
                                            </FormLabel>
                                            <Col sm={3}>
                                                <GroupSelector
                                                    value={groupId}
                                                    onChange={setGroupId}
                                                    taskList={props.taskList}
                                                    disabled={creating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Tipo
                                            </FormLabel>
                                            <Col sm={3}>
                                                <SelectFormControl
                                                    value={type}
                                                    onChange={setType}
                                                    disabled={creating}
                                                >
                                                    <option value="">Seleccione</option>
                                                    {Object.keys(TaskTypes).map((type: string, i: number) => (
                                                        <option key={i} value={type}>
                                                            {Object.values(TaskTypes)[ i ]}
                                                        </option>
                                                    ))}
                                                </SelectFormControl>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                {type === 'hito' && 'Fecha de hito'}
                                                {type === 'reunion' && 'Fecha de reunión'}
                                                {type !== 'hito' && type !== 'reunion' && 'Fecha de inicio'}
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputDateTime
                                                    date={startDate}
                                                    time={startTime}
                                                    onChange={handleChangeStart}
                                                    readOnly={creating}

                                                />
                                            </Col>
                                        </FormGroup>
                                        {(type !== 'hito' && type !== 'reunion') && (
                                            <>
                                                <FormGroup as={Row}>
                                                    <FormLabel className='col-sm-3'>
                                                        Fecha de fin
                                                    </FormLabel>
                                                    <Col sm={3}>
                                                        <InputDateTime
                                                            date={endDate}
                                                            time={endTime}
                                                            onChange={handleChangeEnd}
                                                            readOnly={creating}

                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup as={Row}>
                                                    <FormLabel className='col-sm-3'>
                                                        Días de ejecución
                                                    </FormLabel>
                                                    <Col sm={2}>
                                                        <InputFormControl
                                                            type="number"
                                                            value={executeDays}
                                                            // onChange={() => null}
                                                            readOnly
                                                            onEnter={() => handleCreateTask()}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup as={Row}>
                                                    <FormLabel className='col-sm-3'>
                                                        Fecha de liberación
                                                    </FormLabel>
                                                    <Col sm={3}>
                                                        <InputFormControl
                                                            type="date"
                                                            value={releaseDate}
                                                            onChange={setReleaseDate}
                                                            readOnly={creating}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup as={Row}>
                                                    <FormLabel className='col-sm-3'>
                                                        Código de color
                                                    </FormLabel>
                                                    <Col sm={3}>
                                                        <button
                                                            className="color-code-toggle"
                                                            onClick={() => setShowColorCodeSelector(true)}
                                                        >
                                                            {colorCode && (
                                                                <>
                                                                    <span className="code">{colorCode?.name}</span>
                                                                    <span className="color" style={{ backgroundColor: colorCode?.color }}></span>
                                                                </>
                                                            )}
                                                            {!colorCode && 'Seleccionar color'}
                                                        </button>
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        )}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey='restrictions'>
                                        <Row>
                                            <Col sm={7}>
                                                <div onFocus={handleCheckListFocus}>
                                                    <CheckList
                                                        addText="Añadir restricción"
                                                        items={restrictions}
                                                        onChange={setRestrictions}
                                                        canCreate={plan.can('add', 'Restriction')}
                                                    /></div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='relations'>
                                        <RelationsList
                                            taskList={props.taskList?.list || []}
                                            items={relations}
                                            task={{
                                                groupId,
                                                name,
                                            }}
                                            onChange={setRelations}
                                            isNewTask
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='responsibles'>
                                        <Row>
                                            <Col sm={7}>
                                                <TaskResponsibleList
                                                    responsibles={responsibles}
                                                    projectMembers={props.projectMembers}
                                                    onChange={setResponsibles}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='metered'>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Unidad
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputFormControl
                                                    value={taskUnit}
                                                    onChange={setTaskUnit}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Metrado
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputFormControl
                                                    value={taskMetered > 0 ? taskMetered : ''}
                                                    onChange={setTaskMetered}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Rendimiento
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputFormControl
                                                    value={taskPerformance > 0 ? taskPerformance : ''}
                                                    onChange={setTaskPerformance}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                N° de cuadrilla
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputFormControl
                                                    value={taskCrewNumber > 0 ? taskCrewNumber : ''}
                                                    onChange={setTaskCrewNumber}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <FormLabel className='col-sm-3'>
                                                Tiempo estimado (días)
                                            </FormLabel>
                                            <Col sm={3}>
                                                <InputFormControl
                                                    value={taskEstimatedDays > 0 ? taskEstimatedDays : '-'}
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Tab.Pane>
                                </Tab.Content></div>

                        </Tab.Container>
                    </div></Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary"
                        onClick={handleClose}
                    >
                        <span className="icon-cancelar"></span> Cancelar
                    </button>

                    <button className="btn btn-primary"

                        onClick={() => handleCreateTask()}


                        disabled={creating}
                    >
                        <span className="icon-guardar" ></span> {creating ? 'Creando...' : 'Crear'}
                    </button>

                </Modal.Footer>
            </Modal>

            <ColorCodeSelector
                show={showColorCodeSelector}
                onHide={() => setShowColorCodeSelector(false)}
                projectId={props.project?._id}
                // codes={colorsCodes}
                code={colorCode}
                onSelect={(code: ColorCode) => {

                    setColorCode(code)
                    setShowColorCodeSelector(false)
                }}
            />
        </>
    )
}

export default TaskCreator
