import React, { useState, useRef, useEffect } from 'react';

import { getRowClassName, getTableWidth } from './GanttHelper';

import './Scss/GanttTableBodyRow.scss';

type Props = {
  index: number;
  item: any;
  columns: any;
  isSelected: boolean;
  onClick: (item: any, isItRight: boolean) => void;
  schema: string;
  rowHeight: number;
  children?: JSX.Element | React.ReactNode;
  onDragStart: (e: React.DragEvent, index: number) => any;
  onDrop: (e: React.DragEvent, index: number) => any;
  onDragOver: (e: React.DragEvent) => any;
  onMouseOver: () => void
  onMouseOut: () => void
  sortEnabled: boolean;
};

const GanttTableBodyRow = (props: Props) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const { schema, sortEnabled, isSelected } = props;

  const [isDragging, setIsDragging] = useState(false);

  const getItemHeight = (item: any) => {

    const isExpendedGroup = item.schema === 'group' && !item.isCollapsed
    const isMeeting = item.schema === 'task' && item.type === 'reunion'
    const isMilestone = item.schema === 'task' && item.type === 'hito'

    if (isExpendedGroup || isMeeting || isMilestone)
      return 32

    return props.rowHeight
  }

  const handleDragStart = schema !== "macro" ? undefined : props.onDragStart;

  const handleDragEnd = () => {
    if (schema === "macro") {
      setIsDragging(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (schema === "macro") {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e: any) => {
    setIsDragging(false)
  }

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    props.onClick(props.item, false)
  }

  const handleRightClick = (e: any) => {
    e.preventDefault();

    props.onClick(props.item, true)
  }

  return (
    <div
      ref={rowRef}
      className={`${getRowClassName(props.isSelected, props.item)} ${sortEnabled ? 'draggable' : ''} ${isDragging ? 'dragging-over' : ''}`}
      style={{
        width: getTableWidth(props.columns),
        height: getItemHeight(props.item),
        position: 'absolute',
        top: props.item.top,
      }}
      onClick={handleClick}
      draggable={sortEnabled}
      onDragStart={(e) => {
        setIsDragging(true);
        if (handleDragStart && sortEnabled) {
          handleDragStart(e, props.index);
        }
      }}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={schema !== "macro" && props.sortEnabled ? undefined : (e) => props.onDrop(e, props.index)} // Pass the index of the row
      onMouseOver={() => props.onMouseOver()}
      onMouseOut={() => props.onMouseOut()}
      onContextMenu={handleRightClick}
    >
      {props.children}
    </div>
  );
};

export default GanttTableBodyRow;
