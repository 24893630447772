
import React from "react";

import { getAdminNavigation } from "../../admin-navigation";
import { Link } from "react-router-dom";

interface Props {
  menu?: string;
  submenu?: string;
  open?: boolean;
}

const AdminLayoutSidebar = ({ menu: menuStr, submenu: submenuStr, open }: Props) => {

  const { menu } = getAdminNavigation(menuStr, submenuStr);

  return (
    <div className="side-content-wrap">
      <div className={`sidebar-left ${open ? 'open' : ''}`}>
        <ul className="navigation-left">
          {menu.map((navItem: any, i: number) => (
            <li key={i} className={`nav-item ${navItem.active ? 'active': ''}`}>
              <Link to={navItem.path} className="nav-item-hold">
                {navItem.icon}
                <div>
                  {navItem.text}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AdminLayoutSidebar;
