
import React, { useEffect, useMemo, useRef, useState } from "react";

import { getSVGTextWidth } from "../../../GanttHelper";

interface IColorCodeTextProps {
  colorCodeName: string;
  itemLeft: number;
  itemTop: number;
  itemWidth: number;
  itemVerticalSize: number;
  opacity: number;
  isHovered: boolean
}

const ColorCodeText = (props: IColorCodeTextProps) => {

  const {
    colorCodeName,
    itemTop,
    itemLeft,
    itemWidth,
    itemVerticalSize,
    opacity,
    isHovered,
  } = props

  const colorRef: any = useRef();

  const [ colorWidth, setColorWidth ] = useState(0);
  const [ colorTop, setColorTop ] = useState(0);

  const [ truncatedName, setTruncatedName ] = useState('')

  const itemLeftCenter = itemLeft + (itemWidth / 2)

  const colorLeft = useMemo(() => {

    const colorHalf = colorWidth / 2

    const limitLeft = itemLeft + 6

    if (itemLeftCenter - colorHalf < limitLeft) {

      return limitLeft
    }

    return itemLeftCenter - colorHalf
  }, [ colorWidth, itemLeft, itemWidth ])

  const getFontSize = () => {

    return (itemVerticalSize > 2) ? '1.2rem' : '0.8rem'
  }

  useEffect(() => {

    if (!colorRef.current) return

    setColorTop((itemVerticalSize * 32) / 2 - 12)

    setColorWidth(colorRef.current.getComputedTextLength());

  }, [ colorRef.current, itemVerticalSize ])

  useEffect(() => {

    const style = {
      fontSize: getFontSize(),
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }

    const maxTextWidth = itemWidth - 12
    const ellipsisWidth = getSVGTextWidth('...', style)

    const truncateName = () => {

      let truncatedName = colorCodeName
      let truncatedWidth = getSVGTextWidth(truncatedName, style)

      let isBigger = truncatedWidth + ellipsisWidth > maxTextWidth

      while(isBigger) {

        truncatedName = truncatedName.slice(0, -1)
        truncatedWidth = getSVGTextWidth(truncatedName, style)

        isBigger = truncatedWidth + ellipsisWidth > maxTextWidth
      }

      return truncatedName
    }

    if (colorWidth > maxTextWidth && itemWidth > 0) {

      const newName = truncateName()
      setTruncatedName(newName + '...')
    } else {

      setTruncatedName(colorCodeName)
    }
  }, [ colorWidth, itemWidth, itemVerticalSize ])

  return (
    <>
      <text
        ref={colorRef}
        style={{
          fontSize: getFontSize(),
          fontWeight: 'bold',
          opacity: 0,
          textTransform: 'uppercase',
        }}
      >
        {colorCodeName}
      </text>
      <text
        className="grid-row-item-text color-name-text dark"
        x={0}
        y={0}
        style={{
          fontSize: getFontSize(),
          transform: `translate(${colorLeft}px, ${itemTop + 14 + colorTop}px)`,
          opacity,
        }}
      >
        {isHovered ? colorCodeName : truncatedName}
      </text>
    </>
  )
}

export default ColorCodeText;
