import moment from 'moment'
import React from 'react'

type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
}

const TaskReleaseDateNotification = (props: Props) => {

    const {
        task,
        project,
        who,
        type,
    } = props.data

    const date = moment(task.releasesAt).format('DD/MM/YYYY')
    const today = moment().format('DD/MM/YYYY')

    let content = (
        <span className='notification-content'>
            La fecha de liberación de su tarea asignada ${task.name} en el proyecto ${project.name}
            {date === today ? ` es hoy ${date}` : ` fue el ${date}`}.
        </span>
    )

    if (type === 'task_release_date_to_assistant') {

        content = (
            <span className='notification-content'>
                La fecha de liberación de la tarea{' '}
                <strong style={{ textTransform: 'uppercase' }}>
                    {task.name}
                </strong>{' '}
                {who.length > 0 && (
                    <>
                        asignada a{' '}
                        <strong key={who._id} style={{ textTransform: 'uppercase' }}>
                            {who[ 0 ].name} {who[ 0 ].surname}{' '}
                        </strong>
                    </>
                )}
                en el proyecto{' '}
                <strong style={{ textTransform: 'uppercase' }}>
                    {project.name}
                </strong>
                {date === today ? ` es hoy ${date}` : ` fue el ${date}`}.
            </span>
        )
    }

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>{props.data.title}</span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    {content}
                </p>
            </div>
        </div>
    )
}

export default TaskReleaseDateNotification