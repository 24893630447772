
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import history from '../../Helpers/History'

import { useSelector, useDispatch } from 'react-redux'
import SessionActions from '../../Redux/Actions/SessionActions'

import InputFormControl from '../../Components/InputFormControl/InputFormControl'
import TopLoader from '../../Components/TopLoader/TopLoader'

import Swal from 'sweetalert2'
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout'

const ForgotPage = (props: any) => {

    const requestRecoveryStatus = useSelector((state: any) => state.SessionReducer.requestRecoveryStatus)
    const dispatch = useDispatch()

    const [ email, setEmail ] = useState('')
    // const [ emailError, setEmailError ] = useState('')

    // let showSwal: boolean = false
    if (requestRecoveryStatus === 'requested') {

        Swal.fire({
            title: 'Solicitud enviada',
            text: 'Le hemos enviado un enlace a su correo electronico, sigalo para continuar.',
            icon: 'success',
            confirmButtonText: '¡Cerrar!',
            width: '500px',
            onClose: () => history.push('/login')
        })
    }

    const requestRecovery = () => {

        dispatch(SessionActions.requestRecoveryAction(email))
    }

    return (
        <AuthLayout>
            <div className="card 0-hidden">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="p-4">
                            <div className="logo text-center mb-4">
                                <img src="/images/logo.svg" alt=""/>
                            </div>
                            <div>
                                <h1 className="h1-title mb-1 text-14">
                                    Restablecer contraseña
                                </h1>

                                <div>
                                    <div className="form-group">
                                        <InputFormControl
                                            type="email"
                                            className="form-control"
                                            placeholder="E-mail"
                                            value={email}
                                            onChange={(value: string) => setEmail(value)}
                                            autoFocus
                                        />
                                        {/* <span id="errorUsername" className="error-message">{emailError}</span> */}
                                    </div>
                                    <button
                                        className="btn btn-block mt-2 btn-handle-recover"
                                        onClick={() => requestRecovery()}
                                    >
                                        Enviar
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    <Link to="/login" className="btn btn-light btn-block">
                                        <u>Iniciar sesión</u>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopLoader show={requestRecoveryStatus === 'requesting'} />
        </AuthLayout>
    )
}

export default ForgotPage
