import React, { useEffect, useState } from "react"
import { Nav, Tab } from "react-bootstrap"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"

import config from "../../config"
import navigation from "../../navigation"

import { useAuth } from "../../Hooks/UseAuth"

import ProjectsActions from "../../Redux/Actions/ProjectsActions"

import IndicatorsService from "../../Services/IndicatorsService"

import MainLayout from "../../Components/MainLayout/MainLayout"
import WeeklyLineCard from "../../Components/Charts/Echarts/WeeklyLineCard/WeeklyLineCard";
import TableWithPieCard from "../../Components/Charts/Echarts/TableWithPieCard/TableWithPieCard";
import BasicColumnCard from "../../Components/Charts/ApexCharts/BasicColumnCard/BasicColumnCard";

const ProjectIndicatorsCNCPage = (props: any) => {

  const dispatch = useDispatch()

  const { userData } = useAuth()

  const project = useSelector((state: any) => state.ProjectsReducer.rowData)
  const indicatorsReducer = useSelector((state: any) => state.IndicatorsReducer)

  const [ tab, setTab ] = useState('cnc')
  // TODO: add week navigator
  const [ week, setWeek ] = useState(4)
  const [ notCompleted, setNotCompleted ] = useState([])
  const [ weeklyCNCs, setWeeklyCNCs ] = useState([])
  const [ weeklyCNCGroups, setWeeklyCNCGroups ] = useState([])
  const [ menu, submenu ] = navigation.getNavigation(
    props.match.params._id,
    'indicators',
    'cnc'
  )

  const getWeekTitle = () => {

    if (!indicatorsReducer.progressPercentHistory.weeks.length) {

      return null
    }

    const weekNumber = indicatorsReducer.progressPercentHistory.weeks[indicatorsReducer.progressPercentHistory.weeks.length - 1]

    const week = moment().week(weekNumber)

    return (
        <span>
            Semana {indicatorsReducer.progressPercentHistory.weeks.length} - Del {week.startOf('week').format('DD/MM/YYYY')} al {week.endOf('week').format('DD/MM/YYYY')}
        </span>
    )
  }

  useEffect(() => {

    const loadProject = async () => {

      await dispatch(ProjectsActions.getRowAction(props.match.params._id))
    }

    loadProject()
  }, [])

  useEffect(() => {

    const getWeeksData = async () => {

      const { data } = await IndicatorsService.getCNCsHistory(project._id)

      setNotCompleted(data)
    }

    const getWeeklyCNCs = async () => {

      const { data } = await IndicatorsService.getWeeklyCNCs(project._id)

      setWeeklyCNCs(data)
    }

    const getGroupedCNCTypesByWeek = async () => {

      const { data } = await IndicatorsService.getGroupedCNCTypesByWeek(project._id)

      setWeeklyCNCGroups(data)
    }

    if (!project._id) return

    getWeeksData()
    getWeeklyCNCs()
    getGroupedCNCTypesByWeek()

  }, [project])

  return (
    <MainLayout
    username={`${userData?.name} ${userData?.surname}`}
    project={project}
    menuBar={menu}
    subMenuBar={submenu}
    rightTitle={project.shortName}
    avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
>
    <div className="row">
      <div className="col-12 pb-3">
        <div className="page-content">
          <div className="page-content-body">
            <div className="ul-widget__head">
              <div className='ul-widget__head-label flex-grow-1'>
                <h3 className='ul-widget__head-title'>
                  Dashboard
                  | <small style={{ fontSize: '70%' }}>
                    {getWeekTitle()}
                  </small>
                </h3>
              </div>
              <div className="ul-widget__head-nav">
                <Tab.Container>
                  <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                    <Nav.Item as="li">
                      <Nav.Link active={tab === 'cnc'} onClick={() => setTab('cnc')}>
                        <span className="icon-cnc_recurso"></span>
                        CNC
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </div>
            </div>
            <div className="ul-widget__body">
              <Tab.Content className="no-padding">
                <Tab.Pane active={tab === 'cnc'}>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <WeeklyLineCard
                        title='Tareas no completadas por semana'
                        tooltip={{
                          formatter: (items: any) => `Tareas no completadas: ${items[0].value}`,
                        }}
                        data={notCompleted}
                        styles={{
                          lineColor: '#663299',
                          itemColor: '#663299',
                          areaGradientColors: ['#66329990', '#fff'],
                          axisXFormatter: 'Sem {value}',
                          axisYFormatter: '{value}',
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <TableWithPieCard
                        title='Tipos de CNC por semana'
                        data={weeklyCNCs}
                        tableFields={[ 'CNC', 'Cantidad' ]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <BasicColumnCard
                        title='CNC por semana'
                        data={weeklyCNCGroups}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainLayout>
  )
}

export default ProjectIndicatorsCNCPage
