
export const CatchedError = (error: any) => {

    let message = ''

    if (error instanceof Error) {

        message = error.message
    }

    const err: any = Object.assign({}, error)

    if (err.response) {

        message = err.response.data.error
    }

    return {
        success: false,
        message,
    }
}
