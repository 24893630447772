
import config from "../config";
import { EMethods, request } from "./ApiService";

export const getApplicationSettings = async (): Promise<any> => {

  const url = config.apiHost + '/settings'

  try {

    const { data } = await request(EMethods.get, 'APPLICATION_GET_SETTINGS', url)

    return { data }
  } catch (error) {

    return { error }
  }
}
