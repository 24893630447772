
interface IState {
  loadDelayStatus: 'none' | 'loading' | 'loaded'
  delayData: {
    tasks: number
    estimatedTotal: number
  }
  loadProgressStatus: 'none' | 'loading' | 'loaded'
  progressData: any[]
  error: string
}

const initialState: IState = {
  loadDelayStatus: 'none',
  delayData: {
    tasks: 0,
    estimatedTotal: 0,
  },
  loadProgressStatus: 'none',
  progressData: [],
  error: '',
}

const DashboardReducer = (state: IState = initialState, { type, payload, error }: any) => {

  const st = { ...state }

  if (type === 'DASHBOARD_LOAD_NONE') {

    st.loadDelayStatus = 'none'
    st.loadProgressStatus = 'none'
  } else if (type === 'DASHBOARD_CLEANUP') {

    st.delayData = {
      tasks: 0,
      estimatedTotal: 0
    }
    st.progressData = []

    st.loadDelayStatus = 'none'
    st.loadProgressStatus = 'none'
  } else if (type === 'DASHBOARD_SET_DELAY_DATA') {

    st.delayData = payload.delayData
    st.loadDelayStatus = 'loaded'
  } else if (type === 'DASHBOARD_LOAD_PROGRESS_DATA') {

    st.loadProgressStatus = 'loading'
  } else if (type === 'DASHBOARD_SET_PROGRESS_DATA') {

    st.progressData = payload.progressData
    st.loadProgressStatus = 'loaded'
  }

  return st
}

export default DashboardReducer
