
import moment from "moment";
import React from "react";

import ChangeComments from "./ChangeComments";
import ChangeDate from "./ChangeDate";
import ChangeRestrictions from "./ChangeRestrictions";
import ChangeString from "./ChangeString";
import ChangeResponsibles from "./ChangeResponsibles";

import './LastChanges.scss'

type Props = {
  fieldName: string,
  fieldFrom: any,
  fieldTo: any,
  updatedAt: string,
}

const ChangeItem = ({ fieldName, fieldFrom, fieldTo, updatedAt }: Props) => {

  if (!fieldFrom && !fieldTo) {

    return null
  }

  return (
    <div className="ul-widget-s6__item">
      <span className="ul-widget-s6__badge">
        <p className="badge-dot-primary ul-widget6__dot"></p>
      </span>
      <span className="ul-widget-s6__text">
        {fieldName === 'name' && <ChangeString name="name" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'progress' && <ChangeString name="progress" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'type' && <ChangeString name="type" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'colorCode' && <ChangeString name="colorCode" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'executeDays' && <ChangeString name="executeDays" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'unit' && <ChangeString name="unit" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'metered' && <ChangeString name="metered" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'performance' && <ChangeString name="performance" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'crewNumber' && <ChangeString name="crewNumber" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'estimatedDays' && <ChangeString name="estimatedDays" from={fieldFrom} to={fieldTo} />}

        {fieldName === 'startsAt' && <ChangeDate name="startsAt" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'endsAt' && <ChangeDate name="endsAt" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'releasesAt' && <ChangeDate name="releasesAt" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'startedAt' && <ChangeDate name="startedAt" from={fieldFrom} to={fieldTo} />}
        {fieldName === 'endedAt' && <ChangeDate name="endedAt" from={fieldFrom} to={fieldTo} />}

        {fieldName === 'restrictions' && <ChangeRestrictions from={fieldFrom} to={fieldTo} />}
        {fieldName === 'responsibles' && <ChangeResponsibles from={fieldFrom} to={fieldTo} />}
        {fieldName == 'comments' && <ChangeComments from={fieldFrom} to={fieldTo} />}

      </span>
      <span className="ul-widget-s6__time">{moment(updatedAt).format('DD/MM/YYYY H:mm a')}</span>
    </div>
  );
}

export default ChangeItem
