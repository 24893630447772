
import React from 'react'
import SvgIcon from './SvgIcon'

interface Props {
  name: string
  far?: boolean
  fas?: boolean
  fw?: boolean
  spin?: boolean
  style?: any
  svg?: boolean
  width?: number
}

const Icon = (props: Props) => {

  if (props.fas || props.far) {

    const classes: any = [
      props.fas ? 'fas' : 'far',
      `fa-${props.name}`
    ]

    if (props.fw) {
      classes.push('fa-fw')
    }

    if (props.spin) {
      classes.push('fa-spin')
    }

    return (
      <i className={classes.join(' ')} style={props.style}></i>
    )
  }

  if (props.svg) {
    return <SvgIcon name={props.name} width={props.width} />
  }

  return (
    <span className={`icon-${props.name}`} style={props.style}></span>
  )
}

export default Icon
