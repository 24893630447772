
import { request, EMethods } from './ApiService'

import config from '../config'
import { CatchedError } from '../Helpers/HttpHelper'

const getResult = async (project: string) => {

    const url = config.apiHost + '/teams?project=' + project

    try {

        const response = await request(EMethods.get, 'TEAMS_GET_RESULT', url)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const createRow = async (name: string, project: string) => {

    const url = config.apiHost + '/teams'

    const data = {
        name,
        project
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_CREATE_ROW', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const updateRow = async (name: string, team_id: string) => {

    const url = config.apiHost + '/teams/' + team_id

    const data = {
        name
    }

    try {

        const response = await request(EMethods.put, 'TEAMS_CREATE_ROW', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}


const deleteRow = async (team_id: string) => {

    const url = config.apiHost + '/teams/' + team_id

    try {

        const response = await request(EMethods.delete, 'TEAMS_DELETE_ROW', url)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const addMe = async (team_id: string) => {

    const url = `${config.apiHost}/teams/add-me`

    try {

        const res = await request(EMethods.post, 'TEAMS_ADD_ME', url, { team_id })

        return {
            success: true,
            data: res.data
        }
    } catch (error) {

        return CatchedError(error)
    }
}

const mailInviteUser = async (email: string, team_id: string) => {

    const url = config.apiHost + '/teams/mail-invite-user'

    const data = {
        email,
        team_id,
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_MAIL_INVITE_USER', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const inviteUser = async (user_id: string, team_id: string) => {

    const url = config.apiHost + '/teams/invite-user'

    const data = {
        team_id,
        user_id
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_INVITE_USER', url, data)

        return {
            success: true,
            data: response.data
        }
    } catch (error) {

        //todo: remove try catch

        return CatchedError(error)
    }
}

const acceptInvite = async (invite_id: string) => {

    const url = config.apiHost + '/teams/accept-invite'

    const data = {
        invite_id
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_ACCEPT_INVITE', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const rejectInvite = async (invite_id: string) => {

    const url = config.apiHost + '/teams/reject-invite'

    const data = {
        invite_id
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_REJECT_INVITE', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const removeUser = async (user_id: string, team_id: string) => {

    const url = config.apiHost + '/teams/remove-user'

    const data = {
        team_id,
        user_id
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_REMOVE_USER', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

const moveUser = async (user_id: string, from_team: string, to_team: string) => {

    const url = config.apiHost + '/teams/move-user'

    const data = {
        user_id,
        from_team,
        to_team,
    }

    try {

        const res = await request(EMethods.post, 'TEAMS_MOVE_USER', url, data)

        return res.data
    } catch (error) {

        throw error
    }
}


const hideTasks = async (project_id: string, user_id: string, showOnlyMyTasks: boolean) => {

    const url = config.apiHost + '/user-project-settings/hide-tasks'

    const data = {
        user_id,
        project_id,
        showOnlyMyTasks
    }

    try {

        const res = await request(EMethods.put, 'TEAMS_HIDE_TASKS', url, data)

        return res.data
    } catch (error) {

        throw error
    }
}

const removeUserFromAllTeams = async (user_id: string, project_id: string) => {

    const url = config.apiHost + '/teams/remove-user-from-all-teams'

    const data = {
        user_id,
        project_id
    }

    try {

        const response = await request(EMethods.post, 'TEAMS_REMOVE_USER_FROM_ALL_TEAMS', url, data)

        return response.data
    } catch (error) {
        throw error
    }
}


export default {
    getResult,
    createRow,
    updateRow,
    deleteRow,
    addMe,
    mailInviteUser,
    inviteUser,
    acceptInvite,
    rejectInvite,
    removeUser,
    moveUser,
    hideTasks,
    removeUserFromAllTeams,
}
