
import { get, post } from './ApiService'

import config from '../config'

export const loadProgrammation = async (project_id: string) => {

  const url = `${config.apiHost}/programmation/${project_id}`

  return await get(url, 'GET_PROGRAMMATION')
}

export const loadTasklist = async (project_id: string) => {

  const url = `${config.apiHost}/programmation/${project_id}/tasklist`

  return await get(url, 'GET_PROGRAMMATION_TASKLIST')
}

export const loadTasks = async (project_id: string) => {

  const url = `${config.apiHost}/programmation/${project_id}/tasks`

  return await get(url, 'GET_PROGRAMMATION_TASKS')
}

export const loadUsers = async (project_id: string, users_ids: string[]) => {

  const url = `${config.apiHost}/programmation/${project_id}/users`

  const data = {
    users_ids
  }

  return await post(url, data, 'GET_PROGRAMMATION_USERS')
}

export const loadColorCodes = async (project_id: string, color_codes_ids: string[]) => {

  const url = `${config.apiHost}/programmation/${project_id}/colorcodes`

  const data = {
    color_codes_ids
  }

  return await post(url, data, 'GET_PROGRAMMATION_COLORCODES')
}

export const loadRelations = async (project_id: string, tasks_ids: string[]) => {

  const url = `${config.apiHost}/programmation/${project_id}/relations`

  const data = {
    tasks_ids
  }

  return await post(url, data, 'GET_PROGRAMMATION_RELATIONS')
}

export const loadVersions = async (project_id: string, tasks_ids: string[]) => {

  const url = `${config.apiHost}/programmation/${project_id}/versions`

  const data = {
    tasks_ids
  }

  return await post(url, data, 'GET_PROGRAMMATION_VERSIONS')
}
