
import React from 'react'

const SplashPage = () => {

  return (
    <div className="background">
      <div className="auth-layout-wrap">
        <div className="auth-content">
          <div className='card 0-hidden'>
            <div className="row">
              <div className="col-12 pt-4 pl-5 pr-5 pb-1">
                <img src="/images/logo.svg" width="320" alt="" />
              </div>
              <div className="col-12 pt-1 pl-5 pr-5 pb-4" style={{
                textAlign: 'center'
              }}>
                <div className="spinner spinner-primary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashPage

