import {EMethods, request} from './ApiService'

import config from '../config'

const getResult = async (project_id: string) => {

    const url = config.apiHost + '/project-cnc/' + project_id

    const response = await request(EMethods.get, 'PROJECT_CNC_LOAD_RESULT', url)

    return response.data
}

const createRow = async (row: any) => {

    const url = config.apiHost + '/project-cnc'

    const response = await request(EMethods.post, 'PROJECT_CNC_CREATE_ROW', url, row)

    return response.data
}

const updateRow = async (project_id: string, project_cnc_id: string, row: any) => {

    const url = config.apiHost + '/project-cnc/' + project_id + '/' + project_cnc_id

    const response = await request(EMethods.put, 'PROJECT_CNC_TOGGLE_ROW', url, row)

    return response.data
}

export default {
    getResult,
    createRow,
    updateRow,
}