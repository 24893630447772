
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { removeRestrictionAction, updateRestrictionAction } from "../../Redux/Actions/TasksActions";

import Icon from "../Icon/Icon";

interface IProps {
  show: boolean
  group: any[]
  openOrClosed: string
  onHide: () => void
}

const RestrictionsGroup = (props: IProps) => {

  const dispatch = useDispatch()

  const [ items, setItems ] = useState<any[]>([])
  const [ updating, setUpdating ] = useState<string[]>([])
  const[ deleting, setDeleting ] = useState<string[]>([])

  const handleUpdate = async (item: any) => {

    if (!updating.includes(item._id)) {

      setUpdating([ ...updating, item._id ])
    }

    const check = props.openOrClosed === 'ABIERTAS'

    await dispatch(updateRestrictionAction(item._id, check))

    const newItems = items.filter((i: any) => i._id !== item._id)

    setItems(newItems)
  }

  const handleDelete = async (item: any) => {

    if (!deleting.includes(item._id)) {

      setDeleting([ ...deleting, item._id ])
    }

    await dispatch(removeRestrictionAction(item._id))

    const newItems = items.filter((i: any) => i._id !== item._id)

    setItems(newItems)
  }

  const handleClose = () => {

    setUpdating([])

    props.onHide()
  }

  useEffect(() => {

    setItems(props.group)
  }, [ props.group ])

  useEffect(() => {

    if (!items.length) {

      handleClose()
    }
  }, [ items.length ])

  return (
    <Modal
      show={props.show}
      dialogClassName="modal-task-editor"
    >
      <Modal.Header>
        <Modal.Title>
          Restricciones grupales - {props.group[0]?.description}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Tarea / Creado por</th>
              <th style={{ width: 100 }}>Fec. Identif.</th>
              <th style={{ width: 120 }}>Fec. Liberac.</th>
              <th></th>
              <th style={{ width: 240 }}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any) => (
              <tr key={item._id}>
                <td>{item.taskId}</td>
                <td>
                  <strong>{item.taskName}</strong>
                  <div>
                    <small>{item.creator}</small>
                  </div>
                </td>
                <td>
                  {item.createdAt && moment(item.createdAt).format('DD/MM/YYYY')}
                </td>
                <td>
                  {item.taskReleasesAt && moment(item.taskReleasesAt).format('DD/MM/YYYY')}
                </td>
                <td>
                </td>
                <td>
                  <button className="btn btn-normal mr-1"
                    onClick={() => handleUpdate(item)}
                  >
                    {props.openOrClosed === 'ABIERTAS' && (
                      <>
                        <Icon
                          name={!updating.includes(item._id) ? 'check' : 'spinner'}
                          spin={updating.includes(item._id)}
                          fas
                          fw
                        /> Marcar
                      </>
                    )}
                    {props.openOrClosed === 'CERRADAS' && (
                      <>
                        <Icon
                          name={!updating.includes(item._id) ? 'times' : 'spinner'}
                          spin={updating.includes(item._id)}
                          fas
                          fw
                        /> Desmarcar
                      </>
                    )}
                  </button>
                  <button className="btn btn-normal mr-1"
                    onClick={() => handleDelete(item)}
                  >
                    <Icon
                      name={!deleting.includes(item._id) ? 'trash' : 'spinner'}
                      spin={deleting.includes(item._id)}
                      fas
                      fw
                    /> Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary mr-1"
          onClick={() => handleClose()}
        >
          <span className="icon-cancelar"></span> Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default RestrictionsGroup
