import { TEAMS_ACTION_TYPES } from "../Actions/TeamsActions"

const initialState = {
    // loadResultStatus: 'none',
    loadRowStatus: 'none',
    createRowStatus: 'none',
    updateRowStatus: 'none',
    deleteRowStatus: 'none',
    inviteUserStatus: 'none',
    addUserStatus: 'none',
    removeUserStatus: 'none',
    result: null,
    row: {}
}

const TeamsReducer = (state = initialState, action: any) => {

    const st = Object.assign({}, state)

    if (action.type === TEAMS_ACTION_TYPES.CLEAR_DATA) {

        st.result = null
        st.row = {}
    } else if (action.type === 'TEAMS_GET_RESULT_START') {

        // st.loadResultStatus = 'loading'
    } else if (action.type === TEAMS_ACTION_TYPES.SET_RESULT) {

        // st.loadResultStatus = 'loaded'
        st.result = action.content.data
    } else if (action.type === 'TEAMS_GET_RESULT_ERROR') {

        // st.loadResultStatus = action.content.error
    } else if (action.type === 'TEAMS_CREATE_ROW_START') {

        st.createRowStatus = 'creating'
    } else if (action.type === 'TEAMS_CREATE_ROW_END') {

        st.createRowStatus = 'created'
    } else if (action.type === 'TEAMS_CREATE_ROW_ERROR') {

        st.deleteRowStatus = action.content.error
    } else if (action.type === 'TEAMS_DELETE_ROW_START') {

        st.deleteRowStatus = 'deleting'
    } else if (action.type === 'TEAMS_DELETE_ROW_END') {

        st.deleteRowStatus = 'deleted'
    } else if (action.type === 'TEAMS_DELETE_ROW_ERROR') {

        st.deleteRowStatus = action.content.error
    } else if (action.type === 'TEAMS_INVITE_USER_START') {

        st.inviteUserStatus = 'removing'
    } else if (action.type === 'TEAMS_INVITE_USER_END') {

        st.inviteUserStatus = 'removed'
    } else if (action.type === 'TEAMS_INVITE_USER_ERROR') {

        st.inviteUserStatus = action.content.error
    } else if (action.type === 'TEAMS_ADD_USER_START') {

        st.addUserStatus = 'adding'
    } else if (action.type === 'TEAMS_ADD_USER_END') {

        st.addUserStatus = 'added'
    } else if (action.type === 'TEAMS_ADD_USER_ERROR') {

        st.addUserStatus = action.content.error
    } else if (action.type === 'TEAMS_REMOVE_USER_START') {

        st.removeUserStatus = 'removing'
    } else if (action.type === 'TEAMS_REMOVE_USER_END') {

        st.removeUserStatus = 'removed'
    } else if (action.type === 'TEAMS_REMOVE_USER_ERROR') {

        st.removeUserStatus = action.content.error
    }

    return st
}

export default TeamsReducer
