
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import Reducers from './Reducers/Reducers'
// import logger from 'redux-logger'

export default createStore(Reducers, applyMiddleware(
  thunk,
  // logger
))
