import React from 'react';
import { Alert } from 'react-bootstrap';

import { useChat } from '../../../Hooks/UseChat';

import ChatList from '../ChatList/ChatList';
import ChatProjectsTree from '../ChatProjectsTree/ChatProjectsTree';

import './ChatSidebar.scss';

interface Props {
  onSelectChat?: () => void;
}

const ChatSidebar: React.FC<Props> = ({ onSelectChat }: Props) => {
  const { connected, lastChats, joinChat } = useChat();


  const handleSelectChat = (chat_id: string) => {

    joinChat(['', chat_id]);

    if (onSelectChat) onSelectChat();
  }

  return (
    <div className='x-chat-sidebar'>
      <div className="x-chat-sidebar-body custom-scrollbar">
        {!connected && (
          <Alert variant="warning">You are not connected to the chat server.</Alert>
        )}
        <ChatList
          chats={lastChats}
          onSelectChat={(chat: any) => handleSelectChat(chat._id)}
        />
      </div>
      <ChatProjectsTree onSelectItem={() => {
        if (onSelectChat) onSelectChat();
      }} />
    </div>
  );
};

export default ChatSidebar;