
import React from "react";

type Props = {
  left: number;
  top: number;
}

const GanttChartBodyMeeting = (props: Props) => {

  const left = props.left + 4
  const top = props.top + 6

  return (
    <g className="gantt-chart-item-meeting">
      <rect
        className="grid-row-item-meeting"
        width="18"
        height="18"
        x="1"
        y="0"
        rx="3"
        ry="3"
        transform={`translate(${left + 2},${top})`}
      />
    </g>
  )
}

export default GanttChartBodyMeeting
