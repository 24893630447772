
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'
import _ from 'lodash'

import config from './config'
import NotificationsService from './Services/NotificationsService'

import NotificationsActions from './Redux/Actions/NotificationsActions'
import { getApplicationSettingsAction } from './Redux/Actions/ApplicationActions'

import { useAuth } from './Hooks/UseAuth'

import TermsAndPoliciesRequester from './Components/TermsAndPoliciesRequester/TermsAndPoliciesRequester'

import SplashPage from './Pages/SplashPage/SplashPage'

import AppRouter from './AppRouter'

import TopLoader from './Components/TopLoader/TopLoader'

import Toast from './Components/Toast/Toast'

import './App-little-purple.scss'
import './App.scss'
import './icons.css'

const App = () => {

    moment.locale('es')

    moment.updateLocale('es', {
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
        weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
        week: {
            dow: 1
        }
    })

    const { isAuthenticated, authToken } = useAuth()

    const dispatch = useDispatch()

    const showLoader = useSelector((state: any) => state.InterfaceReducer.showLoader)
    const notifications = useSelector((state: any) => state.NotificationsReducer.result)

    const [ settingsLoaded, setSettingsLoaded ] = useState(false)

    useEffect(() => {

        const loadSettings = async () => {

            setSettingsLoaded(false)

            await dispatch(getApplicationSettingsAction())

            setTimeout(() => {

                setSettingsLoaded(true)
            }, 1000)
        }

        if(!settingsLoaded) loadSettings()
    }, [ isAuthenticated ])

    useEffect(() => {

        const loadNotifications = async () => {

            const { data } = await NotificationsService.getResult()

            if (!_.isEqual(data, notifications)) {

                dispatch(NotificationsActions.setNotifications(data))
            }
        }

        let interval: any;
        const autoLoadNotifications = config.autoLoadNotifications

        if (authToken && autoLoadNotifications) {

            interval = setInterval(() => {

                loadNotifications()
            }, 10000)
        } else if (authToken && !autoLoadNotifications) {

            loadNotifications()
        }

        return () => {

            clearInterval(interval)
        }
    }, [])

    return (
        <div className="App">
            {!settingsLoaded && <SplashPage />}
            {settingsLoaded && (
                <>
                    <AppRouter />

                    <TermsAndPoliciesRequester />
                </>
            )}

            <TopLoader show={showLoader} />

            <Toast />
        </div>
    )
}

export default App
