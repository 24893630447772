
import React, { useState } from 'react'
import InputFormControl from '../InputFormControl/InputFormControl'
import CheckListItem from './CheckListItem'

import _ from 'lodash'

type Props = {
    addText?: string,
    items: any,

    onChange?: (list: any[], deleted: any[], added: any[]) => void,
    readOnly?: boolean,
    canCreate?: boolean,
    testId?: string,
}

const getEmptyItem = () => {

    return {
        description: '',
        responsable: '',
        checked: false,
    }
}

const CheckList = (props: Props) => {

    const testId = props.testId || 'check-list'

    const [ state, setState ] = useState({
        createMode: false,
        item: getEmptyItem()
    })

    const [ deleted, setDeleted ] = useState<any[]>([])
    const [ added, setAdded ] = useState<any[]>([])

    const setItemDescription = (description: string) => {

        const item = state.item
        item.description = description

        setState({
            ...state,
            item
        })
    }

    const onCreateItem = () => {

        if (state.item.description.trim() === '') {

            return
        }

        const items = [ ...props.items ]
        items.push(state.item)

        setAdded([ ...added, state.item ])

        if (props.onChange)
            props.onChange(items, deleted, added)

        setState({
            ...state,
            item: getEmptyItem()
        })
    }

    const onUpdateItem = (itemToUpdate: any, newItem: any) => {

        const items = props.items.map((item: any) => {

            const isTheItemToUpdate = _.isEqual(item, itemToUpdate)

            if (isTheItemToUpdate) {

                return newItem
            }

            return item
        })

        if (props.onChange)
            props.onChange(items, deleted, added)
    }

    const onDeleteItem = (item: any) => {

        const items = props.items.filter((_item: any) => _item !== item)

        setDeleted([ ...deleted, item ])

        if (props.onChange)
            props.onChange(items, deleted, added)
    }

    const handleCancel = (e: any) => {

        e.preventDefault()
        e.stopPropagation()
        setState({
            ...state,
            createMode: false,
            item: getEmptyItem()
        })

    }


    function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {

        const input = e.currentTarget.querySelector('input');
        if (e.key === 'Enter' && input === document.activeElement) {
            e.stopPropagation();


        }
    }

    return (
        <div data-testid={testId}>
            {props.items.map((item: any, i: number) => (
                <CheckListItem key={i}
                    testId={`${testId}-item-${i}`}
                    item={item}
                    onChange={(itemUpdated: any) => onUpdateItem(item, itemUpdated)}
                    onDelete={() => onDeleteItem(item)}
                    readOnly={props.readOnly}
                />
            ))}
            {!state.createMode ? (
                <button data-testid="set-create-mode-button" className="btn btn-normal mb-1"
                    onClick={() => setState({ ...state, createMode: true })}
                    disabled={props.readOnly || !props.canCreate}
                >
                    <span className="icon-agregar"></span> Agregar restricción
                </button>
            ) : (
                <div data-testid="check-list-blank-item" className="input-group mb-3" onKeyDown={handleKeyDown}>
                    <InputFormControl
                        testId='check-list-blank-item-description'
                        autoFocus
                        value={state.item.description}
                        placeholder="Esc para cancelar"
                        onChange={(value: string) => setItemDescription(value)}
                        onEnter={() => onCreateItem()}
                        onEscape={handleCancel}
                        style={{ height: 34 }}
                    />
                    <div className="input-group-append">
                        <button data-testid="check-list-create-button" className="btn btn-normal" type="button"
                            onClick={() => onCreateItem()}
                        >
                            <i className="fas fa-check fa-fw"></i>
                        </button>
                        <button data-testid="blank-item-cancel-button" className="btn btn-normal" type="button"

                            onClick={

                                handleCancel}


                        >
                            <span className="icon-cancelar"></span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CheckList
