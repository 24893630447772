
import React from 'react'

import { ToastContainer, ToastOptions, ToastPosition, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ToastMsg from '../ToastMsg/ToastMsg'

class Toast extends React.Component {

    static myInstance: any

    constructor(props: any) {
        super(props)

        Toast.myInstance = this
    }

    static show(title: string, content: any, options?: ToastOptions) {

        this.myInstance._show(title, content, options)
    }

    static close() {

        this.myInstance._close()
    }

    _show(title: string, content: string, options: ToastOptions) {

        toast.dark(<ToastMsg title={title} content={content} />, {
            toastId: 'main-toast',
            progress: undefined,
            ...options
        })
    }

    _close() {

        toast.dismiss()
    }

    render() {

        return (
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        )
    }
}

export default Toast
