
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';

import { useChat } from '../../../Hooks/UseChat';

import { getDashboardMemberships } from '../../../Services/AdminDashboardService';

import AdminLayout from '../../../Layouts/AdminLayout/AdminLayout';

import Icon from '../../../Components/Icon/Icon';
import RadarChart from '../../../Components/Charts/ReCharts/RadarChart/RadarChart';
import CountryFlagEmoji from '../../../Components/CountryFlagEmoji/CountryFlagEmoji';

enum Membership {
  free = 'Plan Gratis',
  full = 'Plan Full',
  expert = 'Plan Experto',
  pro = 'Plan Pro',
  enterprise = 'Plan Empresa',
  starter = 'Plan Starter'
}

const AdminHomePage = () => {

  const { adminDashboardData, joinAdminHomeChannel } = useChat();

  const [ membershipsData, setMembershipsData ] = useState<any[]>([])

  const getMembershipData = async () => {

    const { data, error } = await getDashboardMemberships()

    if (error) return

    const { data: memberships } = data

    const membershipsData: any[] = []

    for (const key in memberships) {

      const name: any = Membership

      membershipsData.push({
        subject: name[key],
        A: memberships[key],
        fullMark: 150,
      })
    }

    setMembershipsData(membershipsData)
  }


  useEffect(() => {

    joinAdminHomeChannel();
    getMembershipData()
  }, [])

  return (
    <AdminLayout title="Dashboard" menu="dashboard">
      <Row>
        <Col xl={3} lg={4} md={6} sm={6}>
          <Card className="card-icon-bg card-icon-bg-primary mb-4">
            <Card.Body className="text-center">
              <Icon name="equipos_full"
                style={{
                  fontSize: '4rem',
                  color: 'var(--color-primary)'
                }}
              />
              <div className="content" style={{ margin: '0 1rem', maxWidth: 'none' }}>
                <p className="text-muted mt-2 mb-2">Usuarios conectados</p>
                <p className="lead text-primary text-24 m-0">
                  {adminDashboardData.users_connected.length}
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={6}>
          <Card className="card-icon-bg card-icon-bg-primary mb-4">
            <Card.Body className="text-center">
              <Icon name="proyecto_full"
                style={{
                  fontSize: '4rem',
                  color: 'var(--color-primary)'
                }}
              />
              <div className="content" style={{ margin: '0 1rem', maxWidth: 'none' }}>
                <p className="text-muted mt-2 mb-2">Proyectos Abiertos</p>
                <p className="lead text-primary text-24 m-0">
                  {adminDashboardData.projects_working.length}
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="mb-4">
            <Card.Body>
              <h4 className="text-primary">Membresías activas</h4>
              <RadarChart data={membershipsData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Card className='text-left mb-2'>
            <Card.Body>
              <h4 className="text-primary">Usuarios conectados</h4>
              <Table bordered responsive>
                <thead className='thead-light'>
                  <tr>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>País</th>
                  </tr>
                </thead>
                <tbody>
                  {adminDashboardData.users_connected.map((user: any, index: number) => (
                    <tr key={index}>
                      <td>{user.user_name}</td>
                      <td>{user.user_email}</td>
                      <td>
                        <CountryFlagEmoji code={user.country_code} showName />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} lg={6}>
          <Card className='text-left mb-2'>
            <Card.Body>
              <h4 className="text-primary">Proyectos abiertos</h4>
              <Table bordered responsive>
                <thead className='thead-light'>
                  <tr className=''>
                    <th>Nombre</th>
                    <th>Pl4nner</th>
                    <th style={{ width: 80 }}>Usuarios</th>
                  </tr>
                </thead>
                <tbody>
                  {adminDashboardData.projects_working.map((project: any, index: number) => (
                    <tr key={index}>
                      <td>{project.name}</td>
                      <td>
                        {project.user.name} {project.user.surname}
                        <div>
                          <small>{project.user.email}</small>
                        </div>
                      </td>
                      <td>{project.users.length}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </AdminLayout>
  )
}

export default AdminHomePage;
