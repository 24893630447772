
import React from "react";

type Props = {
  name: string;
  color: string;
  onClick: (e: any) => void;
  selected: boolean;
  buttons?: any[]
}

const Component = (props: Props, itemRef: any) => (
  <div className="color-code-selector-item">
      <button
          className="select"
          ref={itemRef}
          onClick={props.onClick}
      >
          <span className="code bold">{props.name}</span>
          <span className="color" style={{ backgroundColor: props.color }}></span>
      </button>
      {props.buttons && (
        <>
          <div className="separator"></div>
          {props.buttons.map((button, index) => (
            <button
              key={index}
              className='action-button cursor-pointer'
              onClick={button.onClick}
            >
              <i className={button.icon}></i>
            </button>
          ))}
        </>
      )}
  </div>
)

const ColorCodeOption = React.forwardRef<any, any>(Component)

export default ColorCodeOption
