
import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import type { EChartsOption } from 'echarts';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LegendComponent } from 'echarts/components';

echarts.use([
  LegendComponent,
  PieChart,
]);

const TableWithPieCard = (props: any) => {

  const {
    title,
    data,
    tableFields,
  } = props

  const option: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      show: false,
      bottom: 0
    },
    series: [
      {
        type: 'pie' ,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 1
        },
        data: data.map((item: any) => ({ name: item.label, value: item.value })),
        height: 360,
        radius: ['70%', '90%'],
      },

    ]
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className='card-title mb-3'>{title}</div>
          <div className="row">
            <div className="col-md-6">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            {tableFields.map((field: string, i: number) => (
                              <th key={i}>{field}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                      {data.map((item: any, i: number) => (
                        <tr key={i}>
                          <td>{item.label}</td>
                          <td className="text-danger">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
              <ReactEChartsCore
                echarts={echarts}
                option={option}
                style={{ height: '360px', width: '100%' }}
              />
            </div>
        </div>
      </div>
    </div>
  )
}

export default TableWithPieCard
