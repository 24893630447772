
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import moment from "moment";

import { getProjects, purgeTasks } from "../../../Services/AdminProjectsService";

import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";

import Icon from "../../../Components/Icon/Icon";

const AdminProjectsPage = () => {

  const [ projects, setProjects ] = useState<any[]>([])
  const [ pagination, setPagination ] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })
  const [ paginationItems, setPaginationItems ] = useState<any[]>([])
  const [ criteria, setCriteria ] = useState<any>('')

  const loadProjects = async () => {

    const { data, total }  = await getProjects(criteria, pagination.page - 1, pagination.limit)

    setProjects(data)
    setPagination({ ...pagination, total })
  }

  const handlePurgeTasks = async (project_id: string) => {

    const { error } = await purgeTasks(project_id)

    if (error) {

      console.log('error', error)
      return
    }

    loadProjects()
  }

  useEffect(() => {

    loadProjects()
  }, [ pagination.page ])

  useEffect(() => {

    const getPaginationItems = () => {

      const items = []

      const pages = Math.ceil(pagination.total / pagination.limit)

      for (let i = 1; i <= pages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === pagination.page} onClick={() => setPagination({ ...pagination, page: i })}>
            {i}
          </Pagination.Item>,
        )
      }

      return setPaginationItems(items)
    }

    getPaginationItems()
  }, [ pagination.total, pagination.page ])

  return (
    <>
      <AdminLayout title="Proyectos" menu="projects" submenu="list">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between pb-2">
                  <Card.Title className="">Proyectos</Card.Title>
                  <div className="btn-toolbar">
                    <InputGroup>
                      <Form.Control
                        placeholder="Buscar..."
                        value={criteria}
                        onChange={(e: any) => setCriteria(e.target.value)}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            loadProjects();
                          }
                        }}
                      />
                      <InputGroup.Append>
                        <button className="btn btn-normal"
                          onClick={() => loadProjects()}
                        >
                          <i className="fa fa-fw fa-search"></i>
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col">Nombres</th>
                        <th scope="col">Pl4nner</th>
                        <th scope="col" style={{ width: 200 }}>Tareas</th>
                        <th scope="col" style={{ width: 130 }}>Fec. Creado</th>
                        <th scope="col" style={{ width: 260 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project: any, i: number) => (
                        <tr key={project._id}>
                          <td>{((pagination.page - 1) * pagination.limit) + i + 1}</td>
                          <td>
                              <strong>{project.name}</strong>
                              <div>
                                <small>
                                  <i>{project.shortName}</i>
                                </small>
                              </div>
                          </td>
                          <td>
                            {project.user?.name} {project.user?.surname}
                          </td>
                          <td>
                            {project.tasks_not_listed > 0 && (
                              <>
                                <div>
                                  En lista: {project.tasks_listed}
                                </div>
                                <div>
                                  Fuera de lista: <span className="text-danger">{project.tasks_not_listed}</span>{' '}
                                  <button className="btn btn-normal"
                                    onClick={() => handlePurgeTasks(project._id)}
                                  >
                                    <Icon name="trash" fas fw />
                                  </button>
                                </div>
                                <div>
                                  Total: {project.tasks}
                                </div>
                              </>
                            )}
                            {project.tasks_not_listed === 0 && project.tasks_listed}
                          </td>
                          <td>
                            {moment(project.createdAt).format('DD/MM/YYYY')}
                          </td>
                          <td>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="table-responsive">
                  <Pagination>
                    {paginationItems}
                  </Pagination>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>
    </>
  )
}

export default AdminProjectsPage
