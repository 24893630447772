
import SessionService from '../../Services/SessionService'

import { RegisterData, ConfirmData, ReConfirmData } from '../../Types/Interfaces'
import Toast from '../../Components/Toast/Toast'

const file = 'SessionActions'

const registerAction = (registerData: RegisterData) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'SESSION_REGISTER_START'
        })

        try {

            await SessionService.register(registerData)

            dispatch({
                type: 'SESSION_REGISTER_END',
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'SESSION_REGISTER_ERROR',
                content: {
                    error: error instanceof Error && error.message
                }
            })
        }
    }
}

const registerResetAction = () => {

    return {
        type: 'SESSION_REGISTER_RESET'
    }
}

const confirmAction = (confirmData: ConfirmData) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'SESSION_CONFIRM_START'
        })

        try {

            await SessionService.confirm(confirmData)

            dispatch({
                type: 'SESSION_CONFIRM_END',
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'SESSION_CONFIRM_ERROR',
                content: {
                    error: error instanceof Error && error.message
                }
            })
        }
    }
}

const reConfirmAction = (reConfirmData: ReConfirmData) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'SESSION_RECONFIRM_START'
        })

        try {

            await SessionService.reConfirm(reConfirmData)

            dispatch({
                type: 'SESSION_RECONFIRM_END',
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'SESSION_RECONFIRM_ERROR',
                content: {
                    error: error instanceof Error && error.message
                }
            })
        }
    }
}

const confirmResetAction = () => {

    return {
        type: 'SESSION_CONFIRM_RESET'
    }
}

const requestRecoveryAction = (email: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'SESSION_REQUEST_RECOVERY_START'
        })

        try {

            await SessionService.requestRecovery({ email })

            dispatch({
                type: 'SESSION_REQUEST_RECOVERY_END'
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'SESSION_REQUEST_RECOVERY_ERROR',
                content: {
                    error: error instanceof Error && error.message
                }
            })
        }
    }
}

const resetPasswordAction = (data: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'SESSION_RESET_PASSWORD_START'
        })

        try {

            await SessionService.resetPassword(data)

            dispatch({
                type: 'SESSION_RESET_PASSWORD_END',
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'SESSION_RESET_PASSWORD_ERROR',
                content: {
                    error: error instanceof Error && error.message
                }
            })
        }
    }
}

const changePasswordAction = (actual: string, password: string) => {

    return async (dispatch: any) => {

        dispatch({ type: 'SESSION_CHANGE_PASSWORD_START' })

        try {

            await SessionService.changePassword(actual, password)

            Toast.show('Guardado!', 'La contraseña se actualizó satisfactoriamente.')

            dispatch({ type: 'SESSION_CHANGE_PASSWORD_END' })
        } catch (error) {

            if (error instanceof Error) {

                const err: any = { ...error }

                //todo: remove try catch

                if (err.response && err.response.data && err.response.data.error === 'wrong_credentials') {

                    Toast.show('Upss', 'Tu contraseña actual no es correcta.')
                }

                // if (error.message) {

                // }

            }

            dispatch({ type: 'SESSION_CHANGE_PASSWORD_ERROR' })
        }
    }
}

export default {
    registerAction,
    registerResetAction,
    confirmAction,
    reConfirmAction,
    confirmResetAction,
    requestRecoveryAction,
    resetPasswordAction,
    changePasswordAction,
}
