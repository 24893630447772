
import * as XLSX from 'xlsx'

const useworker = typeof Worker !== 'undefined';

const getWorkbook = (content: any, cb: any) => {

  const scripts = document.getElementsByTagName('script');
  let dropsheetPath;
  for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.indexOf('dropsheet') != -1) {
          dropsheetPath = scripts[i].src.split('dropsheet')[0];
      }
  }
  const worker = new Worker('/js/excel-sheet-worker.js');
  worker.onmessage = function(e: any) {

    const { data, error } = e

    if (error) {

      console.error(error)
      return
    }

    cb(JSON.parse(data.data))
  };
  worker.postMessage({
      content,
      type: 'xlsx'
  });
}

const to_json = (workbook: any) => {
  if (useworker && workbook.SSF) XLSX.SSF.load_table(workbook.SSF);
  const result: any = {};
  workbook.SheetNames.forEach(function(sheetName: any) {
      const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          raw: false,
          header: 1
      });
      if (roa.length > 0) result[sheetName] = roa;
  });
  return result;
}

const getWorkBookData = (wb: XLSX.WorkBook, sheetidx?: number) => {

  const sheet = wb.SheetNames[sheetidx || 0];
  const json = to_json(wb)[sheet];

  return json
}

export const ReadSheetFile = (content: any) => {

  return new Promise((resolve) => {

    if (useworker) {
      getWorkbook(content, (workbook: any) => {

        const data = getWorkBookData(workbook)

        resolve(data)
      })
      return
    }

    const workbook = XLSX.read(content, { type: 'array' })

    const data = getWorkBookData(workbook)

    resolve(data)
  })
}


