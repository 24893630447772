import React, { useEffect } from "react";

import navigation from "../../navigation";

import { useDispatch, useSelector } from "react-redux";
import ProjectsActions from "../../Redux/Actions/ProjectsActions";

import MainLayout from "../../Components/MainLayout/MainLayout";


const ProjectResourcesRestrictionsPage = (props: any) => {

    const dispatch = useDispatch()


    const project_id = props.match.params._id

    const project = useSelector((state: any) => state.ProjectsReducer.rowData)

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'resources',
        'control-hh'
    )

    const loadProject = () => {

        dispatch(ProjectsActions.getRowAction(project_id))
    }

    useEffect(() => {

        if (!project._id) {

            loadProject()
        }
    }, [ project ])

    return (
        <MainLayout
            project={project}
            menuBar={menu}
            subMenuBar={submenu}
        >
            <h1>hello</h1>

        </MainLayout>
    )
}

export default ProjectResourcesRestrictionsPage