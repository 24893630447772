
export const getChatTasksFromList = (list: any[], tasks: any[]) => {

  list.forEach((item: any) => {

    if (item.type === 'task') {

      const task = tasks.find((t: any) => t._id === item.task)

      item.task = task
    } else {

      item.group.items = getChatTasksFromList(item.group.items, tasks)
    }
  })

  return list
}

export const getChatTasksTreeOptions = (taskList: any[], level?: number) => {

  const lvl = level || 0

  const getLevelIndentationString = () => {

    let indentation = ''

    for (let i = 0; i < lvl; i++) {

        indentation += '-- '
    }

    return indentation
  }

  const options: any[] = []

  taskList.forEach((item: any) => {

    if (item.type === 'task') {

      options.push({
        value: item.task._id,
        label: `${getLevelIndentationString()} ${item.task.name}`
      })
    } else {

      options.push({
        value: item.group._id,
        label: `${getLevelIndentationString()} ${item.group.name}`,
        isGroup: true
      })

      const subOptions = getChatTasksTreeOptions(item.group.items, lvl + 1)

      if (subOptions.length) {

        options.push(...subOptions)
      }
    }
  })

  return options
}
