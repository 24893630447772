import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import InputFormControl from "../InputFormControl/InputFormControl";

type Props = {
  team: any;
  show: boolean;

  saveDispatch: any;
  onHide: any;
};

const TeamCreator = (props: Props) => {
  const [team, setTeam] = useState({
    _id: props.team ? props.team._id : "",
    name: props.team ? props.team.name : "",
  });

  const [inputFocus, setInputFocus] = useState(false);
  const [inputBlurred, setInputBlurred] = useState(false);

  const save = () => {
    props.saveDispatch(team);
  };

  const cancel = () => {
    setTeam({
      _id: "",
      name: "",
    });

    setInputFocus(false);
    setInputBlurred(false);

    props.onHide();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onHide()}
        backdrop="static"
        keyboard={true}
        onEntered={() => setInputFocus(true)}>
        <Modal.Header>
          <Modal.Title>Crear equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label htmlFor="" className="col-sm-3 col-form-label">
              Nombre
            </label>
            <div className="col-sm-9">
              <InputFormControl
                autoFocus={true}
                value={team.name}
                onChange={(value: string) => setTeam({ ...team, name: value })}
                onEnter={() => save()}
                onBlur={() => setInputBlurred(true)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => cancel()}>
            <span className="icon-cancelar"></span> Cancelar
          </button>
          <button
            className="btn btn-primary active"
            onClick={() => save()}
            disabled={team.name === ""}>
            <span className="icon-guardar"></span> Crear
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeamCreator;
