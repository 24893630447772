import React from 'react';



type Props = {
    isNotRead: boolean,
    icon: any,
    data: any,
    onClick: any,
    timeDifference: string
    action: any
}

const AssistantProfileNotification = (props: Props) => {

    return (
        <div className={`${props.isNotRead ? 'dropdownnotification' : ''} dropdown-item d-flex`}
            onClick={props.onClick}
        >
            <div className="notification-icon" style={{ padding: '10px' }}>
                {props.icon}
            </div>
            <div className="notification-details flex-grow-1">
                <p className="m-0 d-flex align-items-center">
                    <span>Proyecto <strong>{props.data.project.name}</strong></span>
                    <span
                        className={`badge badge-pill badge-muted ml-1 mr-1`}
                    >
                        {props.data.status === 'read' ? null : (
                            <span className="badge badge-pill badge-primary ml-1 mr-1">Pendiente</span>
                        )}
                    </span>
                    <span className="flex-grow-1"></span>
                    <span className=" text-muted ml-auto">
                        Hace {props.timeDifference}
                    </span>
                </p>
                <p className=" text-muted m-0">
                    <span className='notification-content'>
                        {props.action === 'assigned' && (
                            <span>
                                <strong>{props.data.userFrom.name} {props.data.userFrom.surname}</strong> te ha asignado como <strong>Asistente Pl4nner</strong>
                            </span>

                        )}
                        {props.action === 'removed' && (
                            <span>
                                Has sido removido como <strong>Asistente Pl4nner</strong>
                            </span>
                        )}
                        en <strong>{props.data.project.name}</strong>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default AssistantProfileNotification