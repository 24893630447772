
import React, { ReactNode } from 'react'
import { Card, Table } from 'react-bootstrap'

type Props = {
    icon?: ReactNode,
    title?: string,
    className?: string,
    children: any,
}

const PCard = (props: Props) => {

    return (
        <Card className={`table-card ${props.className}`}>
            <div>
                <Table className="card-table">
                    <thead>
                        <tr>
                            <th>
                                {props.icon}
                                {props.title}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {props.children}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Card>
    )
}

export default PCard
