import { assignCNC } from "../../Services/ResourcesCNCService"

export const cleanResourcesCNCAction = () => {

  return {
    type: 'RESOURCES_CNC_CLEANUP'
  }
}

export const assignCNCAction = (task_weekly_progress_id: string, cnc_id: string) => {

  return async (dispatch: any) => {

    dispatch({
      type: 'RESOURCES_CNC_ASSIGNING',
      payload: {
        task_weekly_progress_id
      }
    })

    const { error, data } = await assignCNC(task_weekly_progress_id, cnc_id)

    if (error) {

      dispatch({
        type: 'RESOURCES_CNC_ASSIGNING_ERROR',
        payload: {
          task_weekly_progress_id
        }
      })
      return false
    }

    const { data: task_weekly_progress } = data

    dispatch({
      type: 'RESOURCES_CNC_ASSIGNING_SUCCESS',
      payload: {
        data: task_weekly_progress
      }
    })

    return true
  }
}