
import React, { Fragment, useEffect, useRef } from "react";

import { IContextMenuOption } from "../../Hooks/UseContextMenu";

import './ContextMenu.scss'

interface Props {
  options: IContextMenuOption[]
  show: boolean
  onHide: () => void
  top: number
  left: number
}

const ContextMenu = (props: Props) => {

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {

    window.addEventListener('click', props.onHide)

    return () => {
      window.removeEventListener('click', props.onHide)
    }
  }, [])

  return (
    <div
      ref={ref}
      className={`context-menu ${props.show ? 'show' : ''}`}
      style={{
        top: props.top,
        left: props.left
      }}
    >
      {props.options.map((option, index) => (
        <Fragment key={index}>
          {option.separator && <div key={index} className="context-menu__separator"></div>}
          {!option.separator && (
            <div key={index}
              className={`context-menu__item ${option.items && option.items.length ? 'has-items' : ''} ${option.disabled ? 'disabled' : ''}`}
              onClick={option.action}
            >
              <span className="icon">
                {option.icon}
              </span>
              <span className="text">{option.label}</span>
              {option.items && option.items.length && (
                <i className="fas fa-chevron-right" style={{ marginTop: 4 }}></i>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default ContextMenu;
