
import { request, EMethods } from './ApiService'

import config from '../config'

interface ISearchData {
    criteria: string
    team_id: string
}

const search = async (data: ISearchData) => {

    const url = config.apiHost + '/users/search'

    try {

        const response = await request(EMethods.post, 'TEAMS_GET_RESULT', url, data)

        return response.data
    } catch (error) {

        //todo: remove try catch

        throw error
    }
}

export default {
    search,
}
