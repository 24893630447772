
import React, { useMemo, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface IChartColor {
  stroke: string
  fill: string
}

export interface IChartSerie {
  name: string
  color: IChartColor | string
}

interface IMyAreaChartProps {
  data: any[]
  series: IChartSerie[]
  colors?: IChartColor[] | string[]
  useGradients?: boolean
  strokeWidth?: number
  tooltip?: boolean
  customTooltip?: any
}

const MyAreaChart = (props: IMyAreaChartProps) => {

  const tooltip = props.tooltip !== undefined ? props.tooltip : true
  const strokeWidth = props.strokeWidth || 1

  const getStrokeColor = (color: string | IChartColor) => {
    if (typeof color === 'string') return color
    return color.stroke
  }

  const getFillColor = (color: string | IChartColor) => {
    if (typeof color === 'string') return color
    return color.fill
  }

  return (
    <div style={{
      height: '300px',
      width: '100%',
    }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 10,
            right: 10,
            left: -20,
            bottom: 0,
          }}
        >
          {props.useGradients && (
            <defs>
              {props.series.map((serie, i) => (
                <linearGradient key={i} id={serie.name} x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="45%"
                    stopColor={typeof serie.color === 'string' ? serie.color : serie.color.fill}
                    stopOpacity={1}
                  />
                  <stop offset="100%" stopColor="#ffffff" stopOpacity={0.75} />
                </linearGradient>
              ))}
            </defs>
          )}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          {tooltip && (
            <>
              {!props.customTooltip && (
                <Tooltip />
              )}
              {props.customTooltip && (
                <Tooltip content={props.customTooltip} />
              )}
            </>
          )}
          <Legend />
          {props.series.map((serie, i) => (
            <Area key={i}
              type="monotone"
              strokeWidth={strokeWidth}
              dataKey={serie.name}
              stroke={getStrokeColor(serie.color)}
              fill={props.useGradients ? `url(#${serie.name})` : getFillColor(serie.color)}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MyAreaChart;
