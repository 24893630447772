
import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { getAdminNavigation } from '../../admin-navigation';

import AdminLayoutHeader from './AdminLayoutHeader';
import AdminLayoutSidebar from './AdminLayoutSidebar';

import './AdminLayout.scss';

interface Props {
  title?: string;
  menu?: string;
  submenu?: string;
  children: React.ReactNode;
}

const AdminLayout = ({ title, menu: menuStr, submenu: submenuStr, children }: Props) => {

  const w = window.innerWidth

  const [ openSidebar, setOpenSidebar ] = useState(w > 768 ? true : false)

  const { submenu, dropdown, dropdownIcon } = getAdminNavigation(menuStr, submenuStr);

  useLayoutEffect(() => {

    const onResize = () => {

      const w = window.innerWidth

      if (w >= 768) setOpenSidebar(true)

      if (w < 768) setOpenSidebar(false)
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  })

  return (
    <div className="app-admin-wrap layout-sidebar-large clearfix">
      <AdminLayoutHeader openSidebar={openSidebar} onToggleSidebar={(open) => setOpenSidebar(open)} />
      <AdminLayoutSidebar menu={menuStr} submenu={submenuStr} open={openSidebar} />
      <div className="main-content-wrap sidenav-open d-flex flex-column">
        <div className="main-content">
          <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="breadcrumb">
            <h1>{title}</h1>
          </div>
          <div className='d-flex flex-row'>
            {submenu && (
              <ul className="nav justify-content-end">
                {submenu && submenu.map((navItem: any, i: number) => (
                  <li key={i} className={`nav-item ${navItem.active ? 'active': ''}`}>
                    <Link to={navItem.path} className={`nav-link ${navItem.active ? 'active' : ''}`}>
                      {navItem.icon} {navItem.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {dropdown && (
              <div className="btn-toolbar">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {dropdownIcon}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropdown.map((navItem: any, i: number) => (
                      <Dropdown.Item key={i} as={Link} to={navItem.path}>
                        {navItem.icon} {navItem.text}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        <div className="separator-breadcrumb border-top"></div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default AdminLayout;
