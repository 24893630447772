
import React from 'react'

const DropdownContent = (props: any) => {

    const showDefaultContent = () => {

        return (
            <>
                Nothing to show
            </>
        )
    }

    return (
        <div className={`dropdown-menu ${props.className} ${props.show ? 'show' : null}`}>
            {!props.children ? showDefaultContent() : props.children}
        </div>
    )
}

export default DropdownContent
