
import React, { useMemo } from "react";
import Kanban from "../../Components/Kanban/Kanban";

import './TasksGroupByState.scss'

type Props = {
  height: number;
  tasks: any;
  teams: any;

  onSelectTask: (task_id: string) => void;
}

const TaskGroupByState = (props: Props) => {

  const getAllTasksFromList = (list: any) => {

    const tasks: any[] = []

    list.forEach((item: any) => {

      if (item.type === 'task') {

        tasks.push(item.task)
      } else {

        const subTasks = getAllTasksFromList(item.group.items)

        if (subTasks.length)
          tasks.push(...subTasks)
      }
    })

    return tasks
  }

  const groupedByState = useMemo(() => {

    const tasks: any = {
      activa: [],
      'activa-atrasada': [],
      liberada: [],
      atrasada: [],
      pendiente: [],
      completada: [],
    }

    const allTasks = getAllTasksFromList(props.tasks)

    allTasks.map((task: any) => {

      if (task.type !== 'hito' && task.type !== 'reunion') {

        tasks[task.status].push(task)
      }
    })

    return tasks
  }, [props.tasks])

  const columns = [
    {
      icon: (<span className='icon-estado_activa' />),
      title: 'Activas',
      className: 'activa',
      tasks: groupedByState['activa']
    },
    {
      icon: (<span className='icon-estado_activa_atrasada' />),
      title: 'Activas atrasadas',
      className: 'activa-atrasada',
      tasks: groupedByState['activa-atrasada']
    },
    {
      icon: (<span className='icon-estado_liberada' />),
      title: 'Liberadas',
      className: 'liberada',
      tasks: groupedByState['liberada']
    },
    {
      icon: (<span className='icon-estado_atrasada text-color-atrasada' />),
      title: 'Atrasadas',
      className: 'atrasada',
      tasks: groupedByState['atrasada']
    },
    {
      icon: (<span className='icon-estado_pendiente' />),
      title: 'Pendientes',
      className: 'pendiente',
      tasks: groupedByState['pendiente']
    },
    {
      icon: (<span className='icon-estado_completada' />),
      title: 'Completadas',
      className: 'completada',
      tasks: groupedByState['completada']
    }
  ]

  return (
    <Kanban
      height={props.height}
      columns={columns}
      onSelectTask={props.onSelectTask}
    />
  )
}

export default TaskGroupByState;
