
import React, { useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFeedback } from "../../Hooks/UseFeedback";
import { useAuth } from "../../Hooks/UseAuth";
import { useChat } from "../../Hooks/UseChat";
import Swal from "sweetalert2";
import config from "../../config";
import OptionsDialog from "../../Components/OptionsDialog/OptionsDialog";
import { Menu, Submenu } from "../../admin-navigation";

interface Props {
  menu?: Menu[];
  submenu?: Submenu[];
  openSidebar?: boolean;
  onToggleSidebar?: (open: boolean) => void;
}

const AdminLayoutHeader = ({ menu, submenu, openSidebar, onToggleSidebar }: Props) => {

  const location = useLocation().pathname;

  const history = useHistory()

  const { userData, ability, logout } = useAuth()

  const { unsetCurrentChat } = useChat()

  const { show: showFeedbackForm } = useFeedback()

  const [ showUserDialog, setShowUserDialog ] = useState(false)

  const handleLogout = async () => {

    const { isConfirmed } = await Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas cerrar sesión?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, cerrar sesión',
        cancelButtonText: 'Cancelar'
    })

    if (!isConfirmed) return

    unsetCurrentChat()

    logout()
}

  const userDialogOptions = useMemo(() => {

    const options: any[] = [{
      icon: <i className="fas fa-cogs"></i>,
      label: 'Salir de administración',
      color: 'var(--color-green)',
      onClick: () => {
          history.push('/')
      }
    }]

    options.push({
        icon: <span className="icon-cancelar"></span>,
        color: 'var(--color-danger)',
        label: 'Cerrar sesión',
        onClick: () => handleLogout()
    })

    return options
  }, [])

  return (
    <>
      <div className="main-header">
        <div className="logo logo-left">
          <Link to="/admin">
            <img src="/svg-icons/logo.svg" style={{
              width: 130
            }} />
            <span className="tag">Admin</span>
          </Link>
        </div>
        <div className="menu-toggle" onClick={() => onToggleSidebar && onToggleSidebar(!openSidebar)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* {menu && (
          <>
            <div className="d-none d-lg-block text-right" style={{ flex: 1 }}>
              <ul className="project-menu">
                {menu.map((menuItem, index) => (
                  <li key={index}>
                    <Link
                        className={`svg-icon-menu ${menuItem.active && 'active'}`}
                        to={menuItem.path}
                    >
                        <div className="svg-icon">
                            <div className="svg-icon-container">
                                <span className={`main-menu-icon ${menuItem.icon}${menuItem.active ? '_full' : ''}`}></span>
                            </div>
                        </div>
                        <span className={`svg-icon-text width-${menuItem.textWidth}`}>
                            {menuItem.text}
                        </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )} */}
        <div className="logo logo-right">
            <div
                className="btn-user-dialog cursor-pointer"
                onClick={() => setShowUserDialog(true)}
            >
                {location == '/profile' && (
                    <span className="username">
                        {userData?.name}
                    </span>
                )}
                {!userData?.avatar && (
                    <span className="icon-usuario"></span>
                )}
                {userData?.avatar && (
                    <img src={`${config.apiHost}/${userData.avatar}`}
                        style={{
                            width: 32,
                            height: 32,
                            borderRadius: '50%'
                        }}
                    />
                )}
            </div>
        </div>
      </div>

      <OptionsDialog
                show={showUserDialog}
                title={userData?.name || 'Usuario'}
                closeButton
                onHide={() => setShowUserDialog(false)}
                options={userDialogOptions}
            />

    </>
  )
}

export default AdminLayoutHeader
