
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { subject } from '@casl/ability'

import { usePlan } from '../../Hooks/UsePlan'

import Dropdown from '../Dropdown/Dropdown'
import InputFormControl from '../InputFormControl/InputFormControl'

import './ResponsibleSearch.scss'

type Props = {
  text: any,
  listItems: any,
  checkedItems?: any,
  disabled?: boolean,

  onItemSelected: any,
}

const ResponsibleSearch = (props: Props) => {

  const { plan } = usePlan()

  const inputRef = React.useRef<any>(null)

  const [show, setShow] = useState(false)
  const [criteria, setCriteria] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [usersResult, setUsersResult] = useState([])

  const search = (criteria: string) => {

    const words = criteria.toLowerCase().split(' ')

    const lowerItems = props.listItems.map((item: any) => {

      item.name = item.name.toLowerCase()
      item.surname = item.surname.toLowerCase()
      item.team_id = item.team ? item.team._id : ''
      item.team_name = item.team ? item.team.name : ''
      item.team_users = item.team ? item.team.users : []

      return item
    })

    const result: any = _.filter(lowerItems, (item: any) => {

      return (item.name.indexOf(words) > -1) || (item.surname.indexOf(words) > -1)
    })

    setCriteria(criteria)
    setShowResults(criteria !== '' && result.length ? true : false)
    setUsersResult(result)
  }

  const isChecked = (item_id: string) => {

    return props.checkedItems.includes(item_id)
  }

  const handleToggle = (show: boolean) => {

    setCriteria('')
    setShowResults(false)
    setUsersResult([])

    setShow(show)
  }

  const handleAddFirstUser = () => {

    if (usersResult.length > 0) {

      handleAddUser(usersResult[0])
    }
  }

  const handleAddUser = (user: any) => {

    props.onItemSelected(user)
    setCriteria('')
    setShowResults(false) // Clear the search results after adding a user
    setUsersResult([])

    inputRef.current?.setFocus()
  }

  const handleAddTeam = (users: any) => {

    users.forEach((user: any) => handleAddUser(user))
  }

  const canUserBeAdded = (userId: any) => {

    return plan.can('add', subject('Responsible', { userId }))
  }

  useEffect(() => {

    if (show) {

      inputRef.current?.setFocus()
    }
  }, [show])

  return (
    <Dropdown
      show={show}
      toggleClass="btn btn-normal"
      contentClass="dropdown-search"
      toggle={props.text}
      onToggle={(show: boolean) => handleToggle(show)}
      disabled={props.disabled}
    >
      <div className="search-window px-2">
        <div className="form-group">
          <InputFormControl
            ref={inputRef}
            placeholder="Buscar usuario o equipo"
            value={criteria}
            onChange={(value: string) => search(value)}
            onEnter={() => handleAddFirstUser()}
          />
          {!showResults ? null : (
            <div className="list-group">
              {usersResult.map((user: any, i: number) => (
                <div key={i} className="search-item list-group-item"

                >
                  <div className="search-item-data">
                    <span className="search-item-avatar m-1">
                      {user.name.charAt(0) + user.surname.charAt(0)}
                    </span>
                    <div className="search-item-texts">
                      <div className="search-item-user">
                        {` ${user.name} ${user.surname}`}
                      </div>
                      <div className="search-item-team">
                        <i className="fas fa-fw fa-users"></i> {user.team_name}
                      </div>
                      {!canUserBeAdded(user._id) && (
                        <small>
                          El usuario no puede ser agregado como responsable
                        </small>
                      )}
                    </div>
                    <span className="search-item-check">
                      {isChecked(user._id) && (
                        <i className="fas fa-user-check fa-fw"></i>
                      )}
                    </span>
                  </div>
                  <div className="search-item-actions text-right">
                    <button className="btn btn-sm btn-normal mr-1"
                      onClick={() => handleAddTeam(user.team_users)}
                      disabled={!canUserBeAdded(user._id)}
                    >
                      <i className="fas fa-fw fa-users"></i> Agregar equipo
                    </button>
                    <button className="btn btn-sm btn-normal"
                      onClick={() => handleAddUser(user)}
                      disabled={!canUserBeAdded(user._id)}
                    >
                      <i className="fas fa-fw fa-user"></i> Agregar usuario
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  )
}

export default ResponsibleSearch
