
import config from "../../config";
import { EMethods, request } from "../../Services/ApiService";
import { ColorCode } from "../../Types/Tasks";

const TAG = 'COLOR_CODE';

export enum COLOR_CODE_ACTIONS {
  GET_RESULT_END = 'COLOR_CODE/GET_RESULT_END',
  CREATE_END = 'COLOR_CODE_CREATE_END',
  CREATE_ERROR = 'COLOR_CODE/CREATE_ERROR',
  UPDATE_END = 'COLOR_CODE_UPDATE_END'
}

const getResult = (project: string) => {

  const url = `${config.apiHost}/color-code?project_id=${project}`

  return async (dispatch: any) => {

    try {

      const res = await request(EMethods.get, 'COLOR_CODE_GET_RESULT', url)

      dispatch({
        type: COLOR_CODE_ACTIONS.GET_RESULT_END,
        payload: res.data.data
      })
    } catch (error) {

      if (error instanceof Error) {

        //todo: remove try catch
      }
    }
  }
}

const create = (colorCode: ColorCode, project_id: any) => {

  const url = `${config.apiHost}/color-code`

  const data = {
    project_id,
    name: colorCode.name,
    color: colorCode.color,
  }

  return async (dispatch: any) => {

    try {

      const res = await request(EMethods.post, 'COLOR_CODE_CREATE', url, data)

      dispatch({
        type: COLOR_CODE_ACTIONS.CREATE_END,
        payload: res.data.data
      })
    } catch (error) {

      if (error instanceof Error) {

        //todo: remove try catch
      }
    }
  }
}

const update = (colorCode: ColorCode, project_id: any) => {

  const url = `${config.apiHost}/color-code/${colorCode._id}`

  const data = {
    project_id,
    name: colorCode.name,
    color: colorCode.color,
  }

  return async (dispatch: any) => {

    try {

      const res = await request(EMethods.put, 'COLOR_CODE_UPDATE', url, data)

      dispatch({
        type: COLOR_CODE_ACTIONS.UPDATE_END,
        payload: res.data.data
      })
    } catch (error) {

      if (error instanceof Error) {

        //todo: remove try catch
      }
    }
  }
}

export default {
  getResult,
  create,
  update,
}
