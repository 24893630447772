
import React from "react";

type Props = {
  fromTop: number;
  fromLeft: number;
  toTop: number;
  toLeft: number;
  type: string;
}

const GanttChartBodyArrow = (props: Props) => {

  const goesUp = props.fromTop > props.toTop
  const goesLeftOrKeeps = props.fromLeft >= props.toLeft

  let path = ``

  let x = props.fromLeft
  let y = props.fromTop

  if (!goesUp) {

    if (props.type === 'diamond_to_start') {

      x += 16
      y += 1
    } else if (props.type === 'start_to_start') {

      x += 12
    } else if (props.type === 'end_to_start') {

      x -= 12
    }

    y += 26
    path += `M ${x},${y}`

    if (goesLeftOrKeeps) {

      y += 2
      path += ` L ${x},${y}`

      // dobla a la izquierda
      x -= 5
      y += 5
      path += ` a 5 5 0 0 1 -5 5`

      // retrocede hasta el destino
      x = props.toLeft - 15
      path += ` L ${x},${y}`

      // dobla hacia abajo
      x -= 5
      y += 5
      path += ` a 5 5 0 0 0 -5 5`
    }

    // baja hasta el destino
    y = props.toTop + 11
    path += ` L ${x},${y}`

    // dobla hacia la derecha
    path += ` a 5 5 0 0 0 5 5`
  } else {

    if (props.type === 'start_to_start') {

      x += 12
    } else if (props.type === 'end_to_start') {

      x -= 12
    }

    y += 6
    path += `M ${x},${y}`

    if (goesLeftOrKeeps) {

      y -= 1
      path += ` L ${x},${y}`

      // dobla a la izquierda
      x -= 5
      y -= 5
      path += ` a 5 5 0 0 0 -5 -5`

      // retrocede hasta el destino
      x = props.toLeft - 15
      path += ` L ${x},${y}`

      // dobla hacia arriba
      x -= 5
      y -= 5
      path += ` a 5 5 0 0 1 -5 -5`

      // sube hasta el destino
      y = props.toTop + 21
      path += ` L ${x},${y}`

      // dobla hacia la derecha
      path += ` a 5 5 0 0 1 5 -5`
    }
  }

  // conecta con el destino
  path += ` L ${props.toLeft + 4},${props.toTop + 16}`

  // dibuja la flecha
  path += ` M ${props.toLeft - 4},${props.toTop + 9} L ${props.toLeft + 3},${props.toTop + 16}`
  path += ` M ${props.toLeft - 4},${props.toTop + 22} L ${props.toLeft + 3},${props.toTop + 16}`

  return (
    <path
      className="gantt-arrow"
      d={path}
    />
  )
}

export default GanttChartBodyArrow
