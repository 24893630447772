import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import { useAuth } from "../../../Hooks/UseAuth";
import { useChat } from "../../../Hooks/UseChat";

import TreeUserItem from "./TreeUserItem";

interface ChatTeamItemProps {
  team: any;
  onSelectItem: () => void;
}

const TreeTeamItem: React.FC<ChatTeamItemProps> = ({ team, onSelectItem }) => {

  const { userData } = useAuth()

  const { joinChat } = useChat();

  const userId = userData?.id

  const [ open, setOpen ] = useState(false);

  const isNotMyTeam = () => {

    const exist = team.users.some((user: any) => user._id === userId)

    return !exist
  }

  const handleClick = (e: any) => {
    e.stopPropagation();

    joinChat([ 'team', team._id ]);
    onSelectItem();
  }

  return (
    <Accordion className={`chat-projects-tree-item ${open && 'open'}`}>
      <Accordion.Toggle as='div' className='toggle' eventKey="0" onClick={() => setOpen(!open)}>
        <span className='toggle-caret'>
          <i className="fas fa-caret-right"></i>
        </span>
        <button className="texts"
          onClick={(e: any) => handleClick(e)}
          disabled={isNotMyTeam()}
        >
          <span className="icon-grupo_equipo"></span>
          <span className='item-name'>{team.name}</span>
        </button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <>
          {!team.users.length && (
            <div className="item-no-subitems">El equipo aún no tiene usuarios</div>
          )}
          {team.users.map((user: any) => (
            <TreeUserItem
              key={user._id}
              user={user}
              onSelectItem={onSelectItem}
            />
          ))}
        </>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default TreeTeamItem;
