
import React from "react";

import { useAuth } from "../../../Hooks/UseAuth";
import { useChat } from "../../../Hooks/UseChat";

interface ChatUserItemProps {
  user: any;
  onSelectItem: () => void;
}

const TreeUserItem: React.FC<ChatUserItemProps> = ({ user, onSelectItem }) => {

  const { userData } = useAuth()

  const { joinChat } = useChat();

  const handleClick = () => {

    joinChat([ 'private', user._id ]);
    onSelectItem();
  }

  if (userData?.id === user._id) return null

  return (
    <div className="chat-projects-tree-item">
      <div className="no-toggle cursor-pointer" onClick={() => handleClick()}>
        <div className="texts cursor-pointer">
          <span className="icon-usuario"></span>
          <span className='item-name'>{user.name} {user.surname}</span>
        </div>
      </div>
    </div>
  )
}

export default TreeUserItem;
