
export const cleanLookahead = () => {

  return {
    type: 'LOOKAHEAD_CLEANUP'
  }
}

export const regenerateLookahead = () => {

  return {
    type: 'LOOKAHEAD_LOAD_NONE'
  }
}

export const setLookaheadAction = (result: any[], start: string, end: string, week: number, collapsedGroups: string[]) => {

  return {
    type: 'LOOKAHEAD_LOAD_SUCCESS',
    payload: {
      result,
      start,
      end,
      week,
      collapsedGroups,
    }
  }
}

export const setLookaheadCollapsedGroupsAction = (collapsedGroups: string[]) => {

  return {
    type: 'LOOKAHEAD_SET_COLLAPSED_GROUPS',
    payload: {
      collapsedGroups,
    }
  }
}

export const setLookeaheadGanttItemsAction = (ganttItems: any[]) => {

  return {
    type: 'LOOKAHEAD_SET_GANTT_ITEMS',
    payload: {
      ganttItems
    }
  }
}
