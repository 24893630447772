
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import moment from "moment";

import config from "../../../config";

import { createExcelFile } from "../../../Helpers/ExcelHelper";

import { getUsers, getUsersToExport, saveUser, sendConfirmationEmail } from "../../../Services/AdminUsersService";

import AdminLayout from "../../../Layouts/AdminLayout/AdminLayout";

import Icon from "../../../Components/Icon/Icon";
import UserEditor from "./Components/UserEditor/UserEditor";

const defRow = {
  name: '',
  surname: '',
  email: '',
  verified: false,
  phone: '',
  isAdmin: false,
}

const AdminUsersPage = () => {

  const [ users, setUsers ] = useState<any[]>([])
  const [ pagination, setPagination ] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })
  const [ paginationItems, setPaginationItems ] = useState<any[]>([])
  const [ criteria, setCriteria ] = useState<any>('')
  const [ showEditor, setShowEditor ] = useState(false)

  const [ row, setRow ] = useState<any>(defRow)

  const [ emailsConfirmationSend, setEmailsConfirmationSend ] = useState<any>({
    sending: [],
    sent: [],
  })

  const handleSave = async (row: any) => {

    const { data, error } = await saveUser(row)

    if (error) {
      console.log(error)
      return
    }

    const newUser = users.find((plan: any) => plan._id === data._id)

    if (!newUser) {
      setUsers([ ...users, data ])
    } else {

      const newUsers = users.map((plan: any) => {
        if (plan._id === data._id) {
          return data
        }

        return plan
      })

      setUsers(newUsers)
    }

    setRow(defRow)

    setShowEditor(false)
  }

  const handleCancel = async () => {

    setRow(defRow)

    setShowEditor(false)
  }

  const loadUsers = async () => {

    const { data, total }  = await getUsers(criteria, pagination.page - 1, pagination.limit)

    setUsers(data)
    setPagination({ ...pagination, total })
  }

  const handleExport = async () => {

    const { data, error } = await getUsersToExport(criteria)

    if (error) {
      console.log(error)
      return
    }

    const sheetData = data.map((user: any, i: number) => {

      return {
        '#': `${i + 1}`,
        'Nombres': user.name,
        'Apellidos': user.surname,
        'Email': user.email,
        'Teléfono': user.phone,
        'Fec. Registro': moment(user.createdAt).format('DD/MM/YYYY H:mm a'),
        'Verificado': user.verified ? 'Si' : 'No',
      }
    })

    createExcelFile(sheetData, {
      filename: 'usuarios.xlsx',
      adjustColumnsWidth: true,
    })
  }

  const handleSendConfirmationEmail = async (user_id: any) => {

    setEmailsConfirmationSend({
      ...emailsConfirmationSend,
      sending: [ ...emailsConfirmationSend.sending, user_id ],
    })

    const response = await sendConfirmationEmail(user_id)

    if (typeof response === 'object' && 'error' in response) {
      console.log(response.error)
      return
    }

    setEmailsConfirmationSend({
      sending: emailsConfirmationSend.sending.filter((id: any) => id !== user_id),
      sent: [ ...emailsConfirmationSend.sent, user_id ],
    })
  }

  useEffect(() => {

    loadUsers()
  }, [ pagination.page ])

  useEffect(() => {

    const getPaginationItems = () => {

      const items = []

      const pages = Math.ceil(pagination.total / pagination.limit)

      for (let i = 1; i <= pages; i++) {
        items.push(
          <Pagination.Item key={i} active={i === pagination.page} onClick={() => setPagination({ ...pagination, page: i })}>
            {i}
          </Pagination.Item>,
        )
      }

      return setPaginationItems(items)
    }

    getPaginationItems()
  }, [ pagination.total, pagination.page ])

  return (
    <>
      <AdminLayout title="Usuarios" menu="users" submenu="list">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between pb-2">
                  <Card.Title className="">Usuarios</Card.Title>
                  <div className="btn-toolbar">
                    <button className="btn btn-normal mr-1" onClick={() => handleExport()}>
                      <Icon name="download" fas /> Exportar
                    </button>
                    <InputGroup>
                      <Form.Control
                        placeholder="Buscar..."
                        value={criteria}
                        onChange={(e: any) => setCriteria(e.target.value)}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            loadUsers();
                          }
                        }}
                      />
                      <InputGroup.Append>
                        <button className="btn btn-normal"
                          onClick={() => loadUsers()}
                        >
                          <i className="fa fa-fw fa-search"></i>
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: 40 }}>#</th>
                        <th scope="col" style={{ width: 60 }}>Avatar</th>
                        <th scope="col">Nombres y apellidos / Email</th>
                        <th scope="col" style={{ width: 130 }}>Teléfono</th>
                        <th scope="col" style={{ width: 130 }}>Fec. Registro</th>
                        <th scope="col" style={{ width: 260 }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user: any, i: number) => (
                        <tr key={i}>
                          <td>{((pagination.page - 1) * pagination.limit) + i + 1}</td>
                          <td>
                            {user.avatar && (
                              <img src={config.apiHost + '/' + user.avatar} alt={user.name} className="rounded-circle" />
                            )}
                          </td>
                          <td>
                            <strong className={user.verified ? 'text-primary' : 'text-secondary'}>
                              {user.name} {user.surname} {' '}
                            </strong>
                            {user.isAdmin && (
                              <span className="badge badge-warning mr-1" title="Administrador">
                                <Icon name="crown" fas />
                              </span>

                            )}
                            {user.verified && (
                              <span className="badge badge-primary" title="Verificado">
                                <Icon name="check" fas />
                              </span>
                            )}
                            <div>
                              {user.email}
                            </div>
                            <div>
                              {!user.verified && (
                                <>
                                  <button
                                    className="btn btn-link p-0 text-danger"
                                    onClick={() => handleSendConfirmationEmail(user._id)}
                                    disabled={emailsConfirmationSend.sending.includes(user._id) || emailsConfirmationSend.sent.includes(user._id)}
                                  >
                                    {!emailsConfirmationSend.sending.includes(user._id) && !emailsConfirmationSend.sent.includes(user._id) && (
                                      <>
                                        <Icon name="envelope" fas /> Enviar email de confirmación
                                      </>
                                    )}
                                    {emailsConfirmationSend.sending.includes(user._id) && (
                                      <>
                                        <Icon name="spinner" fas spin /> Enviando ...
                                      </>
                                    )}
                                    {emailsConfirmationSend.sent.includes(user._id) && (
                                      <>
                                        <Icon name="check" fas /> Enviado
                                      </>
                                    )}
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                          <td align="right">
                            {user.phone}
                          </td>
                          <td>
                            {moment(user.createdAt).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            <button className="btn btn-normal mr-1"
                              onClick={() => {
                                setRow(user)
                                setShowEditor(true)
                              }}
                            >
                              <i className="fas fa-fw fa-edit"></i> Editar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="table-responsive">
                  <Pagination>
                    {paginationItems}
                  </Pagination>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AdminLayout>

      <UserEditor
        show={showEditor}
        row={row}
        onHide={() => handleCancel()}
        onSave={(row) => handleSave(row)}
      />
    </>
  )
}

export default AdminUsersPage;
